import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import React, {MouseEvent } from "react";
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    [x: string]: any;
    application_group_name:string
  };
}

  
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  selectedFile:any;
  openDialog: boolean;
  AlertMessage: string;
  industryData1: IndustryItem[];
  anchorEl: HTMLElement | null;
  totalResults1: number;
  resultperPage1 : number;
  currentPage: number;
  searchText: string;
  imageUrl:any;
  industry: string;
  selectedIndustryId:string;
  selectedIndustryName:string;
  industryname1:any;
  applicationGroup:any
  IndustryId:any;
  dialogMode:string;
  token: string;
  alertVisible: boolean;
  value: number | 0;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addApplicationApiCallId: any;
  getApplicationApiCallId: any;
  editApplicationApiCallId:any;
  deleteApplicationApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      applicationGroup:"",
      industryData1: [],
      IndustryId:0,
      dialogMode: "",
      imageUrl:"",
      AlertMessage: "",
      alertVisible: false,
      openDialog: false,
      anchorEl: null,
      selectedFile:"",
      industry: "",
      selectedIndustryId:"",
      selectedIndustryName:"",
      industryname1:"Application",
      token: "",
      searchText: '',
      totalResults1: 0, 
      resultperPage1: 12, 
      currentPage: 1 ,
      value: 0,
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    const Industry=localStorage.getItem('industryname')
    this.setState({industryname1:Industry})
    const applicationGroup=localStorage.getItem('application_group');
    this.setState({applicationGroup:applicationGroup});
    this.getApplication();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
        if (apiRequestCallId === this.addApplicationApiCallId) {
          this.handleAddApplicationResponse(responseJson);
        } 
        else if (apiRequestCallId === this.getApplicationApiCallId) {
          this.handleGetApplicationResponse(responseJson);
        }
        else if (apiRequestCallId === this.editApplicationApiCallId) {
          this.handleEditApplicationResponse(responseJson);
        }
        else if (apiRequestCallId === this.deleteApplicationApiCallId) {
          this.handleDeleteApplicationResponse(responseJson);
        }
      }
  }

  handleEditApplicationResponse(responseJson: any) {
    if (responseJson?.product?.data) {
      this.setState({ 
        openDialog: false,
        anchorEl: null,
        industry: "",
      });
      this.getApplication(); 
      this.showAlert(
        configJSON.successTitle,
        configJSON.successEditIndustry
      );
    } else {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorEditIndustry
      );
    }
  }
  handleToggleSidebar = () => { }
  
  handleDeleteApplicationResponse(responseJson: any) {
      this.setState({ 
        openDialog: false, 
        anchorEl: null,
        industry:"",
      });
      this.getApplication(); 
    
  }
  handleAddApplicationResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ 
        industryData1: responseJson?.data, 
        openDialog: false, 
        industry: "" 
      });
      this.getApplication(); 
    }
  }
  
  handleGetApplicationResponse(responseJson: any) {
    if (responseJson?.data) {
      const { count, items, page } = responseJson.meta?.pagy || {}; 
      console.log(responseJson?.data,"hello shyam")
    
      this.setState({
        industryData1: responseJson.data, 
        totalResults1: count || 0,       
        resultperPage1: items || 12,    
        currentPage: page || 1,       
      });
    }
  }
  handleMenuClick = (event: MouseEvent<HTMLElement>,selectedIndustryId:string,selectedIndustryName:string) => {
    this.setState({ anchorEl: event.currentTarget, IndustryId:selectedIndustryId,industry: selectedIndustryName});

  };

  handleMenuCloseApplication = (): void => {
    this.setState({ anchorEl: null });
  };

  handleRowClick =(path :string, item:any)=>{
    localStorage.setItem("application_name",item.attributes.application_name)
    const fullPath = `${path}?id=${item.id}`;
    console.log(fullPath, "Navigating to");
    window.location.href = fullPath;

  }

  navigateToo = (path:string)=> {
    window.location.href = path;
    }

  handlePageChangeApplication = (event: React.ChangeEvent<unknown> | null, value: number) => {
    this.setState({ currentPage: value });
    this.getApplication();
  };

  getResultTextApplication = (page: number): string => {
    const { resultperPage1, totalResults1 } = this.state;
    const start = (page - 1) * resultperPage1 + 1;
    const end = Math.min(start + resultperPage1 - 1, totalResults1);
    return `${start}-${end} of ${totalResults1} results`;
  }


  handleComOpens = (mode: string ) => {
    

    this.setState({
      openDialog: true,
      dialogMode: mode, 
     
    });
    
  };
  
  handleCloses = () => {
    this.setState({
      openDialog: false,
      anchorEl: null,
      dialogMode: "",
      AlertMessage: "",
      alertVisible: false,
      industry:"",
    });
  };
  handleIndustryMarket = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value
    this.setState({ industry: name })
  }

  handleNavigateApplication = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)

  }
  addNewApplication = async () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");   
    const formData = new FormData();

    formData.append('product_application[application_name]',this.state.industry);
   
    formData.append('product_application[application_group_id]', id || '');
         
      const header = {
        token: await getStorageData('authToken'),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addApplicationApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applicationAddAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  getApplication = async () => {
   
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    
    const header = {
      "Content-Type": configJSON.industryApiContentType,
      token: await getStorageData("authToken"),
    };

    const queryParams = `?id=${id}&page=${this.state.currentPage}&items=${this.state.resultperPage1}`;
    const apiEndpoint = `${configJSON.applicationGetAPIEndPoint}${queryParams}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getApplicationApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  editApplication = async () => {
  
    const formData = new FormData();

    formData.append('product_application[application_name]',this.state.industry);    

      const header = {
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.applicationEditAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editApplicationApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpUpdateType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };

  deleteApplication = async () => {
      const header = {
        "Content-Type": configJSON.industryApiContentType,
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.applicationDeleteAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteApplicationApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };
  
  
   
  // Customizable Area End
}
