import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  history: any,
  navigation:any
  // Customizable Area End
}

interface S {
//   txtInputValue: string;
//   txtSavedValue: string;
//   enableField: boolean;
    // Customizable Area Start
    sidebarToggle:boolean;
    openDialog: string;
    activeComponent: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserCategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCompanyList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
    //   txtInputValue: "",
    //   txtSavedValue: "A",
    //   enableField: false,
      
            // Customizable Area Start
            sidebarToggle:false,
            openDialog:"Group Name",
            activeComponent: "Company"
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    const { pathname } = this.props.history.location;
    const lastPathSegment = pathname.split('/').pop();
    if (lastPathSegment === "Project") {
      this.setState({activeComponent:"Project"});
    } else {
      this.setState({activeComponent:"Company"});
    }
  }

  // Customizable Area Start

  handleToggle = (menu: string) => {
    this.setState({ openDialog: menu});
  };
  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleSidebarItemClick = (componentName: string) => {
    this.setState({ activeComponent: componentName });
    this.props.history.push(`/UserCategories/${componentName}`)
  }
  handleToggleSidebar = () => {     
    this.setState({ sidebarToggle: !this.state.sidebarToggle});}
   
  // Customizable Area End
}
