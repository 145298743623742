import React from "react";
// Customizable Area Start
import { Box, TableContainer, Typography, Table, TableHead, TableBody, TableRow, TableCell, Radio, Checkbox, Modal } from "@material-ui/core";

import QuotationComparisionController, { Props } from "./QuotationComparisionController";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { acceptIcon, negotiateIcon, rejectIcon } from "./assets";
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Done from "@material-ui/icons/Done";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Button } from "@mui/material";


// Customizable Area End
export default class QuotationComparision extends QuotationComparisionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  Comparisiontable = () => {
    const labelData = [
      { text: "KEI Cable" },
      { text: "RR Cable" },
      { text: "POLYCAB" },
      { text: "Bosch" },
    ];

    return (
      <Box style={{ ...styles.Maincontainer, width: "94%" }}>
        <Box style={styles.header}>
          <Typography variant="h6" component="div" style={styles.heading}>
            QID52421458
          </Typography>
        </Box>
        <Box style={{ width: "1500px" }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #E7E8EF' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ ...styles.Brandname, fontFamily: "Inter, sans-serif", padding: '8px 0px 8px 17px' }}>
                <span style={{ margin: "auto 0px", fontFamily: "Inter, sans-serif" }}>Brand Name</span>
              </div>
              {(() => {
                const displayedBrands = new Set();

                return this.state.quatationData?.attributes?.quotation_items?.items.map((item: any, index: number) => {
                  if (displayedBrands.has(item.brand_name)) {
                    return null;
                  }

                  displayedBrands.add(item.brand_name);

                  return (
                    <div key={index} style={{ flex: 1, textAlign: "center", backgroundColor: this.state.setSelectedValue === item.brand_name ? "#FFF5EC" : "transparent", padding: '8px 0px 8px 17px'}}>
                      <div style={{
                        flex: 1,
                        textAlign: "center",
                        padding: '8px 0px 8px 17px',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: this.state.setSelectedValue === item.brand_name ? "#FFF5EC" : "transparent",
                      }}>
                        <span style={{
                          fontFamily: "Inter, sans-serif",
                          fontWeight: "bold",
                          color: this.state.setSelectedValue === item.brand_name ? "#FF8533" : "#333",
                        }}>{item.brand_name}</span>

                        <Radio
                          data-testId="Radiobutton"
                          checked={this.state.setSelectedValue === item.brand_name}
                          onChange={() => this.handleChange(item.brand_name)}
                          icon={<RadioButtonUncheckedIcon style={{ fontSize: 24, color: "gray" }} />}
                          checkedIcon={<CheckCircleIcon style={{ fontSize: 24, color: "#FF8533" }} />}
                        />
                      </div>
                    </div>
                  );
                });
              })()}
            </div>
          </div>

          <TableContainer style={{ overflowX: 'initial' }}>
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ ...styles.maintcell, width: "150px", borderTop: "none", fontFamily: "Inter, sans-serif" }}>Product Name</TableCell>
                  <TableCell style={{ ...styles.maintcell, width: "70px", borderTop: "none", fontFamily: "Inter, sans-serif" }}>Qty</TableCell>
                  {(() => {
                    const displayedBrands = new Set();

                    return this.state.quatationData?.attributes?.quotation_items?.items.flatMap((item: any, index: number) => {
                      if (displayedBrands.has(item.brand_name)) {
                        return [];
                      }

                      displayedBrands.add(item.brand_name);

                      return [
                        <TableCell key={`rate-${index}`} style={{ ...styles.maintcell, borderTop: "none", fontFamily: "Inter, sans-serif" }}>Rate</TableCell>,
                        <TableCell key={`amount-${index}`} style={{ ...styles.maintcell, borderTop: "none", fontFamily: "Inter, sans-serif" }}>Amount</TableCell>
                      ];
                    });
                  })()}

                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.quatationData?.attributes?.quotation_items?.items?.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell style={{ ...styles.CellHeight, display: 'flex', alignItems: 'center', justifyContent: 'left', height: '60px', backgroundColor: this.state.checkedItems[index] ? "#FFF5EC" : "transparent" }}>

                      <label className="custom-checkbox" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                        <Checkbox
                          key={index}
                          data-testid="CheckBox"
                          checked={this.state.checkedItems[index] || false}
                          onChange={() => this.handleCheckboxChange(index, item.item_id)}
                          style={{
                            color: this.state.checkedItems[index] ? '#FF8A3C' : '#F3F4F6',
                          }}
                        />
                        <span style={styles.label}>{item.product_name}</span>
                        {this.state.isChecked && (
                          <CheckIcon
                            style={{
                              position: 'absolute',
                              top: '4px',
                              left: '5px',
                              fontSize: '16px',
                              color: '#fff',
                            }}
                          />
                        )}
                      </label>
                    </TableCell>
                    <TableCell align='center' style={{ ...styles.CellHeight, fontFamily: "Inter, sans-serif", height: '60px' }}>{item.quantity}</TableCell>
                    <TableCell style={{ ...styles.CellHeight1, fontFamily: "Inter, sans-serif", height: '60px', backgroundColor: this.state.checkedItems[index] ? "#FFF5EC" : "transparent" }}>{item.rate}</TableCell>
                    <TableCell style={{ ...styles.CellHeight, fontFamily: "Inter, sans-serif", height: '60px', backgroundColor: this.state.checkedItems[index] ? "#FFF5EC" : "transparent" }}>{item.amount}</TableCell>

                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Subtotal
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontWeight: 700,
                      fontFamily: "Inter, sans-serif",
                      textAlign: "end",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.sub_total}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Taxes
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontFamily: "Inter, sans-serif",
                      textAlign: "end",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.taxes}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Transport
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontFamily: "Inter, sans-serif",
                      textAlign: "end",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.transport}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Loading/Unloading
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontFamily: "Inter, sans-serif",
                      textAlign: "end",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.loading_uploading}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Total Cost
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontWeight: 700,
                      fontFamily: "Inter, sans-serif",
                      textAlign: "end",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.total_cost}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Payment Terms
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontWeight: 700,
                      fontFamily: "Inter, sans-serif",
                      textAlign: "center",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.peyment_terms}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} style={{ ...styles.colspanhead, fontFamily: "Inter, sans-serif", height: '60px' }}>
                    Validity of Quotation
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{
                      ...styles.firstother,
                      height: '60px',
                      fontWeight: 700,
                      fontFamily: "Inter, sans-serif",
                      textAlign: "center",
                    }}>
                    {this.state.quatationData?.attributes?.quotation_items?.validaty_of_quotation}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <Box style={{ ...styles.note, fontFamily: "Inter, sans-serif", padding: '10px 10px 10px 10px' }}>Note:</Box>
          </Box>
        </Box>
        <div style={{ display: "flex", marginTop: "40px", justifyContent: "right" }}>
          <div style={{ marginLeft: "17px" }}><img src={negotiateIcon} data-testId="Negotiatebutton" alt="negotiate" onClick={() => this.handlepopup(this.state.quatationData?.id)} style={{ cursor: "pointer" }} />
            <Modal open={this.state.open} onClose={this.handleclose}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "509px",
                  height: "266px",
                  backgroundColor: "background.paper",
                  boxShadow: "4",
                  background: "white",
                  padding: 3,
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              ><Box style={{ marginTop: "50px" }}>
                  <IconButton
                    onClick={this.handleclose}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div style={{
                    position: "relative",
                    width: 64,
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "225px"
                  }}>

                    <div style={{
                      position: "absolute",
                      width: 60,
                      height: 60,
                      backgroundColor: "#FFEFE4",
                      borderRadius: "50%",
                    }}></div>

                    <div style={{
                      position: "absolute",
                      width: 40,
                      height: 40,
                      backgroundColor: "#EE6900",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      <Done style={{ color: "white", fontSize: 24 }} />
                    </div>
                  </div>
                  <Typography variant="h6" style={{ marginTop: "20px", fontSize: "24px", fontWeight: "bolder" }}>
                    Negotiation Request Sent
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: "8px", width: "70%", marginLeft: "75px", color: "gray" }}>
                    Negotiation request has been sent for QID52421458.
                    You will get notified once vendor updates the price.
                  </Typography>
                </Box>
              </Box>
            </Modal>
          </div>
          <div style={{ marginLeft: "17px" }}><img src={rejectIcon} onClick={this.handlepopups} data-testId="Rejectbutton" alt="reject" />
            <Modal open={this.state.opens} onClose={this.handlecloses}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "600px",
                  backgroundColor: "white",
                  boxShadow: "24",
                  padding: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  height: "362px"
                }}
              >
                <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center", borderBottom: "1px solid #E2E8F0" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                    Reject
                  </Typography>
                  <IconButton
                    onClick={this.handlecloses}
                    style={{ marginRight: "20px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Typography variant="body1" style={{ marginTop: "10px", color: "gray", marginLeft: "20px", textAlign: "initial", width: "85%" }}>
                  Are you sure you want to reject all quotations for{" "}
                  <strong>EST-240218-0010-2?</strong> Once rejected, this quotation ID
                  wont be available for further actions.
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding:"20px",
                    height:"auto",
                    backgroundColor: "#f8f9fa",
                    width: "80%",
                    borderRadius: 1,

                  }}
                >
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Quotation ID
                    </Typography>
                    <Typography variant="body2" >
                      QID52421458
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Rejected Vendors
                    </Typography>
                    <Typography variant="body2" >
                      04
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Quantity
                    </Typography>
                    {this.state.quatationData?.attributes?.quotation_items?.items?.length > 0 ? (
                      this.state.quatationData.attributes.quotation_items.items.map((item: any, index: number) => (
                        <Typography variant="body2" key={index}>
                          {item.quantity}
                        </Typography>
                      ))
                    ) : (
                      <Typography>No items available</Typography>
                    )}
                  </Box>
                </Box>
                <Box style={{ height: "104px", display: "flex", justifyContent: "end",marginTop:"26px", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                  <img src={rejectIcon} style={{ marginRight: "20px" }} onClick={() => this.handleReject(this.state.quatationData?.id)} data-testId="Rejectbutton" alt="reject" />
                </Box>
              </Box>
            </Modal>
          </div>
          <div style={{ marginLeft: "17px" }}><img src={acceptIcon} onClick={this.handleAcpopups} data-testId="Acceptbutton" alt="accept" />
            <Modal open={this.state.openAc} onClose={this.handleAccloses}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "600px",
                  backgroundColor: "white",
                  boxShadow: "24",
                  padding: 3,
                  borderRadius: 2,
                  textAlign: "center",
                  height: "362px"
                }}
              >
                <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center", borderBottom: "1px solid #E2E8F0" }}>
                  <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                    Accept
                  </Typography>
                  <IconButton
                    onClick={this.handleAccloses}
                    style={{ marginRight: "20px" }}
                  >
                    <CloseIcon />
                  </IconButton>

                 

                </Box>

                <Typography variant="body1" style={{ marginTop: "10px", color: "gray", marginLeft: "20px", textAlign: "initial", width: "85%" }}>
                  Are you sure want to accept partial quotations from{" "}
                  <strong>KEI Cable vendor?</strong> Once
                  accepted quotation will be sent for PO generation.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                    p: 2,
                    bgcolor: "#f8f9fa",
                    width: "80%",
                    borderRadius: 1,
                    height:"auto"
                  }}
                >
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Vendor Name
                    </Typography>
                    {this.state.quatationData?.attributes?.quotation_items?.items?.length > 0 ? (
                      this.state.quatationData.attributes.quotation_items.items.map((item: any, index: number) => (
                        <Typography variant="body2" key={index}>
                          {item.brand_name}
                        </Typography>
                      ))
                    ) : (
                      <Typography>No items available</Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Accepted Items
                    </Typography>
                 
                        <Typography variant="body2">
                        {this.state.quatationData?.attributes?.quotation_items?.items?.length}
                        </Typography>
                     
                  </Box>
                  <Box>
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Quantity
                    </Typography>
                    {this.state.quatationData?.attributes?.quotation_items?.items?.length > 0 ? (
                      this.state.quatationData.attributes.quotation_items.items.map((item: any, index: number) => (
                        <Typography variant="body2" key={index}>
                          {item.quantity}
                        </Typography>
                      ))
                    ) : (
                      <Typography>No items available</Typography>
                    )}

                  </Box>
                </Box>
                <Box style={{ height: "104px", display: "flex", justifyContent: "end",marginTop:"30px", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                  <img src={acceptIcon} style={{ marginRight: "20px" }} onClick={() => this.acceptQuotationComparisonItems(this.state.quatationData?.id, "partially_converted")} data-testId="Rejectbutton" alt="reject" />
                </Box>
              </Box>
            </Modal>
          </div>

          <Modal open={this.state.openss} onClose={this.handleActcloses}>
            <Box
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "600px",
                backgroundColor: "white",
                boxShadow: "24",
                padding: 3,
                borderRadius: "8px",
                textAlign: "center",
                height: "590px",
                fontFamily:"Inter, sans-serif"

              }}
            >
              <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center",fontFamily:"Inter, sans-serif", borderBottom: "1px solid #E2E8F0" }}>
                <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                  Add Details
                </Typography>
                <IconButton
                  onClick={this.handleActcloses}
                  style={{ marginRight: "20px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography style={{ marginLeft: "20px", display: "flex",fontFamily:"Inter, sans-serif", justifyContent: "space-between", height: "40px", alignItems: "center", }}>
                Please and natoes and Terms & Conditions,
              </Typography>

              <Typography style={{ marginLeft: "20px", display: "flex",fontFamily:"Inter, sans-serif", justifyContent: "space-between",fontWeight:"bolder", height: "30px", alignItems: "center", }}>
                Notes
              </Typography>
              <Box style={{backgroundColor:"#F3F4F6",borderRadius:"8px",fontFamily:"Inter, sans-serif",height:"140px",width:"552px",display:"flex",marginLeft:"20px"}}>
              <Typography variant="body1" style={{ marginTop: "5px", color: "black", marginLeft: "10px", textAlign: "initial", width: "528px",height:"100px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing erit. Integer malesuada condimentum odio vitae malesuada. Proin quis ligula eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                Nullam molis odio sem, vel lacinia massa
              </Typography>
              </Box>

              <Typography style={{ marginLeft: "20px",fontFamily:"Inter, sans-serif",fontWeight:"bolder", display: "flex", justifyContent: "space-between", marginTop: "10px", height: "30px", alignItems: "center", }}>
                Terms and Conditions
              </Typography>
              <Box style={{backgroundColor:"#F3F4F6",borderRadius:"8px",height:"140px",width:"552px",fontFamily:"Inter, sans-serif",display:"flex",marginLeft:"20px"}}>

              <Typography variant="body1" style={{ marginTop: "5px", color: "black", marginLeft: "10px", textAlign: "initial", width: "528px",height:"100px" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing erit. Integer malesuada condimentum odio vitae malesuada. Proin quis ligula eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                Nullam molis odio sem, vel lacinia massa
              </Typography>
              </Box>


              <Box style={{ height: "90px",fontFamily:"Inter, sans-serif",marginTop:"30px", display: "flex", justifyContent: "end", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                <Button onClick={this.handleSuccesspopups} variant="contained" size="large" style={{ marginRight: "20px",fontWeight:"bold", width:"120px",height:"44px", backgroundColor: "#FD8C3F", borderRadius: "12px" }} >
                  Add
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={this.state.openssc} onClose={this.handleSuccesscloses}>
              <Box
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "509px",
                  height: "266px",
                  backgroundColor: "background.paper",
                  boxShadow: "4",
                  background: "white",
                  padding: 3,
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              ><Box style={{ marginTop: "50px" }}>
                  <IconButton
                    onClick={this.handleSuccesscloses}
                    sx={{ position: "absolute", top: 8, right: 8 }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div style={{
                    position: "relative",
                    width: 64,
                    height: 64,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "225px"
                  }}>

                    <div style={{
                      position: "absolute",
                      width: 60,
                      height: 60,
                      backgroundColor: "#FFEFE4",
                      borderRadius: "50%",
                    }}></div>

                    <div style={{
                      position: "absolute",
                      width: 40,
                      height: 40,
                      backgroundColor: "#EE6900",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                      <Done style={{ color: "white", fontSize: 24 }} />
                    </div>
                  </div>
                  <Typography variant="h6" style={{ marginTop: "20px", fontSize: "24px", fontWeight: "bolder" }}>
                  Quotation Request Accepted 
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: "8px", width: "70%", marginLeft: "75px", color: "gray" }}>
                  Quotation request has been accepted for QID52421458 and PO is generated. 
                  Please submit relevant PO to proceed further. 
                  </Typography>
                </Box>
              </Box>
            </Modal>
        </div>


      </Box>

    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Navbar {...this.props} history={this.props.history} />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Comparisiontable()}
        </SideBar>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  header: {
    alignItems: "left",
    margin: "45px 0px",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  Maincontainer: {
    width: "94%",
    margin: "0px 20px 0px 45px",
    fontFamily: "Inter, sans-serif",
  },
  Brandname: {
    width: "268px",
    height: "57px",
    fontWeight: 700,
    fontSize: "14px",
    backgroundColor: "#F3F4F6",
    borderRight: "1px solid #E7E8EF",
    color: "#565E6C",
    alignContent: "center",
  },
  Kefname: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#64748B",
  },
  maintcell: {
    fontSize: "14px",
    fontWeight: 700,
    backgroundColor: "#F3F4F6",
    color: "#565E6C",
    border: "1px solid #E7E8EF",
    BorderTop: "none",
    height: "69px",
    width: '100%'
  },
  label: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842"
  },
  firstother: {
    fontSize: "14px",
    padding: '12px 24px 12px 20px',
    backgroundColor: "#F1F5F9",
    color: "#272727",
    lineHight: '22px',
    border: "1px solid #E7E8F0",
  },
  CellHeight: {
    lineHeight: "22px",
    padding: '12px 24px 12px 20px',

    border: "1px solid #E7E8EF",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  common: {
    fontSize: "14px",
    fontWeight: 700,
  },
  CellHeight1: {
    lineHeight: "22px",
    padding: '12px 24px 12px 20px',
    border: "1px solid #E7E8EF",
    fontSize: "14px",
    fontWeight: 400,
  },
  colspanhead: {
    lineHeight: "22px",
    padding: '12px 24px 12px 20px',
    fontSize: "14px",
    fontWeight: 700,
    color: "#272727",
    border: "1px solid #E7E8F0",
    backgroundColor: "#F0F0F0"
  },
  colspanhead1: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#272727",
    border: "1px solid #E7E8F0",
    lineHeight: "37px",
    backgroundColor: "#F3F4F6"
  },
  note: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#272727",
    border: "1px solid #E7E8F0",
    borderTop: "none",
    lineHeight: "50px",
    backgroundColor: "#F3F4F6",
    height: "40px"
  },
};

function setSelectedValue(value: any) {
  throw new Error("Function not implemented.");
}
// Customizable Area End
