import React, { } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, IconButton, Select, Input, MenuItem, Hidden } from '@material-ui/core';
import { styled } from "@material-ui/styles";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import { Message } from "../../../packages/framework/src/Message";
import {  homeIcon, notificationIcon } from '../../../packages/blocks/navigationmenu/src/assets';


import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { Menu } from '@material-ui/icons';

import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
type S = { showDropDownCountry: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory: string, isSubMenuOpen: boolean, token: any, catData: any, catAllData: any, subCat: any, selectedItem: any, dropAccount: boolean, showCartData: boolean };
interface Props {
  handleNavigate?: () => void;
  handleToggleSidebar?: () => void;
  history?: any
}


const NavigationBlock = styled("div")(({ theme }) => ({
  // height:"76px",
  fontFamily: 'Inter, sans-serif',
  "& .MuiTypography-body1": {
    fontFamily: 'Inter, sans-serif',
  },
  '& .logo': {
    width: "92px",
    height: "48px",
    cursor: 'pointer',
    margin: "auto 0",
    '@media (max-width: 600px)': {
      width: "50px",
      height: "30px",
      marginLeft: "-20px",
      marginRight: "0px",
    }
  },
  '& .dropdown-list': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "10px"
  },
  '& .location-icon': {
    width: "20px",
    height: "22px"
  },
  '& .down-icon': {
    cursor: "pointer",
    margin: "1px"
  },
  "& .dropdown-list-data": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "45px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    left: "156px",
    width: "162px",
    '@media (max-width: 600px)': {
      left: "70px !important",
    },
    '@media (max-width: 900px)': {
      left: "120px",
    }
  },
  "& .dropdown-list-data li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "400",
    color: "#FAFAFA",
    padding: " 10px 0",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .dropdwon-box": {
    position: "relative",
    "& .dropdown-wrapper": {
      position: "absolute"
    }
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item .selected": {
    backgroundColor: "#FFE0CC",
    color: "black",
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px"
  },
  "& .search-icon": {
    width: "24px",
    height: "24px"
  },
  "& .upload-btn": {
    width: "142px",
    height: "44px",
    margin: "22px 28px",
    borderRadius: "8px",
    border: "1px solid #64748B",
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    '@media (max-width: 600px)': {
      width: "10px",
      height: "32px",
      margin: "auto 10px",
      marginTop: "9px"
    }
  },
  "& .user-data": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "47px",
    gap: "10px",
    marginBottom: "11px"
  },
  "& .dropdown-list-account": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "60px",
    right: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "162px",
  },
  "& .dropdown-list-account li": {
    padding: "0px 20px",
    margin: "15px 0px",
    gap: "16px"
  },
  "& .dropdown-list-account li:last-of-type a": {
    border: "none",
  },
  "& .cart-main": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "69px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "497px",
    height: "361px",
    right: "10px"
  },
  "& .close-btn": {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "31px",
    marginRight: "34px",
  },
  "& .view-btn": {
    border: "1px solid #FF6600",
    width: "405px",
    height: "50px",
    borderRadius: "10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  "& .input-step": {
    display: "inline-flex",
    width: "90px",
    overflow: "visible",
    padding: "4px",
    border: "1px solid #94A3B8",
    height: "30px",
    borderRadius: "8px"
  },
  "& .input-step input": {
    background: "#0000",
    border: "0",
    color: "black",
    height: "100%",
    textAlign: "center",
    width: "4em"
  }
}
))
interface SS {
  id: any;
}
class UserNavbar extends BlockComponent<Props, S, SS> {
  getCatList: string = "";
  private dropdownRef = React.createRef<HTMLUListElement>();
  private boxRef = React.createRef<HTMLDivElement>();
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      showDropDownCountry: false,
      showDropDownLanguage: false,
      scrollPosition: 0,
      showDropDownCategori: false,
      selectedCategory: "",
      isSubMenuOpen: false,
      token: "",
      catData: [],
      catAllData: [],
      subCat: "",
      selectedItem: "",
      dropAccount: false,
      showCartData: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token })
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: any): void {

  }

  handleDropdownClick = (label: any) => {
    const subCategories = label.sub_categories.map((subCategory: any) => subCategory.name);

    this.setState((prevState: any) => ({
      showDropDownCategori: true,
      isSubMenuOpen: !prevState.isSubMenuOpen,
      subCat: subCategories,
      selectedItem: label.name
    }));
  };

  handlePagesClick = (label: any) => {
    if (label === "platform") {
      this.props.history.push("/Platform");
    }
    if (label === "materials") {
      this.props.history.push("/Price")
    }
    if (label === "news") {
      this.props.history.push("/News")
    }
    if (label === "logo") {
      this.props.history.push("/")
    }
  };

  handleAccountClick = (label: any) => {
    if (label === "Logout") {
      localStorage.removeItem('otp-token');
      localStorage.clear();
      if (this.props.history.location.pathname == '/') {
        this.props.history.push("/LandingPage")
      } else {
        this.props.history.push("/")
      }
    }
    if (label === "Dashboard") {
      window.location.href="/UserCategories";
    }
  };
  handleNavigatation = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleToggleSubMenu = () => {
    this.setState((prevState: any) => ({
      isSubMenuOpen: !prevState.isSubMenuOpen,
    }));
  };

  handleDropdownOpen = () => {
    this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
  }

  handleDropdownLanguageOpen = () => {
    this.setState({ showDropDownLanguage: !this.state.showDropDownLanguage })
  }

  handleAccountOpen = () => {
    this.setState({ dropAccount: !this.state.dropAccount })
  }

  handleDrodownCat = () => {
    this.setState({ showDropDownCategori: !this.state.showDropDownCategori })
  }
  handleOutsideClick = (event: MouseEvent) => {
    if (
      this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node) &&
      this.boxRef.current && !this.boxRef.current.contains(event.target as Node)
    ) {
      this.setState({ dropAccount: false });
    }
  };


  handleScroll = (scrollType: any) => {
    const container = document.getElementById('scroll-container');
    const scrollAmount = 200;
    const newPosition =
      scrollType === 'right'
        ? this.state.scrollPosition + scrollAmount
        : this.state.scrollPosition - scrollAmount;

    if (container) {
      container.scrollTo({
        top: 0,
        left: newPosition,
        behavior: 'smooth',
      });
    }

    this.setState({ scrollPosition: newPosition })
  };
  cartOpen = () => {
    this.setState({ showCartData: !this.state.showCartData })
  }

  render() {
    const menuItems = [
      { label: "India" },
      { label: "USA" },
      { label: "Canada" },
    ];

    const languageItems = [
      { label: "English" },
      { label: "Spanish" },
    ];

    const accountItems = [
      {
        label: "My Profile", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183726)">
              <path opacity="0.2" d="M127.998 172.286C165.08 172.286 195.141 142.225 195.141 105.143C195.141 68.0609 165.08 38 127.998 38C90.9164 38 60.8555 68.0609 60.8555 105.143C60.8555 142.225 90.9164 172.286 127.998 172.286Z" fill="black" />
              <path d="M127.998 172.286C165.08 172.286 195.141 142.225 195.141 105.143C195.141 68.0609 165.08 38 127.998 38C90.9164 38 60.8555 68.0609 60.8555 105.143C60.8555 142.225 90.9164 172.286 127.998 172.286Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M34 217.047C56.9964 189.686 89.46 172.285 128 172.285C166.54 172.285 199.004 189.686 222 217.047" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183726">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
      {
        label: "Dashboard", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183739)">
              <path opacity="0.2" d="M40 128H128V216H48C45.8783 216 43.8434 215.157 42.3431 213.657C40.8429 212.157 40 210.122 40 208V128Z" fill="black" />
              <path opacity="0.2" d="M128 40H208C210.122 40 212.157 40.8429 213.657 42.3431C215.157 43.8434 216 45.8783 216 48V128H128V40Z" fill="black" />
              <path d="M208 40H48C43.5817 40 40 43.5817 40 48V208C40 212.418 43.5817 216 48 216H208C212.418 216 216 212.418 216 208V48C216 43.5817 212.418 40 208 40Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M128 40V216" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M216 128H40" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183739">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>
      },
      {
        label: "Settings", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183691)">
              <path opacity="0.2" d="M230.101 108.76L198.251 90.62C197.611 89.46 196.941 88.33 196.251 87.21L196.131 51.21C186.065 42.7151 174.485 36.1976 162.001 32L130.001 49.89C128.661 49.89 127.311 49.89 126.001 49.89L94.0005 32C81.5197 36.2113 69.9467 42.7425 59.8905 51.25L59.7305 87.25C59.0305 88.37 58.3605 89.51 57.7305 90.66L25.8905 108.76C23.3802 121.457 23.3802 134.523 25.8905 147.22L57.7405 165.36C58.3805 166.52 59.0505 167.65 59.7405 168.77L59.8605 204.77C69.9277 213.273 81.5109 219.798 94.0005 224L126.001 206.13C127.34 206.13 128.691 206.13 130.001 206.13L162.001 224C174.471 219.785 186.033 213.254 196.081 204.75L196.241 168.75C196.941 167.63 197.611 166.49 198.241 165.34L230.081 147.24C232.6 134.538 232.607 121.465 230.101 108.76ZM128.001 168C120.089 168 112.356 165.654 105.778 161.259C99.1997 156.864 94.0728 150.616 91.0453 143.307C88.0178 135.998 87.2257 127.956 88.7691 120.196C90.3125 112.437 94.1221 105.31 99.7162 99.7157C105.31 94.1216 112.438 90.312 120.197 88.7686C127.956 87.2252 135.999 88.0173 143.308 91.0448C150.617 94.0723 156.864 99.1992 161.259 105.777C165.655 112.355 168.001 120.089 168.001 128C168.001 138.609 163.786 148.783 156.285 156.284C148.783 163.786 138.609 168 128.001 168Z" fill="black" />
              <path d="M128 168C150.091 168 168 150.091 168 128C168 105.909 150.091 88 128 88C105.909 88 88 105.909 88 128C88 150.091 105.909 168 128 168Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M130.048 206.11C128.708 206.11 127.358 206.11 126.048 206.11L93.9983 224C81.5216 219.803 69.949 213.289 59.8883 204.8L59.7683 168.8C59.0583 167.68 58.3883 166.55 57.7683 165.39L25.8983 147.24C23.3881 134.543 23.3881 121.477 25.8983 108.78L57.7383 90.68C58.3883 89.53 59.0583 88.39 59.7383 87.27L59.8983 51.27C69.95 42.7564 81.5195 36.2184 93.9983 32L125.998 49.89C127.338 49.89 128.688 49.89 129.998 49.89L161.998 32C174.475 36.1969 186.048 42.7109 196.108 51.2L196.228 87.2C196.938 88.32 197.608 89.45 198.228 90.61L230.078 108.75C232.589 121.447 232.589 134.513 230.078 147.21L198.238 165.31C197.588 166.46 196.918 167.6 196.238 168.72L196.078 204.72C186.033 213.235 174.471 219.776 161.998 224L130.048 206.11Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183691">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
      {
        label: "Logout", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183710)">
              <path opacity="0.2" d="M216 48H40C35.5817 48 32 51.5817 32 56V200C32 204.418 35.5817 208 40 208H216C220.418 208 224 204.418 224 200V56C224 51.5817 220.418 48 216 48Z" fill="black" />
              <path d="M176 104V136H80" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M104 112L80 136L104 160" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M216 48H40C35.5817 48 32 51.5817 32 56V200C32 204.418 35.5817 208 40 208H216C220.418 208 224 204.418 224 200V56C224 51.5817 220.418 48 216 48Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183710">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
    ];
    return (
      <>
        <NavigationBlock>
          <Box sx={webStyle.navbar}>
            <Box style={webStyle.navBox}>
              <MasterFinances >
                <IconButton onClick={this.props.handleToggleSidebar}>
                  <Menu />
                </IconButton>
              </MasterFinances>
              <img className="logo" src={require("./kx_logo.jpg")} alt="kxLogo" onClick={() => this.props.history.push('/LandingPage')} />
              
            </Box>
            <Box sx={webStyle.searchContent}>
                <div>
                <Select
                  labelId="Category"
                  defaultValue="" 
                  displayEmpty
                  inputProps={{ 'aria-label': 'Category' }}
                  disableUnderline
                >
                  <MenuItem value="" disabled>Category</MenuItem>
                </Select>
                <Input placeholder="Search the marketplace"  disableUnderline />
                </div>
                <img className='search-icon' src={require("./search.png")} alt="search" />
              </Box>

            <Box style={webStyle.navItems}>
              <Box sx={webStyle.naviconsContainer} >
                <img src={homeIcon} alt='button-home'  onClick={() => { this.handlePagesClick("logo") }} />
                <img src={notificationIcon} alt="btn-bell-fill" />
              </Box>
              <Box sx={webStyle.languageContainer} >
                <Box sx={webStyle.profileIconBox} onClick={this.handleAccountOpen}>

                  <span style={webStyle.profileIcon}>MG</span>
                </Box>
                <Box sx={webStyle.displayBox} onClick={this.handleAccountOpen}>
                  <Typography style={webStyle.logoText} >Mihir Group</Typography>
                </Box>
                <Hidden>
                      {this.state.dropAccount && (
                        <ul className="dropdown-list-account" ref={this.dropdownRef}>
                          {accountItems.map((item) => (
                            <li
                              key={item.label}
                              id="dropdowndata"
                              className={`dropdown-item ${this.state.selectedItem.label == item.label ? 'selected' : ''}`}
                              data-test-id="dropdowndata"
                              onClick={() => this.handleAccountClick(item.label)}
                            >
                              <span>{item.image}</span>
                              <span>{item.label}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Hidden>
              </Box>
            </Box>
          </Box>
        </NavigationBlock>
      </>
    )
  }
}
const MasterFinances = styled(Box)({
  display: "none !important",
  "@media (max-width: 600px)": {
    display: "block !important",
  },
  "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
    display: "block !important",
  },

});
const webStyle = {
  logoText: {
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    cursor: "pointer"
  },
  navBox: {
    display: "flex", gap: "20px",
    "@media (max-width: 600px)": {
      gap: "10px",
      width: "50vw",
    },

  },
  profileIconContainer: {
    height: "44px",
    width: '44px',
    borderRadius: "50%",
    bgcolor: "#FF6600",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width: 600px)': {
      width: "30px",
      height: "30px",
      padding: "3px"
    }
  },
  bulkOrder: {
    fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize: { xs: "12px", sm: "16px" }, minWidth: "max-content"
  },
  navbar: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 29px",
    height: "76px",
    borderBottom: "1px solid #E2E8F0",
    "@media (max-width:900px)": {
      justifyContent: "space-around",
      width: "90vw"
    },
  },
  languageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: "14px",
    "@media (max-width:900px)": {
      width: "13px",
      marginTop: "8px",
      justifyContent: "space-between",
    },
  },
  displayBox: {
    display: { xs: "none", md: 'block', lg: "block" }
  },
  displayUpload: {
    display: { xs: "none", sm: "block", md: 'block', lg: "block" }
  },
  displayOrderBox: {
    display: { xs: "none", sm: "none", md: 'block', lg: "block" }
  },
  navigationContainer: {
    display: "flex",
    alignItems:'center',
  },
  navigationContent: {
    margin: "0 10px",
    background: "#F3F4F6",
    width: "170px",
    borderRadius: "10px",
    '@media (max-width: 600px) ': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    },
    '@media (max-width: 688px) and (min-width: 600px)': {
      width: "110px !important",
    },
    '@media (max-width: 900px)': {
      width: "180px",
      height: "30px",
      marginTop: "11px",
      marginLeft: "-5px",
      marginRight: "5px",
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    },
  },
  selectCategory:{
    display: 'flex',
    padding: '12px 14px 12px 16.5px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '9.5px'
  },
  searchContent: {

    display: 'flex',
    width: "492px",
    height: '30px',
    padding: '12px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: "0 10px",
    flexDirection: "row",
    background: "#F3F4F6",
    borderRadius:"9px",
    fontSize: "50px",
    '@media (max-width: 600px) ': {
      width: "78px !important",
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    },
    '@media (max-width: 688px) and (min-width: 600px)': {
      width: "110px !important",
    },
    '@media (max-width: 900px)': {
      height: "30px",
      marginTop: "11px",
      marginLeft: "-5px",
      marginRight: "5px",
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    },
  },
  product: {
    '@media (max-width: 600px)': {
      display: "none",
    }
  },

  navItems: {
    display: "flex",
    gap: "55px",
    alignItems: "center",
    '@media (max-width: 600px)': {
      alignItems: "center",
      minWidth: "26%",
      padding: '0 55px',
      width: "50vw",
    }
  },
  naviconsContainer: {
    display: "flex",
    gap: '15px',
    height:"20px"
  },
  profileIcon: {
    color:  '#EE6900', 
    leadingTrim: 'both',          
    textEdge: 'cap',               
    fontFamily: 'Inter, sans-serif', 
    fontSize: '13px',              
    fontStyle: 'normal',            
    fontWeight: 700,             
    lineHeight: '28px'              
  },
  profileIconBox: {
    borderRadius: "9px",
    backgroundColor: "#FFF6F0",
    width: "42px",
    padding: "0px 10px",
    display: 'flex',              
    height: '38px',                
    justifyContent: 'center',     
    alignItems: 'center' 
  }
};
export default UserNavbar