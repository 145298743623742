import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
export const configJSON = require("./config");
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}
interface PurchaseOrderAttributes {
  id: number,
  purchase_order_id: string,
  po_value: string,
  po_validate: string,
  receipts: string,
  amount_invoiced: string,
  quotation_id: string,
  pending_po_amount: string,
  payment_terms: string,
  project_name: string,
  company_name: string,
  status: string,
  created_at:string,
  product_name:string [],
  subscription:boolean
}

export interface PurchaseOrder{
  id:number,
  type: string,
  attributes: PurchaseOrderAttributes
}

interface PurchaseListResponse{
  data: PurchaseOrder[] ,
  error:string       
}


// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  children?: React.ReactNode;
  handleSearchFun?: (event:React.ChangeEvent<HTMLInputElement>)=>void;
  mycart?:boolean;
  history?:any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  sidebar:boolean;
  open: { [key: string]: boolean };
  role:string
  toggle: boolean;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  active:string;
  value: number,
  searchTerm: string;
  showAdditionalRow: number[],
  notificationCounts: number[],
  anchorEL: HTMLElement | null;
  isSidebarCollapsed:boolean;
  purchaseOrderList:PurchaseOrder[];
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  purchaseOrderListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];

    this.state = {
      type: "",
      sidebar:false,
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      open: {},
      token: "",
      role:"user",
      toggle: false,
      loading: false,
      active:"",
      value: 0,
      searchTerm: "",
      showAdditionalRow: [],
      notificationCounts: [2, 5, 0, 1, 3, 0],
      anchorEL: null,
      isSidebarCollapsed:false,
      purchaseOrderList:[]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.purchaseOrderListApi();
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            dashboardData: webResponseJson.data.attributes.sub_attributres,
            totalCandidates: webResponseJson.data.attributes.total_candidates,
            type: webResponseJson.data.type,
            errorMsg: "",
            loading: false
          });
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
      }
    }

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    switch (apiRequestCallId) {  
      case this.purchaseOrderListApiCallId:
        this.handlePurchaseOrderListResponse(responseJson);
        break;
      case this.dashboardApiCallId:
        break;
      case this.apiDashboardItemCallId:
          break;
      default:
        break;
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleTabChange = (event:React.ChangeEvent<{}>, newValue:number) => {
    this.setState({ value: newValue });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEL: event.currentTarget || null });
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEL: null });
  };

  handleViewClick = (poId: number) => {
    this.setState((prevState) => {
      const isInArray = prevState.showAdditionalRow.includes(poId);
      const updatedShowAdditionalRow = isInArray
        ? prevState.showAdditionalRow.filter((id) => id !== poId) 
        : [...prevState.showAdditionalRow, poId];
      return {
        showAdditionalRow: updatedShowAdditionalRow,
      };
    });
  };

  
  toggleSlider = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));    
  };
  getNavigationMessage = (path:string|undefined) => {
   const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), path);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint,  token } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  purchaseOrderListApi = async () => {
    this.setState((prevState) => ({ ...prevState, isLoader: true }))
    this.purchaseOrderListApiCallId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: `${configJSON.purchaseOrderListApiEndPoints}`,
      token: await getStorageData('authToken'),
    });
  };

  handlePurchaseOrderListResponse =(responseJson:PurchaseListResponse)=>{
   if(responseJson.data){
    this.setState({purchaseOrderList:responseJson.data});
   }
  }

   formatDate = (dateString:string) => {
    const options :Intl.DateTimeFormatOptions = { 
      year: 'numeric',
      month: 'short',  
      day: '2-digit'
    };
    const date = new Date(dateString);
    
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };
  
  formatTime = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = {
      hour: '2-digit', 
      minute: '2-digit', 
      hour12: true,
    };
  
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  // Customizable Area End
}
