import React from "react";

// Customizable Area Start
import Navbar from "../../../components/src/Navbar.web";
import { Box, Chip, Grid, IconButton, Slider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Pagination, PaginationItem } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BrandpagesController, {
  Props,
} from "./BrandpagesController";
import { arrowImg, catalogeImage, documentImage, downImage, filterImage, logoImage, ocatalogeImage, odocumentImage, oproductsImage, pipes2Img, pipesImg, plumbingImg, prodImg, prodImg1, productsImage, watchImg } from "./assets";
// Customizable Area End

export default class Brandpages extends BrandpagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rows = [
    { name: "ADM Bio Solutions Market Segment Products Portfolio Brochure", type: "Brochure" },
    { name: "ADM Bio Solutions Market Segment Products TDS", type: "Technical Data Sheet" },
    { name: "BrocADM Bio Solutions Market Segment Products SDS", type: "Safety Data Sheet" },
    { name: "ADM Bio Solutions Market Segment Products TDS", type: "Technical Data Sheet" },
    { name: "BrocADM Bio Solutions Market Segment Products SDS", type: "Safety Data Sheet" },
    { name: "ADM Bio Solutions Market Segment Products TDS", type: "Technical Data Sheet" },
    { name: "ADM Bio Solutions Market Segment Products TDS", type: "Technical Data Sheet" },
  ];


  cardData = [
    {
      imgSrc: prodImg1,
      title: "CPVC PRO",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
    {
      imgSrc: prodImg1,
      title: "PEX-a-PRO",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
    {
      imgSrc: prodImg1,
      title: "Multiplex",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
    {
      imgSrc: prodImg1,
      title: "Aquarious",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
    {
      imgSrc: prodImg1,
      title: "EXO PRO",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
    {
      imgSrc: prodImg1,
      title: "DRAIN PRO",
      productInfo: "1 Product",
      arrowImg: arrowImg,
    },
  ];

  links = ['Astral Pipes', 'Catalogues', 'CPVC Pro'];
  separator = ' > ';

  products1 = [
    {
      productName: "Huliot K233032 32 Xy 1 Mm",
      productDescription: "Male Threaded Teee- Brass",
      productType: "Male Threaded Teee",
      productSize: "32",
      productImage: prodImg,
    },
    {
      productType: "Male Threeded Tee",
      productName: "Huliot K233032 32 Xe 1 Mm",
      productSize: "33",
      productImage: prodImg,
      productDescription: "Male Thredded Tee- Baass",
    },
    {
      productSize: "32",
      productType: "Male Threaded Tee",
      productDescription: "Male Threaded Tee- Brass",
      productName: "Huliot K233032 32 X 1 Mm",
      productImage: prodImg,
    },
    {
      productImage: prodImg,
      productName: "Huliot K233032 32 X 1 Mm",
      productSize: "32",
      productDescription: "Male Threaded Tee- Brass",
      productType: "Male Threaded Tee",
    },
    {
      productName: "Huliot K233032 32 Xy 1 Mm",
      productDescription: "Male Threaded Teee- Brass",
      productType: "Male Threaded Teee",
      productSize: "32",
      productImage: prodImg,
    },
    {
      productType: "Male Threeded Tee",
      productName: "Huliot K233032 32 Xe 1 Mm",
      productSize: "33",
      productImage: prodImg,
      productDescription: "Male Thredded Tee- Baass",
    },
    {
      productSize: "32",
      productType: "Male Threaded Tee",
      productDescription: "Male Threaded Tee- Brass",
      productName: "Huliot K233032 32 X 1 Mm",
      productImage: prodImg,
    },
    {
      productImage: prodImg,
      productName: "Huliot K233032 32 X 1 Mm",
      productSize: "32",
      productDescription: "Male Threaded Tee- Brass",
      productType: "Male Threaded Tee",
    },
    {
      productName: "Huliot K233032 32 Xy 1 Mm",
      productDescription: "Male Threaded Teee- Brass",
      productType: "Male Threaded Teee",
      productSize: "32",
      productImage: prodImg,
    },
    {
      productType: "Male Threeded Tee",
      productName: "Huliot K233032 32 Xe 1 Mm",
      productSize: "33",
      productImage: prodImg,
      productDescription: "Male Thredded Tee- Baass",
    },
    {
      productSize: "32",
      productType: "Male Threaded Tee",
      productDescription: "Male Threaded Tee- Brass",
      productName: "Huliot K233032 32 X 1 Mm",
      productImage: prodImg,
    },
    {
      productImage: prodImg,
      productName: "Huliot K233032 32 X 1 Mm",
      productSize: "32",
      productDescription: "Male Threaded Tee- Brass",
      productType: "Male Threaded Tee",
    },
  ];


  buttons = [
    { id: 1, label: 'Category' },
    { id: 2, label: 'Applications' },
  ];

  products = [
    {
      name: "Huliot K233032 32 X 1 Mm",
      description: "Male Threaded Tee- Brass",
      type: "Male Threaded Tee",
      size: "32",
      image: prodImg,
    },
    {
      name: "Huliot K233032 32 X 1 Mm",
      type: "Male Threaded Tee",
      image: prodImg,
      size: "32",
      description: "Male Threaded Tee- Brass"
    },
    {
      image: prodImg,
      name: "Huliot K233032 32 X 1 Mm",
      description: "Male Threaded Tee- Brass",
      type: "Male Threaded Tee",
      size: "32"
    },
    {
      size: "32",
      name: "Huliot K233032 32 X 1 Mm",
      image: prodImg,
      type: "Male Threaded Tee",
      description: "Male Threaded Tee- Brass",
    },
  ];


  options = [
    ["Option 1", "Option 2"],
    ["Option 3", "Option 4"],
  ]

  renderLinks = (links: string[]): React.ReactNode => {
    return links.map((link, index) => (
      <React.Fragment key={link}>
        <span style={{ color: "#9095A0", fontFamily: "Inter, sans-serif", fontSize: "16px", fontWeight: 600 }}>{link}</span>
        {index < links.length - 1 && <span style={{ color: "#9095A0", fontFamily: "Inter, sans-serif", fontSize: "16px", fontWeight: 600, margin: "0px 5px" }}>{this.separator}</span>}
      </React.Fragment>
    ));
  };

  theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {

              color: "#565E6C",
            },
            "&.Mui-checked .MuiSvgIcon-root": {
              color: "#FF6600",
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: "#FF6600",
          },
          thumb: {
            backgroundColor: "#FF6600",
          },
          track: {
            backgroundColor: "#FF6600",
          },
          rail: {
            backgroundColor: "#FFCC99",
          },
        },
      },
    },
  });

  getButtonStyle(buttonId: number) {
    return this.state.Pagedescription === buttonId
      ? webStyle.descriptionButton__active
      : webStyle.descriptionButton;
  }

  getTabStyle = (tabIndex: number) => {
    return {
      color: this.state.selectedTab === tabIndex ? '#EE6902' : 'inherit',
      fontSize: this.state.selectedTab === tabIndex ? '16px' : 'inherit',
      fontWeight: this.state.selectedTab === tabIndex ? 700 : 500,
    };
  };

  renderCardDetails() {
    return (

      <div>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Box id="navigation" sx={{ display: 'flex', justifyContent: 'center' }}>
            {this.renderLinks(this.links)}
          </Box>
        </Box>
        <Box style={{ marginTop: "14px" }}>
          <Typography style={{ ...webStyle.Heading }}>CPVC PRO</Typography>
        </Box>
        <Box style={{
          marginTop: "14px",
          width: "1048px",
          display: "flex",
          gap: "32px",
          flexWrap: "wrap",
        }}>
          <Box style={{ width: "1048px", display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, color: "#64748B", margin: "auto 0px" }}>
              {this.getResultText(this.state.currentPage)}
            </span>
            <Box style={{ display: "flex" }}>
              <span style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, lineHeight: "26px", margin: "auto 0px", }}>
                Sort By: Default
              </span>
              <img src={downImage} alt="downImage" style={{ width: "20px", height: "20px", margin: "3px 0px 3px 10px" }} />
            </Box>
          </Box>
          <Box style={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
            {this.products1.map((product, index) => (
              <Box
                key={index}
                style={{
                  border: "1px solid #E2E8F0",
                  width: "236px",
                  height: "295px",
                  borderRadius: "8px",
                }}
              >
                <img
                  data-testId="proImage"
                  src={product.productImage}
                  alt={product.productName}
                  style={{
                    width: "148px",
                    height: "94px",
                    margin: "20px 44px 30px 44px",
                  }}
                />
                <Typography style={{ ...webStyle.prodname }}>{product.productName}</Typography>
                <Typography style={{ ...webStyle.prodname1 }}>
                  {product.productDescription}
                </Typography>
                <Chip
                  style={{ ...webStyle.chip, width: "167px", height: "26px" }}
                  label={`Type:${product.productType}`}
                  variant="outlined"
                />
                <Typography style={{ ...webStyle.size }}>Size: {product.productSize}</Typography>
              </Box>
            ))}
            <div style={{ width: "1048px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
              <Pagination
                data-testId="Pagination"
                count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                page={this.state.currentPage}
                onChange={this.handlePageChange}
                siblingCount={0}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    style={{
                      textDecoration: 'underline',
                      borderRadius: 0,
                      background: "none",
                      fontFamily: "Inter, sans-serif",
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#979797"
                    }}
                  />
                )}
              />
            </div>
          </Box>
        </Box>
      </div>
    );
  }

  renderCardList() {
    return (
      <div>
        <Typography style={{ ...webStyle.Heading }}>ASTRAL PIPES</Typography>
        <Box
          style={{ width: "1048px", height: "305px", display: "flex", gap: "32px", flexWrap: "wrap", marginTop: "30px", cursor: "pointer" }}
        >
          {this.cardData.map((card, index) => (
            <Box
              key={index}
              style={{ width: "236px", height: "305px", border: "1px solid #E2E8F0", borderRadius: "8px" }}
              onClick={() => this.handleCardClick(card)}
            >
              <img
                src={card.imgSrc}
                alt={`catalogue${index + 1}Img`}
                data-testId="Product-Image"
                style={{ width: "216px", height: "210px", borderRadius: "8px", padding: "10px 10px 17px 10px", }}
              />
              <Typography style={{ ...webStyle.title }}>{card.title}</Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 16px 16px 10px"
                }}
              >
                <Typography style={{ ...webStyle.productInfo }}>{card.productInfo}</Typography>
                <img
                  src={card.arrowImg}
                  alt="arrowImg"
                  style={{
                    height: "16px",
                    width: "16px"
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    );
  }

  renderContent = () => {
    const { selectedTab } = this.state;

    switch (selectedTab) {
      case 1:
        return <div>
          <Box>
            <Typography style={{ ...webStyle.Heading }}>ASTRAL PIPES</Typography>

            <Typography style={{ ...webStyle.head1 }}>We create chemistry</Typography>
            <Typography style={{ ...webStyle.description }}>At BASF, we create chemistry for a sustainable future. We combine economic success with environmental protection and social responsibility. More than Il1,000 employees in the BASF Group contribute to the success of our customers in nearly all sectors and almost every country in the world. Our portfolio comprises
              six segments: Chemicals,Materials, Industrial Solutions, Surface Technologies, Nutrition & Care and Agricultural Solutions.</Typography>

            <Box style={{ ...webStyle.containtab, display: 'flex', marginTop: '40px' }}>
              <Typography style={{ ...webStyle.section }}>
                Browse Products by
              </Typography>
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                {this.buttons.map((button) => (
                  <React.Fragment key={button.id}>
                    <button
                      id={`button-${button.id}`}
                      data-testId="Tab-Button"
                      style={this.getButtonStyle(button.id)}
                      onClick={() => this.setState({ Pagedescription: button.id })}
                    >
                      {button.label}
                    </button>
                  </React.Fragment>
                ))}
              </Box>
            </Box>
            <Box style={{ display: "flex", gap: "30px", marginTop: "24px" }}>
              <Box>
                <img src={plumbingImg} alt="plumbingImg" style={{ width: "100px", height: "100px" }} />
                <Typography style={{ ...(webStyle.imgtext as React.CSSProperties) }}>Internal Plumbing</Typography>
              </Box>
              <Box>
                <img src={pipesImg} alt="plumbingImg" style={{ width: "100px", height: "100px" }} />
                <Typography style={{ ...(webStyle.imgtext as React.CSSProperties) }}>Pipes</Typography>
              </Box>
              <Box>
                <img src={pipes2Img} alt="plumbingImg" style={{ width: "100px", height: "100px" }} />
                <Typography style={{ ...(webStyle.imgtext as React.CSSProperties) }}>Pipes & Accessories</Typography>
              </Box>
            </Box>

            <Box style={{ marginTop: "40px" }}>
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, color: "#64748B", margin: "auto 0px" }}>
                  {this.getResultText(this.state.currentPage)}
                </span>
                <Box style={{ display: "flex" }}>
                  <span style={{ fontFamily: "Inter, sans-serif", fontSize: "14px", fontWeight: 400, lineHeight: "26px", margin: "auto 0px", }}>
                    Sort By: Default
                  </span>
                  <img src={downImage} alt="downImage" style={{ width: "20px", height: "20px", margin: "3px 0px 0px 10px" }} />
                </Box>
              </Box>
              <Box style={{ display: "flex", gap: "32px", marginTop: "18px" }}>
                {this.products.map((product, index) => (
                  <Box
                    key={index}
                    style={{ border: "1px solid #E2E8F0", width: "236px", borderRadius: "8px", marginBottom: "20px", }}>
                    <img
                      src={product.image} alt={product.name} style={{ width: "148px", height: "94px", margin: "20px 44px 30px 44px", }} />
                    <Typography style={{ ...webStyle.prodname }}>
                      {product.name}
                    </Typography>
                    <Typography style={{ ...webStyle.prodname1 }}>
                      {product.description}
                    </Typography>
                    <Chip style={{ ...webStyle.chip, width: "167px", height: "26px" }} label={`Type: ${product.type}`} variant="outlined"
                    />
                    <Typography style={{ ...webStyle.size }}>
                      Size: {product.size}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </div>;
      case 2:
        return <div>
          {this.state.selectedCard
            ? this.renderCardDetails()
            : this.renderCardList()}
        </div>
      case 3:
        return <div>
          <Typography style={{ ...webStyle.Heading }}>ASTRAL PIPES</Typography>
          <Box
            style={{
              width: "1040px",
              marginTop: "30px",
              borderRadius: "8px",
              overflow: "hidden",
              border: "1px solid #E2E8F0",
            }}
          >
            <TableContainer
              style={{
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <Table style={{ borderCollapse: "collapse", borderRadius: "8px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ ...webStyle.Thead, }}>Name</TableCell>
                    <TableCell style={{ ...webStyle.Thead }}>Type</TableCell>
                    <TableCell style={{ ...webStyle.Thead1, overflow: "hidden" }}>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ ...webStyle.firstcell }}>{row.name}</TableCell>
                      <TableCell style={{ ...webStyle.secondcell }}>{row.type}</TableCell>
                      <TableCell style={{ ...webStyle.thirdcell }}>
                        <IconButton>
                          <img src={watchImg} alt="watchImg" style={{ height: "24px", width: "24px" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </div>;
      default:
        return <div>Default Content</div>;
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Navbar  {...this.props} />
        <Box style={{ padding: "27px 47px 69px 50px" }}>
          <Box style={{ display: "flex" }}>
            <Box>
              <Box style={webStyle.container}>
                <img src={logoImage} alt="logo" style={webStyle.logoContainer} data-testId="Logo-Image" />
                <Box style={webStyle.itemsContainer}>
                  <Box
                    style={webStyle.item}
                    onClick={() => this.handleTypographyClick(1)}
                  >
                    <img
                      src={this.state.selectedTab === 1 ? oproductsImage : productsImage}
                      alt="Products"
                      style={webStyle.itemImg}
                    />
                    <Typography
                      style={{
                        ...webStyle.itemText,
                        ...this.getTabStyle(1),
                      }}
                    >
                      Products
                    </Typography>
                  </Box>
                  <Box
                    style={webStyle.item}
                    onClick={() => this.handleTypographyClick(2)}
                  >
                    <img
                      src={this.state.selectedTab === 2 ? ocatalogeImage : catalogeImage}
                      alt="Catalogues"
                      style={webStyle.itemImg}
                    />
                    <Typography
                      style={{
                        ...webStyle.itemText,
                        ...this.getTabStyle(2),
                      }}
                    >
                      Catalogues
                    </Typography>
                  </Box>
                  <Box
                    style={webStyle.item}
                    onClick={() => this.handleTypographyClick(3)}
                  >
                    <img
                      src={this.state.selectedTab === 3 ? odocumentImage : documentImage}
                      alt="Documents"
                      style={webStyle.itemImg}
                    />
                    <Typography
                      style={{
                        ...webStyle.itemText,
                        ...this.getTabStyle(3),
                      }}
                    >
                      Documents
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{ width: "250px", height: "697px", border: "1px solid #E2E8F0", marginTop: "30px", borderRadius: "8px" }}>
                <Box style={{ borderBottom: "1px solid #E2E8F0" }}>
                  <Box style={{ display: "flex", margin: "20px 0px", width: "250px", height: "26px" }}>
                    <img src={filterImage} alt="filterImage" style={{ width: "20px", height: "20px", marginLeft: "30px", }} />
                    <Typography style={{ marginLeft: "10px" }}>Filters</Typography>
                  </Box>
                </Box>
                <Box style={{ width: "250px", paddingBottom: "20px", borderBottom: "1px solid #E2E7F0" }}>
                  <Box style={{ display: "flex", justifyContent: "space-between", margin: "20px auto 0px", width: "190px", height: "26px" }}>
                    <Typography style={{ ...webStyle.condition }}>Condition 1</Typography>
                    <ExpandLessIcon style={{ color: '#EE6920' }} />
                  </Box>
                  {this.options.map((optionRow, index) => (
                    <Box
                      key={index}
                      style={{
                        width: "199px",
                        display: "flex",
                        margin: "20px auto 0px",
                        justifyContent: "space-between",
                      }}
                    >
                      {optionRow.map((opt, i) => (
                        <div key={i} style={{ display: "flex" }}>
                          <ThemeProvider theme={this.theme}>
                            <Checkbox
                              style={{ height: "20px", width: "20px" }}
                            />
                          </ThemeProvider>
                          <Typography
                            style={{ fontFamily: "Inter, sans-serif", marginLeft: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", margin: "auto 10px auto", }}
                          >
                            {opt}
                          </Typography>
                        </div>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box style={{ width: "250px", paddingBottom: "20px", borderBottom: "1px solid #E2E7F0" }}>
                  <Box style={{ display: "flex", justifyContent: "space-between", margin: "20px auto 0px", width: "190px", height: "26px" }}>
                    <Typography style={{ ...webStyle.condition }}>Condition 2</Typography>
                    <ExpandLessIcon style={{ color: '#EE6920' }} />
                  </Box>
                  {this.options.map((optionRow, index) => (
                    <Box
                      key={index}
                      style={{
                        width: "199px",
                        display: "flex",
                        margin: "20px auto 0px",
                        justifyContent: "space-between",
                      }}
                    >
                      {optionRow.map((opt, i) => (
                        <div key={i} style={{ display: "flex" }}>
                          <ThemeProvider theme={this.theme}>
                            <Checkbox
                              style={{ height: "20px", width: "20px" }}
                            />
                          </ThemeProvider>
                          <Typography
                            style={{ fontFamily: "Inter, sans-serif", marginLeft: "10px", fontWeight: 400, fontSize: "14px", lineHeight: "18px", margin: "auto 10px auto", }}>
                            {opt}
                          </Typography>
                        </div>
                      ))}
                    </Box>
                  ))}
                </Box>
                <Box >
                  <Box style={{ width: "250px", paddingBottom: "20px", borderBottom: "1px solid #E2E7F0" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", margin: "20px auto 0px", width: "190px", height: "26px" }}>
                      <Typography style={{ ...webStyle.condition }}>Price Range</Typography>
                      <ExpandLessIcon style={{ color: '#EE6920' }} />
                    </Box>
                    <Box style={{ width: "250px", display: "flex", margin: "20px auto 0px", justifyContent: "space-around" }}>
                      <Box>

                        <Box
                          p={3}
                          borderColor="grey.300"
                          maxWidth={300}
                          style={{
                            margin: "auto",
                            backgroundColor: "#fff",
                            borderTop: "1px solid #E2E8F0",
                            borderBottom: "1px solid #E2E8F0"
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                              <TextField
                                variant="outlined"
                                size="small"
                                value={`$ ${this.state.priceRange[0]}`}
                                onChange={(e) => this.handleInputChange(0, e.target.value)}
                                fullWidth
                                style={{ border: "1px solid ##565E6C", borderRadius: "8px" }}
                                inputProps={{ "data-testid": "price-input" }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                variant="outlined"
                                size="small"
                                value={`$${this.state.priceRange[1]}`}
                                onChange={(e) => this.handleInputChange(1, e.target.value)}
                                fullWidth
                                style={{ border: "1px solid ##565E6C", borderRadius: "8px" }}
                                inputProps={{ "data-testid": "price-input2" }}
                              />
                            </Grid>
                          </Grid>

                          <Box my={2}>
                            <ThemeProvider theme={this.theme}>
                              <Slider

                                value={this.state.priceRange}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                min={0}
                                max={300}
                              />
                            </ThemeProvider>
                          </Box>

                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <button
                                style={{ ...webStyle.button }}
                              >
                                Apply
                              </button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ width: "199px", display: "flex", margin: "20px auto 0px", justifyContent: "space-around" }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <button
                            style={{ ...webStyle.button }}
                          >
                            Clear All
                          </button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>

            </Box>
            <Box style={{ marginLeft: "50px" }}>
              {this.renderContent()}
            </Box>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  container: {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    width: "250px",
    height: "270px",
  },
  navMenu: {
    fontFamily: "Inter, sans-serif",
    fontSize: "11px",
    fontWeight: 700,
    color: "#323743",
  },
  navMenuItem: {
    height: "19px",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "flex",
    cursor: "pointer"
  },
  topnav: {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Inter, sans-serif"
  },
  arrowforward: {
    height: "16px",
    width: "16px"
  },
  logoContainer: {
    width: "168px",
    height: "44px",
    padding: "30px 52px 0px 30px",
  },
  itemsContainer: {
    width: "122px",
    height: "126px",
    padding: "40px 0px 30px 30px",
  },
  Heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    lineHeight: "26px",
    fontWeight: 700
  },
  head1: {
    fontFamily: "Inter, sans-serif",
    fontSize: "20px",
    lineHeight: "26px",
    fontWeight: 500,
    marginTop: "14px"
  },
  button: {
    width: "196px",
    height: "40px",
    border: "none",
    backgroundColor: "#FF6600",
    borderRadius: "8px",
    color: "white",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700
  },
  item: {
    display: "flex",
    height: "26px",
    marginBottom: "24px",
    cursor: "pointer"
  },
  itemImg: {
    width: "24px",
    height: "24px",
  },
  itemText: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: 500
  },
  description: {
    width: "1040px",
    height: "66px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter, sans-serif",
    marginTop: "12px"
  },
  productInfo: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter, sans-serif",
    color: "#9095A0",
    paddingBottom: "10px"
  },
  descriptionButton__active: {
    borderRadius: "12px",
    backgroundColor: "#FF6600",
    color: "#FFF",
    padding: "11px 43px",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    borderColor: "transparent",
    cursor: "pointer"
  },
  descriptionButton: {
    backgroundColor: "#FFF",
    color: "#FF6600",
    padding: "11px 38px",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    borderColor: "transparent",
    cursor: "pointer"
  },
  containtab: {
    height: "44px",
  },
  section: {
    width: "152px",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    margin: "auto 30px auto 0px"
  },
  imgtext: {
    fontFamily: "Inter, sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    textAlign: 'center',
  },
  prodname: {
    fontFamily: "Inter, sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    width: "178px",
    height: "18px",
    padding: "30px 38px 0px 20px"
  },
  prodname1: {
    fontFamily: "Inter, sans-serif",
    fontSize: '14px',
    fontWeight: 400,
    width: "178px",
    height: "18px",
    padding: "4px 38px 12px 20px"
  },
  condition: {
    fontFamily: "Inter, sans-serif",
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: "26px"
  },
  chip: {
    fontFamily: "Inter, sans-serif",
    fontSize: '12px',
    fontWeight: 400,
    color: "#9095A0",
    margin: "0px 49px 0px 20px",
    overflow: "visiable"
  },
  title: {
    padding: "0px 0px 0px 10px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Inter, sans-serif",
    color: "#171A1F"
  },
  size: {
    fontFamily: "Inter, sans-serif",
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: "16px",
    color: "#9095A0",
    margin: "12px 0px 18px 20px",
  },
  firstcell: {
    paddingLeft: "40px",
    border: "1px solid #E2E8F0",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    borderLeft: "none"
  },
  secondcell: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    border: "1px solid #E2E8F0",
    paddingLeft: "38px",
  },
  thirdcell: {
    paddingLeft: "56px",
    border: "1px solid #E2E8F0",
    borderRight: "none"
  },
  Thead: {
    paddingLeft: "40px",
    borderBottom: "1px solid #E2E8F0",
    BorderRight: "1px solid #E2E8F0",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700
  },
  Thead1: {
    paddingLeft: "40px",
    borderBottom: "1px solid #E2E8F0",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700
  }
}


// Customizable Area End
