import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ListProductVariationController, { Props } from "./ListProductVariationController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../../packages/components/src/SideBar.web";
import  VariationListTable  from "./components/VariationListTable";
import { Box } from "@material-ui/core";

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
});
export interface ProductVariation {
    upiName: string,
    criteria01: string,
    criteria02: string,
    criteria03: string,
    id: number
}
// Customizable Area End

export default class ListProductVariation extends ListProductVariationController {
    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Usernavbar />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText" >
                    <Box style={{width:"96%"}}>
                    <VariationListTable totalResults={this.state.totalResults}
                        handleMenuClick={this.handleMenuClick}
                        handlePageChange={this.handlePageChange}
                        handleMenuClose={this.handleMenuClose}
                        currentPage={this.state.currentPage}
                        anchorEl={this.state.anchorEl}
                        data={this.state.listDataVariation}
                        resultperPage={this.state.resultperPage} 
                        handleToggle1= {this.handleToggle1}
                        open1={this.state.open1}
                        handleToggle={this.handleToggle}
                        open={this.state.open}
                        handleApply={this.handleApply}
                        handleClearAll={this.handleClearAll}
                        handleCheckboxChange={this.handleCheckboxChange}
                        handleRadioChange ={this.handleRadioChange}
                        selectedOption={this.state.selectedOption}
                        selectedBrands={this.state.selectedBrands}
                        handleNavigate= {this.handleNavigate}
                        data-test-id="tableList"
                        handleSearchText={this.handleSearchInput}
                        />
                        </Box>
                </SideBar>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
// Customizable Area End
