import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Button,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  Menu,
  MenuItem,
  Modal,
  TextField,
} from "@material-ui/core";
import { filterIcon, seacrhIcon, sortingIcon, CancelIcon, nextImg, edit_, delete1 } from "./assets";

import LogisticalListController, {
  Props,
} from "./LogisticalListController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
// Customizable Area End

export default class LogisticalList extends LogisticalListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Brandstable = () => {
    const { brandsData } = this.state
    const tabData = [
      { label: 'All Results', value: 0 },
    ];

    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography variant="h6" style={webStyle.Headingss}>
            Logistical Codes
          </Typography>
          <Button data-testid="openModalButton" onClick={this.handleOpen} variant="contained" style={{ ...webStyle.CheckoutButton, fontFamily: "Inter, sans-serif", textTransform: "none", boxShadow: "none" }}>
            Add New Logistical Codes
          </Button>
          <Modal data-testid="successModal"
            open={this.state.openSuccessModal}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description"
          >
            <Box
              style={{
                background: "#fff",
                borderRadius: "8px",
                width: "600px",
                top: "50%",
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid #E2E8F0", padding: "10px" }}>
                <Typography variant="h6" style={{ fontSize: '25px' }}>
                  Add New Logistical Code
                </Typography>
                <img onClick={this.handleClose} src={CancelIcon} />
              </div>
              <div style={{ borderBottom: "1px solid #E2E8F0" }}>
                <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
                  <Box style={webStyle.formItem}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Product Tag</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                    </Box>
                    <TextField
                      required
                      onChange={this.handleChange}
                      placeholder="Enter Product Tag"
                      error={this.state.error}
                      helperText={this.state.error ? "This field is required" : ""}

                      inputProps={{
                        style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                      }} style={{
                        ...webStyle.formField,
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                      }}
                    />
                  </Box>
                </Box>
              </div>
              <Box data-testid="addLogisticalButton" onClick={this.navigateToAddNewLogistical} style={{ display: "flex", justifyContent: "flex-end" }}>
                <img src={nextImg} alt="nextImg" style={webStyle.nextButton} />
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.NavTab }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={0} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography style={webStyle.ActiveTabs}>
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Box style={webStyle.SearchContainers}>
              <InputBase
                placeholder="Search"
                style={webStyle.SearchInputs}
                inputProps={{
                  style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: 12,
                    fontWeight: 400,
                    color: "rgba(0, 0, 0, 0.87)",
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon} alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} alt="filter icon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...webStyle.TableHeads }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss1, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                    Product Tag
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss2, fontFamily: "Inter, sans-serif", borderBottom: "none" }}>
                    Logistical Codes
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCellss3, borderBottom: "none" }}>
                    Actions
                  </TableCell>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={webStyle.TBodys}>
                <TableRow style={{ height: "30px" }}></TableRow>
                {brandsData.map((brand: any, index: number) => (
                  <TableRow key={index} data-testId="Rowclick" style={{ height: "45px" }}>
                    <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                    <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0" }}>{brand.attributes.product_tag}</TableCell>
                    <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>
                      {Array.isArray(brand.attributes.logisticl_codes) ? (
                        brand.attributes.logisticl_codes.map((log: any, index: number) => (
                          <div key={index}>
                            <div>{log.code}</div>
                          </div>
                        ))
                      ) : (
                        <span>{brand.attributes.logisticl_codes}</span> 
                      )}
                    </TableCell>
                    <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", }}>
                      <IconButton style={{ marginLeft: "200px" }}
                        aria-label="more"
                        data-testid="MoreVertIcon"
                      onClick={(event) => this.handleMenuClick(event, brand.id,brand.attributes.product_tag,brand.attributes.logisticl_codes)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        data-testid="Menu"
                        open={Boolean(this.state.anchorEl)}
                        onClose={()=>{this.setState({ anchorEl: null });}}
                        PaperProps={{
                          style: { boxShadow: 'none', border: "1px solid #E7E2Ef", width: "110px" },
                        }}
                      >
                        <MenuItem data-testId="EditMenuItem" onClick={this.navigateToAddNewLogistical}  > <img src={edit_} alt="editicon" style={{ paddingRight: '10px' }}/>Edit</MenuItem>
                        <MenuItem data-testId="DeleteMenuItem" onClick={this.deleteLogisticalListAPICall} ><img src={delete1} alt="editicon" style={{ paddingRight: '10px' }}/>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell style={{ width: "0%", borderBottom: "1px solid #E7E2EF", borderRight: "1px solid #E7E2EF", borderTop: "1px solid #E7E2EF", }}></TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                  <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={5}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <span style={{ fontFamily: "Inter, sans-serif", fontSize: "12px", fontWeight: 400, color: "#64748B" }}>{this.getResultText(this.state.currentPage)}</span>
                      <Pagination data-testid="pagination"
                        count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                        page={this.state.currentPage}
                        onChange={this.handlePageChange}
                        siblingCount={0}
                        boundaryCount={1}
                        shape="rounded"
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            style={{
                              minWidth: "31px",
                              height: "36px",
                              borderRadius: "12px",
                              fontFamily: "Inter, sans-serif",
                              fontSize: "12px",
                              fontWeight: 500,
                              color: item.selected ? "#FFFFFF" : "#475569",
                              backgroundColor: item.selected ? "#FF6600" : "#F3F4F6",
                              border: "1px solid #E2E8F0",
                              boxShadow: item.selected ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
                            }}
                          />
                        )}
                      />
                    </div>
                  </TableCell>
                  <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                </TableRow>
                <TableRow style={{ height: "30px" }}></TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar history={this.props.history}/>
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Brandstable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number;
  };
} = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",
  },
  Headingss: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  TBodys: {
    padding: "20px",
  },
  Cells1: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#323842",
  },
  Cells: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
  },
  TableHeads: {
    backgroundColor: "#f3f4f6",
  },
  TableCellss: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  TableCellss1: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    paddingRight: "300px",
  },
  TableCellss2: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    paddingRight: "100px",
  },
  TableCellss3: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    paddingLeft: "220px",
  },
  NavTab: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
  },
  SearchContainers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "265px",
  },
  SearchInputs: {
    width: "173px",
    height: "35px",
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  ActiveTabs: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    paddingRight: "20px",
    marginLeft: "20px",
    marginBottom: "20px",
    alignItems: "start",
  },
  formItem: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
    width: '100%' as string,
  },
  formField: {
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',
  },
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
  },
  textFieldStyle: {
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
  },
  nextButton: {
    margin: "16px 20px 16px 0px",
    boxShadow: "none",
  },
};
// Customizable Area End