import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}
interface ApiResponse {
  data: unknown;
  error: string;
  errors:string[];
}

interface  IBrandCatalogue {
  id:number,
  type: string,
  attributes: {
      name: string,
      image: {
          url: string
      }
  }
}

interface ICategory {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string
  }
}

interface ICategoryProps{
  data:ICategory[]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  openModal: boolean;
  catalogueName: string;
  category: string;
  uploadedFile: File | null;
  listBrandCatalogueVariation:IBrandCatalogue[];
  errors: {
    catalogueName: string | null;
    category: string | null;
    image: string | null;
  };
  listCategory:ICategoryProps
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalougesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBrandCatalogueAPiCallId:string="";
  employeeId:number=0;
  getListCategoriesApiCallId:string="";
  addNewBrandCatalogueApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
    sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   anchorEl: null,
   value: 12,
   openModal: false,
   catalogueName: "",
   category: "",
   uploadedFile: null,
   listBrandCatalogueVariation:[],
   errors:{catalogueName:null,category:null,image:null},
   listCategory:{} as ICategoryProps
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
     this.employeeId = this.props.navigation.getParam("navigationBarTitleText");
    await this.getListVariationApi();
    await this.getListCategoryApi();
// Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId) {
      case this.getBrandCatalogueAPiCallId:
        this.handleGetListVariationResponse(responseJson);
        break;
      case this.getListCategoriesApiCallId:
        this.handleGetListCategoryResponse(responseJson);
          break
      case this.addNewBrandCatalogueApiCallId:
        this.handleCreateBrandCatalogue(responseJson);
            break
      default:
        break;
    }
  }

  navigateToCreditnoteDetail = () => {
    this.handleNavigate('CatalougeDetail')
   };
 
   handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleOpenModal = () => {
    this.setState({ openModal: true });
  };

  handleCloseModal = () => {
    this.setState({ openModal: false ,uploadedFile:null,catalogueName:"",category:"",errors:{category:"",catalogueName:"",image:""}});
  };

  handleCatalogueNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors = { ...this.state.errors };
    if (event.target.value =="") {
      errors.catalogueName = "Catalogue Name  is required";
    } else {
      errors.catalogueName = "";
    }
    this.setState({ catalogueName: event.target.value,errors });
  };

  handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const errors = { ...this.state.errors };
    if (event.target.value ===""||undefined||null) {
      errors.category = "Category  is required";
    } else {
      errors.category = "";
    }
    this.setState({ category: event.target.value as string,errors });
  };


  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const errors = { ...this.state.errors };
    if (event.target.files==null) {
      errors.image = "Image Name  is required";
    } else {
      errors.image = "";
    }
    if (event.target.files && event.target.files[0]) {
      this.setState({ uploadedFile: event.target.files[0],errors });
    }
  };

  getListVariationApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getBrandCatalogueAPiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getBrandCatalogueEndPoint}?brand_id=${this.employeeId}`,
      token: await getStorageData('authToken'),
    });
  };

  handleGetListVariationResponse(responseJson: ApiResponse) {
    if (!responseJson || responseJson.error) {
      return;
    }
    this.setState({ 
      listBrandCatalogueVariation:responseJson.data as IBrandCatalogue[]
    })
  }

  handleGetListCategoryResponse(responseJson: ApiResponse) {
    if (!responseJson || responseJson.error) {
      return;
    }
    this.setState({ 
      listCategory:responseJson.data as ICategoryProps
    })
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, token, isJsonStringify } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      isJsonStringify ? JSON.stringify(body) : body
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleAdd = () => {
    const errors = { catalogueName: "", category: "", image: "" }
    let valid = true;
    if (this.state.catalogueName == "") {
      errors.catalogueName = "Catalogue Name  is required";
      valid = false;
    }
    if (this.state.category == "") {
      errors.category = "Category Name  is required";
      valid = false;
    }
    if (this.state.uploadedFile == null) {
      errors.image = "Image is required";
      valid = false;
    }
    if (valid) {
      this.addBrandCatalogueApi();
    } else {
      this.setState({ errors });
    }
  }

  getListCategoryApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getListCategoriesApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getListCategoriesEndPoint}`,
      token: await getStorageData('authToken'),
    });
  };

  addBrandCatalogueApi = async () => {
    const formData = new FormData();
    formData.append("catalogues[name]",this.state.catalogueName);
    formData.append("catalogues[category_id]",this.state.category);
    formData.append("catalogues[brand_id]",this.employeeId.toString());
    if(this.state.uploadedFile)
    formData.append("catalogues[image]",this.state.uploadedFile);
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.addNewBrandCatalogueApiCallId = await this.apiCall({
      method: configJSON.postMethod,
      endPoint: `${configJSON.addBrandCatalogueEndPoint}`,
      token: await getStorageData('authToken'),
      body:formData,
      isJsonStringify:false
    });
  };

  handleCreateBrandCatalogue = async (responseJson: ApiResponse) => {
    if (!responseJson || responseJson.errors) {
      this.setState({ errors: { catalogueName: responseJson.errors[0] as string, image: "", category: "" } });
      return;
    }
    else {
      await this.getListVariationApi();
      this.handleCloseModal();
    }
  }
  // Customizable Area End
}