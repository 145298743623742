import React from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
  Button,
} from "@material-ui/core";
import { AddImg, downImage, FilesImg, uploadicon } from "./assets";

import AddbrandsController, {
  Props,
} from "./AddbrandsController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete } from "@mui/material";
import Chip from "@mui/material/Chip";
import CloseIcon from "@material-ui/icons/Close"


// Customizable Area End

export default class Addbrands extends AddbrandsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start



  Addbrands = () => {
    const tabData = [
      { label: 'Brand Details', value: 0 },
      { label: 'Brand Contact Information', value: 1 },
      { label: 'Region of Operations', value: 2 },
      { label: 'Business Information', value: 3 },
      { label: 'Brand Documents', value: 4 },
    ];

    const options = [
      {
        label: "AAC Blocks 4",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];

    const industryDatas = this.state.industryData.map((item) => ({
      id: item.id,
      name: item.attributes.name,
    }));

    const renderTabContent = () => {
      switch (this.state.value) {
        case 0:
          return renderBrandDetails();
        case 1:
          return renderBrandConDetails();
        case 2:
          return renderOtherTabs();
        case 3:
          return renderBusinessInformation();
        default:
          return renderBrandDocument();
      }
    };



    const renderBrandDetails = () => {
      const { errors } = this.state;

      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Name</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter Brand Name"
                  onChange={(e) => this.handleChange(e, "brandName")}
                  value={this.state.brandName}
                  error={!!errors?.brandName}
                  helperText={errors?.brandName}
                  inputProps={{
                    'data-testid': 'brand-name-input',
                    style: { fontWeight: 400, fontSize: "16px", fontFamily: "Inter, sans-serif", color: "#BCC1CA" },
                  }}
                  style={webStyle.formField}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B" },
                  }}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Industry / Sector</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  id="catalogue-field"
                  options={industryDatas}
                  getOptionLabel={(option: any) => option.name}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Select Industry / Sector"
                      error={!!errors?.industrySector}
                      helperText={errors?.industrySector}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "16px",
                          fontFamily: "Inter, sans-serif",
                          color: "#BCC1CA",
                          padding: "6px 8px",
                          height: "44px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            src={downImage}
                            style={{ width: "15px", height: "15px", marginRight: "8px" }}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": {
                      maxHeight: "200px",
                      boxShadow: "none",
                      border: "1px solid #F3F4F6",
                      borderRadius: "8px",
                    },
                  }}
                  onChange={(event: any, value: any) => {
                    this.setState({ industrySector: value?.id || "" });
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Brand Tagline</Typography>
                <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
              </Box>
              <TextField
                placeholder="Enter Brand Tagline"
                onChange={(e) => this.handleChange(e, "brandTagline")}
                value={this.state.brandTagline}
                error={!!errors?.brandTagline}
                helperText={errors?.brandTagline}
                inputProps={{
                  style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA" },
                }}
                style={{
                  ...webStyle.formField,
                  width: "100%",
                  height: "44px",
                  borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B" },
                }}
              />
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Logo</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Box
                  style={{
                    height: "256px",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F3F4F6",
                  }}
                >
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, `qualityFile1`)}
                    data-testId="product-brand-image"
                  /> {this.state.qualityFile1Name && (
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.qualityFile1Name}
                    </Typography>
                  )}
                  <label htmlFor="upload-file">
                    <Button
                    component="span"
                      variant="contained"
                      startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                      style={{
                        backgroundColor: "#FF6600",
                        color: "#FFFFFF",
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "16px",
                        textTransform: "none",
                        borderRadius: "12px",
                        padding: "16px 14px 16px 14px",
                        height: "34px",
                        width: "90px",
                        gap: "6px",
                        cursor: "pointer"
                      }}
                    >
                      Upload
                    </Button>
                  </label>
                  <br />
                  {errors?.qualityFile1 && (
                    <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                      {errors.qualityFile1}
                    </Typography>
                  )}
                  <Typography
                    style={{
                      fontFamily: "Inter, sans-serif",
                      marginTop: "3px",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    OR
                  </Typography>
                  <Box style={{ display: "flex", marginLeft: "7px" }}>
                    <label htmlFor="upload-file" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                      <Typography
                        component="span"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#FF6600",
                          fontFamily: "Inter, sans-serif",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginTop: "8px",
                        }}
                      >
                        Browse Files
                      </Typography>
                      <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "12px" }}
                      />
                    </label>
                    <input
                      type="file"
                      id="upload-file"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => this.handleFileChange(e, "qualityFile1")}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Description</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  placeholder="Description"
                  onChange={(e) => this.handleChange(e, "brandDescription")}
                  value={this.state.brandDescription}
                  error={!!errors?.brandDescription}
                  helperText={errors?.brandDescription}
                  inputProps={{
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif",
                      color: "#BCC1CA",
                    },
                  }}
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                    height: "257px",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", marginRight: "15px", height: "65px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Box>
        </>
      );
    };
    const renderBrandDocument = () => {
      const { errors } = this.state;

      return (
        <>
          <Box style={{
            padding: "20px",
            display: "flex",
            gap: "20px",
            borderBottom: "1px solid #E1E7EF",

          }}>

            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%" }}>
              {this.state.certificates.map((certificate: any, index: any) => (
                <Box key={index} style={{ ...webStyle.formItem, marginBottom: "15px" }}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>Certificate Name</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <TextField
                    placeholder="Enter Certificate Name"
                    value={certificate.certificateName}
                    onChange={(e) => this.handleChange(e, "certificateName", index)}
                    style={{ ...webStyle.formField }}
                    InputProps={{
                      disableUnderline: true,
                      style: { padding: "8px 8px", fontSize: "16px", fontFamily: "Inter, sans-serif", color: "#BCC1CA" },
                    }}
                  />
                  {this.state.errors?.['certificates[0].certificateName'] && (
                    <Typography style={{ color: '#DC2626', fontSize: '12px' }}>
                      {this.state.errors['certificates[0].certificateName']}
                    </Typography>
                  )}

                  <Box
                    style={{
                      height: "256px",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#F3F4F6",
                      marginTop: "25px"
                    }}
                  >
                    <input
                      type="file"
                      id={`certificate-file-${index}`}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => this.handleFileChange(e, "qualityFile2", index, "certificates")}
                    />
                    {certificate.qualityFile2Name && (
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginLeft: "10px",
                          color: "blue",
                          paddingBottom: "10px",
                        }}
                      >
                        {certificate.qualityFile2Name}
                      </Typography>
                    )}
                    <label htmlFor={`certificate-file-${index}`}>
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                        style={{
                          backgroundColor: "#FF6600",
                          color: "#FFFFFF",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "none",
                          borderRadius: "12px",
                          padding: "16px 14px 16px 14px",
                          height: "34px",
                          width: "90px",
                          gap: "6px",
                          cursor: "pointer"
                        }}
                      >
                        Upload
                      </Button>
                    </label>
                    <br />
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        marginTop: "3px",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      OR
                    </Typography>

                    <Box style={{ display: "flex", marginLeft: "7px" }}>
                      <label
                        htmlFor={`certificate-file-${index}`}
                        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                      >
                        <Typography
                          component="span"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#FF6600",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            marginTop: "8px",
                          }}
                        >
                          Browse Files
                        </Typography>
                        <img
                          src={FilesImg}
                          alt="FilesImg"
                          style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "12px" }}
                        />

                        <input
                          type="file"
                          id={`certificate-file-${index}`}
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => this.handleFileChange(e, "qualityFile2", index, "certificates")}
                        />

                      </label>
                    </Box>
                  </Box>


                </Box>
              ))}
              {/* Add button for adding certificate */}
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>

                <label
                  htmlFor="quality-test-upload"
                  style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}
                  onClick={this.addCertificateField}
                >
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add Another Certificate
                  </Typography>
                </label>
              </Box>
            </Box>


            {/* License Box */}
            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%", }}>
              {this.state.licenses.map((license: any, index: any) => (
                <Box key={index} style={{ ...webStyle.formItem, marginBottom: "15px" }}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>License</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <Autocomplete
                    id={`catalogue-field-${index}`}
                    disablePortal
                    options={options}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Select a License"
                        style={{ ...webStyle.formField }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: { fontSize: "16px", fontFamily: "Inter, sans-serif", color: "#BCC1CA", padding: "8px" },
                          endAdornment: (
                            <img
                              alt="Dropdown Icon"
                              src={downImage}
                              style={{ width: "15px", height: "15px", marginRight: "8px" }}
                            />
                          ),

                        }}
                      />
                    )}
                  />
                  {errors?.license && (
                    <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                      {errors.license}
                    </Typography>
                  )}

                  <Box
                    style={{
                      height: "256px",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#F3F4F6",
                      marginTop: "25px",
                    }}
                  >
                    <input
                      type="file"
                      id={`license-file-${index}`}
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => this.handleFileChange(e, "qualityFile3", index, "licenses")}
                    />
                    {license.qualityFile3Name && (
                      <Typography
                        style={{
                          fontFamily: "Inter, sans-serif",
                          fontSize: "14px",
                          fontWeight: 400,
                          marginLeft: "10px",
                          color: "blue",
                          paddingBottom: "10px",
                        }}
                      >
                        {license.qualityFile3Name}
                      </Typography>
                    )}

                    <label htmlFor={`license-file-${index}`}>
                      <Button
                        variant="contained"
                        startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />} style={{
                          backgroundColor: "#FF6600",
                          color: "#FFFFFF",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "none",
                          borderRadius: "12px",
                          padding: "16px 14px 16px 14px",
                          height: "34px",
                          width: "90px",
                          gap: "6px",
                          cursor: "pointer"
                        }}
                        component="span"
                      >
                        Upload
                      </Button>
                    </label>
                    <br />

                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        marginTop: "3px",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      OR
                    </Typography>

                    <Box style={{ display: "flex", marginLeft: "7px" }}>
                      <label
                        htmlFor={`license-file-${index}`}
                        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                      >
                        <Typography
                          component="span"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            color: "#FF6600",
                            fontFamily: "Inter, sans-serif",
                            fontSize: "14px",
                            fontWeight: 400,
                            marginTop: "8px",
                          }}
                        >
                          Browse Files
                        </Typography>
                        <img
                          src={FilesImg}
                          alt="FilesImg"
                          style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "12px" }}
                        />
                        <input
                          type="file"
                          id={`upload-file-${index}`}
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => this.handleFileChange(e, "qualityFile3", index, "licenses")}
                        />
                      </label>

                    </Box>
                  </Box>

                </Box>
              ))}
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                <label
                  htmlFor="quality-test-upload"
                  style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}
                  onClick={this.addLicenseField}
                >
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add Another License
                  </Typography>
                </label>
              </Box>
            </Box>

          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              component="span"
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.postAddBrandApi}
            >
              Save
            </Button>
          </Box>
        </>
      );
    };


    const renderBusinessInformation = () => {
      const { errors } = this.state;
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Legal Business Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "legalName")}
                  value={this.state.legalName}
                  placeholder="Legal Business Name"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.legalName && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.legalName}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Tax Identification Number (TIN/VAT/GST)*</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "taxNumber")}
                  value={this.state.taxNumber}
                  placeholder="Tax Identification Number"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.taxNumber && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.taxNumber}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box>
              <Typography style={{ ...webStyle.Title }}>
                Business Address
              </Typography>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Street</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "street")}
                  value={this.state.street}
                  placeholder="Enter Street"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.street && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.street}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>City</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "city")}
                  value={this.state.city}
                  placeholder="Enter City"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.city && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.city}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "24px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>State</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "state")}
                  value={this.state.state}
                  placeholder="Enter State"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.state && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.state}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Country</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "country")}
                  value={this.state.country}
                  placeholder="Enter Country"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.country && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.country}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Zip Code</Typography></Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "zipcode")}
                  value={this.state.zipcode}
                  placeholder="Enter Zip Code"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderBrandConDetails = () => {
      const { errors } = this.state;
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Contact Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "primaryConName")}
                  value={this.state.primaryConName}
                  placeholder="Enter Contact Name"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.primaryConName && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.primaryConName}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Primary Phone Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "primaryPhoneNum")}
                  value={this.state.primaryPhoneNum}
                  placeholder="Enter Phone Number"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontSize: "14px",
                      fontFamily: "Inter, sans-serif",
                      color: "#1E293B",
                    },
                  }}
                />
                {errors?.primaryPhoneNum && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.primaryPhoneNum}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alternative Contact Information</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "alternativeContact")}
                  value={this.state.alternativeContact}
                  placeholder="Enter Alternative Contact Information"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.alternativeContact && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.alternativeContact}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Customer Support Contact Details</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "customerSupport")}
                  value={this.state.customerSupport}
                  placeholder="Enter Customer Support Contact Details"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.customerSupport && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.customerSupport}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Email Address</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "emailAddress")}
                  value={this.state.emailAddress}
                  placeholder="Enter Email Address"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, fontFamily: "Inter, sans-serif", color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "14px", fontFamily: "Inter, sans-serif", color: "#1E293B", },
                  }}
                />
                {errors?.emailAddress && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.emailAddress}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.handleSaveBrandConDetails}
            >
              Save
            </Button>
          </Box>
        </>
      );
    };

    const renderOtherTabs = () => {
      const { regions, yourChips, region, errors } = this.state;
      return (
        <>
          <Box style={{
            padding: "20px",
            display: "flex",
            gap: "20px",
            borderBottom: "1px solid #E1E7EF",
          }}>
            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%" }}>
              {this.state.cities.map((city: any, index: any) => (
                <Box key={index} style={{ ...webStyle.formItem, marginBottom: "15px" }}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>Select City</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <Autocomplete
                    id={`catalogue-field-${index}`}
                    disablePortal
                    open={this.state.openAutocomplete[index]}
                    onOpen={() => this.handleAutocompleteToggle(index)}
                    onClose={() => this.handleAutocompleteToggle(index)}
                    options={options}
                    value={city.cityName}
                    onChange={(event: any, value: any) => this.handleCityChange(event, value, index)}
                    data-testid="autocomplete-dropdown"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: "16px",
                            fontWeight: 400,
                            fontStyle: "Inter, sans-serif",
                            color: "#BCC1CA",
                          },
                        }}
                        placeholder="Select City"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            fontSize: "16px",
                            fontFamily: "Inter, sans-serif",
                            color: "#1E293B",
                            padding: "10px",
                            height: "44px",
                            borderRadius: "8px",
                            background: "#F3F4F6",
                          },
                          endAdornment: (
                            <img
                              src={downImage}
                              alt="Dropdown Icon"
                              style={{ width: "15px", height: "15px", marginRight: "8px" }}
                            />
                          ),
                        }}
                        data-testid="autocomplete-input"
                      />
                    )}
                    sx={{
                      "& .MuiAutocomplete-paper": {
                        borderRadius: "8px",
                        maxHeight: "200px",
                        boxShadow: "none",
                        border: "1px solid #F3F4F6",
                      },
                    }}
                  />
                  {errors?.city && (
                    <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                      {errors.city}
                    </Typography>
                  )}
                </Box>
              ))}

              {/* Add Another City Button */}
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                <label
                  htmlFor="quality-test-upload"
                  style={{ display: "flex", cursor: "pointer", marginTop: "20px" }}
                  onClick={this.addCityField}
                >
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>
                    Add Another City
                  </Typography>
                </label>
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%" }}>
              <Box style={{ ...webStyle.formItem }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Region</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>

                <Autocomplete
                  multiple
                  style={{ border: "none" }}
                  options={regions}
                  value={yourChips}
                  onChange={this.handleRegionSelect}
                  renderTags={(value: any, getTagProps: any) =>
                    value.map((chip: any, index: number) => (
                      <Chip
                        style={{
                          height: "24px",
                          width: "80px",
                          color: "#171A1F",
                          background: "#DDDFE1",
                          borderRadius: "4px",
                          fontSize: "16px",
                          fontWeight: 400
                        }}
                        label={chip}
                        deleteIcon={<CloseIcon style={{ height: 12, width: 12, color: "#171A1F" }} />}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Select Region"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "16px",
                          fontFamily: "Inter, sans-serif",
                          color: "#BCC1CA",
                          height: "44px",
                          borderRadius: "8px",
                          background: "#F3F4F6",
                          paddingLeft: "8px",
                          border: "none",
                        },
                        endAdornment: (
                          <img
                            src={downImage}
                            alt="Dropdown Icon"
                            style={{ width: "15px", height: "15px", marginLeft:"5px" }}
                          />
                        ),
                      }}

                    />
                  )}
                />

                {errors?.region && (
                  <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "8px" }}>
                    {errors.region}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.handleSaveOtherTabs}
            >
              Save
            </Button>
          </Box>
        </>
      );
    };
    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography style={webStyle.Headingss}>
            Add New Brand
          </Typography>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              {renderTabContent()}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar history={this.props.history}/>
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Addbrands()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",

  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",
  },
  Headingss: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "1.5",
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    alignItems: "start",
  },
  formGrids: {
    gap: "20px",
    alignItems: "start",
    width: "49%"
  },

  formItem: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
    width: '100%' as string,
  },
  AddType: {
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FF6600",
    marginLeft: "8px"
  },
  formField: {
    width: '100%',
    height: '44px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "Inter, sans-serif",
  },
  textFieldStyle: {
    fontSize: "14px",
    fontWeight: 400,
    backgroundColor: "#F3F4F6",
    border: "none",
    borderRadius: "8px",
    boxShadow: "none",
  },
  uploadContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontFamily: "Inter, sans-serif",
  },

  formHeading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px",
    borderBottom: "1px solid #E1E7EF"
  },
  activeTab: {
    fontFamily: 'Inter, sans-serif',
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'center' as 'center',
    color: '#EE6900',
  },
  inactiveTab: {
    fontFamily: 'Inter, sans-serif',
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    color: '#565E6C',
  },
  Title: {
    fontFamily: 'Inter, sans-serif',
    marginBottom: "20px",
    fontSize: '20px',
    fontWeight: 600,
  }
}


// Customizable Area End