import React from "react";
// Customizable Area Start
import { Typography, Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import {
  about,
  vector,
  trust,
  commit,
  customer,
  boxIcon,
  whatsapp,
  havels,
  gmIcon,
  crom,
  polycab,
  footerlocation,
  phone,
  mail,
  product,
  locationColor,
  downColor,
  filter,
  arrow,
  postman,
  jira,
  jiter,
  awsIcon,
  zapier,
  keyIcon,
  kxLogo,
  builMat,
  acc1,
  ultra2,
  jindal3,
  pidilite4,
  polycab5,
  rr6,
  kalika7,
  globe8,
  greenply9,
  dots,
  code1,
  stack2,
  time3,
  price4,
  deliver5,
  hands6,
  find7,
  quality8,
  circle,
  x1,
  facebook2,
  linkedin3,
  youtube4,
  chat
} from "./assets";
// Customizable Area End

// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController";
import Navbar from "../../../components/src/Navbar.web";
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Footer from "../../../components/src/Footer.web";
import UserNavbar from "../../../components/src/UserNavbar.web";


const AboutBlock = styled("div")(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  cursor: "default",
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden'
  },
  "& .MuiTypography-body1": {
    fontFamily: 'Inter, sans-serif',
  },
  "& .logo": {
    "@media (max-width:900px)": {
      width: "60px"
    },
  },
  "& .mainTextCat": {
    fontSize: "24px",
    fontWeight: 500,
    color: "#272727",
    marginTop: "120px",
    marginLeft: "40px",
    width: "700px",
    "@media (max-width:900px)": {
      marginTop: "50px",
      width: "80vw",
    },
  },
  "& .grayText": {
    color: "#7F7D7D",
  },
  "& .grayTextsec": {
    color: "#7F7D7D",
    fontWeight: 400,
  },
  "& .seccondaryTextCat": {
    fontSize: "16px",
    fontWeight: 600,
    color: "#272727",
    width: "500px",
    marginTop: "20px",
    marginLeft: "40px",
    "@media (max-width:900px)": {
      width: "75vw",
    },
  },
  "& .pageCatAndApp": {
    display: "flex",
    justifyContent: "center",
    marginTop: "80px",
    "@media (max-width:900px)": {
      width: "100vw",
    },
  },
  "& .categories-active": {
    fontSize: "14px",
    fontWeight: 700,
    color: "#171A1F",
    width: "127px",
    padding: "6px",
    borderBottom: "4px solid #171A1F",
    textAlign: "center",
    "@media (max-width:900px)": {

    },
  },
  "& .categories": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#565E6C",
    width: "115px",
    textAlign: "center",
    padding: "6px",
  },
  "& .catCard": {
    width: "120px",
    height: "110px",
    borderRadius: "12px",
    backgroundColor: "#FBFBFB",
    marginLeft: "14px",
    marginTop: "12px",
    textAlign: "center",
    display: "flex",
    border: "1px solid rgba(222, 225, 230, 0.2)",
  },
  "& .catCards": {
    width: "900px",
    height: "240px",
    marginTop: "12px",
    marginLeft: "40px",
    "@media (max-width:900px)": {
      width: "100vw",
      height: "100%",
      marginLeft: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  },
  "& .catNameText": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842",
  },
  "& .viewAllText": {
    fontSize: "12px",
    fontWeight: 600,
    color: "#EE6900",
  },
  "& .viewAllContainer": {
    display: "flex",
    justifyContent: "flex-end",
    width: "830px",
    marginTop: "30px",
    "@media (max-width:900px)": {
      width: "90vw",
    },

  },
  "& .buildMat": {
    width: "100%",
    height: "640px",
    position: "static",
    top: "100px",
    right: "0px",
    transition: "opacity 0.2s ease-in-out",
    opacity: 1,
    "@media (max-width:960px)": {
      display: "none"
    },
  },
  "& .buildMat.fade-out": {
    opacity: 0,
  },
  "& .buildMat.fade-in": {
    opacity: 1,
  },
  "& .topBrands": {
    marginTop: "150px",
    backgroundColor: "rgba(0, 0, 0, .02)",
    paddingTop: "30px",
    zIndex: 10,
    marginBottom: "30px",
    position: "relative",
    "@media (max-width:900px)": {
      marginTop: "100px",
    },

  },
  "& .topBrandsText": {
    fontSize: "28px",
    fontWeight: 700,
    color: "#323842",
    display: "flex",
    justifyContent: "center",
    "@media (max-width:900px)": {
      width: "100vw"
    },
  },
  "& .topBrandsImg": {
    height: "50px",
    "@media (max-width:900px)": {
      marginTop: "10px",
    },
  },
  "& .topBrandsImages": {
    display: "flex",
    margin: "30px 40px",
    justifyContent: "space-between",
    "@media (max-width:900px)": {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      alignItems: "center",
      margin: "30px 0px",
    },
  },
  "& .whyKratexText": {
    textAlign: "center",
    "@media (max-width:900px)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  },
  "& .whyKratexTextMain": {
    fontSize: "28px",
    fontWeight: 700,
    color: "#323643"
  },
  "& .whyKratexTextSecondary": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#323643",
    "@media (max-width:900px)": {
      width: "90vw",
      textAlign: "center"
    },
  },
  "& .kratexMarketplaceCard": {
    textAlign: "center",
    alignItems: "center",
  },
  "& .kratexText": {
    fontSize: "30px",
    fontWeight: 500,
    color: "#323643"
  },
  "& .marketplaceText": {
    fontSize: "30px",
    fontWeight: 600,
    color: "#EE6900",
    marginTop: "-5px"
  },
  "& .aboutMarketplace": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#7F7D7D",
  },
  "& .learnMoreBtn": {
    color: "#FFF",
    backgroundColor: "#FF6600",
    height: "32px",
    borderRadius: "8px",
    padding: "10px 6px",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    borderColor: "transparent",
    alignItems: "center",
    display: "flex"
  },
  "& .learnMoreBtnText": {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center"
  },
  "& .whyKratexCards": {
    width: "93vw",
    margin: "70px 40px",
    borderBottom: "1px solid #F8F8F8",
    "@media (max-width:900px)": {
      margin: "70px 0px",
      width: "100vw",
    },
  },
  "& .whyKratexCard": {
    textAlign: "center"
  },
  "& .whyKratexCardText": {
    fontSize: "26px",
    fontWeight: 600,
    color: "#323643"
  },
  "& .whyKratexCardTextSec": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#323643"
  },
  "& .aboutKratexBoxSec": {
    marginTop: "14px",
    border: "1px solid #DEE1E6",
    borderRadius: "24px",
    fontSize: "14px",
    padding: "20px 16px",
    backgroundColor: 'white'
  },
  "& .learnMoreBtnKratexCard": {
    color: "#FF6600",
    backgroundColor: "#FFF6F0",
    height: "32px",
    borderRadius: "8px",
    padding: "10px 6px",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
    borderColor: "transparent",
    alignItems: "center",
    display: "flex"
  },
  "& .learnMoreBtnTextKratex": {
    color: "#FF6600",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .aboutKratexTextSec": {
    fontSize: "14px",
    fontWeight: 400,
    color: "#9095A1"
  },
  "& .learnMoreBtnKratexCardBox": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px"
  },
  "& .learnMoreBtnKratexCardBox-main": {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  "& .firstDesignDots": {
    width: "200px",
    height: "160px",
    position: 'absolute',
    top: "1054px",
    left: "-45px",
    objectFit: "cover",
    zIndex: -10,
    "@media (max-width:900px)": {
      display: "none"
    },
  },
  "& .secondDesignDots": {
    width: "170px",
    height: "160px",
    position: 'absolute',
    top: "1390px",
    right: "0px",
    zIndex: -1,
    objectFit: "cover"
  },
  "& .FeatureText": {
    fontSize: "28px",
    fontWeight: 700,
    color: "#323643",
    textAlign: "center"
  },
  "& .featureCard": {
    width: "170px",
    height: "150px",
    borderRadius: "12px",
    margin: "15px",
    display: "grid",
    textAlign: "center",
    gridTemplateRows: "1fr 1fr",
    alignItems: "center"
  },
  "& .featureImgCard": {
    height: "30px",
    justifySelf: "center"
  },
  "& .featureTextCard": {
    width: "153px",
    color: "#323643",
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    alignSelf: "start",
    "@media (max-width:700px)": {
      fontSize: "12px",
      whiteSpace: "wrap",
    },
    "@media (max-width:1200px)": {
      width: "100%"
    },
  },
  "& .featuresCards": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
    "@media (max-width:900px)": {
      marginLeft: "0vw",
    },
  },
  "& .featuresCardsCont": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .thirdDesignDots": {
    width: "95px",
    height: "90px",
    position: 'absolute',
    top: "1534px",
    left: "55px",
    zIndex: -1,
    objectFit: "cover"
  },
  "& .designCircle": {
    width: "372px",
    height: "425px",
    position: 'absolute',
    top: "1750px",
    left: "-255px",
    zIndex: -1,
    "@media (max-width:900px)": {
      top: "4250px",
    },
  },
  "& .chat": {
    width: "43px",
    height: "38px",
    position: 'fixed',
    bottom: "35px",
    right: "40px",
    zIndex: 11,
    backgroundColor: "#EE6900",
    borderRadius: "19px",
    "@media (max-width:900px)": {
      bottom: "30px",
      right: "30px"
    },
  },
  "& .chatIco": {
    width: "23px",
    height: "23px",
    marginTop: "7.5px",
    marginLeft: '10px'
  },
  "& .helpChat": {
    width: "263px",
    height: "38px",
    position: 'fixed',
    bottom: "80px",
    right: "40px",
    backgroundColor: "#FFF",
    borderRadius: "12px",
    border: "1px solid #E1E7EF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "@media (max-width:900px)": {
      bottom: "80px",
      right: "30px"
    },
  },
  "& .helpChatText": {
    color: "#171A1F",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .chatClose": {
    color: "#171A1F",
    width: "12px",
    height: "12px"
  },

  "& .catCardImg":{
    height:"110px",
    width:"120px",
    transition: "transform 0.3s ease",
    display: "none",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }
}));
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  data = [
    {
      price: "USD 32,000",
      name: "4 hrs ago",
      age: "1.18%",
      brand: "GM - Cuba series",
      action: whatsapp,
      id: "Universal Stainer",
    },
    {
      id: "Plug Top",
      brand: "Havells",
      price: "USD 12,000",
      age: "2.88%",
      action: whatsapp,
      name: "8 hrs ago",
    },
    {
      brand: "Polycab",
      name: "1 days ago",
      price: "USD 44,000",
      action: whatsapp,
      id: "Multi Plug Adaptor",
      age: "0.68%",
    },
    {
      id: "Pendant Light",
      name: "10 days ago",
      price: "USD 52,000",
      brand: "Crompton",
      age: "3.68%",
      action: whatsapp,
    },
    {
      id: "Wardrobe Sliding System",
      name: "15 min ago",
      brand: "GM - Cuba series",
      action: whatsapp,
      age: "5.8%",
      price: "USD 35,000",
    },
  ];

  settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  categories = [

    {name:"Steel",
      img:require("../assets/Steel_cut.png")
    }, 
    {name:"Cement",
    img:require("../assets/Cement_cut.png")
    },  
    {name:"Plumbing",
    img:require("../assets/Pipes_cut.png")
    },  
    {name:"Electrical", 
    img:require("../assets/Electric_cut.png")
    }, 
    {name:"Fire", 
    img:require("../assets/Fire_cut.png")
    }, 
    {name:"Safety", 
    img:require("../assets/Safety_cut.png")
    }, 
    {name:`Tiles & Surfaces`, 
    img:require("../assets/Tiles_cut.png")
    }, 
    {name:`Paints & Polishes`, 
    img:require("../assets/Paints_cut.png")
    }, 
    {name:`Wood & Laminates`, 
    img:require("../assets/Wood_cut.png")
    }, 
    {name:`Blocks and Bricks`, 
    img:require("../assets/Blocks_cut.png")
    }, 
    {name:`Raw materials`, 
    img:require("../assets/Raw_cut.png")
    }, 
    {name:`RMC`,
    img:require("../assets/RMC_cut.png")
    }, 
];
  topBrands = [acc1, ultra2, jindal3, pidilite4, polycab5, rr6, kalika7, globe8, greenply9];
  feature = [{
    img: code1,
    description: '1 Lakh+ Multi-Brand SKUs'
  },
  {
    img: stack2,
    description: "200+Brands"
  },
  {
    img: time3,
    description: "Up to 45 Days Credit*"
  },
  {
    img: price4,
    description: "Lowest Prices"
  },
  {
    img: deliver5,
    description: "On time Delivery"
  },
  {
    img: hands6,
    description: "Personal Relationship Manager"
  },
  {
    img: find7,
    description: "Complete Transparency"
  },
  {
    img: quality8,
    description: "Quality Assurance"
  }
  ]
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <div>
        {localStorage.getItem("userType")==="seller"? (
         <UserNavbar handleToggleSidebar={this.handleToggleSidebar} data-test-id="UserNavbar" history={this.props.history} />           
        ) : (
          <Navbar handleNavigate={this.handleNavigate} data-test-id="Navbar" {...this.props} />
        )}

        <AboutBlock>
          <Grid container>
            <Grid item className="CatAndApp" xs={12} sm={12} md={8} lg={8}>
              <Grid container direction="column">
                <Grid item direction="column">
                  <Typography className="mainTextCat">
                    <span className="grayText">The</span> most intelligent and efficient way <span className="grayText">to</span> source Building Materials <span className="grayText">powered by</span> ProdIQ and Kratex AI Assistant<span style={{ color: "#FF6600" }}>.</span>
                  </Typography>
                  <Typography className="seccondaryTextCat">
                    <span className="grayTextsec">Search, discover, sample, quote and purchase from over hundreds of supplier</span> - all in one place.
                  </Typography>
                </Grid>
                <Grid item direction="row" className="pageCatAndApp" xs={12} sm={12} md={8} lg={8}>
                  <Typography className="categories-active">Categories</Typography>
                  <Typography className="categories">Applications</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={3} className="catCards">
                    {this.categories.map((elem, i) => {
                      return (
                        <Grid item className="catCard" justifyContent="center" alignItems="flex-end" onMouseEnter={() => this.changeLargeImg(elem.img)} onMouseLeave={() => this.changeLargeImg(builMat)}>
                          <Typography className="catNameText">{elem.name}</Typography>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item className="viewAllContainer">
                  <Typography className="viewAllText">Request Access</Typography>
                </Grid>
              </Grid>

            </Grid>
            <Grid item className="buildMaterialsImg" xs={1} sm={1} md={4} lg={4}>
              <img src={this.state.imgSrc} className={`buildMat ${this.state.fadeOut ? 'fade-out' : 'fade-in'}`} />
            </Grid>
          </Grid>
          <Box className="helpChat">
            <Typography className="helpChatText">
              Hi, Message us if you need anything
            </Typography>
            <CloseIcon className="chatClose" />
          </Box>
          <Box className="chat">
            <img src={chat} className="chatIco" />
          </Box>
          <Grid container direction="column" className="topBrands">
            <Grid item className="topBrandsText">
              <Typography className="topBrandsText">Top Brands</Typography>
            </Grid>
            <Grid item className="topBrandsImages" direction="row">
              {this.topBrands.map((elem, i) => {
                return (
                  <Grid item className="topBrandsImg">
                    <img src={elem} className="topBrandsImg" />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid container direction="column">
            <img src={dots} className="firstDesignDots" />
            <img src={dots} className="secondDesignDots" />
            <Grid item direction="column" className="whyKratexText">
              <Typography className="whyKratexTextMain">Why KrateX</Typography>
              <Typography className="whyKratexTextSecondary">Revolutionizing your raw material procurement process with our cutting-edge solutions.</Typography>
            </Grid>
            <Grid item direction="row">
              <Grid container direction="row" className="whyKratexCards" spacing={10} justifyContent="space-around">
                <Grid item direction="column" className="kratexMarketplaceCard" xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Typography className="kratexText">KrateX</Typography>
                  <Typography className="marketplaceText">Marketplace</Typography>
                  <Typography className="aboutMarketplace">
                    Experience smarter, more efficient procurement with our diverse marketplace
                    integrated with  AI-driven ProdIQ and KrateX AI Assistant. Enjoy automated workflows,
                    real-time insights, and complete transparency, enabling end-to-end procurement and
                    supply chain efficiency, all trackable on our ProcureTech Dashboard.
                  </Typography>
                  <Box className="learnMoreBtnKratexCardBox-main">
                    <button className="learnMoreBtn">
                      <Typography className="learnMoreBtnText">Learn More</Typography>
                    </button>
                  </Box>
                </Grid>
                <Grid item direction="column" className="whyKratexCard" xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Typography className="whyKratexCardText">Prod<span style={{ color: "#EE6900" }}>IQ</span></Typography>
                  <Typography className="whyKratexCardTextSec">Standardize. Harmonize. Synchronize.</Typography>
                  <Box className="aboutKratexBoxSec">
                    <Typography className="aboutKratexTextSec">
                      It is the most efficient PIM solution for the construction industry,
                      offering real-time data synchronization, effortless backend integration,
                      and AI-driven data cleansing for accurate, up-to-date product information.
                    </Typography>
                    <Box className="learnMoreBtnKratexCardBox">
                      <button className="learnMoreBtnKratexCard">
                        <Typography className="learnMoreBtnTextKratex">Learn More</Typography>
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item direction="column" className="whyKratexCard" xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Typography className="whyKratexCardText">Procure<span style={{ color: "#EE6900" }}>Tech</span></Typography>
                  <Typography className="whyKratexCardTextSec">Source. Automate. Control. </Typography>
                  <Box className="aboutKratexBoxSec">
                    <Typography className="aboutKratexTextSec">
                      It is the most efficient PIM solution for the construction industry,
                      offering real-time data synchronization, effortless backend integration,
                      and AI-driven data cleansing for accurate, up-to-date product information.
                    </Typography>
                    <Box className="learnMoreBtnKratexCardBox">
                      <button className="learnMoreBtnKratexCard">
                        <Typography className="learnMoreBtnTextKratex">Learn More</Typography>
                      </button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item direction="column" className="whyKratexCard" xs={12} sm={12} md={6} lg={3} xl={3}>
                  <Typography className="whyKratexCardText">KrateX <span style={{ color: "#EE6900" }}>AI</span></Typography>
                  <Typography className="whyKratexCardTextSec">Source. Automate. Control.</Typography>
                  <Box className="aboutKratexBoxSec">
                    <Typography className="aboutKratexTextSec">
                      It is the most efficient PIM solution for the construction industry,
                      offering real-time data synchronization, effortless backend integration,
                      and AI-driven data cleansing for accurate, up-to-date product information.
                    </Typography>
                    <Box className="learnMoreBtnKratexCardBox">
                      <button className="learnMoreBtnKratexCard">
                        <Typography className="learnMoreBtnTextKratex">Learn More</Typography>
                      </button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="column" justifyContent="center" className="featuresCardsCont">
            <img src={dots} className="thirdDesignDots" />
            <img src={circle} className="designCircle" />
            <Typography className="FeatureText">Features</Typography>
            <Grid item xs={12} sm={12} md={12} lg={12} className="featuresCards">
              <Grid container justifyContent="center">
                {this.feature.map((elem, i) => {
                  return (
                    <Grid item direction="column" className="featureCard" alignItems="center" justifyContent="center" xs={2} sm={2} md={1} lg={1}>
                      <img src={elem.img} className="featureImgCard" key={i} />
                      <Typography className="featureTextCard" >{elem.description}</Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </AboutBlock>
        <Footer />
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  container: {
    textAlign: "center",
    marginTop: "10px",
  },
  priceBoxSlider: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  tableBoxFirst: {
    display: "flex",
    width: "120px",
    height: "100%",
    padding: "12px 12.5px",
    margin: "-2px",
    borderRight: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
    "@media (max-width:900px)": {
      padding: "12px 0",
    },
  },
  tableBox: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    padding: "12px 12.5px",
    margin: "-2px",
    borderRight: "1px solid #CBD5E1",
    borderBottom: "1px solid #CBD5E1",
    "@media (max-width:900px)": {
      padding: "12px 0",
    },
  },
  tableBoxLast: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    padding: "12px 12.5px",
    margin: "-2px",
    borderBottom: "1px solid #CBD5E1",
    "@media (max-width:900px)": {
      padding: "12px 0",
    },
  },
  priceBoxRightSection: {
    //padding: "1rem",
    // border: "1px solid #CBD5E1",
    width: '100%',
    borderRadius: "8px",
    marginTop: "10px",
    "@media (max-width:900px)": {
      padding: "0 !important",
    },
  },
  priceBoxRightSectionTop: {
    display: "flex",
    justifyContent: 'space-between',
    padding: "1rem",
    borderRadius: '5px',
    border: "1px solid #CBD5E1",
    "@media (max-width:900px)": {
      padding: "0",
    },
  },
  readMore: {
    display: "flex", justifyContent: "start", marginLeft: "10px", width: { lg: "74%", md: '74%', xs: "100%" }, alignItems: "center"
  },
  displayCenter: {
    display: "flex", justifyContent: "center"
  },
  logoImg: {
    minWidth: "325px", mb: 3, display: "flex", justifyContent: "center", alignItems: "center"
  },
  logoImgBox: {
    minWidth: "325px", mb: 4, display: "flex", justifyContent: "center", alignItems: "center"
  },
  logoMainBox: {
    display: "flex", float: "right", marginBottom: "54px", flexWrap: "wrap", justifyContent: "center", alignItems: "center",
    "@media (max-width:900px)": {
      marginTop: "20px",
    },
  },
  sliderImg: {
    zIndex: 1,
    borderRadius: "8px"
  }
};
// Customizable Area End