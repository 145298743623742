import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start

  quotationData: {
    type: string;
    quantity: string;
  }[];

  sidebar: boolean;
  open: { [key: string]: boolean };
  openp: boolean
  opens: boolean;
  openAc: boolean;
  openss: boolean;
  openssc: boolean;
  role: string
  toggle: boolean;
  totalCandidates: string;
  selectedId: any;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  active: string;
  value: number,
  searchTerm: string;
  showAdditionalRow: boolean;
  anchorEL: HTMLElement | null;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  expandedRows: { [key: number]: boolean };
  QuotationitemList: {
    id: number;
    type: string;
    attributes: {
      quotation_id: string;
      account_id: number;
      category_id: number;
      total_price: number;
      status: string;
      amount: number;
      Project: string;
      Company: string;
      validity: string;
      last_updated_date: string,
      last_updated_time: string
      brand: string;
      quotation_items: {
        id: number,
        quotation_id: number,
        product_id: number,
        product_name: string,
        brand_name: string
      }
    };
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QuatationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllquotationItemList: string = "";
  rejectquotationComperisionItemList: any;
  acceptQuotationComparisonItemList: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      type: "",
      openp: false,
      sidebar: false,
      quotationData: [],
      openAc: false,
      openss: false,
      openssc: false,
      opens: false,
      totalCandidates: "",
      errorMsg: "",
      open: {},
      token: "",
      selectedId: null,
      role: "user",
      toggle: false,
      loading: false,
      active: "",
      value: 0,
      searchTerm: "",
      showAdditionalRow: false,
      anchorEL: null,
      totalResults: 0,
      resultperPage: 12,
      currentPage: 1,
      QuotationitemList: [],
      expandedRows: {},
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getToken();
    this.getAllQuatationItems();
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getAllquotationItemList) {
        this.handelQuatationResponse(responseJson);
      }
      if (apiRequestCallId === this.rejectquotationComperisionItemList) {
        this.handelStatusQuatationResponse(responseJson);

      }
      if (apiRequestCallId === this.acceptQuotationComparisonItemList) {
        this.handelacceptQuatationResponse(responseJson);

      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if the current page has changed
  }

  handelacceptQuatationResponse(responseJson: any) {
    if (responseJson?.message) {
      setTimeout(() => {
        this.setState({ anchorEL: null });
        this.handleActpopups();
      }, 2000);
    }
  }


  handelStatusQuatationResponse(responseJson: any) {
    if (responseJson?.message) {
      setTimeout(() => {
        this.setState({ anchorEL: null });
        this.handlecloses();
        this.handleclose();
        this.handleAccloses();
        window.location.href = "/Quotations";
      }, 2000);
    }
  }



  handelQuatationResponse(responseJson: any) {
    if (responseJson.data) {
      const { count, items, page } = responseJson?.data?.meta?.pagy || {};
      this.setState(
        {
          QuotationitemList: responseJson?.data?.data,
          currentPage: page || 1, 
          totalResults: count || 12,
          resultperPage: items || 12,
        },
        () => {
          console.log("Updated Current Page:", this.state.totalResults);
        }
      );
    }
  }
  

  getAllQuatationItems = async (page= 1) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllquotationItemList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getQuotationItem}?page=${page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getResultTextApplication = (page: number): string => {
    const { resultperPage, totalResults} = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }


  handleSelectItem = (id: any) => {
    sessionStorage.setItem("Id", id)
  };
  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };


  handleNavigate = (route?: string, id?: number) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    id && message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);

    this.send(message);
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  handleMenuClick = (event: React.MouseEvent<HTMLElement>, rowId: number): void => {
    this.setState({ anchorEL: event.currentTarget || null, selectedId: rowId });
  };
  handleMenuClose = (): void => {
    this.setState({ anchorEL: null });
  };
  handleclose = () => {
    this.setState({ openp: false });
  }

  handlepopup = (id: any) => {
    this.setState({ openp: true }, () => {
      this.handleNegotiate(this.state.selectedId)
    });
  }

  handlepopups = () => {
    this.setState({ opens: true });
  }
  handlecloses = () => {
    this.setState({ opens: false });
  }
  handleReject = () => {
    this.rejectQuatationComparisionItems(this.state.selectedId, "cancelled");
  };

  handleNegotiate = (quotationId: any) => {
    this.rejectQuatationComparisionItems(quotationId, "negotiation");
  };

  handleAcpopups = () => {
    this.setState({ openAc: true });
  }
  handleAccloses = () => {
    this.setState({ openAc: false });
  }

  handleActpopups = () => {
    this.setState({ openAc: false, openss: true });
  }
  handleActcloses = () => {
    this.setState({ openss: false });
  }
  handleSuccesspopups = () => {
    this.setState({ openss: false, openssc: true });
    setTimeout(() => {
      this.setState({ openssc: false });
      window.location.href = "/Quotations";
    }, 2000)
  }
  handleSuccesscloses = () => {
    this.setState({ openssc: false });
  }


  handleViewClick = (id: number) => {
    sessionStorage.setItem("Id", id.toString())
    this.handleNavigate("QuotationComparision", id)
  };

  handleExpandClick = (id: number) => {
    this.setState(prevState => ({
      expandedRows: {
        ...prevState.expandedRows,
        [id]: !prevState.expandedRows[id],
      },
    }));
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, value: any) => {
    this.setState({ currentPage: value });
    this.getAllQuatationItems(value);

  };

  badgecount = (count: number) => {
    return count > 100 ? '99+' : count.toString()
  };

  rejectQuatationComparisionItems = async (quotationId: any, status: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const apiendpoint = `${configJSON.rejectComperisionQuotationItem}?id=${quotationId}&status=${status}`;
    this.rejectquotationComperisionItemList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  acceptQuotationComparisonItems = async (quotationId: any, status: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const encodedItems = encodeURIComponent(JSON.stringify(12));
    const apiendpoint = `${configJSON.acceptComperisionQuotationItem}?id=${quotationId}&items=${encodedItems}&status=${status}`;

    this.acceptQuotationComparisonItemList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };



  // Customizable Area End
}
