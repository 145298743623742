import React, { ReactNode } from "react";

// Customizable Area Start
import {
  Typography,
  Box,
  Table,
  TableContainer,
  Tabs,
  Tab,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { AddImg, downImage, FilesImg, uploadicon } from "./assets";

import AddProductController, {
  DropdownOption,
  Props,
} from "./AddProductController";
import Usernavbar from "../../../components/src/UserNavbar.web";
import SideBar from "../../../components/src/SideBar.web"
import { Autocomplete, Button } from "@mui/material"


// Customizable Area End

export default class AddProduct extends AddProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  /*istanbul ignore next*/
  AddProduct = () => {
    const tabData = [
      { label: 'Basic', value: 0 },
      { label: 'Feature', value: 1 },
      { label: 'Brand Details', value: 2 },
      { label: 'Standards', value: 3 },
      { label: 'Product Descriptions', value: 4 },
      { label: 'Detailed Specifications', value: 5 },
      { label: 'Images', value: 6 },
      { label: 'Documents', value: 7 },
      { label: 'Quality Tests and Certifications', value: 8 },
    ];

    const tabData1 = [
      { label: 'Brand Details', value1: 0 },
      { label: 'General Properties', value1: 1 },
      { label: 'Physical Properties', value1: 2 },
    ];


    const optionss = this.state.brandData.map((item: any) => ({
      id: item.id,
      label: item.attributes.name,
    }))

    const options = [
      {
        label: "Havells",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Polycab",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Bosch",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Phillips",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "RR Cabel",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];
    const options1 = [
      {
        label: "Collection 01",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 02",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 03",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 04",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "Collection 05",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ];
    const optionsstandards = [
      {
        label: "IS",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "EN",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
      {
        label: "ASTM",
        size: "Size: 625x240x100mm Make: Alpiine",
      },
    ]

    const renderTabContent = () => {
      switch (this.state.value) {
        case 0:
          if (this.state.path === "/ProductList") {
            this.setState({ value: 1 })
          } else {
            return renderBasicDetails();
          }
        case 1:
          return renderFeatureDetails();
        case 2:
          return renderBrandDetails();
        case 3:
          return renderStandards();
        case 4:
          return renderProductDescription();
        case 5:
          return renderDetailedSpecification();
        case 6:
          return renderImages();
        case 7:
          return renderDocuments();
        default:
          return renderQualityTest();
      }
    };

    const renderTabContent1 = () => {
      switch (this.state.value1) {
        case 0:
          return renderBrandDetails1();
        case 1:
          return renderGeneralProp();
        default:
          return renderPhysicalProp();
      }
    };
    const renderBasicDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", fontFamily: "Inter, sans-serif" }}>
            <Box>
              <Typography style={{ ...webStyle.Title }}>
                UPID Version Data
              </Typography>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>PIM Product Identifier(UPID)</Typography>
              </Box>
              <TextField
                placeholder="Enter Product Identifier"
                onChange={(e) => this.handleChange(e, "upid")}
                value={this.state.upidName}
                style={{
                  ...webStyle.formField, width: "100%", borderRadius: "8px", height: "44px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { ...webStyle.inputField },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Version Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.productVersionName}
                  placeholder="Enter Product Version Name"
                  disabled
                  onChange={(e) => this.handleChange(e, "productVersionName")}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                />
                {this.renderError("productVersionName")}
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Alias</Typography>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "44px",
                    alignItems: "center",
                    background: '#F3F4F6',
                  }}
                >
                  {this.state.alias.map((alias: any, index: any) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        background: "#DDDFE1",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "#1E293B",
                        marginLeft: "12px"
                      }}
                    >
                      {alias}
                      <span
                        onClick={() => {
                          const newAliases = this.state.alias.filter((_: any, i: any) => i !== index);
                          this.setState({ alias: newAliases });
                        }}
                        style={{
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        &times;
                      </span>
                    </Box>
                  ))}
                  <TextField
                    value={this.state.inputAlias}
                    onChange={(e) => this.setState({ inputAlias: e.target.value })}
                    onKeyDown={this.handleKeyDown}
                    placeholder="Enter Alias"
                    style={{
                      ...webStyle.formField,
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        ...webStyle.inputField
                      },
                    }}

                  />
                </Box>
              </Box>

            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Category</Typography>
                </Box>
                <TextField
                  value={this.state.productCategory}
                  placeholder="Enter Product Category"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Product Sub Category</Typography>
                </Box>
                <TextField
                  placeholder="Enter Product Sub Category"
                  value={this.state.productSubcategory}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Short Description</Typography>
              </Box>
              <TextField
                placeholder="Enter Short Description"
                value={this.state.productDescription}
                onChange={(e) => this.handleChange(e, "productDescription")}
                style={{
                  ...webStyle.formField, width: "100%", borderRadius: "8px", height: "44px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#BCC1CA", fontSize: "16px", fontWeight: 400,fontFamily: "Inter, sans-serif", },
                }}
              />
            </Box>
          </Box>
          <Box style={{ padding: "20px", }}>
            <Typography style={{ ...webStyle.Title }}>
              Product Data
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Enter Product Type</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                    border: "1px solid #F3F4F6",
                  }}
                >
                  <select
                    value={this.state.productType}
                    onChange={(e) => this.handleChange(e, "productType")}
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                      fontSize: "16px",
                      border: "none",
                      borderRadius: "8px",
                      background: "transparent",
                      height: "44px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option value="" disabled>
                      Select Product Type
                    </option>
                    {this.state.productTypeOptions.map((option: DropdownOption) => (
                      <option key={option.id} style={{color: "#BCC1CA",
                        fontSize: "16px",}} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <img
                    alt="Dropdown Icon"
                    src={downImage}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      width: "15px",
                      height: "15px",
                      color: "#BCC1CA",
                      transform: "translateY(-50%)",
                    }}
                  />
                  {this.renderError("productType")}
                </div>
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Physical Form</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                    border: "1px solid #F3F4F6",
                  }}
                >
                  <select
                    value={this.state.physicalForm}
                    onChange={(e) => this.handleChange(e, "physicalForm")}
                    style={{
                      width: "100%",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                      fontSize: "16px",
                      border: "none",
                      borderRadius: "8px",
                      background: "transparent",
                      height: "44px",
                      appearance: "none",
                      WebkitAppearance: "none",
                      MozAppearance: "none",
                    }}
                  >
                    <option value="" disabled>
                      Select Physical Form
                    </option>
                    {this.state.physicalFormOptions.map((option: DropdownOption) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <img
                    alt="Dropdown Icon"
                    src={downImage}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "8px",
                      width: "15px",
                      height: "15px",
                      transform: "translateY(-50%)",
                    }}
                  />
                  {this.renderError("physicalForm")}
                </div>
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>
                    Material Composition
                  </Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "44px",
                    alignItems: "center",
                    background: '#F3F4F6',
                  }}
                >
                  {this.state.materialcomp.map((material: any, index: number) => (
                    <Box
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        fontSize: "14px",
                        color: "#1E293B",
                        background: "#DDDFE1",
                        marginLeft: "12px"
                      }}
                    >
                      {material}
                      <span
                        onClick={() => {
                          const newMaterials = this.state.materialcomp.filter((_: any, i: any) => i !== index);
                          this.setState({ materialcomp: newMaterials });
                        }}
                        style={{
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        &times;
                      </span>
                    </Box>
                  ))}
                  <TextField
                    value={this.state.inputValue}
                    onChange={(e) => this.setState({ inputValue: e.target.value })}
                    onKeyDown={this.handleKeyDown2}
                    placeholder="Enter Add Material Composition"
                    inputProps={{
                      style: {
                        fontSize: "16px",
                        fontWeight: 400,
                        color: "#BCC1CA",
                        fontFamily: "Inter, sans-serif",
                      },
                    }}
                    style={{
                      ...webStyle.formField,
                      flex: 1,
                      border: "none",
                      outline: "none",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        fontSize: "16px",
                        padding: "6px 8px",
                        color: "#1E293B",
                        border: "none",
                        outline: "none",
                      },
                    }}
                  />


                </Box>
                {this.renderError("materialcomp")}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sample Availability</Typography>
                </Box>
                <TextField
                  value={this.state.sampleAvail}
                  onChange={(e) => this.handleChange(e, "sampleAvail")}
                  placeholder="Enter Sample Availability"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", color: "#1E293B", fontSize: "14px",fontFamily: "Inter, sans-serif", },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px" }}>
            <Typography style={{ ...webStyle.Title }}>
              Product Taxation
            </Typography>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>HSN Code Number</Typography>
              </Box>
              <TextField
                value={this.state.hsncode}
                placeholder="Enter HSN Code Number"
                onChange={(e) => this.handleChange(e, "hsncode")}
                inputProps={{
                  style: { color: "#BCC1CA", fontWeight: 400, fontSize: "16px", },
                }}
                style={{
                  ...webStyle.formField, borderRadius: "8px", width: "100%", height: "44px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { padding: "6px 8px", color: "#1E293B", fontSize: "16px",fontFamily: "Inter, sans-serif" },
                }}
              />
            </Box>
          </Box>
          <Box style={{ padding: "20px", }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Heading</Typography>
                </Box>
                <TextField
                  value={this.state.heading}
                  placeholder="Enter Heading"
                  onChange={(e) => this.handleChange(e, "heading")}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "16px", fontWeight: 400, color: "#BCC1CA",fontFamily: "Inter, sans-serif" },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Sub Heading</Typography>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "subheading")}
                  value={this.state.subheading}
                  placeholder="Enter Sub Heading"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      fontWeight: 400,
                      color: "#BCC1CA",
                      fontSize: "16px",
                      fontFamily: "Inter, sans-serif"
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={{ ...webStyle.formItem, marginTop: "20px" }}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Product Description (80 letters)</Typography>
              </Box>
              <TextField
                value={this.state.productDescription1}
                placeholder="Enter Product Description"
                onChange={(e) => this.handleChange(e, "productDescription1")}
                style={{
                  ...webStyle.formField, height: "44px", width: "100%", borderRadius: "8px",
                }}
                InputProps={{
                  disableUnderline: true,
                  style: { fontSize: "16px", padding: "6px 8px", fontWeight: 400, color: "#BCC1CA",fontFamily: "Inter, sans-serif" },
                }}
              />
            </Box>
            <Box style={webStyle.formGrid2}>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>CGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("cgst")}
                  onChange={(e) => this.handleTaxChange(e, "cgst")}
                  placeholder="Enter CGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "16px",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                      fontFamily: "Inter, sans-serif"
                    },

                  }}
                />
              </Box>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>SGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("sgst")}
                  onChange={(e) => this.handleTaxChange(e, "sgst")}
                  placeholder="Enter SGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                      padding: "6px 8px",
                      color: "#BCC1CA",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif"
                    },
                  }}
                />
              </Box>
              <Box style={{ ...webStyle.formItem, flex: 1 }}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>IGST</Typography>
                </Box>
                <TextField
                  value={this.getTaxValue("igst")}
                  onChange={(e) => this.handleTaxChange(e, "igst")}
                  placeholder="Enter IGST"
                  style={{
                    ...webStyle.formField,
                    width: "100%",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "14px",
                      padding: "6px 8px",
                      fontWeight: 400,
                      color: "#BCC1CA",
                      fontFamily: "Inter, sans-serif"

                    },
                  }}
                />

              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Units Data
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit of Measurement</Typography>
                </Box>
                <TextField
                  value={this.state.unitOfMeasure}
                  onChange={(e) => this.handleChange(e, "unitOfMeasure")}
                  placeholder="Enter Unit of Measurement"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA",fontFamily: "Inter, sans-serif" },
                  }}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { padding: "6px 8px", fontSize: "16px", color: "#1E293B", },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Secondary Unit of Measurement</Typography>
                </Box>
                <TextField
                  value={this.state.secondunitOfMeasure}
                  onChange={(e) => this.handleChange(e, "secondunitOfMeasure")}
                  placeholder="Enter Secondary Unit of Measurement"
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA", fontFamily: "Inter, sans-serif"},
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",

                      fontSize: "16px",
                      color: "#1E293B",
                    },
                  }}
                />
              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Unit Conversion</Typography>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "unitConversion")}
                  placeholder="Enter Unit Conversion"
                  value={this.state.unitConversion}
                  inputProps={{
                    style: { fontSize: "16px", fontWeight: 400, color: "#BCC1CA",fontFamily: "Inter, sans-serif" },
                  }} style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "6px 8px",
                      color: "#1E293B",
                      fontSize: "16px",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                height: "40px",
                width: "87px",
                gap: "8px",
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                cursor: "pointer"
              }}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderProductDescription = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formItem}>
              <Box style={{ display: "flex" }}>
                <Typography style={webStyle.label}>Product Description</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
              </Box>
              <TextField
                value={this.state.productDescript}
                placeholder="Enter Product Description"
                onChange={(e) => this.handleChange(e, "productDescript")}
                style={{
                  ...webStyle.formField1,
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    ...webStyle.inputField
                  },
                }}
              />
              {this.state.errors.productDescript && (
                <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "4px" }}>
                  {this.state.errors.productDescript}
                </Typography>
              )}
            </Box>

          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                textTransform: "none",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
                variant="contained"
              onClick={this.handleSubmits1}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderDetailedSpecification = () => {
      return (
        <>
          <Box style={{ width: "94%", ...webStyle.MainContainer }}>
            <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
              <Box style={{ ...webStyle.navTabContainer }}>
                <Box
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    overflowX: "auto",
                  }}
                >
                  <Tabs value={this.state.value1} onChange={(event, newValue) => this.handleTabChange1(newValue)} TabIndicatorProps={{
                    style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                  }}>
                    {tabData1.map(({ label, value1 }) => (
                      <Tab
                        key={value1}
                        style={{ textTransform: "none", height: "58px" }}
                        label={
                          <Typography
                            style={this.state.value1 === value1 ? webStyle.activeTab : webStyle.inactiveTab}
                          >
                            {label}
                          </Typography>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
              <TableContainer>
                <Table aria-label="simple table">
                  {renderTabContent1()}
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={() => { this.setState({ value: 6 }); this.detailSpecificationApi(); }}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderStandards = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGridstand}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standards</Typography><span style={{ fontWeight: 700, color: "#DC2626", marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  options={optionsstandards}
                  id="standard"
                  value={this.state.selectedStandard || null}
                  onChange={(event, newValue) => this.setState({ selectedStandard: newValue })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={this.state.selectedStandard || "Select Standards"}
                      error={Boolean(this.state.errors?.standards)}
                      helperText={this.state.errors?.standards}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            style={webStyle.dropdownIcon}
                            src={downImage}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": webStyle.autocompletePaper
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>IS Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  onChange={(e) => this.handleChange(e, "IsNumber")}
                  placeholder="Enter IS Number"
                  error={Boolean(this.state.errors.IsNumber)}
                  helperText={this.state.errors.IsNumber}
                  value={this.state.IsNumber}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer",
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
              }}
              onClick={() => { this.setState({ value: 4 }); this.standardsApi(); }}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderFeatureDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Brand Details
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand</Typography>
                </Box>
                <TextField
                  value={this.state.brands}
                  onChange={(e) => this.handleChange(e, "brands")}
                  placeholder="Enter Brand"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Collection Name</Typography>
                </Box>
                <TextField
                  value={this.state.brandCollectName}
                  onChange={(e) => this.handleChange(e, "brandCollectName")}
                  placeholder="Enter Brand Collection Name"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standard Packaging</Typography>
                </Box>
                <TextField
                  value={this.state.standardPackage}
                  placeholder="Enter Standard Packaging"
                  onChange={(e) => this.handleChange(e, "standardPackage")}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Master Packaging</Typography>
                </Box>
                <TextField
                  placeholder="Enter Master Packaging"
                  value={this.state.masterPackage}
                  onChange={(e) => this.handleChange(e, "masterPackage")}
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              General Properties
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Colour</Typography>
                </Box>
                <TextField
                  value={this.state.colour}
                  onChange={(e) => this.handleChange(e, "colour")}
                  placeholder="Enter Colour"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Material</Typography>
                </Box>
                <TextField
                  value={this.state.material}
                  onChange={(e) => this.handleChange(e, "material")}
                  placeholder="Enter Material"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Typography style={{ ...webStyle.Title }}>
              Physical Properties
            </Typography>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Length in Inch</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.lengthInInch}
                  onChange={(e) => this.handleChange(e, "lengthInInch")}
                  placeholder="Enter Length in Inch"
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                  error={!!this.state.errors.lengthInInch}
                  helperText={this.state.errors.lengthInInch}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Width in Inch</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  placeholder="Enter Width in Inch"
                  value={this.state.widthInInch}
                  onChange={(e) => this.handleChange(e, "widthInInch")}

                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                  error={!!this.state.errors.widthInInch}
                  helperText={this.state.errors.widthInInch}
                />
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Inlet Size</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.InletSize}
                  placeholder="Enter Inlet Size"
                  onChange={(e) => this.handleChange(e, "InletSize")}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                  error={!!this.state.errors.InletSize}
                  helperText={this.state.errors.InletSize}
                />
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Thickness</Typography>
                  <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>
                    *
                  </span>
                </Box>
                <TextField
                  value={this.state.thickness}
                  placeholder="Enter Thickness"
                  onChange={(e) => this.handleChange(e, "thickness")}
                  style={{ ...webStyle.formField }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                  error={!!this.state.errors.thickness}
                  helperText={this.state.errors.thickness}
                />
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                fontSize: "16px",
                lineHeight: "24px",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                height: "40px",
                width: "87px",
                gap: "8px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                backgroundColor: "#FF6600",
                cursor: "pointer"
              }}
              onClick={this.handleSubmit1}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const renderBrandDetails1 = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
              {Array.from({ length: 10 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index !== 0 ? (
                      <Box >
                        <Typography><br /></Typography>
                      </Box>
                    ) : (
                      <Box >
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    <Checkbox
                      defaultChecked={false}
                      {...label}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      value={this.state.keys1[index]}
                      placeholder="Enter Key"
                      onChange={(e) => this.handleKeyChange(e, index, "keys1")}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField
                        },
                      }}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      options={options}
                      id="basicdetails-field"
                      disablePortal
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            endAdornment: (
                              <img
                                alt="Dropdown Icon"
                                style={webStyle.dropdownIcon}
                                src={downImage}
                              />
                            ),
                            style: {
                              ...webStyle.inputField
                            },

                          }}
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": webStyle.autocompletePaper
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </>
      );
    };

    const renderGeneralProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid4}>
              {Array.from({ length: 10 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index !== 0 && (
                      <Box >
                        <Typography ><br /></Typography>
                      </Box>
                    )}
                    {index === 0 && (
                      <Box >
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    <Checkbox
                      {...label}
                      defaultChecked={false}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      placeholder="Enter Key"
                      onChange={(e) => this.handleKeyChange(e, index, "keys1")}
                      value={this.state.keys1[index]}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      id="catalogue-field"
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          InputProps={{
                            disableUnderline: true,
                            ...params.InputProps,
                            style: {
                              ...webStyle.inputField
                            },
                            endAdornment: (
                              <img
                                style={webStyle.dropdownIcon}
                                alt="Dropdown Icon"
                                src={downImage}
                              />
                            ),
                          }}
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": webStyle.autocompletePaper
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>

          </Box>
        </>
      );
    };

    const renderPhysicalProp = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid5}>
              {Array.from({ length: 15 }).map((_, index) => (
                <React.Fragment key={index}>
                  <Box style={webStyle.formItem}>
                    {index === 0 && (
                      <Box >
                        <Typography style={webStyle.label}>Feature</Typography>
                      </Box>
                    )}
                    {index !== 0 && (
                      <Box>
                        <Typography ><br></br></Typography>
                      </Box>
                    )}
                    <Checkbox
                      defaultChecked={false}
                      {...label}
                    />
                  </Box>

                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Key</Typography>
                    </Box>
                    <TextField
                      onChange={(e) => this.handleKeyChange(e, index, "keys2")}
                      placeholder="Enter Key"
                      value={this.state.keys2[index]}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField
                        },
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Unit</Typography>
                    </Box>
                    <TextField
                      value={this.state.units[index]}
                      onChange={(e) => this.handleKeyChange(e, index, "units")}
                      placeholder="Enter Units"
                      InputProps={{
                        style: {
                          ...webStyle.inputField
                        },
                        disableUnderline: true,
                      }}
                    />
                  </Box>
                  <Box style={webStyle.formItem}>
                    <Box >
                      <Typography style={webStyle.label}>Value</Typography>
                    </Box>
                    <Autocomplete
                      disablePortal
                      options={options}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Value"
                          value=""
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...webStyle.inputField
                            },
                            endAdornment: (
                              <img
                                alt="Dropdown Icon"
                                src={downImage}
                                style={webStyle.dropdownIcon}
                              />
                            ),
                          }}
                          id="catalogue-field"
                        />
                      )}
                      sx={{
                        "& .MuiAutocomplete-paper": webStyle.autocompletePaper
                      }}
                    />
                  </Box>
                </React.Fragment>
              ))}
            </Box>

          </Box>
        </>
      );
    };
    const renderQualityTest = () => {
      return (
        <>
          <Box style={{ padding: "20px", display: "flex", gap: "30px", borderBottom: "1px solid #E1E7EF" }}>

            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%" }}>
              {this.state.certificates.map((certificate: any, index: any) => (
                <Box key={index} style={{ marginBottom: "20px" }}>
                  <Box style={{ ...webStyle.formItem, marginBottom: "15px" }}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Certificate Name</Typography>
                      <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                    </Box>
                    <TextField
                      placeholder="Enter Certificate Name"
                      style={{ ...webStyle.formField, width: "100%", height: "40px", borderRadius: "8px" }}
                      value={certificate.certificateName || this.state.certificates[index]?.certificateName}
                      onChange={(e) => this.handleChanges(e, index, "certificates")}
                      InputProps={{
                        disableUnderline: true,
                        style: { ...webStyle.inputField },
                      }}
                    />

                  </Box>

                  <Box style={webStyle.uploadContainer}>
                    <input
                      type="file"
                      id={`quality-file-${index}`}
                      style={{ display: "none" }}
                      accept="image/*,application/pdf"
                      onChange={(e) => this.handleFileChange(e, "qualityFile1", index, "certificates")}

                    />
                    {certificate.qualityFile1Name && (
                      <Typography style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        marginLeft: "10px",
                        color: "blue",
                        paddingBottom: "10px",
                      }}>
                        {certificate.qualityFile1Name}
                      </Typography>
                    )}

                    <label htmlFor={`quality-file-${index}`}>
                      <Button
                        variant="contained"
                        component="span"
                        startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                        style={{
                          backgroundColor: "#FF6600",
                          color: "#FFFFFF",
                          padding: "16px 14px 16px 14px",
                          height: "34px",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "none",
                          borderRadius: "12px",
                          width: "90px",
                          cursor: "pointer"
                        }}
                      >
                        Upload
                      </Button>                    </label>
                    <br />
                    <Typography style={{ marginTop: "3px", fontSize: "14px", fontWeight: 400,fontFamily: "Inter, sans-serif", }}>OR</Typography>
                    <label htmlFor={`quality-file-${index}`}>
                      <Box style={{ display: "flex", marginLeft: "7px" }}>
                        <Typography component="span" style={{
                          textDecoration: "underline", cursor: "pointer",
                          fontSize: "14px",fontFamily: "Inter, sans-serif", color: "#FF6600", fontWeight: 400, marginTop: "8px"
                        }}>
                          Browse Files
                        </Typography>
                        <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "8px" }}
                      />               </Box>
                    </label>
                  </Box>
                </Box>
              ))}

              <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <label style={{ display: "flex", cursor: "pointer", marginTop: "20px" }} onClick={this.addCertificateField}>
                  <img alt="AddImg" src={AddImg} />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>Add another Certificate</Typography>
                </label>
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrids, flex: "1", minWidth: "45%" }}>
              {this.state.qualityTests.map((test: any, index: any) => (
                <Box key={index} style={{ marginBottom: "20px" }}>
                  <Box style={{ ...webStyle.formItem, marginBottom: "15px" }}>
                    <Box style={{ display: "flex" }}>
                      <Typography style={webStyle.label}>Quality Test Name</Typography>
                      <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                    </Box>
                    <TextField
                      placeholder="Enter Quality Test Name"
                      value={test.qualityTestName || this.state.qualityTests[index]?.qualityTestName}
                      onChange={(e) => this.handleChanges(e, index, "qualityTests")}
                      style={{
                        ...webStyle.formField1,
                        width: "100%",
                        height: "44px",
                        borderRadius: "8px",
                      }}
                      InputProps={{
                        disableUnderline: true,
                        style: { ...webStyle.inputField },
                      }}
                    />
                  </Box>
                  <Box
                    style={webStyle.uploadContainer}
                  >
                    <input
                      type="file"
                      id={`quality-file2-${index}`}
                      style={{ display: "none" }}
                      accept="image/*,application/pdf"
                      onChange={(e) => this.handleFileChange(e, "qualityFile2", index, "qualityTests")}
                    />
                    {test.qualityFile2Name && (
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          marginLeft: "10px",
                          color: "blue",
                          paddingBottom: "10px",
                        }}
                      >
                        {test.qualityFile2Name}
                      </Typography>
                    )}

                    <label htmlFor={`quality-file2-${index}`}>
                      <Button
                        component="span" 
                        variant="contained"
                        style={{
                          backgroundColor: "#FF6600",
                          color: "#FFFFFF",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          textTransform: "none",
                          borderRadius: "12px",
                          padding: "16px 14px 16px 14px",
                          height: "34px",
                          width: "90px",
                          cursor: "pointer",
                          fontSize: "14px",
                          lineHeight: "16px",
                        }}
                        startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                      >
                        Upload
                      </Button>                    </label>
                    <br />
                    <Typography style={{ marginTop: "3px", fontFamily: "Inter, sans-serif",fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                    <label htmlFor={`quality-file2-${index}`}>
                      <Box style={{ display: "flex", marginLeft: "7px" }}>
                        <Typography
                          component="span"
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "14px",
                            color: "#FF6600",
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 400,
                            marginTop: "8px",
                          }}
                        >
                          Browse Files
                        </Typography>
                        <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "8px" }}
                      />                      </Box>
                    </label>
                  </Box>
                </Box>
              ))}
              <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                <label style={{ display: "flex", cursor: "pointer", marginTop: "20px" }} onClick={this.addQualityTestField}>
                  <img src={AddImg} alt="AddImg" />
                  <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>Add another Quality Test </Typography>
                </label>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer"
              }}
              onClick={this.certificationApi}
            >
              Save
            </Button>
          </Box>


        </>
      );
    };

    const renderDocuments = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            {this.state.documents.map((doc: any, index: any) => (
              <Box key={doc.id} style={webStyle.formGrid}>
                <Box style={webStyle.formItem}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>Document Title</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <TextField
                    value={doc.title || ""}
                    onChange={(e) => this.handleDocumentChange(index, "title", e.target.value)}
                    placeholder="Enter Document Title"
                    style={{
                      ...webStyle.formField,
                      width: "100%",
                      borderRadius: "8px",
                      height: "44px",
                    }}
                    InputProps={{
                      disableUnderline: true,
                      style: { ...webStyle.inputField },
                    }}
                  />
                </Box>

                <Box style={webStyle.formItem}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>Document Type</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <Autocomplete
                    disablePortal
                    options={["pdf", "word", "excel", "image"]}
                    value={doc.document_type || doc.docType}
                    onChange={(event, newValue) => this.handleDocumentChange(index, "document_type", newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Document Type"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            ...webStyle.inputField
                          },
                          endAdornment: (
                            <img alt="Dropdown Icon" style={webStyle.dropdownIcon} src={downImage} />
                          ),
                        }}
                      />
                    )}
                  />
                </Box>

                <Box style={{ marginTop: "20px" }}>
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>Upload Document</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <Box
                    style={webStyle.uploadContainer}
                  >
                    <input
                      type="file"
                      id={`upload-file-${index}`}
                      style={{ display: "none" }}
                      accept={this.getAcceptedFileType(doc.document_type || doc.docType)}
                      onChange={(e) => this.handleFileChange(e, "documentFile", index, "documents")}

                    />
                    {doc.documentFileName && (
                      <Typography style={{ fontSize: "14px", marginLeft: "10px", color: "blue", paddingBottom: "10px", fontWeight: 400, }}>
                        {doc.documentFileName}
                      </Typography>
                    )}
                    <label htmlFor={`upload-file-${index}`}>
                      <Button 
                        startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                        component="span" 
                        variant="contained"
                        style={{
                          backgroundColor: "#FF6600",
                          color: "#FFFFFF",
                          fontFamily: "Inter, sans-serif",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "16px",
                          height: "34px",
                          width: "90px",
                          textTransform: "none",
                          borderRadius: "12px",
                          padding: "16px 14px 16px 14px",
                          cursor: "pointer"
                        }}
                      >
                        Upload
                      </Button>                    </label>
                    <br />
                    <Typography style={{fontFamily: "Inter, sans-serif", marginTop: "3px", fontSize: "14px", fontWeight: 400 }}>OR</Typography>
                    <label htmlFor={`upload-file-${index}`}>
                      <Box style={{ marginLeft: "7px", display: "flex" }}>
                        <Typography
                          component="span"
                          style={{
                            textDecoration: "underline",
                            fontFamily: "Inter, sans-serif",
                            color: "#FF6600",
                            fontSize: "14px",
                            marginTop: "8px",
                            fontWeight: 400,
                            cursor: "pointer",
                          }}
                        >
                          Browse Files
                        </Typography>
                        <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "8px" }}
                      />                
                         </Box>
                    </label>
                  </Box>
                  {doc.errorMessage && (
                    <Typography style={{ color: "#DC2626", fontSize: "12px", marginTop: "10px" }}>
                      {doc.errorMessage}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
            <Box style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <label style={{ cursor: "pointer", display: "flex" }} onClick={this.addDocumentField}>
                <img src={AddImg} alt="AddImg" />
                <Typography style={{ ...webStyle.AddType, marginLeft: "5px" }}>Add Another Document</Typography>
              </label>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                width: "87px",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                gap: "8px",
                fontWeight: 700,
                height: "40px",
                cursor: "pointer",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
              }}
              onClick={() => { this.setState({ value: 8 }); this.documentsApi(); }}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderImages = () => {
      return (
        <>
          <Box style={{ padding: "21px", borderBottom: "1px solid #E1E7EF" }}>

            <Box style={{ marginTop: "20px", ...webStyle.formGrid }}>
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Upload Product Images</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Box
                  style={webStyle.uploadContainer}
                >
                  <input
                    id="upload-file"
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={(e) => this.handleFileChange(e, "pictureFile")}
                    type="file"
                  />
                  {this.state.pictureFileName && (
                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "blue",
                        paddingBottom: "10px",
                      }}
                    >
                      {this.state.pictureFileName}
                    </Typography>
                  )}
                  <label htmlFor="upload-file">
                   
                    <Button
                     component="span" 
                    style={{
                      borderRadius: "12px",
                      padding: "16px 14px 16px 14px",
                      height: "34px",
                      backgroundColor: "#FF6600",
                      color: "#FFFFFF",
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "16px",
                      textTransform: "none",
                      width: "90px",
                      cursor: "pointer"
                    }}
                      variant="contained"
                      startIcon={<img src={uploadicon} style={{ height: "14px", width: "14px" }} />}
                      >
                      Upload
                    </Button>                  
                    </label><br />
                  <Typography style={{ marginTop: "3px", fontSize: "14px",fontFamily: "Inter, sans-serif", fontWeight: 400 }}>OR</Typography>
                  <label htmlFor="upload-file">
                    <Box style={{ display: "flex", marginLeft: "7px" }}>
                      <Typography component="span" style={{
                        textDecoration: "underline",
                        fontSize: "14px", fontWeight: 400, marginTop: "8px",fontFamily: "Inter, sans-serif", cursor: "pointer", color: "#FF6600",
                      }}>
                        Browse Files
                      </Typography>
                      <img
                        src={FilesImg}
                        alt="FilesImg"
                        style={{ width: "18px", height: "18px", margin: "5px 5px", marginTop: "7px" }}
                      />                    </Box>
                  </label>
                </Box>
              </Box>
              <Box
                style={{
                  width: "220px",
                  height: "256px",
                  marginTop: "35px",
                  marginLeft: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  border: '2px dashed #FF6600',
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label htmlFor="upload-file">
                  <img src={AddImg} alt="Add" style={{ cursor: "pointer" }} />
                </label>
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                color: "#FFFFFF",
                fontSize: "16px",
                lineHeight: "24px",
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                gap: "8px",
                cursor: "pointer",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
              }}
              onClick={() => { this.setState({ value: 7 }); this.imagesApi(); }}
            >
              Save
            </Button>
          </Box>

        </>
      );
    };

    const renderBrandDetails = () => {
      return (
        <>
          <Box style={{ padding: "20px", borderBottom: "1px solid #E1E7EF" }}>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brands</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <Autocomplete
                  disablePortal
                  id="catalogue-field"
                  onChange={this.handleBrandChange}
                  options={optionss}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value=""
                      placeholder={this.state.brandName || "Select Brands"}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField
                        },
                        endAdornment: (
                          <img
                            alt="Dropdown Icon"
                            style={webStyle.dropdownIcon}
                            src={downImage}
                          />
                        ),
                      }}

                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": webStyle.autocompletePaper
                  }}
                />
                {this.state.errors?.selectedBrand && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.selectedBrand}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Collection Name</Typography>
                </Box>
                <Autocomplete
                  disablePortal
                  options={options1}
                  id="catalogue-field"
                  getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
                  value={this.state.brandCollection}
                  onChange={(event, newValue) => this.handleBrandCollectionChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={this.state.brandCollection || "Brand Collection Name"}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          ...webStyle.inputField,
                        },
                        endAdornment: (
                          <img
                            style={webStyle.dropdownIcon}
                            alt="Dropdown Icon"
                            src={downImage}
                          />
                        ),
                      }}
                    />
                  )}
                  sx={{
                    "& .MuiAutocomplete-paper": webStyle.autocompletePaper,
                  }}
                />



              </Box>
            </Box>
            <Box style={webStyle.formGrid}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand Product Name</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.brandProduct}
                  onChange={(e) => this.handleChange(e, "brandProduct")}
                  placeholder="Enter Brand Product Name"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
                {this.state.errors?.brandProduct && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.brandProduct}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Brand SKU Number</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.brandSKU}
                  onChange={(e) => this.handleChange(e, "brandSKU")}
                  placeholder="Enter Brand SKU Number"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
                {this.state.errors?.brandSKU && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.brandSKU}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box style={{ ...webStyle.formGrid, marginTop: "20px" }}>
              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Standard Packaging</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.standardPackage1}
                  onChange={(e) => this.handleChange(e, "standardPackage1")}
                  placeholder="Enter Standard Packaging"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      ...webStyle.inputField
                    },
                  }}
                />
                {this.state.errors?.standardPackage1 && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.standardPackage1}
                  </Typography>
                )}
              </Box>

              <Box style={webStyle.formItem}>
                <Box style={{ display: "flex" }}>
                  <Typography style={webStyle.label}>Master Packaging</Typography><span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                </Box>
                <TextField
                  value={this.state.masterPackage1}
                  onChange={(e) => this.handleChange(e, "masterPackage1")}
                  placeholder="Enter Master Packaging"
                  style={{
                    ...webStyle.formField,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: { ...webStyle.inputField },
                  }}
                />
                {this.state.errors?.masterPackage1 && (
                  <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.masterPackage1}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer", height: "65px", marginRight: "15px" }}>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#FF6600",
                lineHeight: "24px",
                gap: "8px",
                color: "#FFFFFF",
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                textTransform: "none",
                borderRadius: "12px",
                padding: "16px 24px 16px 24px",
                height: "40px",
                width: "87px",
                fontSize: "16px",
                cursor: "pointer"
              }}
              onClick={this.handleSubmits}
            >
              Save
            </Button>
          </Box>
        </>
      );
    };

    return (
      <Box style={{ width: "94%", ...webStyle.MainContainer }}>
        <Box style={webStyle.Headers}>
          <Typography variant="h6" style={webStyle.Headingss}>
            Add Product
          </Typography>
          <Button variant="contained" style={{ ...webStyle.CheckoutButton, textTransform: "none", boxShadow: "none" }}>
            Bulk Upload (CSV)
          </Button>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px", minWidth: `${label.length * 7 + 30}px`, }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              {renderTabContent()}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render(): ReactNode {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar history={this.props.history} />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.AddProduct()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  MainContainer: {
    marginLeft: "30px",
    fontFamily: 'Inter, sans-serif',
    marginTop: "20px",
  },
  Headers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },
  Headingss: {
    fontFamily: 'Inter, sans-serif',
    lineHeight: "1.5",
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "left" as React.CSSProperties["textAlign"]
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "20px",
    alignItems: "start",
  },
  formGrids: {
    gap: "20px",
    alignItems: "start",
    width: "49%"
  },
  formGridstand: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    alignItems: "start",
    gap: "20px",
    paddingBottom: '25%'
  },
  formGrid2: {
    display: "grid",
    gap: "15px",
    gridTemplateColumns: "1fr 1fr 1fr",
    alignItems: "start",

  },
  formGrid3: {
    display: "grid",
    alignItems: "start",
    gridTemplateColumns: "3fr 3fr 1fr 3fr 3fr",
    gap: "16px",

  },
  formGrid4: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr 8fr 8fr",
    alignItems: "start",
  },
  formGrid5: {
    display: "grid",
    gap: "16px",
    gridTemplateColumns: "1fr 5fr 5fr 5fr",
    alignItems: "start",
  },

  formItem: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column' | 'row-reverse' | 'column' | 'column-reverse',
    width: '100%' as string,

  },
  AddType: {
    fontSize: "16px",
    marginLeft: "8px",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: 'Inter, sans-serif',
    color: "#FF6600",
  },
  formField: {
    background: '#F3F4F6',
    borderRadius: '8px',
    fontFamily: 'Inter, sans-serif',
    width: '100%',
    height: '44px',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  formField1: {
    background: '#F3F4F6',
    width: '100%',
    height: '200px',
    borderRadius: '8px',
    boxSizing: 'border-box' as 'border-box',
  } as React.CSSProperties,
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    marginTop: "10px",
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: "#334155",
  },
  saveButton: {
    boxShadow: "none",
    margin: "16px 20px 16px 0px",
    fontFamily: 'Inter, sans-serif',
    cursor: 'grab',
  },
  CheckoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    width: "max-content",
    fontWeight: 500,
    height: "44px",
    cursor: 'grab',
  },
  navTabContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "58px",
    justifyContent: "space-around",
    borderBottom: "1px solid #E1E7EF",
  },
  dropdownIcon: {
    width: "15px",
    height: "15px",
    marginRight: "8px",
  },
  inputField: {
    padding: "6px 8px",
    fontFamily: 'Inter, sans-serif',
    fontSize: "16px",
    fontWeight: 400,
    color: "#BCC1CA",
    height: "44px",
    background: "#F3F4F6",
    borderRadius: "8px",
    width: "100%",
  },
  autocompletePaper: {
    maxHeight: "200px",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid #F3F4F6",
  },
  activeTab: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: '12px',
    textAlign: 'center' as 'center',
    lineHeight: '24px',
    color: '#EE6900',
    cursor: 'grab',
  },
  inactiveTab: {
    lineHeight: '24px',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    fontFamily: 'Inter, sans-serif',
    color: '#565E6C',
    cursor: 'grab',
  },
  Title: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: "20px",
    fontFamily: 'Inter, sans-serif',
    marginTop: "20px"
  },
  uploadContainer: {
    borderRadius: "8px",
    height: "256px",
    display: "flex",
    flexDirection: "column" as "column",
    background: "#F3F4F6",
    alignItems: "center",
    justifyContent: "center",
  },
}
// Customizable Area End