import React from "react";

import {
    // Customizable Area Start
    Typography,
    Box,
    Table,
    TableContainer,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import UserNavbar from "../../../components/src/UserNavbar.web";
import ApplicationProductController, { Props } from "./ApplicationProductController.web";
import { Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from '@material-ui/icons/ArrowForwardIos';
import {productimg } from "./assets";

// Customizable Area End

export default class ApplicationProduct extends ApplicationProductController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    cartTableProduct = () => {
        const { currentPage, totalResults2, resultperPage2 } = this.state;

        const upidData = [
            { product: "AAC Blocks 4\"", category: "Blackwork", brand: "Havells" },
            { product: "AAC Blocks 4\"", category: "AAC Blocks", brand: "Polycab" },
            { product: "Insulation Panels 5\"", category: "Thermal", brand: "Rockwool" },
            { product: "Concrete Pipes 6\"", category: "Plumbing", brand: "Tata Pipes" },
            { product: "Steel Rods 10mm", category: "Rebar", brand: "JSW Steel" },
            { product: "PVC Pipes 2\"", category: "Plumbing", brand: "Supreme" },
            { product: "Cement Bags 50kg", category: "Building Materials", brand: "UltraTech" },
            { product: "Electrical Wiring 2mm", category: "Wiring", brand: "Finolex" },
            { product: "Tiles 600x600mm", category: "Flooring", brand: "Kajaria" },
            { product: "Glass Panels 4mm", category: "Glasswork", brand: "Saint-Gobain" },
        ];


        return (
            <Box style={{ width: "94%", ...webStyle2.mainContainer2 }}>
                <Box style={webStyle2.headers1}>
                    <Typography
                        style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "#1E293B",
                            paddingBottom: "4px",
                            fontFamily: "Inter, sans-serif"

                        }}
                        onClick={() => this.navigateToProduct("/ApplicationIndustry")}
                    >
                        Industry / Market

                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "5px" }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography style={{
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#1E293B",
                        paddingBottom: "4px",
                        marginLeft: "6px",
                        fontFamily: "Inter, sans-serif"

                    }}
                        onClick={() => this.navigateToProduct("/ApplicationGroup")}
                    >
                        {this.state.industryname}
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "5px" }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography style={{
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#1E293B",
                        paddingBottom: "4px",
                        marginLeft: "6px",
                        fontFamily: "Inter, sans-serif"
                    }}
                        onClick={() => this.navigateToProduct("/Application")}
                    >
                        {this.state.applicationGroup}
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "5px", }}>
                        <ArrowBackIcon style={{ color: "#64748B", fontSize: "14px" }} />
                    </Typography>
                    <Typography style={{ marginLeft: "6px", marginBottom: "5px", fontFamily: "Inter, sans-serif", color: "#565E6C", fontWeight: "normal", fontSize: "14px" }}>
                        {this.state.applicationame}
                    </Typography>

                </Box>
                <Box style={webStyle2.header2}>
                    <Typography variant="h6" style={webStyle2.heading2}>
                        {this.state.applicationame}
                    </Typography>

                </Box>
                {/* Table Container */}
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px 8px 8px 8px" }}>
                    <TableContainer>
                        <Table>
                            <thead>
                                <tr style={{ backgroundColor: "#F1F5F9", borderBottom: "2px solid #E2E7F0" }}>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "left",
                                            color: "#565E6C",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Product
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "left",
                                            color: "#565E6C",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Category
                                    </th>
                                    <th
                                        style={{
                                            padding: "12px",
                                            textAlign: "left",
                                            color: "#565E6C",
                                            fontWeight: "bold",

                                        }}
                                    >
                                        Brand
                                    </th>
                                    <th style={{ padding: "12px", textAlign: "center", color: "#565E6C", fontWeight: "bold" }}>
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {upidData.map((elem, i) => (
                                    <tr key={i} style={{ borderBottom: "1px solid #E2E7F0" }}>
                                        <td style={{ display: "flex", alignItems: "center", padding: "12px", }}>
                                            <div style={{ marginRight: "8px" }}>
                                                <img
                                                    src={productimg}
                                                    alt="product"
                                                    style={{ width: "40px", height: "40px", borderRadius: "4px" }}
                                                />
                                            </div>
                                            <div>
                                                <Typography style={{ fontSize: "14px", fontWeight: "bold", color: "#1E293B" }}>
                                                    {elem.product}
                                                </Typography>
                                                <Typography style={{ fontSize: "12px", color: "#64748B" }}>
                                                    Size: 625x240x100 Make: Alpine
                                                </Typography>
                                            </div>
                                        </td>
                                        <td style={{ padding: "12px", fontSize: "14px", color: "#475569" }}>
                                            {elem.category}
                                        </td>
                                        <td style={{ padding: "12px", fontSize: "14px", color: "#475569" }}>
                                            {elem.brand}
                                        </td>
                                        <td style={{ textAlign: "center", padding: "12px" }}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM10 18C10 16.9 10.9 16 12 16C13.1 16 14 16.9 14 18C14 19.1 13.1 20 12 20C10.9 20 10 19.1 10 18Z"
                                                    fill="#979797"
                                                />
                                            </svg>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </TableContainer>
                    <Box
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px 16px",
                            borderTop: "1px solid #E2E7F0",
                        }}
                    >
                        <Typography style={{ fontSize: "12px", color: "#64748B" }}>
                            {`1-${upidData.length} of ${totalResults2} results`}
                        </Typography>
                        <Pagination
                            page={currentPage}
                            count={Math.ceil(totalResults2 / resultperPage2)}
                            siblingCount={0}
                             data-test-id="pagination2"
                             shape="rounded"
                            boundaryCount={1}
                           
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    style={{
                                        minWidth: "31px",
                                        height: "36px",
                                        fontWeight: 500,
                                        color: item.selected ? "#FFFFFF" : "#475569",
                                        backgroundColor: item.selected ? "#FF6600" : "#F3F4F6",
                                        border: "1px solid #E2E8F0",
                                        boxShadow: item.selected ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
                                        borderRadius: "12px",
                                        fontFamily: "Inter, sans-serif",
                                        fontSize: "12px",
                                                                           }}
                                />
                            )}
                        />
                    </Box>
                </Box>
            </Box>
        );
    };


    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <UserNavbar handleToggleSidebar={this.handleToggleSidebar} history={this.props.history} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.cartTableProduct()}
                </SideBar>

            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle2: {
    [key: string]: {
        [property: string]: string | number
    }
} = {
    mainContainer2: {
        marginLeft: "47px",
        fontFamily: "Inter, sans-serif",
    },
    header2: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "20px 0px",
    },
    headers1: {
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin: "20px 0px",
    },
    heading2: {
        fontFamily: "Inter, sans-serif",
        fontSize: "24px",
        fontWeight: "bolder",
        lineHeight: "26px",
        textAlign: "left",
    },
    Tbody: {
        padding: "20px",
        fontFamily: "Inter, sans-serif",
    },

};

// Customizable Area End