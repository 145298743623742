
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../../packages/framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}
export interface ProductCat {
    productId: string;
    productName: string;
    imageUrl: string | null;
    imageName: string | null;
    bestSeller: boolean;
    New: string; 
    status: string;
  }

export interface ChipDataItem {
    label: string;
    key: number;
    checked: boolean;
    value: string
  }
  
  interface ApiResponse {
    sub_categories:{data:CategoryData[]} ;
    error: string;
  }

  interface ISubCateAttribute{
    id: number,
    name: string,
    parent_category_id: number,
    level: number,
    is_upid: boolean,
    created_at: string,
    updated_at: string,
    image: string,
    product_type:number
  }
  export interface ISubcategory{
      id: string,
      type: string,
      attributes: ISubCateAttribute
  }

  interface ISubCateData{
    data: ISubcategory[]
  }

  interface IProductAttribute{
    id: number,
    primary_image:{url:string}|null,
    product_type:number,
    brand_product_name:string
  }

  export interface IListProduct{
    id: string,
    type: string,
    attributes: IProductAttribute
  }

  interface CategoryAttributes {
    id: number;
    name: string;
    category_id: string;
    image: string;
    sub_categories: ISubCateData;
    error:string
  }
  interface CategoryData {
    id: string;
    type: string;
    attributes: CategoryAttributes;
  }

  interface IBreadCrumb{
    nameOfCate:string,id:number
  }

  interface PagyVars {
    page: number;
    items: number;
    outset: number;
    size: number[];
    page_param: string;
    params: Record<string, unknown>;
    fragment: string;
    link_extra: string;
    i18n_key: string;
    cycle: boolean;
    request_path: string;
    count: number;
  }

  interface PagyMeta {
    vars: PagyVars;
    count: number;
    page: number;
    outset: number;
    items: number;
    last: number;
    pages: number;
    offset: number;
    params: Record<string, unknown>;
    from: number;
    to: number;
    in: number;
    prev: number | null;
    next: number | null;
  }

  interface IProductListRespose{
    data:IListProduct[],
    error:string,
    meta: {
      pagy: PagyMeta;
    };

  }

  interface ICateResponse{
    data:{data:CategoryData[]},
    error:string,
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  priceRangeValue:number[],
  catBlock:boolean;
  categoryId:  number | null,
  subCategoryId: number | null,
  productList: ProductCat[];
  idBrand: number | null;
  tokenData:string;
  showFilterData: boolean;
  chipData: ChipDataItem[];
  shortChipData: ChipDataItem[];
  chipString: string[];
  letChatName:string;
  subCategoryList:ISubCateData;
  productListByCat:IListProduct[];
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  categoryList:CategoryAttributes[];
  titleName:string;
  subCateOfParent:CategoryData[];
  subSelectedCate2:CategoryData;
  subCate3List:CategoryData[];
  subCateSelected3:CategoryData;
  breadCrumbData:IBreadCrumb[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProductCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: any;
  getProductList: string = "";
  getSubCategoryListApiCallId:string="";
  CatId:number=0;
  getProductsByCatApiCallId:string="";
  getCategoriesApiCallId:string="";
  parentCate:{name:string,id:number}={name:"",id:0};
  getSub2APiCall:string="";
  getSubCategoryOfParaListApiCallId:string="";
  getSubCateOf2ApiCallId:string="";
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
        priceRangeValue:[15, 80],
        catBlock:false,
        categoryId:null,
        subCategoryId: null,
        productList: [{
          productName: "Huliot K233032 32 X 1 Mm", imageName: "hello img",
          productId: "11",
          imageUrl: null,
          bestSeller: true,
          New: "",
          status: ""
        },
        {
          productName: "Huliot K233033 32 X 1 Mm", imageName: "hello img",
          productId: "12",
          imageUrl: null,
          bestSeller: false,
          New: "new",
          status: "status"
        },
        {
          productName: "Huliot K233035 32 X 1 Mm", imageName: "hello hello",
          productId: "13",
          imageUrl: null,
          bestSeller: false,
          New: "",
          status: "best"
        },
        {
          productName: "Huliot K233037 32 X 1 Mm", imageName: "hello ts",
          productId: "11",
          imageUrl: null,
          bestSeller: false,
          New: "",
          status: ""
        },
        {
          productName: "Huliot K233036 32 X 2 Mm", imageName: "hello js",
          productId: "13",
          imageUrl: null,
          bestSeller: false,
          New: "new",
          status: "status"
        }],
        idBrand: null,
        tokenData:"",
        showFilterData:false,
        chipData: [
          { label: "Newest to Oldest", key: 0, checked: false, value: "updated_desc" },
          { label: "Oldest to Newest", key: 1, checked: false, value: "updated_asc" },
          { label: "Sort by A to Z", key: 2, checked: false, value: "name_asc" },
        ],
        shortChipData:[],
        chipString: [],
        letChatName:"",
        subCategoryList:{} as ISubCateData,
        productListByCat:[],
        totalResults: 140,
        resultperPage: 12,
        currentPage: 1,
        categoryList:[],
        titleName:"",
        subCateOfParent:[],
        subSelectedCate2:{} as CategoryData,
        subCate3List:[],
        subCateSelected3:{} as CategoryData,
        breadCrumbData:[]
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    let pathName = new URL(document.URL).pathname.split('/');
    let lastElement = pathName[pathName.length - 1];
    this.CatId = +lastElement;
    await this.getSubCategoryListApi();
    await this.getProductByCatApi();
    await this.getCategoryListApi();
     this.getBreadCrumb();
 
  }

  async componentDidUpdate() {
    let pathNames = new URL(document.URL).pathname.split('/')
    let lastElement = pathNames[pathNames.length - 1];
    if (this.CatId !== + lastElement) {
      this.CatId = +lastElement;
      await this.getSubCategoryListApi();
      await this.getProductByCatApi();
      await this.getCategoryListApi();
      this.getBreadCrumb();
    }
  }
 

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        
        case this.getSubCategoryListApiCallId:
          this.handleSubCateListResponse(responseJson);
          break;
        case this.getProductsByCatApiCallId:
          this.handleProductListResponse(responseJson);
          break;
        case this.getCategoriesApiCallId:
          this.handleCategoryListResponse(responseJson);
          break;
        case this.getSubCategoryOfParaListApiCallId:
           this.handlerESPONSE(responseJson);
           break;
        case this.getSubCateOf2ApiCallId:
           this.handleResSub2(responseJson);
           break;
        default:
          break;
      }
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (route?:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleToggleSidebar = () => {     
    this.setState({ catBlock: !this.state.catBlock});}


  handleOpen = () => {
    this.setState({ showFilterData: !this.state.showFilterData })
  }
  handleDropdownClick = (data: { label: string, key: number, checked: boolean }, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === data.key ? { ...item, checked: event.target.checked } : item
    )

    const updatedShortChipData = updatedChipData.filter(item => item.checked)

    const dataValue = updatedShortChipData.map((data:ChipDataItem) => {
      return data.value
    })
  
    this.setState({
      chipData: updatedChipData,
      shortChipData: updatedShortChipData,
      chipString: dataValue
    });

  }

  handleDelete = (chipToDelete: { label: string, key: number, checked: boolean })  => {
    const updatedChipData = this.state.chipData.map(item =>
      item.key === chipToDelete.key ? { ...item, checked: false } : item
    )
    const deleteDataStore = this.state.shortChipData.filter((item: { label: string, key: number, checked: boolean }) => item.key !== chipToDelete.key);

    const deleteStringData = deleteDataStore.map((data: ChipDataItem) => {
      return data.value
    })
    this.setState({ chipData: updatedChipData, shortChipData: deleteDataStore, chipString: deleteStringData})
  };

  delete = () => {
    this.setState({ shortChipData: [], chipData: this.state.chipData.map(item => ({ ...item, checked: false })), showFilterData: false, chipString:[] });
  }

  getSubCategoryListApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getSubCategoryListApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getSubCategoriesEndPoint}${this.CatId}`,
      token: await getStorageData('authToken'),
    });
  };

  getCategoryListApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getCategoriesApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getCategoriesEndpoint}`,
      token: await getStorageData('authToken'),
    });
  };

  getProductByCatApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getProductsByCatApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getProductsByCatEndPoint}${this.CatId}&page=${this.state.currentPage}`,
      token: await getStorageData('authToken'),
    });
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint,  token } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleSubCateListResponse = (responseJson: CategoryAttributes) => {
    if (!responseJson || responseJson.error) {
      return;
    }
    this.setState({
      subCategoryList: responseJson.sub_categories as ISubCateData
    },()=>{this.fetchNamesFromURL()})
  }

  handleProductListResponse = (responseJson: IProductListRespose) => {
    if (!responseJson || responseJson.error) {
      return;
    }
    this.setState({
      productListByCat: responseJson.data 
    })
    const { count, items, page } = responseJson.meta?.pagy || {};
    this.setState({
      totalResults: count ,
      currentPage: page||1,
      resultperPage: items,
    })
  }

  navigateWithSub = (parentCateId: number,idSubcate:number) => {
    let pathName = new URL(document.URL).pathname.split("/");
    pathName = pathName.slice(2);
    const basePath = pathName.join("/");
    let newPath = `${basePath}/${idSubcate}`;
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCatalogueWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), newPath);
    this.send(message);
  };

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page },()=>{this.getProductByCatApi()}); 
  };

  handleCategoryListResponse = (responseJson: ICateResponse) => {
    if (responseJson.data && !responseJson.error && responseJson.data.data.length > 0) {
      const categoryData = responseJson?.data?.data?.map((category: CategoryData) => category.attributes);
      this.setState({ categoryList: categoryData },()=>{   this.fetchNamesFromURL();this.getBreadCrumb();})
    } else {
      return
    }
  }

  fetchNamesFromURL = async () => {
    let pathName = new URL(document.URL).pathname.split('/');
    pathName = pathName.slice(2);
    
    if(pathName.length ==1){
      let parentCate =this.state.categoryList?.find((item)=>item.id==this.CatId);
      this.setState({titleName:parentCate?.name||""})
    }

    if(pathName.length ==2){
      await this.getSubCategoryOfParaListApi(+pathName[0]);
    }

    if (pathName.length==3){
     await this.getSubCategoryOfParaListApi(+pathName[0]);
     await this.getSubCategoryOf2ListApi();
     
    }
   
   
  }


  getName = ()=>{
    let pathName = new URL(document.URL).pathname.split('/');
    pathName = pathName.slice(2);

    if(pathName.length ==1){
      let parentCate =this.state.categoryList?.find((item)=>item.id==this.CatId);
      this.setState({titleName:parentCate?.name||""})
    }
    if(pathName.length ==2){
      this.setState({titleName:this.state.subSelectedCate2?.attributes?.name||""})
    }
    if(pathName.length ==3){
      this.setState({titleName:this.state.subCateSelected3?.attributes?.name||""})
    }
    this.getBreadCrumb();
  }

  getBreadCrumb = ()=>{
    let pathName = new URL(document.URL).pathname.split('/');
    pathName = pathName.slice(2);
    if(pathName.length ==1){
      let parentCate =this.state.categoryList?.find((item)=>item.id==+pathName[0]);
      this.setState({breadCrumbData:[{nameOfCate:this.parentCate?.name||"",id:parentCate?.id||0}]})
    }
    if(pathName.length ==2){
      let parentCate =this.state.categoryList?.find((item)=>item.id==+pathName[0]);
      this.setState({breadCrumbData:[{nameOfCate:parentCate?.name||"",id:parentCate?.id||0},]})
    }
    if(pathName.length ==3){
      let parentCate =this.state.categoryList?.find((item)=>item.id==+pathName[0]);
      this.setState({breadCrumbData:[{nameOfCate:parentCate?.name||"",id:parentCate?.id||0},{nameOfCate:this.state.subSelectedCate2?.attributes?.name||"",id:this.state.subSelectedCate2?.attributes?.id}]})
    }
  }

  getSubCategoryOfParaListApi = async (num:number) => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getSubCategoryOfParaListApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getSubCategoriesEndPoint}${num}`,
      token: await getStorageData('authToken'),
    });
  };

  getSubCategoryOf2ListApi = async () => {
    let pathName = new URL(document.URL).pathname.split('/');
    pathName = pathName.slice(2);
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getSubCateOf2ApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getSubCategoriesEndPoint}${pathName[1]}`,
      token: await getStorageData('authToken'),
    });
  };

  handlerESPONSE=(RESP:ApiResponse)=>{ 
    this.setState({subCateOfParent:RESP?.sub_categories?.data},()=>{
      let pathName = new URL(document.URL).pathname.split('/');
      pathName = pathName.slice(2);
      let cata=this.state.subCateOfParent.find((item)=>item.id==pathName[1]);
      this.setState({subSelectedCate2:cata?cata:{} as CategoryData},()=>{ this.getName()})
     
    })
  }

  handleResSub2=(RESP:ApiResponse)=>{
    this.setState({subCate3List:RESP.sub_categories.data},()=>{
      let pathName = new URL(document.URL).pathname.split('/');
      pathName = pathName.slice(2);
      let cata=this.state.subCate3List.find((item)=>item.id==pathName[2]);
      this.setState({subCateSelected3:cata?cata:{} as CategoryData},()=>{ this.getName()})
    })
  }

  handleToNavigateParent = (index:number)=>{
    const { breadCrumbData } = this.state;
    const newPath = breadCrumbData
      .slice(0, index + 1) 
      .map((item) => item.id) 
      .join("/"); 
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCatalogueWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), newPath);
    this.send(message);
  }

  handleInsideClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  navigateToProductDetail = (idSubcate:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), idSubcate);
    this.send(message);
  };
  // Customizable Area End
}
