import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { MouseEvent } from 'react'

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
import { Alert } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

interface BrandAttributes {
  id: number;
  name: string;
  primary_contact_name: null;
  primary_contact_number: number;
  created_at: string;
  updated_at: string;
  industry: string;
  images: {
    url: string;
  };
  no_of_catalogues: number;
  no_of_prducts: number;
  brand_certificates: any[];
}

interface Brand {
  id: string;
  type: string;
  attributes: BrandAttributes;
}

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  brandcatalogueNamesList: { name: string, industry: string, no_of_catalogues: number, no_of_prducts: number, id: number }[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandcatalougeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBrandcatalogueApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      openDialog: true,
      activeComponent: "Company",
      anchorEl: null,
      value: 12,
      totalResults: 140,
      resultperPage: 12,
      currentPage: 1,
      brandcatalogueNamesList: [],
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount();
    this.getBrandcatalogueAPICall();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    console.log("test");


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getBrandcatalogueApiCallId) {
        if (!responseJson.errors) {
          console.log("response JSON shyam", responseJson);
          const { count, items, page } = responseJson.meta?.pagy || {};
          const brandcalalogueData: { name: string, industry: string, no_of_catalogues: number, no_of_prducts: number, id: number }[] = responseJson.data.map((item: Brand) => ({
            name: item.attributes.name,
            industry: item.attributes.industry,
            no_of_catalogues: item.attributes.no_of_catalogues,
            no_of_prducts: item.attributes.no_of_prducts,
            id: item.attributes.id
          }))
          console.log("Brand Data:", brandcalalogueData);
          this.setState({
            brandcatalogueNamesList: brandcalalogueData,
            totalResults: count || 140,
            resultperPage: items || 12,
            currentPage: page || 1,
          })
        }
      }
    }
  }

  getBrandcatalogueAPICall = async (Page=1) => {
    const {resultperPage}=this.state;
    const header = {
      token: await getStorageData("authToken"),
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getBrandcatalogueApiCallId = requestMessage.messageId;
  
    // Construct endpoint with pagination parameters
    const endpoint = `${configJSON.getBrandcatalogueEndPoint}?page=${Page}&limit=${resultperPage}`;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  


  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value });
    this.getBrandcatalogueAPICall(value)

  };

  navigateToBrandCatalogue = (route: string) => {
    window.location.href = route;
  };

  goToBrandCatalogue = (brandId: number) => {
    if (!brandId) return
    const NavigateMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    NavigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), "BrandCatalouges");
    NavigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    NavigateMsg.addData(getName(MessageEnum.NavigationScreenNameMessage), brandId);
    this.send(NavigateMsg);
  }

  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };


  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };

  // Customizable Area End
}
