Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod='GET';
exports.httpDeleteMethod='DELETE';
exports.ContentType = 'application/json';
exports.getBrandcatalogueEndPoint ='bx_block_catalogue/brands';
exports.industryGetAPIEndPoint ="bx_block_productdescription/industry_markets";

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "brandpages";
exports.labelBodyText = "brandpages Body";

exports.btnExampleTitle = "CLICK ME";
exports.getallcatalougeItem = "/bx_block_catalogue/brands";

exports.postMethod='POST';
exports.putMethod='PUT';
exports.addProductApiContentType = 'application/json';
exports.addBrandApiEndPoint ='bx_block_catalogue/brands';
exports.getLogisticalListApiEndPoint ='bx_block_productdescription/logistical_codes';
exports.getLogisticalTagListApiEndPoint ='bx_block_productdescription/logistical_tags';
exports.addLogisticalTagListApiEndPoint ='bx_block_productdescription/logistical_codes';
exports.editLogisticalTagListApiEndPoint ='bx_block_productdescription/logistical_codes';



exports.httpGetMethod='GET';
exports.ContentType = 'application/json';
exports.getBrandsEndPoint ='bx_block_catalogue/brands';

exports.getBrandCatalogueEndPoint="bx_block_catalogue/catalogues";
exports.getListCategoriesEndPoint="bx_block_categories/get_categories";
exports.addBrandCatalogueEndPoint="bx_block_catalogue/catalogues";
exports.dummy="dummy";
// Customizable Area End