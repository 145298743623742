import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { builMat } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  corosolTopImage: string[];
  corosolBottomImage: string[];
  token: string | null;
  page:number;
  imgSrc:any;
  fadeOut:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTopCorosole: string = "";
  getBottomCorosole: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      corosolTopImage: [],
      corosolBottomImage: [],
      token: "",
      page:2,
      imgSrc:builMat,
      fadeOut: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getTopImage();
    this.getBottomImage();
    const token = localStorage.getItem("otp-token");
    this.setState({ token: token})
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getTopCorosole) {
      const dataTop = responseJson?.data?.map((topValue: {attributes:{image: {url: string}}}) => {
        return topValue?.attributes?.image?.url;
      });
      this.setState({ corosolTopImage: dataTop });
    }
    if (apiRequestCallId == this.getBottomCorosole) {
      const dataBottom = responseJson?.data?.map((bottomVal: {attributes:{image: {url: string}}}) => {
        return bottomVal?.attributes?.image?.url;
      });
      this.setState({ corosolBottomImage: dataBottom });
    }

    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleToggleSidebar = () => { }
  getTopImage = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.state.token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTopCorosole = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingPage}?type=top`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  changeLargeImg = (src: string) => {
    this.setState({ fadeOut: false,imgSrc: src });
  };

  getBottomImage = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBottomCorosole = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.landingPage}?type=bottom`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleNavigate = (route?:string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  // Customizable Area End
}