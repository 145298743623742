import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../../packages/framework/src/IBlock";
import { Message } from "../../../../packages/framework/src/Message";
import { runEngine } from "../../../../packages/framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { MouseEvent } from 'react';
import { getStorageData } from "../../../../packages/framework/src/Utilities";
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
  isJsonStringify?: boolean;
}

interface PagyVars {
  page: number;
  items: number;
  outset: number;
  size: number[];
  page_param: string;
  params: Record<string, unknown>;
  fragment: string;
  link_extra: string;
  i18n_key: string;
  cycle: boolean;
  request_path: string;
  count: number;
}

interface PagyMeta {
  vars: PagyVars;
  count: number;
  page: number;
  outset: number;
  items: number;
  last: number;
  pages: number;
  offset: number;
  params: Record<string, unknown>;
  from: number;
  to: number;
  in: number;
  prev: number | null;
  next: number | null;
}

interface ApiResponse {
  data: unknown;
  error: string;
  meta: {
    pagy: PagyMeta;
  };
}

interface IproductVaration{
  id: number,
  product_criteria: string,
}
interface Variation {
  id: number,
  upid_name: string,
  product_variations: IproductVaration[]
}

export interface IListVariation {
  id: number,
  type: string,
  attributes: Variation
}

interface IUPIAttribute{
  id: number,
  category_id: number,
  category_name: string,
  upid_name: string,
  created_at: string,
  updated_at: string
}

export interface IUPI{
  id:string,
  type: string,
  attributes: IUPIAttribute
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  open: boolean;
  open1: boolean;
  selectedBrands: { [key: string]: boolean };
  selectedOption: string;
  listDataVariation:IListVariation[];
  searchText:string;
  listUPIData:IUPI[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ListProductVariationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getVariationListCallId: string = '';
  timeoutId: NodeJS.Timeout | null = null;
  getListUPICallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      anchorEl: null,
      totalResults: 140,
      resultperPage: 12,
      currentPage: 1,
      open: false,
      selectedBrands: {},
      selectedOption: "asc",
      open1: false,
      listDataVariation:[],
      searchText:"",
      listUPIData:[]
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
   await super.componentDidMount(); 
   this.getListVariationApi();
   this.getListUPIApi();
 }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId) {
      case this.getVariationListCallId:
        this.handleGetListVariationResponse(responseJson);
        break;
      case this.timeoutId:
          break
      case this.getListUPICallId:
        this.handleGetListUPIResponse(responseJson);
            break
      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = (): void => {
    this.setState({ anchorEl: null });
  };

  handlePageChange = (event: any, page: any) => {
    this.setState({ currentPage: page });
  };
  handleApply = () => {
    this.handleToggle();
    this.getListVariationApi();
  };

  handleRadioChange = (option: string) => {
    this.setState({ selectedOption: option },()=>{
      this.getListVariationApi()
    });
  };

  handleToggle1 = () => {
    this.setState((prevState) => ({ open1: !prevState.open1 }));
  };

  handleToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  handleClearAll = () => {
    const clearedBrands = Object.keys(this.state.selectedBrands).reduce(
      (acc, brand) => {
        acc[brand] = false;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
    this.setState({ selectedBrands: clearedBrands });
  };
  
  handleCheckboxChange = (brand: string) => {
    this.setState((prevState) => ({
      selectedBrands: {
        ...prevState.selectedBrands,
        [brand]: !prevState.selectedBrands[brand],
      },
    }));
  };

  getListVariationApi = async () => {
    const filteredObject = Object.fromEntries(
      Object.entries(this.state.selectedBrands).filter(([key, value]) => value)
    );
    const filterParams = Object.keys(filteredObject)
      .map(filter => `filter[]=${encodeURIComponent(filter)}`)
      .join("&");
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getVariationListCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getListVariationEndPoint}?search=${this.state.searchText}&sort=${this.state.selectedOption}&${filterParams}`,
      token: await getStorageData('authToken'),
    });
  };

  getListUPIApi = async () => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.getListUPICallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getListUPIEndPoint}`,
      token: await getStorageData('authToken'),
    });
  };

  handleGetListVariationResponse(responseJson: ApiResponse) {
    if (!responseJson || responseJson.error) {
      return;
    }
    const { count, items, page } = responseJson.meta?.pagy || {}; 
    this.setState({ 
      totalResults: count | 0,
      currentPage:page|1,
      resultperPage:items|12,
      listDataVariation:responseJson.data as IListVariation[]
    })
  }

  handleGetListUPIResponse(responseJson: ApiResponse) {
    if (!responseJson || responseJson.error) {
      return;
    } 
    this.setState({ 
      listUPIData:responseJson.data as IUPI[]
    })
    const upiNames = this.state.listUPIData.map(item => item.attributes.upid_name);
    const upiNamesObject = upiNames.reduce((acc, upid_name) => {
      acc[upid_name] = false; 
      return acc;
    }, {} as { [key: string]: boolean });
    this.setState({selectedBrands:upiNamesObject});
  }

  handleSearchInput = (searchText: string) => {
    this.setState({ searchText });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(() => {
      this.getListVariationApi();
    }, 800);
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint,  token } =
      data;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": contentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  // Customizable Area End
}
