import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  history: any
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  open: boolean;
  opens: boolean;
  openss:boolean;
  openssc:boolean;
  activeComponent: string;
  openAc: boolean;
  anchorEl: HTMLElement | null;
  selectedAction: string | null;
  searchText: string,
  isChecked: boolean;
  quatationData: any;
  setSelectedValue: any;
  checkedItems: any,
  checkedItemIds:any;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class QuotationComparisionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllquotationComperisionItemList: any;
  rejectquotationComperisionItemList: any;
  acceptQuotationComparisonItemList:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      setSelectedValue: "",
      openAc: false,
      openssc:false,
      isChecked: false,
      openDialog: true,
      opens: false,
      openss:false,
      open: false,
      activeComponent: "Company",
      quatationData: {},
      anchorEl: null,
      selectedAction: null,
      searchText: '',
      checkedItems: {},
      checkedItemIds:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.getAllQuatationComparisionItems()
    const allItemIds = this.state.quatationData?.attributes?.quotation_items?.items?.map((item:any)=> item.item_id) || [];
    this.setState({ checkedItemIds: allItemIds });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getAllquotationComperisionItemList) {
        this.handelQuatationResponse(responseJson);

      }
      if (apiRequestCallId === this.rejectquotationComperisionItemList) {
        this.handelStatusQuatationResponse(responseJson);

      }
      if (apiRequestCallId === this.acceptQuotationComparisonItemList) {
        this.handelAcceptQuatationResponse(responseJson);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

  handelAcceptQuatationResponse(responseJson: any) {
    if (responseJson?.message) {
       this.handleActpopups();
    }
}
  handelStatusQuatationResponse(responseJson: any) {
    if (responseJson?.message) {
        setTimeout(() => {
            window.location.href = "/Quotations";
        }, 2000); 
    }
}


  handelQuatationResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ quatationData: responseJson?.data })
    }
  }
  handleCheckboxChange = (index:number, itemId:any) => {
    this.setState((prevState) => {
      const updatedCheckedItems = { ...prevState.checkedItems };
      if (updatedCheckedItems[index]) {
        delete updatedCheckedItems[index]; 
      } else {
        updatedCheckedItems[index] = true; 
      }

      const selectedItemIds = Object.keys(updatedCheckedItems).map(i => 
        this.state.quatationData?.attributes?.quotation_items?.items[i].item_id
      );

      const finalCheckedItemIds = selectedItemIds.length > 0 ? selectedItemIds : 
        this.state.quatationData?.attributes?.quotation_items?.items?.map((item:any) => item.item_id) || [];

      return {
        checkedItems: updatedCheckedItems,
        checkedItemIds: finalCheckedItemIds,
      };
    });
  };
  handlepopup = (id:any) => {
    this.setState({ open: true },()=>{
      this.handleNegotiate(id)
    });

  }
  handleclose = () => {
    this.setState({ open: false });
  }
  handlepopups = () => {
    this.setState({ opens: true });
  }
  handlecloses = () => {
    this.setState({ opens: false });
  }
  handleAcpopups = () => {
    this.setState({ openAc: true });
  }
  handleAccloses = () => {
    this.setState({ openAc: false });
  }
  handleActpopups = () => {
    this.setState({ openAc: false ,openss: true });
  }
  handleActcloses = () => {
    this.setState({ openss: false });
  }
  handleSuccesspopups = () => {
    this.setState({openss: false,openssc:true });
    setTimeout(()=>{
      window.location.href = "/Quotations";
    },2000)
  }
  handleSuccesscloses = () => {
    this.setState({ openssc: false });
  }


  handleChange(value: any) {
    this.setState({ setSelectedValue: value })
  }

  handleReject = (quotationId:any) => {
    this.rejectQuatationComparisionItems(quotationId, "cancelled");
  };

  handleNegotiate = (quotationId:any) => {
    this.rejectQuatationComparisionItems(quotationId, "negotiation");
  };

  getAllQuatationComparisionItems = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const Id = sessionStorage.getItem("Id")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const apiendpoint = `${configJSON.getComperisionQuotationItem}?id=${Id}`
    this.getAllquotationComperisionItemList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  rejectQuatationComparisionItems = async (quotationId:any,status:any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const apiendpoint = `${configJSON.rejectComperisionQuotationItem}?id=${quotationId}&status=${status}`;
    this.rejectquotationComperisionItemList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiendpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  acceptQuotationComparisonItems = async (quotationId: any, status: any) => {
    const header = {
        "Content-Type": configJSON.apiContentType,
        token: await getStorageData('authToken'),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const encodedItems = encodeURIComponent(JSON.stringify(this.state.checkedItemIds));
    const apiendpoint = `${configJSON.acceptComperisionQuotationItem}?id=${quotationId}&items=${encodedItems}&status=${status}`;

    this.acceptQuotationComparisonItemList = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiendpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Customizable Area End
}
