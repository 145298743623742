import { Box, Grid, styled, Typography } from "@material-ui/core";
import { wishlist, eyeIcon, tool, plus, defaultImage } from "../../../packages/blocks/catalogue/src/assets";
import React from "react";
import { IListProduct } from "../../../packages/blocks/catalogue/src/ProductCatalogueController";

interface ICard {
    product: IListProduct
}

export const ProductCard = (props: ICard) => {
    return <StyledBox className="cat-box">
        <Box className="mainContainerHover">
            <Box className="data-box">
                <Box className="plusIcon">
                    <img src={plus} alt="plus" />
                </Box>
                <Box className="wishlist">
                    <img src={wishlist} alt="wishlist" />
                </Box>
                <Box className="wishlist">
                    <img src={eyeIcon} alt="eye" />
                </Box>
            </Box>
        </Box>
        <Box className="cat-box-img-container">
            <img src={props.product?.attributes?.primary_image !==null ? props.product?.attributes?.primary_image.url :defaultImage} alt="category" className="cat-box-img" />
        </Box>
        <Grid container direction="column" className="productDescription">
            <Grid item>
                <Typography className="cat-box-name">
                    {props.product.attributes.brand_product_name||"No product name found"}
                </Typography>
            </Grid>
            <Grid item className="cat-box-type-container">
                <Typography className="cat-box-type">
                    Type: {props.product.attributes.product_type}
                </Typography>
            </Grid>
            <Grid item>
                <Typography className="cat-box-size" style={{display:"none"}}>
                    Size: 32
                </Typography>
            </Grid>
        </Grid>
    </StyledBox>
}

const StyledBox = styled(Box)({
    boxShadow:"0px 2px 8px 0px #00000014",
    "& .mainContainerHover": {
        display: "none", justifyContent: "space-between", marginTop: "20px"
    },
    "& .plusIcon": {
        width: "44px", height: "44px", backgroundColor: "#FF6600", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center"
    },
    "& .wishlist":{
        width: "44px", height: "44px", backgroundColor: "#64748B", borderRadius: "4px", display: "flex", alignItems: "center", justifyContent: "center" 
    },
    "& .cat-box-img":{
        objectFit:"contain",aspectRatio: 3/2
    }
})