import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  InputBase,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Menu,
  MenuItem,
  InputAdornment,
  Grid
} from '@material-ui/core';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Navbar from "../../../components/src/Navbar.web";
import SideBar from "../../../components/src/SideBar.web";
import { InvoicesIco, addTag, filtericon, invoiceimage, searchicon, sortingicon } from "./assets";
import { ExpandMore } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
type Status = 'in_progress' | 'released' | 'accepted' | 'modification_request' | string;
type CustomButtonProps = {
  status: Status;
  height: number;
  fontSize:number;
};
const SearchInput = styled(InputBase)(({ theme }) => ({
  fontFamily: 'Inter, sans-serif',
  fontSize: 12,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
  '& .MuiInputBase-input::placeholder': {
    color: '#BCC1CA', 
  },
}));
const DashboardBlock = styled("div")(({ theme }) => ({
  "& .MuiTab-root":{
    minWidth: "160px",
    paddingRight:0,
    paddingLeft:0,
    "@media (max-width:1600px)": {
      minWidth:"110px"
    },
  },
  "& .Maincontainer": {
    paddingLeft:"36px",
    paddingRight:"50px",
    fontFamily: "Inter, sans-serif",
    "@media (max-width:600px)": {
      margin: "0px 0px 0px 0px",
    },
    "@media (max-width:1200px)": {
      paddingLeft:"10px",
      paddingRight:"10px",
    },

  },
  "& .MuiTableCell-root":{
    "@media (max-width:600px)": {
      padding:"4px",
      fontSize: "10px",
    },
  },
  "& .TableCellHead": {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
    borderTop: "none",
    borderBottom: "none",
    height:"46px",
    paddingTop:0,
    paddingBottom:0,
    "@media (max-width:600px)": {
      fontSize: "10px",
    },
  },
  "& .searchInput1": {
    width: "40px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    marginRight:"20px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
    "@media (min-width:600px)": {
      width: '173px', 
    },
    '&:focus-within': {
      width: '173px', 
    },
  },
  "& .MuiInputBase-input:focus-within":{
    "@media (max-width:600px)": {
      width: '173px',
    },
  },
  "& .MuiIconButton-root":{
    "@media (max-width:600px)": {
      padding:"2px"
    },
  },
  "& .navTabContainer":{
    "@media (max-width:1326px)": {
      flexWrap:"wrap"
    },
  },
  "& .searchContainer2":{
    "@media (max-width:1326px)": {
      padding:"10px !important"
    },
  },
  "& .firstHeaderCellHead":{
    "@media (max-width:900px)": {
     display:"none !important"
    },
  },
  "& .MuiTabs-fixed":{
    overflow:"auto !important"
  },
  "& .buttonContainer":{
    paddingTop:15,
    "@media (max-width:750px)": {
      flexWrap:"wrap"
     },
  },
  "& .eyeIcon":{
    marginRight:6
  },
  "& .withOutPadLeft":{
    paddingLeft:"0px !important"
  },
  "& .MuiChip-root":{
    fontFamily:"Inter,sans-serif !important"
  }
}))

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    top: "auto",
    right: "auto", 
    left: "auto",
    borderRadius:"8px",
   border:"1px solid #CBD5E1"
  },
  "& .MuiList-padding":{
   paddingTop:0,
   paddingBottom:0,
  },
  "& .MuiListItem-button":{
    borderRadius:8,
    marginRight:4,
    marginLeft:4
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#F3F4F6", 
  },
});


// Customizable Area End
import DashboardController, {
  Props,
  PurchaseOrder,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  unSubscribeTableRow =(row:PurchaseOrder)=> !this.state.showAdditionalRow.includes(row.id) && (
    <TableRow
      style={{
        ...styles.TableRow,
        border: "none",
      }}
    >
      <TableCell style={{ ...styles.tableCell, width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
      <TableCell
        style={{
          ...styles.purchaseId,
          fontFamily: "Inter, sans-serif",
          borderLeft: "1px solid #E7E2F0",
          borderTop: "1px solid #E7E2F0",
          cursor: "pointer",
        }}
        onClick={() => this.getNavigationMessage('OrderDetail')}
      >
        {row.attributes.purchase_order_id}
      </TableCell>
      <TableCell style={styles.tableCell} className="withOutPadLeft">
        <Box>
          <Typography style={{ ...styles.projectname, fontFamily: "Inter, sans-serif" }}>
            {row.attributes.project_name}
          </Typography>
          <Typography variant="caption" style={{ ...styles.companyName, fontFamily: "Inter, sans-serif" }}>
            {row.attributes.company_name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ ...styles.amount, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }} className="withOutPadLeft">
        {row.attributes.amount_invoiced?row.attributes.amount_invoiced:"--"}
      </TableCell>
      <TableCell style={styles.tableCell} className="withOutPadLeft">
        {this.statusButton({status:row.attributes.status,height:32,fontSize:12})} 
      </TableCell>
      <TableCell style={styles.tableCell} className="withOutPadLeft">
        <Box>
          <Typography style={{ ...styles.date, fontFamily: "Inter, sans-serif" }}>
            {this.formatDate(row.attributes.created_at)}
          </Typography>
          <Typography variant="caption" style={{ ...styles.time, fontFamily: "Inter, sans-serif" }}>
            {this.formatTime(row.attributes.created_at)}
          </Typography>
        </Box>
      </TableCell>
      <TableCell style={{ ...styles.tableCell, borderRight: "1px solid #E7E2F0" }}>
        <Box style={{display:"flex",marginLeft:"-30px"}}>
        <IconButton
          aria-label="more"
          onClick={(event) => this.handleMenuClick(event)}
          data-testId="menuIcon"
          style={{padding:"12px 12px 0px 12px"}}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          anchorEl={this.state.anchorEL}
          keepMounted
          open={Boolean(this.state.anchorEL)}
          onClose={this.handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center', 
          }}
          transformOrigin={{
            vertical: 'top', 
            horizontal: 'center', 
          }}
          PaperProps={{
            style: {
              top: '60px', 
              marginTop:"40px",
              marginRight:10,
              paddingTop:"0px",
              paddingBottom:"0px"
            },
          }}
        >
          <MenuItem data-testId="invoicemenuItem" style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
            <img src={invoiceimage} style={styles.imagedropdown} />
            Invoice
          </MenuItem>
          <MenuItem data-testId="proformamenuItem" style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
            <img src={invoiceimage} style={styles.imagedropdown} />
            Pro Forma
          </MenuItem>
          <MenuItem data-testId="addtagsmenuItem" style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
            <img src={addTag} style={styles.imagedropdown} />
            Add Tags
          </MenuItem>
        </StyledMenu>
        <Box>
          <ExpandMore
            style={{
              transition: "transform 0.3s ease",
              transform: this.state.showAdditionalRow.includes(row.id) ? "rotate(180deg)" : "rotate(0deg)",
              padding:"12px 0px 0px 0px",
              cursor:"pointer"
            }}
            data-test-id="expandMoreIcon"
            onClick={() => this.handleViewClick(row.id)}
          />
        </Box>
        </Box>
      </TableCell>
      <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
    </TableRow>
  )
  subscribeRow = (row:PurchaseOrder) => {
    const {showAdditionalRow}=this.state
    const chipbutton = [
      {
        button1: "Building 5",
        button2: "Floor 2",
        button3: "Floor 3",
        button4: "Floor 4",
        button5: "Floor 5",
        button6: "Floor 6",
        button7: "Floor 7",
        button8: "Floor 8",
        button9: "Floor 9",
        button10: "Floor 10",
        button11: "Floor 11",
        button12: "Floor 12",
        button13: "Floor 13",
        button14: "Floor 14",
        button15: "Floor 15",
      },
    ];
    return this.state.showAdditionalRow.includes(row.id) && (
    <>
      <TableRow
        style={{
          ...styles.TableRow,
          border: "none",
        }}
      >
        <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
        <TableCell colSpan={5} style={{ margin: "0px 20px", border: "1px solid #E7E2F0",borderRight:"none" }}>
          <Box style={styles.flexContainer}>
            <Box>
              <Typography style={{ ...styles.poid, fontFamily: "Inter, sans-serif", float: "left" ,width:"100%"}}>
                {row.attributes.purchase_order_id}            
              {row.attributes.subscription && <Chip label="Subscribed" style={styles.subcribed} />}
               </Typography>
              <Box style={styles.buttonContainer} className="buttonContainer">
              {this.statusButton({status:row.attributes.status,height:23,fontSize:11})} 
                <Chip label={`Recieved:${this.formatDate(row.attributes.created_at)}`} style={styles.secondchip} />
                <Box style={styles.thirdchip}>
                  <img src={InvoicesIco} />
                  &nbsp; <p style={{fontFamily:"Inter,sans-serif"}}>Invoices</p>
                </Box>
                <Box style={styles.thirdchip}>
                  <img src={InvoicesIco} />
                  &nbsp; <p style={{fontFamily:"Inter,sans-serif"}}>Pro Forma Invoices</p>
                </Box>
              </Box>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left" style={{borderTop: "1px solid #E7E2F0"}} >  <Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginRight: "22px",
                  marginLeft:"-20px"
                }}
                data-test-id="expandMore"
                onClick={()=>this.handleViewClick(row.id)}
              >
                <ExpandMore
                  style={{
                    transition: "transform 0.3s ease",
                    transform: showAdditionalRow.includes(row.id) ? "rotate(180deg)" : "rotate(0deg)",
                    marginLeft:"20px"
                  }}
                />
                <Button
                  variant="contained"
                  style={{ ...styles.viewbutton, fontFamily: "Inter, sans-serif", textTransform: "none", boxShadow: "none",gap:6 }}
                  data-testId="viewButton"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="eyeIcon">
                    <path d="M10.0052 4.0625C6.04688 4.0625 2.66646 6.52458 1.29687 10C2.66646 13.4754 6.04688 15.9375 10.0052 15.9375C13.9635 15.9375 17.344 13.4754 18.7135 10C17.344 6.52458 13.9635 4.0625 10.0052 4.0625ZM10.0052 13.9583C7.82021 13.9583 6.04688 12.185 6.04688 10C6.04688 7.815 7.82021 6.04167 10.0052 6.04167C12.1902 6.04167 13.9635 7.815 13.9635 10C13.9635 12.185 12.1902 13.9583 10.0052 13.9583ZM10.0052 7.625C8.69104 7.625 7.63021 8.68583 7.63021 10C7.63021 11.3142 8.69104 12.375 10.0052 12.375C11.3194 12.375 12.3802 11.3142 12.3802 10C12.3802 8.68583 11.3194 7.625 10.0052 7.625Z" fill="#E7A573" />
                  </svg>
                  View
                </Button>
              </Box>
            </Box></TableCell>
        <TableCell style={{ width: "0px", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
      </TableRow>
       {this.financialDetail(row)}
       {this.product(row)}
       {this.scheduledelivery(row)}
      {showAdditionalRow.includes(row.id) &&
        <TableRow style={{ ...styles.TableRow, border: "none" }}>
          <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
          <TableCell
            colSpan={6}
            style={{
              ...styles.TableCell,
              borderRight: "1px solid #E7E2F0",
              borderLeft: "1px solid #E7E2F0",
            }}
          >
            <Grid container>
              {chipbutton.map((column, index) => (
                Object.keys(column).map((key, idx) => (
                  <Grid item
                    key={idx}
                    style={{ padding: "8px", border: "none" }}
                    data-test-id="chip-floor"
                  >

                    <Chip
                      key={idx}
                      label={column[key as keyof typeof column]} style={{
                        ...styles.Floorchip,
                        borderRadius: "6px",
                      }}
                    />
                  </Grid>
                ))
              ))}
            </Grid>
          </TableCell>
          <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
        </TableRow>}
    </>
  )}

  financialDetail=(row:PurchaseOrder)=>{
    const financialDetails = [
      {
        first: {
          title: "PO Value",
          value: "Rs. 11,00,000",
        },
        second: {
          title: "Receipts",
          value: "Rs. 3,00,000",
        },
        third: {
          title: "Amount Invoiced",
          value: "Rs. 5,00,000",
        },
        fourth: {
          title: "Quotation ID",
          value: "QID-1234567890",
        },
        fifth: {
          title: "Pending PO Amount",
          value: "--"
        },
        sixth: {
          title: "Payment Terms",
          value: "30 Day credit",
        },
      },
    ];
    return (this.state.showAdditionalRow.includes(row.id) &&
      financialDetails.map((column, index) => (
        <TableRow key={index} style={{ ...styles.TableRow, border: "none" }}>
          <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
          <TableCell
            colSpan={6}
            style={{
              ...styles.TableCell,
              borderRight: "1px solid #E7E2F0",
              borderLeft: "1px solid #E7E2F0",
            }}
          >
            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0px 2px" }}>
              <Box style={{ ...styles.Product, marginLeft: "0px", marginRight: "50px" }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.first.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                  {row.attributes.po_value?row.attributes.po_value:"--"}
                </Typography>
              </Box>
              <Box style={{ ...styles.Product }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.second.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                {row.attributes.receipts?row.attributes.receipts:"--"}
                </Typography>
              </Box>
              <Box style={{ ...styles.Product }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.third.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                {row.attributes.amount_invoiced?row.attributes.amount_invoiced:"--"}
                </Typography>
              </Box>
              <Box style={{ ...styles.Product }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.fourth.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                {row.attributes.quotation_id?row.attributes.quotation_id:"--"}
                </Typography>
              </Box>
              <Box style={{ ...styles.Product }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.fifth.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                {row.attributes.pending_po_amount?row.attributes.pending_po_amount:"--"}
                </Typography>
              </Box>
              <Box style={{ ...styles.Product, borderLeft: "1px solid #E7E2F0" }}>
                <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                  {column.sixth.title}
                </Typography>
                <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                {row.attributes.payment_terms?row.attributes.payment_terms:"--"}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
        </TableRow>
      )))
  }
  
  product=(row:PurchaseOrder)=>{
    const Products = [
      {
        PurchaseId: "PO-240218-0011",
        pipe: "Rectangular Pipe",
        type: "TMT",
        type2: "Square GI Pipe",
        mortax: "Block Jointing Mortar",
      },
    ];
    return(
      this.state.showAdditionalRow.includes(row.id) &&
        Products.map((column, index) => (
          <TableRow key={index} style={{ ...styles.TableRow, border: "none" }}>
            <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
            <TableCell
              colSpan={6}
              style={{
                ...styles.TableCell,
                borderRight: "1px solid #E7E2F0",
                borderLeft: "1px solid #E7E2F0",
              }}
            >
              <Typography style={{ ...styles.purchaseID, marginLeft: "0px" }}>Products</Typography>
              <Box style={{ marginTop: "8px", marginLeft: "0px" }}>
                {row.attributes.product_name.length > 0 ? row.attributes.product_name.map((column) => 
                  (<Typography
                   key={column} 
                    style={{ ...styles.Purchasehead, float: "left", fontFamily: "Inter, sans-serif" }}
                  >
                    {column}
                  </Typography>)
                ) : <Typography
                  style={{ ...styles.Purchasehead, float: "left", fontFamily: "Inter, sans-serif" }}
                >
                  --
                </Typography>
                }
              </Box>
            </TableCell>
            <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
          </TableRow>
        ))
    )
  }

  scheduledelivery=(row:PurchaseOrder)=>{
    const Scheduledelivery = [
      {
        Head: "Schedule of Delivery",
        Date1: "09 Aug, 2024",
        Date2: "19 Aug, 2024",
        Date3: "29 Aug, 2024",
      },
    ];
    return(
      this.state.showAdditionalRow.includes(row.id) &&
        Scheduledelivery.map((column, index) => (
          <TableRow key={index} style={{ ...styles.TableRow, border: "none" }}>
            <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
            <TableCell
              colSpan={6}
              style={{
                ...styles.TableCell,
                borderRight: "1px solid #E7E2F0",
                borderLeft: "1px solid #E7E2F0",
              }}
            >
              <Typography style={{ ...styles.Scheduled, fontFamily: "Inter, sans-serif", marginLeft: "0px" }}>{column.Head}</Typography>
              <Box style={{ marginLeft: "0px" }}>
                <Typography
                  style={{ ...styles.DeliveryDate, float: "left" }}
                >
                  {column.Date1}
                </Typography>
                <Typography
                  style={{ ...styles.DeliveryDate, float: "left" }}
                >
                  {column.Date2}
                </Typography>
                <Typography
                  style={{ ...styles.DeliveryDate, float: "left" }}
                  key={index}
                >
                  {column.Date3}
                </Typography>
              </Box>
            </TableCell>
            <TableCell style={{ width: "0px", border: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
          </TableRow>
        ))
    )
  }

  statusButton = ({status,height,fontSize}:CustomButtonProps) => {
    const getButtonStyles = (status: Status) => {
      switch (status) {
        case 'in_progress':
          return {
            backgroundColor: '#F2F0FF',
            color: '#2200F4',
            text: 'In-Process',
          };
        case 'released':
          return {
            backgroundColor: '#FFF9E6',
            color: '#7E6300',
            text: 'Released',
          };
        case 'accepted':
          return {
            backgroundColor: '#F1F8FD',
            color: '#379AE6',
            text: 'Accepted',
          };
        case 'modification_request':
          return {
            backgroundColor: '#E8F8FF',
            color: '#007BAF',
            text: 'Modification Request',
          };
        default:
          return {
            backgroundColor: '#FFF6F0',
            color: '#EE6900',
            text: status, 
          };
      }
    };

    const { backgroundColor, color, text } = getButtonStyles(status);

    return (
      <Button
        style={{
          backgroundColor,
          color,
          fontFamily: 'Inter, sans-serif',
          textTransform: 'none',
          height:height,
          fontSize:fontSize
        }}
      >
        {text}
      </Button>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const tabLabels = [
      "All POs",
      "Drafts",
      "In-Process",
      "Completed",
      "Cancelled",
      "Modification Requests",
    ];

    const headers = [
      { id: 'purchaseOrderId', label: 'Purchase Order ID',minWidth:160.66 },
      { id: 'company', label: 'Company',minWidth:164.66 },
      { id: 'amount', label: 'Amount' ,minWidth:170.66},
      { id: 'status', label: 'Status',minWidth:130.66 },
      { id: 'dateTime', label: 'Date and Time' ,minWidth:130.66},
      { id: 'actions', label: 'Actions',minWidth:130.66 },
    ];
    const { value } = this.state;

    return (
      //Merge Engine DefaultContainer
      <DashboardBlock>
        <Navbar history={this.props.history} mycart={true}/>
      <Box component="nav" style={{ backgroundColor: "#fff" }}>
        <SideBar navigation={this.props.navigation} mycart={true}>
          <Box className="MainContainer">
            <Box style={styles.header}>
              <Typography variant="h6" component="div" style={styles.heading}>
                Purchase Order
              </Typography>
            </Box>

            <Box style={{ border: "1px solid #E7E2F0", borderRadius: "6px" }}>
              <Box style={styles.navTabContainer} className="navTabContainer">
                <Box
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    overflowX: "auto",
                    borderRadius: "10px 0px"
                  }}
                >
                  <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                    style: { background: "#EE6900", height: "4px", borderRadius: "8px",overflow:"auto" },
                  }}>
                    {tabLabels.map((label, index) => (
                      <Tab
                        key={index}
                        style={{
                          textTransform: "none", height: "58px",
                        }}
                        label={
                          <Box style={{ display: "flex" }} alignItems="center">
                            <Typography
                              style={
                                value === index
                                  ? { ...styles.activeTab, textAlign: 'center' }
                                  : { ...styles.inactiveTab, textAlign: 'center' }
                              }
                            >
                              {label}
                            </Typography>
                            {index < 4 && (
                              <Box style={styles.badge}>
                                <span >{index + 1}</span>
                              </Box>
                            )}
                          </Box>
                        }
                      />
                    ))}
                  </Tabs>
                </Box>
                <Box style={styles.searchContainer2} className="searchContainer2">
                  <SearchInput
                    placeholder="Search"
                    className="searchInput1"
                    inputProps={{
                      style: {
                        fontFamily: 'inter, sans-serif',
                        fontSize: 12,
                        fontWeight: 400,
                        color: "rgba(0, 0, 0, 0.87)",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <img src={searchicon} alt="search icon" style={{ width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                  <img src={sortingicon} data-testId="filterIcon"
                    alt="sorting icon" style={{ width: 20, height: 20 }} />
                  <IconButton>
                    <img src={filtericon} data-testId="MoreVertIcon"
                      alt="filter icon" style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Box>
              </Box>

              <TableContainer style={styles.tableContainer}>
                <Table aria-label="simple table" >
                  <TableHead>
                    <TableRow style={styles.TableHead}>
                      <TableCell style={{ border: 'none', width: '0px' }} className="firstHeaderCellHead">{" "}</TableCell>
                      {headers.map(({ id, label,minWidth }) => (
                        <TableCell  key={id} align="left" className="TableCellHead" style={{minWidth:minWidth,padding:0}}>
                          {label}
                        </TableCell>
                      ))}
                      <TableCell style={{ width: "0px", borderBottom: "none" }} className="firstHeaderCellHead">{" "}</TableCell>
                    </TableRow>
                  </TableHead>
                    <TableBody style={styles.TBody}>
                      <TableRow style={{ height: "40px" }}></TableRow>
                      {this.state.purchaseOrderList.length > 0 && this.state.purchaseOrderList.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                          {this.unSubscribeTableRow(row)}
                          {this.subscribeRow(row)} 
                        </React.Fragment>
                      ))}
                      <TableRow style={{ height: "50px" }} ></TableRow>
                    </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </SideBar >
      </Box >
      </DashboardBlock>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  Maincontainer: {
    width: "94%",
    fontFamily: "Inter, sans-serif", 
  },
  header: {
    alignItems: "left",
    marginTop:34,
    marginBottom:38
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "F3F4F6"
  },
  navTabContainer: {
    fontfamily: "Inter, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  badge: {
    backgroundColor: "#DE3B40",
    color: "white",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    marginLeft: "16px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    lineHeight:"10px",
    fontFamily: "Inter, sans-serif",
    fontSize: "10px",
    fontWeight: 400,
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#323842",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  TableHead: {
    backgroundColor: "#F3F4F6",
  },
  TableCellHead: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableCell: {
    borderTop: "1px solid #E7E2F0"
  },
  firstChip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor:"#EB8689",
    color: "#FFF"
  },

  secondchip: {
    fontfamily: "Inter, sans-serif !important",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#FFFFFF",
    color: "#E7A573",
    border: "1px solid #E7A573",
  },
  thirdchip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#E7A573",
    color: "#FFFFFF",
    lineHeight:"17px",
    padding:"0px 14px",
    display:"flex",
    alignItems:"center"
  },
  TableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9F9",
    },
  },
  TableCell: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#101828",
    borderTop: "1px solid #E2E8F0",
    width: "100%",
  },
  searchContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px 0 0",
  },
  searchInput1: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
  },
  purchaseId: {
    fontSize: "12px",
    fontWeight: 700,
    color:"#323842"
  },
  Project: {
    fontSize: "13px",
    fontWeight: 500,
  },
  companyName: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#64748B"
  },
  amount: {
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "26px",
    color:"#323842"
  },
  projectname: {
    fontSize: "13px",
    fontWeight: 500,
    color:"#323842"
  },
  Statusbutton: {
    height: "32px",
    borderRadius: "9px",
    backgroundColor: "#FFF6F0",
    color: "#EE6900",
    fontSize: "12px",
    fontWeight: 400,
  },
  date: {
    fontSize: "13px",
    fontWeight: 500,
    color:"#323842",
  },
  time: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#64748B"
  },
  imagedropdown: {
    width: "18px",
    height: "18px",
    marginRight: "8px"
  },
  menu: {
    height: "120px",
  },
  menuitem: {
    fontSize: "14px",
    fontWeight: 400,
    paddingTop:10,
    paddingBottom:10
  },
  tableContainer: {
    borderRadius: "4px",
    boxShadow: "none",
  },
  TBody: {
    width: "94%",
    margin: "0 auto",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    marginTop: "15px",
    display: "flex",
    gap: "8px",
    width:"100%"
  },
  poid: {
    fontSize: "12px",
    fontWeight: 700,
  },
  subcribed: {
    height: "22px",
    borderRadius: "6px",
    margin: "0px 0px 0px 9px",
    fontSize: "11px",
    fontWeight: 400,
    backgroundColor:"#E3E7ED",
    color:"#565E6C"
  },
  viewbutton: {
    fontSize: "13px",
    fontWeight: 400,
    height: "36px",
    borderRadius: "12px",
    color: "#E7A573",
    backgroundColor: "#FFFFFF",
    marginTop: "5px",
    paddingLeft:0,
    paddingRight:0
  },
  Product: {
    width: "100%",
    marginTop: "10px",
    marginRight: "0px",
  },
  producttitle: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842"
  },
  productvalue: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#64748B"
  },
  purchaseID: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842",
    marginLeft: "50px",
    fontFamily:"Inter,sans-serif"
  },
  Purchasehead: {
    marginRight: "20px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B",
  },
  Scheduled: {
    fontSize: "12px",
    fontWeight: 500,
    color: "rgba(50, 56, 66, 1)",
    marginLeft: "50px"
  },
  DeliveryDate: {
    marginTop: "8px",
    marginRight: "30px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B"
  },
  Floorchip: {
    backgroundColor: "#F0F6FF",
    fontSize: "11px",
    fontWeight: 400,
    color: "#579AFF"
  }
}
// Customizable Area End
