import React from "react";

import {
  Box,
  Typography,
  Hidden,
  Button,
  Dialog,
  DialogContent,
  BoxProps
  // Customizable Area Start
  ,TextField
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { navData, mihir, vector, addData, camera } from "./assets";
import GeneralInfoBuyer from "../src/GeneralInfoBuyer.web";
import DocumentsBuyer from "../src/DocumentsBuyer.web";
import BankDetailsBuyer from "../src/BankDetailsBuyer.web";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CloseIcon from '@material-ui/icons/Close';


const GroupBlock = styled("div")(({ theme }) => ({
   padding:"24px",
   "& .nav-box": {
    border:" 1px solid #E2E8F0",
    padding: "10px",
    borderRadius:"8px"
   },
   "& .heading-txt": {
    fontSize:"24px",
    fontWeight:700,
    fontFamily: 'Inter,sans-serif',
    color:"#0A0528"
   },
   "& .edit-btn":{
    width: "90px",
    height: "40px",
    margin: "22px 28px",
    borderRadius:"12px",
    border:"1px solid transparent",
    color:"#FFF",
    backgroundColor:"#BDC1CA",
    fontSize:"14px",
    fontWeight:500,
    fontFamily: 'Inter,sans-serif',
    textTransform:"capitalize"
  },
  "& .save-btn":{
    width: "90px",
    height: "40px",
    margin: "22px 28px",
    borderRadius:"12px",
    border:"1px solid transparent",
    color:"white",
    fontSize:"14px",
    fontWeight:500,
    fontFamily: 'Inter,sans-serif',
    backgroundColor:"#FF6600",
    textTransform:"capitalize"
  },
  "& .label-txt": {
    fontSize:"14px",
    fontWeight:700,
    fontFamily: 'Inter,sans-serif',
  },
  "& .input-data": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid transparent",
    backgroundColor: "#F3F4F6",
    marginTop: "10px",
    padding: "10px 8px",
    fontWeight:400,
    fontSize:"16px"
   },
   "& .input": {
    width: "97%",
    height: "44px",
    borderRadius: "8px",
    pointerEvents: "none",
    backgroundColor: "#F3F4F6", 
    color: "#BCC1CA", 
    border: "1px solid transparent",
    marginTop: "10px",
    padding: "10px 8px",
    fontWeight:400,
    fontSize:"16px",
    fontFamily:"Inter,sans-serif",
    "& :placeholder":{
      fontFamily:"Inter,sans-serif"
    }
   },
   "& .input::placeholder":{
    fontFamily: "Inter, sans-serif"
  },
   "& .group-data": {
    border: "1px solid #E2E8F0",
    borderRadius: "8px",
    padding: "29px"
    },
  "& .sort-data": {
    display: "flex",
    // width: "128px",
    height: "44px",
    border: "1px solid transparent", 
    borderRadius:"8px",
    alignItems: "center",
    justifyContent: "flex-end",
    position:"relative",
    paddingRight:"10px",
    backgroundColor:"#F3F4F6",
    margin:"20px 0px"
  },
  "& .upload-btn":{
    width: "202px",
    height: "44px",
    margin: "30px 0px",
    borderRadius:"12px",
    border:"1px solid transparent",
    color:"#FFF",
    fontSize:"16px",
    fontWeight:400,
    backgroundColor:"#FF6600",
    fontFamily: 'Inter,sans-serif',
    '@media (max-width: 600px)': {
      fontSize:"12px",
    },

  },
  "& .dropdown-list-data-language": {
    fontFamily: "Inter,sans-serif",
    position: "absolute",
    top: "35px",
    backgroundColor:"#FFFFFF",
    left:"1%",
    borderRadius: "8px",
    fontWeight:400,
    zIndex: 100,
    padding: "5px",
    transition: "all 0.3s ease-out",
    fontSize: "14px",
    cursor: "pointer",
    maxHeight: "200px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    overflowY: "auto",
    width:"92%",
  },
  "& .dropdown-list-data-language li": {
    padding: "12px 19px",
  },
  "& .drop-data": {
    justifyContent: "flex-end",
    gap: "10px",
    alignItems: "center",
    display: "flex",
    cursor:"pointer"
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .text-data": {
    fontWeight:500,
    fontFamily: 'Inter,sans-serif',
    fontSize: "16px",
    color:"#272727"
  },
  "& .details-data": {
    paddingBottom: "30px",
    border: "1px solid #E2E8F0",
    borderRadius: "8px"
  },
  "& .edit-img": {
    position: "relative",
    top: "-38px",
    left: "22px",
  },
  "& .custom-tab-label": {
    textTransform: "lowercase",
    fontFamily:"Inter,sans-serif",
    fontSize:"12px",
    "@media (max-width:900px)": {
      width:"23vw"
    },
  },
  "& .MuiTabs-scroller.MuiTabs-fixed .MuiTabs-indicator" :{
    backgroundColor: "#FF6600",
    borderBottom: "1px solid #FF6600 !important",
    fontWeight:"700 !important"
  },
 "& .custom-tab-label::first-letter": {
    textTransform: "uppercase"
  },
  "& .MuiTab-textColorPrimary.Mui-selected": {
    color:"#FF6600",
    fontWeight:700
  },
  "& .MuiTabs-centered": {
    justifyContent:"left",
    borderBottom: "1px solid #CBD5E1",
  },
  "& .upload-btn-edit": {
    position: "relative",
    alignItems: "center",
    top: "6px",
    left: "1px",
    flexDirection: "column",
    justifyContent: "center",
    display: "flex",
    zIndex: 2,
    borderRadius: "50px",
    width: "72px",
    height: "72px",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  "& .text-center": {
    marginTop: "5px",
    color:"white",
    fontWeight:500,
    fontFamily: 'Inter,sans-serif',
    fontSize:"12px",
  },
  "& .company-text": {
    width:"100px",
    fontSize:"12px",
    fontWeight:400,
    color:"#171A1F",
    padding:"0px 16px",
    fontFamily:"Inter,sans-serif"
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius:"8px"
  },
  "& .addNewCompany":{
    marginTop:"30px",
    backgroundColor:"#FF6600",
    border:"1px solid transparent",
    borderRadius:"12px",
    padding:"10px 20px",
    color:"white",
    fontFamily:"Inter,sans-serif"
  }
  }))

const CompanyNameInput = styled(TextField)({
  width: "520px",
  height: "60px",
  borderRadius: "5px",
})
import BillingAddress from "./BillingAdress.web";
  
// Customizable Area End

import GroupDataController, {
  Props,
} from "./GroupDataController";

export default class GroupData extends GroupDataController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  renderPopup =()=>{
    const BoxView = Box as React.FC<BoxProps>;
        return(
          <>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleClose}
            style={{ borderRadius: "50px" }}
            PaperProps={{
              style: {
                borderRadius: "8px",width:"600px",height:"300px",
              },
            }}
          >
              <BoxView
                display={"flex"}
                style={{ 
                  cursor: "pointer", 
                  justifyContent: "space-between",
                  margin: "5%",
                  marginBottom: "0px",
                }}
                data-test-id="close-dialoug"
                onClick={this.handleClose}
              >
                <Typography style={{  fontWeight:700, fontSize: "24px",color:"#272727", fontFamily: 'Inter,sans-serif'}}>Add New Company</Typography>
                <CloseIcon />
              </BoxView>
              <DialogContent
                style={{ lineHeight: "26px" ,padding:"22px",color: "#000000", fontSize:"24px", fontFamily: "Inter,sans-serif", overflowX:"hidden",overflowY:"hidden",
                fontWeight: 700}}
              >
                 <div style={{ width:"100%", border:"1px solid #E2E8F0"}}></div>
                <Box style={{ marginTop:"35px"}}>
                  <Typography style={{ fontSize: "14px",fontWeight: 700,color:"#334155",paddingBottom:"4px"}}>Company Name</Typography>
                   <CompanyNameInput
                    variant="outlined"
                    size="small"
                    placeholder="Company Name"
                    data-test-id="company-name"
                    onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleCompanyName(event)}
                    InputProps={{style:{borderRadius: "5px",border: "1px solid transparent"}}}
                />
                </Box>
                <Box style={{ display: "flex", justifyContent: "end", marginTop: "19px", gap: "20px"}}>
                  <Button style={{
                      height: "44px",
                      borderRadius:"8px",
                      width: "120px",
                      fontFamily: 'Inter, sans-serif',
                      border:"1px solid #FF6600",
                      fontSize:"14px",
                      backgroundColor:"#FF6600",
                      color:"white",
                      fontWeight:700,
                      textTransform: "capitalize"
                  }}
                  onClick={this.addNewCompany}
                  data-test-id="add-new-company-btn"
                  >Add</Button>
                </Box>
              </DialogContent>
          </Dialog>
        </>
        )
  }

  // Customizable Area End

  render() { 
    return (
      // Customizable Area Start

      <GroupBlock>
      <Box style={{ display:"flex", justifyContent:"space-between"}}>
          <Box style={{ display:"flex", alignItems: "center", justifyContent: "center", gap: "13px"}}>
          <img src={navData} alt="navData" className="navigation-arrow" style={{border:'1px solid #E2E8F0', borderRadius:"5px",display:"none"}}/>
              <Typography className="heading-txt">Mihir Group</Typography>
              
          </Box>
          <Box>
            {this.state.isEdit ? (
              <Button data-test-id="save-btn" className="save-btn" onClick={this.handleSave}>
              Save
            </Button>
            ) : (
              <Button data-test-id="edit-btn" className="edit-btn" onClick={this.handleEdit}>
              Edit
              </Button> 
            )}
          </Box>
      </Box>
      <Box className="group-data">
        <Typography className="label-txt">Group Name <span style={{ color:"red"}}>*</span></Typography>
        <input id="company" data-test-id="group-input" className={this.state.isEdit ? "input-data" : "input"} type="text" name="groupName" onChange={this.handleGroupNameChange} value={this.state.groupName} placeholder="Mihir Group" ></input>

      <Box style={{ marginTop:"20px"}}>
      <Typography className="label-txt">Group Image</Typography>
        {this.state.isEdit ? (
          <div>
          <label htmlFor="upload-button">
            {this.state.selectedImage.preview ? (
              <img style={{ width:"72px", height:"72px", borderRadius:"50px", border:"1px solid #e09029", marginTop:"5px"}} src={this.state.selectedImage.preview} alt="dummy" width="300" height="300" />
            ) : (
              <>
                <span className="fa-stack fa-2x mt-3 mb-2">
                  <i className="fas fa-circle fa-stack-2x" />
                  <i className="fas fa-store fa-stack-1x fa-inverse" />
                </span>
                <Box style={{ height:"60px"}}>
                  <img style={{ width:"72px", height:"72px", borderRadius:"50px", border:"1px solid #e09029", marginTop:"5px", position:"absolute" }} src={mihir}/>
                  <Box className="upload-btn-edit">
                    <img src={camera} alt="camera"/>
                    <Typography className="text-center">Edit</Typography>
                  </Box>
                </Box>
              </>
            )}
          </label>
          <input
            type="file"
            data-test-id="img-btn"
            id="upload-button"
            style={{ display: "none" }}
            onChange={this.handleImageChange}
          />
          <br />
        </div>
        ) : (
          <Box style={{ width:"72px", height:"72px", borderRadius:"50px", border:"1px solid #e09029", marginTop:"5px", display:"flex", justifyContent:"center", alignItems:"center", overflow:"hidden"}}>
            <img style={{ width:"60px", height:"60px"}} src={this.state.selectedImage.preview?this.state.selectedImage.preview:mihir} data-test-id='group-image'/>
          </Box>
        )}
        </Box>
      </Box> 

      <Box style={{ display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <div className="sort-data" ref={this.boxRef}>
          <Box className="drop-data" data-test-id="company-btn" onClick={this.handleOpen}>
          <Typography className="company-text">
            {this.state.selectedCompany.companyName.length > 10 ? (
              <>
                {this.state.selectedCompany.companyName.slice(0, 15)}
              </>
            ) : (
              this.state.selectedCompany.companyName || 
              (this.state.companyNamesList.length > 0 && this.state.companyNamesList[0].companyName)
            )}
          </Typography>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5268 5.99969C11.2668 5.73969 10.8468 5.73969 10.5868 5.99969L8.00015 8.58635L5.41348 5.99969C5.15348 5.73969 4.73348 5.73969 4.47348 5.99969C4.21348 6.25969 4.21348 6.67969 4.47348 6.93969L7.53348 9.99969C7.79348 10.2597 8.21348 10.2597 8.47348 9.99969L11.5335 6.93969C11.7868 6.68635 11.7868 6.25969 11.5268 5.99969Z" fill="#171A1F"/>
          </svg>

          </Box>
            {this.state.showComData && (
            <ul className="dropdown-list-data-language" ref={this.dropdownRef}>
                  {this.state.companyNamesList.map((item:{id:number,companyName:string}) => (
                <li
                    id="dropdowndata"
                    className="dropdown-item"
                    key={item.companyName}
                    onClick={() => this.selectComName(item)}
                    data-test-id="filter-data"
                >
                  <div style={{ fontFamily: 'Inter,sans-serif'}}>{item.companyName}</div>
                </li>
                ))}
            </ul>
            )}
        </div>

      </Box>

        <Box className="details-data">
          <Tabs
            data-test-id="tab-btn"
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label={<span className="custom-tab-label">General Info</span>}/>
            <Tab label={<span className="custom-tab-label">Documents Details</span>}/>
            <Tab label={<span className="custom-tab-label">Billing Address</span>}/>
            <Tab label={<span className="custom-tab-label">Bank Details</span>}/>
          </Tabs>

          {this.state.value === 0 && <GeneralInfoBuyer general={this.state.general} isEdit={this.state.isEdit} passState={this.handleGeneralBuyerState} fieldRequiredData={this.state.fieldRequiredData} handleGeneralRequire={this.handleGeneralRequire}/>}
          {this.state.value === 1 && <DocumentsBuyer docData={this.state.docData} isEdit={this.state.isEdit} passState={this.handleBuyerCompanyState} sizeErr={this.state.sizeErr}/>}
          {this.state.value === 3 && <BankDetailsBuyer bank={this.state.bank} isEdit={this.state.isEdit} passState={this.handleBuyerBankState} bankNameErr={this.state.bankNameErr} accNoErr={this.state.accNoErr} ifscErr={this.state.ifscErr} branchNameErr={this.state.branchNameErr}/>}
          {this.state.value === 2 && <BillingAddress general={this.state.general} isEdit={this.state.isEdit} passState={this.handleGeneralBuyerState} fieldRequiredData={this.state.fieldRequiredData}/>}
        </Box>
        <button className="addNewCompany" onClick={this.handleComOpen}>Add New Company</button>
        {this.renderPopup()}
      </GroupBlock>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter,sans-serif",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End