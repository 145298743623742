import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import {  MouseEvent } from 'react';
import { Message } from "framework/src/Message";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  location:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  ProductData:any;
  value: number | 0;
  totalResults: number;
  resultperPage : number;
  currentPage: number;
  open: boolean;
  selectedProductId:any
  open1: boolean;
  selectedBrands: { [key: string]: boolean };
  selectedOption: "newest" | "oldest";
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
/*istanbul ignore next */
export default class CatalogueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productListApiCallId: string | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   activeComponent: "Company",
   selectedProductId:"",
   anchorEl: null,
   value: 12,
   totalResults: 140, 
   ProductData:[ 
    
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID6545",
          manufacturer: "Havells",
          category: "Glass",
          subCategory: "Laminated",
          selected: true
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID4562",
          manufacturer: "Hitachi",
          category: "Stone",
          subCategory: "Marble",
          selected: true
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID1596",
          manufacturer: "IFB",
          category: "Wood",
          subCategory: "Engineered",
          selected: false
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID2585",
          manufacturer: "Hitachi",
          category: "Bricks",
          subCategory: "Lime Bricks",
          selected: true
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID3547",
          manufacturer: "USA",
          category: "Steel Beams",
          subCategory: "Channel Beams",
          selected: false
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID0641",
          manufacturer: "IFB",
          category: "Silk",
          subCategory: "Chiffon",
          selected: true
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID6545",
          manufacturer: "Havells",
          category: "Pottery",
          subCategory: "Ceramic",
          selected: false
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID4587",
          manufacturer: "IFB",
          category: "Concrete",
          subCategory: "Concrete",
          selected: false
        },
        {
          product: "AAC Blocks 4”",
          size: "625×240×100mm",
          make: "Alpina",
          productId: "PID7458",
          manufacturer: "Hitachi",
          category: "Filled Sand",
          subCategory: "Filled Sand",
          selected: false
        }
      
],
   open1: false,
   resultperPage: 12, 
   currentPage: 1 ,
   open: false,
   selectedBrands: {
     Havells: false,
     "RR Kabel": true,
     LG: true,
     Bosch: false,
     Crompton: false,
     Polycab: false,
     Hitachi: true,
     Philips: false,
     BajajElectricals: false,
     Siemens: false,
   }, 
   selectedOption: "newest",
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount();
// Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
        if (apiRequestCallId === this.productListApiCallId) {
          this.handleGetProductResponse(responseJson);
        } 
      
      }
  }
  
    
  handleGetProductResponse(responseJson: any) {
   
  }
  handlePageChange = (event :any , page:any) => {
    this.setState({ currentPage: page }, () => {
    });
  };
  

 
  navigateToProductCatalogue = (route: string, productId: any) => {
    this.props.history.push(route, { from: this.props.location.pathname });
  
    localStorage.setItem("ProductId", productId);
  };
  

  handleRowClick = (productId:any) => {
    console.log("Clicked Product ID:", productId); 
    this.setState({ selectedProductId: productId });
  };
 

  getResultTextApplication = (page: number): string => {
    const { resultperPage, totalResults} = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }


  handleMenuClick = (event: MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  
  handleMenuClose = () => {
    this.setState({ anchorEl: null });

  };
  
 
  handleCheckboxChange = (brand: string) => {
    this.setState((prevState) => ({
      selectedBrands: {
        ...prevState.selectedBrands,
        [brand]: !prevState.selectedBrands[brand],
      },
    }));
  };

  handleToggle = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };
  
  handleClearAll = () => {
    const clearedBrands = Object.keys(this.state.selectedBrands).reduce(
      (acc, brand) => {
        acc[brand] = false;
        return acc;
      },
      {} as { [key: string]: boolean }
    );
    this.setState({ selectedBrands: clearedBrands });
  };
  handleApply = () => {
    console.log("Selected Brands:", this.state.selectedBrands);
    this.handleToggle();
  };
 
  handleRadioChange = (option: "newest" | "oldest") => {
    this.setState({ selectedOption: option });
  };
 
  handleToggle1 = () => {
    this.setState((prevState) => ({ open1: !prevState.open1 }));
  };


  // Customizable Area End
}
