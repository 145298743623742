import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import React from "react";
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
export interface Field {
  productCriteria: string; 
  chips: string[];  
  value: string | number;       
}

interface Errors {
  upid: string;
  productCriteria: string[];        
}
interface IVariation {
   id: number, 
   length: string, 
   width: string, 
   height: string, 
   version: string 
}

export interface IUpiList{
  id:number,
  name:string
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  showVariations1:boolean;
  notshow:boolean;
  values: IUpiList;
  criteria: string;
  fields: Field[];    
  showVariations:boolean;
  showEdit:boolean;
  showVariations2:boolean;
  yourChips:string[];
  allCriteriaSelected: boolean;
  errors:Errors,
  productListVarition:IVariation[];
  regenBtn:boolean;
  upiListData:IUpiList[];
  openMenuItem:boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductVariationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      showVariations2:false,
      showEdit:false,
      sidebarToggle: false,
      notshow:false,
      criteria: "",
      values: {id:0,name:""} ,
      fields: [{productCriteria:"",chips:[],value:""}],
      showVariations: false,
      showVariations1:false,
      yourChips:[],
      allCriteriaSelected:false,
      errors: { upid: "", productCriteria: [] },
      productListVarition:[
        { id: 1, length: "10mm", width: "20mm", height: "10mm", version: "UPID 1, Version 1" },
        { id: 2, length: "10mm", width: "30mm", height: "10mm", version: "UPID 1, Version 2" },
        { id: 3, length: "20mm", width: "20mm", height: "10mm", version: "UPID 1, Version 3" },
        { id: 4, length: "20mm", width: "30mm", height: "10mm", version: "UPID 1, Version 4" },
      ],
      regenBtn:false,
      upiListData:[{id:1,name:"UPI1"},{id:2,name:"UPI2"},{id:3,name:"UPI3"}],
      openMenuItem:false
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);   
     // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
  
  }

  handleNavigate = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  addField = () => {
    if (this.state.fields.length < 3) {
      this.setState((prevState) => ({
        fields: [
          ...prevState.fields,
          { productCriteria: "", value: "", chips: [] },
        ],
      }));
    }
  };

  removeField = (index: number) => {
    const newFields = this.state.fields.filter((_: Field, i: number) => i !== index);
    this.setState({ fields: newFields });
    if(!this.state.regenBtn)
      this.handleReGenBtn();
  };

  handleFieldChange = (index: number, value: string | number) => {
    const newFields = [...this.state.fields];
    newFields[index].value = value;
    this.setState({ fields: newFields });
    if(!this.state.regenBtn)
    this.handleReGenBtn();
  };

  handleCriteriaChange = (index: number, event: string) => {
    const newFields = [...this.state.fields];
    newFields[index].productCriteria = event;
    const errors: Errors = { ...this.state.errors };
    if (!newFields[index].productCriteria) {
      errors.productCriteria[index] = "Product Criteria is required";
    } else {
      errors.productCriteria[index] = "";
    }
  
    this.setState({ fields: newFields, errors });
    if(!this.state.regenBtn)
    this.handleReGenBtn();
  };

  handleGenerateVariations = () => {
    const errors: Errors = {
      upid: "",
      productCriteria: [],
    }; 
    let valid = true;
    if (this.state.values.id===0) {
      errors.upid = "UPID Name is required";
      valid = false;
    }
    this.state.fields.forEach((field, index) => {
      if (!field.productCriteria) {
        errors.productCriteria[index] = "Product Criteria is required";
        valid = false;
      } else {
        errors.productCriteria[index] = ""; 
      }
    });
    if (valid) {
      this.setState({ showVariations: true, showVariations1: true, notshow: true });
       setStorageData("Variation Data", JSON.stringify(this.state.fields));
       setStorageData("upiName",JSON.stringify(this.state.values));
    } else {
      this.setState({ errors });
    }
  };
  
  handleSave = () => {
    this.setState({ showEdit: false, notshow: true ,regenBtn:false});
    this.handleNavigate("ListProductVariation");
  };

  handleAddChip = (index: number, chip: string) => {
    const newFields = [...this.state.fields];
    newFields[index].chips = [...newFields[index].chips, chip];
    this.setState({ fields: newFields });
    if(!this.state.regenBtn)
    this.handleReGenBtn();
  };

  handleDeleteChip = (index: number, chipIndex: number) => {
    const newFields = [...this.state.fields];
    newFields[index].chips = newFields[index].chips.filter((_, i) => i !== chipIndex);
    this.setState({ fields: newFields });
    if(!this.state.regenBtn)
    this.handleReGenBtn();
  };

  handleUPIChanges = (value: IUpiList) => {
    const errors: Errors = { ...this.state.errors };
    if (value.name ==="") {
      errors.upid = "UPID Name is required";
    } else {
      errors.upid = "";
    }
    this.setState({ values: value, errors });
    if(!this.state.regenBtn)
    this.handleReGenBtn();
  };

  handleReGenBtn = ()=>{
    if(this.state.showVariations1)
    this.setState({regenBtn:!this.state.regenBtn})
  }

  handleCancelChanges = async ()=>{
    this.setState({fields:JSON.parse(await getStorageData("Variation Data")),regenBtn:false,values:JSON.parse(await getStorageData("upiName"))})
  }

  handleRegenerate = ()=>{
    this.setState({regenBtn:false})
  }

  toggleOpen = () => {
    this.setState({openMenuItem:!this.state.openMenuItem});
  };
  // Customizable Area End
}
