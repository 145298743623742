import React from 'react';
import { Box, List, ListItem, ListItemIcon, Drawer, Collapse, Typography, IconButton } from '@material-ui/core';
import DashboardController from '../../blocks/dashboard/src/DashboardController.web';
import { styled } from '@material-ui/styles';
import DashboardNavbar from './DashboardNav.web';
import { getStorageData } from "../../framework/src/Utilities";
import { setStorageData } from "../../framework/src/Utilities";
export interface Props {
    navigation: any;
    location: string;
    activeMenu: string,
    activeTab: string;
    children: React.ReactNode;
    handleSearchFun?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    mycart?: boolean;
}

const StyledBox=styled(Box)({
    width:'calc(100% - 350px)',
    "@media(max-width:900px)":{
    width:"calc(100% - 35px)"
    }
})
const userListItems = [
    {
        listIcon: <img  src={require("./quatation.svg")} alt="quatation" />, listText: 'Quotation',
        items: [
            {
                title: "Cart",
                to: "MyCart",
            },
            {
                title: "Quotations",
                to: "Quotations",
            },


        ]
    },
    {
        listIcon: <img  src={require("./purchase.svg")} alt="purchase" />, listText: 'Purchase Orders',to:"Dashboard",
    },
    {
        listIcon: <img  src={require("./billing.svg")} alt="billing" />, listText: 'Billing',
        items: [
            {
                title: "Tax Invoices",
                to: "InvoiceBilling",
            },
            {
                title: "Debit Notes",
                to: "DebitNotes",
            },
            {
                title: "Credit Notes",
                to: "CreditNotes",
            },

        ]
    },
    {
        listIcon: <img  src={require("./delivery.svg")} alt="delivery" />, listText: 'Delivery',
        items: [
            {
                title: "Delivery Challan",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            }, {
                title: "Deivery Challan",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            },

        ]
    },
    {
        listIcon: <img  src={require("./rupay.svg")} alt="rupay" />, listText: 'Payments',
        items: [
            {
                title: "Payment Settlements",
                to: "PaymentSettlements",
            },
            {
                title: "Payment Timeline",
                to: "PaymentTimeline",
            },
            {
                title: "Ledger",
                to: "Ledger",
            },

        ]
    },
    {
        listIcon: <img  src={require("./master.svg")} alt="master" />, listText: 'Master',
        items: [
            {
                title: "Roles",
                to: "",
            },
            {
                title: "Users",
                to: "",
            },

        ]
    },
    {
        listIcon: <img  src={require("./user.svg")} alt="user" />, listText: 'Users', to:"",
        items: [
            {
                title: "Rate History",
                to: "",
            },
            {
                title: " Project Master",
                to: "",
            },
        ]
    },
    {
        listIcon: <img  src={require("./group.svg")} alt="group" />, listText: 'Group Name',
        items: [
            {
                title: "Company",
                to: "UserCategories",
            },
            {
                title: "Project",
                to: "ProjectData",
            },
        ]
    },
];
const adminListItems = [
    {
        listIcon: <img  src={require("./quatation.svg")} alt="quatation" />, listText: 'Quotation',
        items: [
            {
                title: "Cart",
                to: "",
            },
            {
                title: "Quotations",
                to: "",
            },
    ]
    },
    {
        listIcon: <img  src={require("./purchase.svg")} alt="sales" />, listText: 'Sales',
        items: [
            {
                title: "Tax Invoices",
                to: "",
            },
            {
                title: "Debit Notes",
                to: "",
            },
            {
                title: "Credit Notes",
                to: "",
            },
            {
                title: "Purchase Orders",
                to: "Dashboard",
            },
        ]
    },
    {
        listIcon: <img  src={require("./billing.svg")} alt="billing" />, listText: 'Billing',
        items: [
            {
                title: "Tax Invoices",
                to: "",
            },
            {
                title: "Debit Notes",
                to: "",
            },
            {
                title: "Credit Notes",
                to: "",
            },

        ]
    },
    {
        listIcon: <img  src={require("./delivery.svg")} alt="delivery" />, listText: 'Delivery',
        items: [
            {
                title: "Delivery Challan",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            }, {
                title: "Deivery Challan",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            },

        ]
    },
    {
        listIcon: <img  src={require("./rupay.svg")} alt="rupay" />, listText: 'Payments',
        items: [
            {
                title: "Payment Settlements",
                to: "PaymentSettlements",
            },
            {
                title: "Payment Timeline",
                to: "PaymentTimeline",
            },
            {
                title: "Ledger",
                to: "Ledger",
            },
        ]
    },
    {
        listIcon: <img  src={require("./pim.svg")} alt="pim" />, listText: 'Catalogue',
        items: [
            {
                title: "My Products",
                to: "MyProducts",
            },
            {
                title: "Catalogue",
                to: "Catalogue",
            },
           
        ]
    },
    {
        listIcon: <img  src={require("./pim.svg")} alt="pim" />, listText: 'PIM',
        items: [
            {
                title: "Products",
                to: "ProductList",
            },
            {
                title: "Categorization",
                to: "Categories",
            },
            {
                title: "Brands",
                to: "Brands",
            },
            {
                title: "Brand Catalogues",
                to: "catalouge",
            },
            {
                title: "Applications",
                to: "ApplicationIndustry",
            },
            {
                title: "Variations",
                to: "ListProductVariation",
            },
            {
                title: "Logistical Codes",
                to: "LogisticalList",
            },

        ]
    },
    {
        listIcon: <img  src={require("./user.svg")} alt="customers" />, listText: 'Customers',
        items: [
            {
                title: "Rate History",
                to: "categories",
            },
            {
                title: " Project Master",
                to: "",
            },
        ]
    },
    {
        listIcon: <img  src={require("./suppliers.svg")} alt="suppliers" />, listText: 'Suppliers',
        items: [
            {
                title: "My Cart",
                to: "MyCart",
            },
            {
                title: "My Quotation",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            },
            {
                title: "Deivery Challan",
                to: "",
            },
            {
                title: "Deivery Challan",
                to: "",
            },
            {
                title: "Pro Forma Invoice",
                to: "",
            },
            {
                title: "Deivery Challan",
                to: "",
            },
            {
                title: "Deivery Challan",
                to: "",
            },
        ]
    },
    {
        listIcon: <img  src={require("./group.svg")} alt="profile" />, listText: 'My Profile',to: "/" ,
        items: [
            {
                title: "Company",
                to: "",
            },
            {
                title: "Project",
                to: "",
            },
        ]
    },
];

class SideBar extends DashboardController {
    constructor(props: Props) {
        super(props)

    }
    async componentDidMount() {
        const activePath = await getStorageData('activePath');
        const newRole = await getStorageData('userType');
        this.setState({role:newRole})
        if (activePath) {
            this.setState({
                open: {
                    ...this.state.open,
                    [`open${activePath}`]: true
                }
            });
        }
    }
   
    sideList = () => {
        const activeListItemStyle = {
            fontWeight: 700,
        };

        const isActive = (index: number, path?: string, listItems?: { title: string, to: string }[]) => {
            if (listItems && listItems.length > 0) {
                return listItems.map(element => element.to).includes(location.pathname.slice(1))
            } else {
                return location.pathname === `/${path}` && path !== undefined;
            }
        }

        const handleClick = async (index: number, isTopLevel: boolean, path: string) => {
            if (isTopLevel) {
                this.setState((prevState) => ({
                    open: {
                        ...prevState.open,
                        [`open${index}`]: !prevState.open[`open${index}`],
                    },
                }));
                await setStorageData('activePath', String(index));
                if(path){
                    this.getNavigationMessage(path);
                }
            }
        };

        return (
                <List style={{ width:"250px",cursor: 'grab',fontFamily:"Inter, sans-serif", padding: this.state.isSidebarCollapsed ?'25px':"" ,paddingRight: this.state.isSidebarCollapsed ?'20px':""}}>
                    {
                        this.state.role === "buyer" ?
                            (userListItems.map((listItem, index: number) => (
                                <div key={index}>
                                    <ListItem button data-testId="sidebar-menu-item" style={{
                                        padding: "14px 0px 12px 0px", marginTop: index === 0 ? "0px" : "10px", display: 'flex', justifyContent: 'center',
                                        alignItems: 'center',
                                    }} onClick={() => handleClick(index, true, listItem.to || '')}
                                    >
                                        <ListItemIcon style={{ justifyContent: this.state.isSidebarCollapsed ? 'center' : '', color: "#272727", width: "20px", height: "20px", minWidth: '0px', }} >
                                            {listItem.listIcon}
                                        </ListItemIcon>
                                        {!this.state.isSidebarCollapsed &&
                                            <Box component={'div'} style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                width: '100%',
                                                marginLeft: "16px",
                                            }}>
                                                <Box component={'div'}><Typography style={{
                                                    ...(isActive(index, listItem.to || '', listItem.items) ? activeListItemStyle : {}), fontSize: "14px", fontFamily: 'Inter, sans-serif', color: '#323842', fontWeight: 700,
                                                }}>{listItem.listText}</Typography></Box>
                                                <Box component={'div'} style={{ display: "flex", alignItems: "center" }}>
                                                    {listItem.items && (this.state.open[`open${index}`] ? <img src={require("./arrow.svg")} alt="arrow" /> : <img src={require("./downarrow.svg")} alt="downarrow" />)}</Box>
                                            </Box>
                                        }
                                    </ListItem>
                                    {!this.state.isSidebarCollapsed && (
                                        <>
                                    {listItem.items && (
                                        <Collapse in={!isActive(index, listItem.to || '') && this.state.open[`open${index}`]} timeout={{ enter: 200, exit: 200 }}   unmountOnExit>
                                            <List component="div" disablePadding >
                                                {listItem.items.map((item, idxs) => (
                                                    <ListItem
                                                        button
                                                        key={idxs}
                                                        onClick={(event) => {
                                                            this.getNavigationMessage(item.to);
                                                        }}
                                                       
                                                    >
                                                        <Typography style={{
                                                            marginLeft: "30px",
                                                            fontWeight: isActive(index, item.to || '') ? 500:400,
                                                            color:isActive(index, item.to || '') ? "#323842":'#9095A0',
                                                             fontSize:"14px", fontFamily:'Inter, sans-serif',
                                                        }}>{item.title}</Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                      </>  ) }
                                </div>
                            ))) :

                            (adminListItems.map((listItem, index: number) => (
                                <div key={index}>
                                    <ListItem button data-testId="sidebar-menu-item" style={{
                                        padding: "14px 0px 12px 0px", marginTop: index === 0 ? "0px" : "10px", display: 'flex', justifyContent: 'center',
                                        alignItems: 'center',
                                    }} onClick={() => handleClick(index, true, listItem.to || '')}
                                    >
                                        <ListItemIcon style={{ justifyContent: this.state.isSidebarCollapsed ? 'center' : '', color: "#272727", width: "20px", height: "20px", minWidth: '0px', }} >
                                            {listItem.listIcon}
                                        </ListItemIcon>
                                        {!this.state.isSidebarCollapsed &&
                                            <Box component={'div'} style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                cursor: 'grab',
                                                width: '100%',
                                                marginLeft: "16px",
                                            }}>
                                                <Box component={'div'}><Typography style={{
                                                    ...(isActive(index, listItem.to || '', listItem.items) ? activeListItemStyle : {}), fontSize: "14px", fontFamily: 'Inter, sans-serif', color: '#323842', fontWeight: 700,
                                                }}>{listItem.listText}</Typography></Box>
                                                <Box component={'div'} style={{ display: "flex", alignItems: "center" }}>
                                                    {listItem.items && (this.state.open[`open${index}`] ? <img src={require("./arrow.svg")} alt="arrow" /> : <img src={require("./downarrow.svg")} alt="downarrow" />)}</Box>
                                            </Box>
                                        }
                                    </ListItem>
                                    {!this.state.isSidebarCollapsed && (
                                        <>
                                    {listItem.items && (
                                        <Collapse in={!isActive(index, listItem.to || '') && this.state.open[`open${index}`]} timeout={{ enter: 200, exit: 200 }}   unmountOnExit>
                                            <List component="div" disablePadding >
                                                {listItem.items.map((item, idxs) => (
                                                    <ListItem
                                                        button
                                                        key={idxs}
                                                        onClick={(event) => {
                                                            this.getNavigationMessage(item.to);
                                                        }}
                                                       
                                                    >
                                                        <Typography style={{
                                                            marginLeft: "30px",
                                                            cursor: 'grab',
                                                            fontWeight: isActive(index, item.to || '') ? 500:400,
                                                            color:isActive(index, item.to || '') ? "#323842":'#9095A0',
                                                             fontSize:"14px", fontFamily:'Inter, sans-serif',
                                                        }}>{item.title}</Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                      </>  ) }
                                </div>
                            )))
                    }
                </List>
        );
    };
    handleToggle=( )=>{
        this.setState({ isSidebarCollapsed:!this.state.isSidebarCollapsed });
    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        // const handleSearch = this.props;
        // const handleChange = this.props.handleSearchFun


        return (
            //Merge Engine DefaultContainer
            <>
                {!this.props.mycart && <DashboardNavbar toggleSlider={this.toggleSlider} handleSearchFun={this.props.handleSearchFun} />}
                <Box component="nav" style={{ backgroundColor: "#fff" , display:"flex", gap : '10px'}}>
                            <MasterFinances >
                                <Drawer open={this.state.toggle} anchor="left" onClose={this.toggleSlider} >
                                    {this.sideList()}
                                </Drawer>
                            </MasterFinances>
                    <SidebarBLock style={{position: 'relative', transition: 'width 0.3s ease-in-out', width: this.state.isSidebarCollapsed ? '100px' : '250px', padding: !this.state.isSidebarCollapsed ? "30px 29px 16px 40px" : '30px 0px 0px 0px', cursor: "pointer",display:"flex",justifyContent: 'center'
                    }}>
                        {this.sideList()}
                        <IconButton
                            style={{ ...webStyle.iconbtn, position: 'absolute', transition: 'all 0.3s ease-in-out', }}
                            onClick={this.handleToggle}
                        >
                            {this.state.isSidebarCollapsed ? <img src={require("./nexticon.svg")} alt="nexticon" /> : <img src={require("./goback.svg")} alt="goback" />}
                        </IconButton>
                    </SidebarBLock>

                            <StyledBox style={{ background: "#F2F2F2 !important" }} >
                                {this.props.children}
                            </StyledBox>
                </Box>
            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const MasterFinances = styled(Box)({
    display: "none",
    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
        display: "block !important",
    },
});
const SidebarBLock = styled(Box)({
    display: "block",
    height: "100%",
    cursor: 'grab',
    marginRight: "5px",
    borderRight: "1px solid #E2E8F0",

    "@media (max-width: 600px)": {
        display: "none !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 900px) ": {
        display: "none !important",
    },
});
const webStyle={
    iconbtn:{
        right: '-16px',
        top: '50%',
        transform: 'translateY(-50%)',
        width:'24px',
        height:"24px",
        borderRadius: '4px',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        border:"1px solid #E2E8F0",
        backgroundColor: '#fff',
        transition: 'all 0.3s ease',
        cursor: 'grab'
    }
}
export default SideBar;
// Customizable Area End
