Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getSubCategoriesEndPoint="bx_block_categories/get_sub_categories?parent_category_id=";
exports.getProductsByCatEndPoint="/bx_block_productdescription/get_product_by_filter?category_id=";
exports.getCategoriesEndpoint="/bx_block_categories/get_categories";
// Customizable Area End