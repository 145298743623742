import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Checkbox, Grid, Drawer, TextField, Menu, MenuItem, ClickAwayListener } from '@material-ui/core';
import Hidden from "@material-ui/core/Hidden";
import { styled } from "@material-ui/styles";
import { Message } from "../../../packages/framework/src/Message";
import { BlockComponent } from "../../../packages/framework/src/BlockComponent";
import { runEngine } from "../../../packages/framework/src/RunEngine";
import { ExpandMore, ChevronLeft, ChevronRight } from '@material-ui/icons';
import MessageEnum, {
  getName
} from "../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import Select, { components } from 'react-select';

import { ComponentProps } from 'react';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIco from '@material-ui/icons/CloseOutlined'
import ArrowDown from "@material-ui/icons/KeyboardArrowDownOutlined";
import ArrowUp from "@material-ui/icons/KeyboardArrowUpOutlined";
import { getStorageData } from 'framework/src/Utilities';
import { Autocomplete } from '@mui/material';

import { Cart, homeIcon, KratexAiAssitant, locationIconBordered, notificationIcon, QuickQuote, UploadRFQ } from '../../../packages/blocks/navigationmenu/src/assets';
interface Category {
  id: string;
  type: string;
  attributes: CategoryAttribute;
}

interface CategoryAttribute {
  id: number;
  name: string;
  level: number;
  sub_categories: SubCategory[];
}

interface SubCategory {
  data: Category;
}


type S = {
  Companies: CompanyInformation[],
  companyName: Company[];
  projectAnchorEl: any;
  open: boolean;
  inputValue: string;
  SelectedCompanyId: any;
  projectData: any;
  projectName: any;
  isProjectSelected: any;
  subCate2AllCate: Category[];
  showDropDownCountry: boolean, subCat2Burger: any[], excludedRoutes: string[], openedItems: any, showBurgerMenu: boolean, showDropDownLanguage: boolean, scrollPosition: number, showDropDownCategori: boolean, selectedCategory: string, isSubMenuOpen: boolean, token: string, catData: any[], catAllData: CategoryData[], subCat: string[], selectedItem: SelectedItem, dropAccount: boolean, catSug: any[], selectOption: string, selectCat: Product[], showMegaMenu: boolean, selCatId1: string, selCatId2: string, subCat2: any, subCat3: any, platform: string
};
interface Props {
  handleNavigate?: (route?: string) => void;
  history: any;
  mycart?: boolean;
  onSelectionChange?: (companyId: string, projectId: string) => void;

}

const DrawerStyled = styled(Drawer)({
  "& .burgerItems": {
    width: "80vw",
    height: "100vh",
    background: "#FFF",
    paddingLeft: "20px",
    paddingTop: "20px",
    display: "flex",
    flexDirection: 'column',
    overflowY: "scroll",
    marginBottom: "20px !important"
  },
  "& .subNavBtn": {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
    "@media (max-width:900px)": {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0px",
      flexDirection: "row",
      fontSize: "24px",
      marginRight: "20px",
    },
  },
  "& .closeBurger": {
    marginLeft: "auto",
    marginRight: "20px"
  },
  "& .burgerCategory": {
    fontSize: "18px",
    fontWeight: 800,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    "& svg": {
      fontSize: "1.5rem"
    }
  },
  "& .burgerCategory1": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    cursor: "pointer"
  },

  "& .burgerCategory1Typo": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    cursor: "pointer"
  },
  "& .burgerCategory2": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "0px",
    cursor: "pointer"
  },
  "& .burgerCategory2Typo": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "0px",
    cursor: "pointer"
  },
  "& .burgerCategory3": {
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "20px",
    cursor: "pointer"
  },
  "& .burgerCategory3Typo": {
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    cursor: "pointer"
  },
})

const NavigationBlock = styled("div")(({ theme }) => ({
  paddingTop: "5px",
  paddingBottom: "5px",
  "& .firstDivOfHeader": {
    "@media(max-width:900px)": {
      marginLeft: "15px"
    }
  },
  "& .selectCompany": {
    "@media(max-width:900px)": {
      width: "70% !important",
    }
  },
  "& .searchContent": {
    "@media(max-width:900px)": {
      marginLeft: "15px !important",
      marginTop: "10px !important",
      padding: "0px 20px !important"
    }
  },
  '& .logo': {
    width: "81px",
    height: "48px",
    margin: "auto 0",
    cursor: "pointer",
    '@media (max-width: 600px)': {
      width: "50px",
      height: "30px",
    }
  },
  '& .dropdown-list': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "30px",
    cursor: "pointer",
    "@media (max-width:900px)": {
      marginLeft: "0px",
    },
  },
  '& .location-icon': {
    width: "20px",
    height: "22px"
  },
  '& .down-icon': {
    cursor: "pointer",
    margin: "0px",
    "@media (max-width:900px)": {
      marginRight: "20px",
      fontSize: "30px"
    },
  },
  "& .dropdown-list-data": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "45px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    left: "156px",
    width: "162px",
    '@media (max-width: 600px)': {
      left: "70px !important",
    },
    '@media (max-width: 900px)': {
      left: "120px",
    }
  },
  "& .dropdown-list-data li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "400",
    color: "#FAFAFA",
    padding: " 10px 0",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .dropdwon-box": {
    position: "relative",
    "& .dropdown-wrapper": {
      position: "absolute"
    }
  },
  "& .dropdown-item": {
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item .selected": {
    backgroundColor: "#FFE0CC",
    color: "black",
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px"
  },
  "& .dropdown-item-login .selected": {
    backgroundColor: "#FFE0CC",
    color: "black",
    display: "flex",
    alignItems: "center"
  },
  "& .dropdown-item-login:hover": {
    backgroundColor: "#FFE0CC",
    borderRadius: "8px"
  },
  "& .search-icon": {
    width: "24px",
    height: "24px",
  },
  "& .search-content": {
    color: "#94A3B8", fontFamily: 'Inter, sans-serif',
    '@media (max-width: 900px)': {
      fontSize: "10px"
    },

  },
  '& .dropdown-list-language': {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "36px 7px",
    gap: "10px",
    "@media (max-width:900px)": {
      margin: "36px 2px",
    },
  },
  "& .dropdown-list-language-logout": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    margin: "36px 31px",
    gap: "10px",
    "@media (max-width:900px)": {
      margin: "36px 10px"
    },
  },
  "& .dropdown-list-data-language": {
    top: "52px",
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "162px",
    '@media (max-width: 600px)': {
      width: "100px",
    },
  },
  "& .dropdown-list-data-language li": {
    padding: "0px 20px",
    margin: "15px 0px",
  },
  "& .dropdown-list-data-language li:last-of-type a": {
    border: "none",
  },
  "& .flag-icon1": {
    width: "24px",
    "@media (max-width:900px)": {
      width: "18px"
    },
  },
  "& .flag-icon": {
    width: "24px",
  },
  '& .account-list': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginRight: "10px",
  },
  "& .account-list-logout": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    margin: "21px 61px",
    gap: "10px",
  },
  "& .user-icon": {
    width: "44px",
    height: "44px"
  },
  "& .loginBox": {
    display: "block",
    '@media (max-width: 688px)': {
      display: "none"
    },
  },
  "& .order-data": {
    margin: "35px 34px"
  },
  "& .order-data-login": {
    margin: "35px 10px",
    '@media (max-width: 700px)': {
      display: "none"
    },
  },
  "& .dropdown-list-cat": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    top: "90px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    left: "55px",
    width: "228px",
  },
  "& .dropdown-list-cat li": {
    padding: "6px 20px",
  },
  "& .dropdown-list-cat li:last-of-type a": {
    border: "none",
  },
  "& .dropdown-list-cat a": {
    margin: "4px",
    display: "inline-block",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: "400",
    color: "#0F172A",
    transition: "all 0.3s ease-out",
    zIndex: 100,
  },
  "& .multi-level-dropdown": {
    fontFamily: "Red Hat Display",
    position: "relative",
  },
  "& .main-menu": {
    listStyle: "none",
    padding: "0",
  },
  "& .menu-item": {
    display: "flex",
    position: "relative",
    marginBottom: "5px",
    width: "80px",
    border: "none",
    zIndex: 100,
  },
  "& .menu-item-label": {
    cursor: "pointer",
    padding: "10px",
  },
  "& .menu-item-label-sub": {
    cursor: "pointer",
    marginLeft: "10px",
    marginRight: "50px",
    marginTop: "10px",
    marginBottom: "7px",
    width: "100%",
  },
  "& .menu-item-label-sub-data": {
    cursor: "pointer",
    fontSize: "12px",
    marginLeft: "10px",
    marginRight: "30px",
    marginTop: "15px",
    width: "100%",
  },
  '& .menu-item:hover .sub-menu': {
    display: 'block',
  },
  '& .sub-menu': {
    position: 'relative',
    top: '36px',
    left: "402%",
    display: 'none',
    listStyle: 'none',
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
  },
  '& .sub-menu li': {
    padding: '6px 20px',
  },
  "& .upload-btn": {
    background: "#FF6600",
    minWidth: "142px",
    height: "44px",
    padding: "5px 0",
    margin: "22px 10px",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "none",
    fontFamily: 'Inter, sans-serif',
    '@media (max-width: 960px)': {
      fontSize: "10px",
      minWidth: "110px !important",
    },
    '@media (max-width: 600px)': {
      minWidth: "30px !important",
      height: "33px",
      margin: "22px 5px",
    }
  },
  "& .user-data": {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    gap: "10px",
    '@media (max-width: 900px)': {
      marginTop: "10px"
    },
    '@media (max-width: 600px)': {
      marginRight: "5px",
    }
  },
  "& .dropdown-list-account": {
    fontFamily: 'Inter, sans-serif',
    position: "absolute",
    maxHeight: "400px",
    top: "45px",
    right: "16px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "162px",
    '@media (max-width: 460px)': {
      right: "0px",
      left: "20px"
    },
  },
  "& .dropdown-list-account li": {
    padding: "3px 20px",
    margin: "15px 0px",
    gap: "16px"
  },
  "& .dropdown-list-account li:last-of-type a": {
    border: "none",
  },
  "& .cart-main": {
    fontFamily: "Red Hat Display",
    position: "absolute",
    maxHeight: "400px",
    top: "69px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    listStyle: "none",
    transition: "all 0.3s ease-out",
    fontSize: "16px",
    zIndex: 100,
    padding: "5px",
    cursor: "pointer",
    width: "497px",
    height: "361px",
    right: "10px",
    "@media (max-width:900px)": {
      width: "90vw",
      top: "90px",
      right: "10px",
    },
  },
  "& .close-btn": {
    display: "flex",
    flexDirection: "row-reverse",
    marginTop: "31px",
    marginRight: "34px",
  },
  "& .view-btn": {
    border: "1px solid #FF6600",
    width: "405px",
    height: "50px",
    borderRadius: "10px",
    marginLeft: "10px",
    marginTop: "10px"
  },
  "& .input-step": {
    display: "inline-flex",
    width: "90px",
    overflow: "visible",
    padding: "4px",
    border: "1px solid #94A3B8",
    height: "30px",
    borderRadius: "8px",
    "@media (max-width:900px)": {
      marginLeft: "-25px"
    },
  },
  "& .input-step input": {
    background: "#0000",
    border: "0",
    color: "black",
    height: "100%",
    textAlign: "center",
    width: "4em"
  },
  "& .search-input": {
    margin: "0 10px",
    display: "flex",
    flexDirection: "row",
    border: "1px solid #CBD5E1",
    padding: "0px 12px",
    width: "100%",
    height: "40px",
    justifyContent: "space-between",
    borderRadius: "10px",
    alignItems: "center",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      }
    }
  },
  "& .selectBtn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "9px",
    color: "#171A1F",
    backgroundColor: "#F3F4F6",
    width: "200px",
    marginLeft: "16px",
    cursor: "pointer",
    height: "40px",
    "@media (max-width:900px)": {
      width: "120px",
      marginLeft: "5px",
    },
  },
  "& .selectBtn-text": {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "12px",
    whiteSpace: "nowrap",
    marginRight: "5px",
    "@media (max-width:900px)": {
      fontSize: "8px",
    },
  },
  "& .selectCategoryBtn": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    gap: "12px",
    marginRight: "10px",
    "@media (max-width:900px)": {
      gap: "0px",
      marginRight: "0px",
    },
  },
  "& .selectCategoryBtn-text": {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: "12px",
    color: "#171A1F"
  },
  "& .userTool": {
    height: "24px",
    cursor: "pointer"
  },
  "& .userTools": {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    maxWidth: "320px",
    alignItems: "center",
    "@media (max-width:900px)": {
      marginTop: "10px",
    },
    "@media (max-width:480px)": {
      maxWidth: "100%",
    },
  },
  "& .userInitials": {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "13px",
    color: "#EE6900",
    backgroundColor: "#FFF6F0",
    padding: "10px 10px",
    borderRadius: "9px",
    marginLeft: "15px",
    "@media (max-width:900px)": {
      marginLeft: "5px",
    },
  },
  "& .userName": {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    fontSize: "13px",
    color: "#171A1F",
    whiteSpace: "nowrap"
  },
  "& .burgerShadow": {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: "0px",
    zIndex: 12,
    opacity: 1,
  },
  "& .burgerItems": {
    width: "80vw",
    height: "100vh",
    background: "#FFF",
    paddingLeft: "20px",
    paddingTop: "20px",
    display: "flex",
    flexDirection: 'column',
    overflowY: "scroll",
    marginBottom: "20px !important"
  },
  "& .subNavBtn": {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
    "@media (max-width:900px)": {
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0px",
      flexDirection: "row",
      fontSize: "24px",
      marginRight: "20px",
    },
  },
  "& .closeBurger": {
    marginLeft: "auto",
    marginRight: "20px"
  },
  "& .burgerCategory": {
    fontSize: "18px",
    fontWeight: 800,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer"
  },
  "& .burgerCategory1": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    cursor: "pointer"
  },

  "& .burgerCategory1Typo": {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px",
    cursor: "pointer"
  },
  "& .burgerCategory2": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "0px",
    cursor: "pointer"
  },
  "& .burgerCategory2Typo": {
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "0px",
    cursor: "pointer"
  },
  "& .burgerCategory3": {
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    paddingLeft: "20px",
    cursor: "pointer"
  },
  "& .burgerCategory3Typo": {
    fontSize: "10px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    cursor: "pointer"
  },
  '& .css-g1d714-ValueContainer': {
    justifyContent: 'center'
  },
}
))

const SubNavigationBlock = styled("div")(({ theme }) => ({
  padding: "0px",
  "& .right-left-icon": {
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "bold",
    '@media (max-width: 600px)': {
      fontSize: "20px"
    }
  },
  "& .down-icon": {
    height: 12,
    width: 12,
    marginLeft: 8,
    '@media (max-width: 600px)': {
      fontSize: "15px"
    }
  },
  "& .main-block": {
    backgroundColor: "#ECEEF1",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    "@media (max-width:900px)": {
      flexWrap: 'wrap',
      padding: '5px 0px',
      height: 'auto',
      width: '100vw',
    },
  },
  "& .category-content": {
    margin: "4px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "110px",
    padding: "5px 18px",
    cursor: "pointer",
    marginRight: 0,
    paddingRight: 0,
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
      margin: "17px 0 17px 10px",
    }
  },
  "& .category-content-text": {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B"
  },
  "& .horizontal-scroll-menu": {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    width: " 67%",
    "@media (max-width:900px)": {
      width: "60%",
    },
  },
  "& .scroll-container": {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    whiteSpace: "nowrap",
    flexGrow: 1,
    cursor: "pointer",
    fontFamily: 'Inter, sans-serif',
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
    }
  },
  "& .menu-item": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: 500,
    padding: "10px",
    paddingLeft: 0,
  },
  "& .menu-item-scr": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: 500,
    padding: "10px",
    paddingLeft: 0,
    color: "#565E6C",
    fontFamily: 'Inter, sans-serif'
  },
  "& .menu-item-scr-active": {
    display: "inline-block",
    marginRight: "10px",
    fontSize: "12px",
    fontWeight: 700,
    padding: "10px",
    paddingLeft: 0,
    color: "#1E293B",
    fontFamily: 'Inter, sans-serif',
  },
  "& .scroll-buttons": {
    margin: "10px",
    "@media (max-width:900px)": {
      margin: "5px",
    },
  },
  "& .subNavBtn": {
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
    fontFamily: 'Inter, sans-serif',
    "@media (max-width:900px)": {
      display: "flex",
      margin: "0px 0px",
      flexDirection: "row",
      width: "auto !important"
    },
  },
  "& .dropdown-megamenu": {
    position: "absolute",
    top: "105px",
    left: "10vw",
    width: "80vw",
    minHeight: "50px",
    height: "auto",
    backgroundColor: "#FFF",
    padding: "30px",
    borderRadius: "0px, 0px, 8px, 8px",
    border: "1px solid #E2E8F0",
    boxShadow: " -4px 0px 4px rgba(0, 0, 0, 0.08), 4px 4px 8px rgba(0, 0, 0, 0.08)",
    zIndex: 200,
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:900px)": {
      top: "0px",
      left: "0vw",
      position: "fixed",
      width: "80vw",
      height: "100vh",
      overflowY: "scroll"
    },
  },
  "& .mobileShadow": {
    "@media (max-width:900px)": {
      width: "100vw",
      height: "200vh",
      position: "fixed",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 10
    },

  },
  "& .megamenu-main-cat": {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    color: "#1E293B",
    cursor: "pointer",
    marginBottom: "8px"
  },
  "& .megamenu-sub-cat": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#0F172A",
    cursor: "pointer",
    margin: "8px 0px"
  },
  "& .megamenu-cat": {
    marginBottom: "50px"
  },
  "& .bulkOrder": {
    fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize: "12px", color: "#1E293B",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "@media (max-width:900px)": {
      fontSize: "20px",
      whiteSpace: "wrap",
    },
  }
}
))

const NavigationBlockWOLogin = styled("div")(({ theme }) => ({
  fontFamily: 'Inter',
  "& .main-content": {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden'
  },
  "& .logo": {
    cursor: "pointer",
    width: "80px",
    height: "45px",
    "@media (max-width:900px)": {
      width: "60px",
      height: "35px",
    },
  },
  "& .navText": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: "#323842",
    marginLeft: "90px",
    textAlign: "center",
    "@media (max-width:900px)": {
      marginLeft: "0px",
      fontSize: "14px",
    }
  },
  "& .navTextTypo": {
    fontFamily: 'Inter, sans-serif',
    fontSize: "14px",
  },
  "& .navText__text": {
    cursor: "pointer",
    "@media (max-width:900px)": {
      padding: "8px"
    },
  },
  "& .navText__text-active": {
    cursor: "pointer",
    fontWeight: 600,
    "@media (max-width:900px)": {
      padding: "8px"
    },
  },
  "& .navMenu": {
    marginTop: "17px",
  },
  "& .navTextAndLogo": {
    marginLeft: "40px",
    "@media (max-width:900px)": {
      marginLeft: "10px",
    },
  },
  "& .loginBtn": {
    color: "#FFFFFF",
    width: "85px",
    height: "32px",
    backgroundColor: "#FF6600",
    borderRadius: "8px",
    marginRight: "40px",
    fontSize: "14px",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    padding: "6px 14px",
    borderColor: "transparent",
    fontFamily: 'Inter, sans-serif',
    cursor: "pointer",
    "@media (max-width:900px)": {
      width: "60px",
      height: "32px",
      marginRight: "10px",
      marginBottom: "5px",
      padding: "6px 2px",
      fontSize: "12px",
    }
  }
}));
interface Company {
  id: number;
  companyName: string;
}

interface SS {
  id: any;
  excludedRoutes: [];
}

interface Image {
  file_name: string;
  content_type: string;
  id: number;
  url: string;
}

interface ProductAttributes {
  id: number;
  name: string;
  brand_name: string;
  price: number | null;
  prices: number[];
  change: string;
  change_up: boolean;
  description: string;
  manufacturer: string;
  specification: string;
  applications: string;
  certifications: string;
  is_recommended: boolean;
  status: string;
  product_id: string;
  images: Image[];
  category: string;
  sub_category: string;
  brand: string;
  updated: string;
  is_new: string;
  is_best_seller: boolean;
  product_feature: {
    data: string[];
  };
}

interface Product {
  id: string;
  type: string;
  attributes: ProductAttributes;
}

interface SelectedItem {
  value: string;
  label: string;
}
interface Product {
  id: string;
  name: string;
  change: number | null;
  brand_name: string;
  price: number | null;
  created_at: string;
  updated_at: string;
  change_up: boolean;
  description: string;
  specification: string;
  is_recommended: boolean;
  applications: string;
  certifications: string;
  category_id: number;
  sub_category_id: number;
  manufacturer: string | null;
  pid: number | null;
}
interface CategoryAttributes {
  id: number;
  name: string;
  category_id: string;
  image: string;
  sub_categories: any[];
}
interface CategoryData {
  id: string;
  type: string;
  attributes: CategoryAttributes;
}
interface CompanyInformation {
  id: string;
  type: string;
  attributes: {
    company_name: string;
    company_type: string;
    email: string;
    full_phone_number: string;
    contact_name: string;
    account_id: number;
  };
}
interface SubProduct {
  id: number;
  name: string;
}
interface SubCategory {
  id: number | string;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  rank: number | null;
  products: SubProduct[];
}

class Navbar extends BlockComponent<Props, S, SS> {
  // static contextType = CompanyProjectContext; 
  getCatList: string = "";
  getSugCatList: string = "";
  getSugCatList2: string = "";
  getSugCatList3: string = "";
  getCompanyInfo: any;
  getProjectInfo: any;
  getSugCatList2BurgerReceive: string = "";
  userType: string = "";
  getAllCategoryInfoAPICallId: string = "";
  private dropdownRef = React.createRef<HTMLUListElement>();
  private boxRef = React.createRef<HTMLDivElement>();
  private burgerRef = React.createRef<HTMLDivElement>();
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);



    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      showDropDownCountry: false,
      projectName: "",
      showDropDownLanguage: false,
      open: false,
      scrollPosition: 0,
      showDropDownCategori: false,
      isProjectSelected: false,
      selectedCategory: "",
      isSubMenuOpen: false,
      projectAnchorEl: null,
      SelectedCompanyId: "",
      token: "",
      catData: [],
      catAllData: [],
      subCat: [],
      selectedItem: { value: "", label: "" },
      catSug: [],
      projectData: [],
      selectOption: "",
      selectCat: [],
      dropAccount: false,
      showMegaMenu: false,
      showBurgerMenu: false,
      inputValue: "",
      Companies: [],
      companyName: [],
      selCatId1: "69",
      selCatId2: "69",
      subCat2: [],
      subCat2Burger: [],
      subCat3: [],
      platform: 'desktop',
      openedItems: {},
      excludedRoutes: [
        '/Catalogue',
        '/MyCart',
        '/ApplicationIndustry',
        '/ApplicationGroup',
        '/Application',
        '/InvoiceBilling',
        '/DebitNotes',
        '/CreditNotes',
        '/Quotations',
        '/PaymentSettlements',
        '/PaymentTimeline',
        '/ProductVariation',
        '/Ledger',
        '/UserCategories',
        '/ProjectData',
        '/TaxInvoice',
        '/PaymentAcknowledgement',
        '/DebitnoteDeatil',
        '/CreditnoteDeatil',
      ],
      subCate2AllCate: []
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    await super.componentDidMount();
    const token = (typeof localStorage !== "undefined" && localStorage.getItem('otp-token')) || '';
    this.setState({ token: token })
    sessionStorage.removeItem("projectId");
    sessionStorage.removeItem("companyid");
    await this.getCategory();
    this.getSuggesionCategory();
    this.updateWindowDimensions();
    this.getCompanyInformation();
    await this.handleSubBurgerMenu()
    window.addEventListener('resize', this.updateWindowDimensions);
    this.userType = await getStorageData("userType");
    document.addEventListener('click', this.handleOutsideClick);

  }
  updateWindowDimensions = () => {
    if (window.innerWidth <= 900) {
      this.setState({
        platform: 'mobile'
      });
    }

  };
  async componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }


  handleOutsideClick = (event: MouseEvent) => {
    if (
      this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node) &&
      this.boxRef.current && !this.boxRef.current.contains(event.target as Node)
    ) {
      this.setState({ dropAccount: false });
    }
  };

  handleBurgerMenu = () => {
    if (this.state.platform == 'mobile') {
      return
    } else {
      return
    }
  }
  handleToggleBurger = () => {
    this.setState({ showBurgerMenu: !this.state.showBurgerMenu })
  }
  toggleItem = async (index: any) => {
    this.setState((prevState) => ({
      openedItems: {
        ...prevState.openedItems,
        [index]: !prevState.openedItems[index],
      },
    }));
    if (this.state.subCat2Burger?.filter((elem2: any) => elem2?.attributes?.parent_category_id === index).length == 0) {
      this.getSuggesionCategory2Burger(index);
    }
    if (this.state.subCat3?.filter((elem2: any) => elem2?.attributes?.parent_category_id === index).length == 0) {
      this.getSuggesionCategory3(index);
    }
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.getCatList) {
      const categoryData = responseJson?.data?.data?.map((category: CategoryData) => category.attributes)
      const cat = responseJson?.data?.data?.map((category: CategoryData) => category.attributes)
      this.setState({ catData: categoryData, catAllData: cat }, () => {
        this.handleSubBurgerMenu()
      })
    }
    // Customizable Area Start
    if (apiRequestCallId == this.getSugCatList) {
      const data = responseJson?.data?.map((cat: { attributes: { id: string, name: string } }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })
      this.setState({ catSug: data, subCat: responseJson?.sub_categories?.data })
      if (this.state.selectOption) {

        let specificationValues: object[] = [];

        responseJson.data.forEach((item: Product) => {



          const specifications = JSON.parse(item.attributes.specification);



          Object.entries(specifications).forEach(([key, value]) => {

            specificationValues.push({ value: value, label: value })

          });

          const catDataId = responseJson.data.map((datas: { attributes: { id: string } }) => {
            return datas.attributes.id
          })
          this.setState({ catSug: specificationValues, selectCat: catDataId[0] });

        });

      }
      // Added a nullish check for 'catSug' to prevent potential errors that occur
      // when this property is undefined or null, which could result in a blank screen.
      // The optional chaining operator (?.) ensures that we safely access the 'length'
      // property only if 'catSug' exists, avoiding runtime exceptions.
      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList2) {

      const data = responseJson?.data?.map((cat: { attributes: { id: string, name: string } }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })

      this.setState({ catSug: data, subCat2: responseJson?.sub_categories?.data })


      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList2BurgerReceive) {

      const data = responseJson?.data?.map((cat: { attributes: { id: string, name: string } }) => {

        return { value: cat.attributes.id, label: cat.attributes.name }

      })

      this.setState({ catSug: data, subCat2: responseJson?.sub_categories?.data })
      this.setState(prevState => ({
        subCat2Burger: Array.isArray(prevState?.subCat2Burger)
          ? [...prevState.subCat2Burger, ...(responseJson?.sub_categories?.data || [])]
          : [...(responseJson?.sub_categories?.data || [])]
      }));


      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }
      // Customizable Area End
    }
    if (apiRequestCallId == this.getSugCatList3) {
      this.setState(prevState => ({
        subCat3: Array.isArray(prevState?.subCat3)
          ? [...prevState.subCat3, ...(responseJson?.sub_categories?.data || [])]
          : [...(responseJson?.sub_categories?.data || [])]
      }));



      if (this.state.catSug?.length === 0) {

        this.handleClick("name")

      }

    }
    if (apiRequestCallId === this.getCompanyInfo) {
      this.handleCompanyResponse(responseJson);
    }
    if (apiRequestCallId === this.getProjectInfo) {
      this.handleProjectResponse(responseJson);
    }
    if (apiRequestCallId === this.getAllCategoryInfoAPICallId) {
      this.handleAllCategoryResponse(responseJson);
    }
  }
  handleProjectResponse(responseJson: any) {
    if (responseJson.Project.data) {
      this.setState({ projectAnchorEl: true, projectData: responseJson.Project.data });

    }
  }

  handleAllCategoryResponse(responseJson: any) {
    if (responseJson.data) {
      if (responseJson.data.data.attributes.sub_categories.length > 0) {
        this.setState({ subCate2AllCate: responseJson.data.data.attributes.sub_categories })
      }
      else {
        this.setState({ subCate2AllCate: [] })
      }
    }
  }

  handleCompanyResponse(responseJson: any) {
    if (responseJson.CompanyInformations.data) {
      const companyData = responseJson.CompanyInformations.data.map((item: CompanyInformation) => ({
        id: item.id,
        companyName: item.attributes.company_name
      }));

      this.setState({
        Companies: responseJson.CompanyInformations.data,
        companyName: companyData,
      });
    }

  }
  handleSelectChange = (selectedOption: any, actionMeta: any) => {
    if (selectedOption) {
      const selectedLabel = selectedOption.map((a: { label: string, value: string }) => {
        return a.label
      })
      const selectedItem = this.state.catSug.find((item: { value: string, label: string }) => item.label === selectedLabel[0])
      const specificationData = selectedItem?.value;
      this.setState({ selectOption: specificationData }, () => {
        this.getSuggesionCategory();
      });
    }
  };
  handleDropdownClick = (label: any) => {
    this.setState({
      showDropDownCategori: true,
      selectedItem: label.name
    });
  };

  handleCategoryClick = (label: any) => {
    const subCategories = label.sub_categories.map((subCategory: SubCategory) => subCategory);
    this.setState((prevState: any) => ({
      isSubMenuOpen: true,
      subCat: subCategories,
      selectedItem: label.name
    }));
  };

  handleAccountClick = (label: any) => {
    if (label === "Logout") {
      localStorage.removeItem('otp-token');
      localStorage.clear();
      if (this.props.history.location.pathname == '/') {
        this.props.history.push("/LandingPage")
      } else {
        this.props.history.push("/")
      }
    }
    if (label === "Dashboard") {
      this.handleNavigatation("UserCategories")
    }
  };

  handlePagesClick = (label: any) => {
    if (label === "platform") {
      this.props.history.push("/Platform");
    }
    if (label === "materials") {
      this.props.history.push("/Price")
    }
    if (label === "news") {
      this.props.history.push("/News")
    }
    if (label === "logo") {
      this.props.history.push("/")
    }
  };

  handleloginClick = () => {
    this.props.history.push("/Signuplogin")
  };

  handleArrowcategory = (condition: any, fontSize: any) => {
    if (condition) {
      return <ArrowUp fontSize={fontSize} />;
    } else {
      return <ArrowDown fontSize={fontSize} />;
    }
  }

  handleDropdownOpen = () => {
    this.setState({ showDropDownCountry: !this.state.showDropDownCountry })
  }

  handleDropdownLanguageOpen = () => {
    this.setState({ showDropDownLanguage: !this.state.showDropDownLanguage })
  }

  handleAccountOpen = () => {
    this.setState({ dropAccount: !this.state.dropAccount })
  }

  handleDrodownCat = () => {
    this.setState({ showDropDownCategori: !this.state.showDropDownCategori })
  }

  handleScroll = (scrollType: string) => {
    const container = document.getElementById('scroll-container');
    const scrollAmount = 200;
    const newPosition =
      scrollType === 'right'
        ? this.state.scrollPosition + scrollAmount
        : this.state.scrollPosition - scrollAmount;

    if (container) {
      container.scrollTo({
        top: 0,
        left: newPosition,
        behavior: 'smooth',
      });
    }

    this.setState({ scrollPosition: newPosition })
  };

  handleClick = (name: any, idCategory?: number) => {
    if (idCategory)
      this.navigateToCatalogueView(idCategory, name)

  };

  handleSubCateClick = (name: any, idCategory: number, idSubcate: number) => {
    if (idCategory)
      this.navigateWithSub(idCategory, name, idSubcate)

  };

  navigateToCatalogueView = (chatId: number, chatName: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCatalogueWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), chatId);
    this.send(message);
    this.setState({ showDropDownCategori: false, showBurgerMenu: false });
  };

  navigateWithSub = (chatId: number, chatName: string, idSubcate: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCatalogueWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${chatId}/${idSubcate}`);
    this.send(message);
    this.setState({ showDropDownCategori: false, showBurgerMenu: false });
  };

  navigateWithSub3 = (chatId: number, idSubcate: number, idSub3: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductCatalogueWeb");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), `${chatId}/${idSubcate}/${idSub3}`);
    this.send(message);
  }


  sleep(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  handleSubBurgerMenu = async () => {
    for (const elem of this.state.catData) {
      await this.sleep(300);

      if (this.state.subCat2) {
        for (const subElem of this.state.subCat2) {
          const idExists = this.state.subCat3?.some(
            (subCat3Elem: any) => subCat3Elem.attributes.parent_category_id === subElem.attributes.id
          );

          if (!idExists) {
            await this.getSuggesionCategory3(subElem.attributes.id);
            await this.sleep(200);
          }
        }
      }
    }
  };

  onMouseEnter = async (prd: any) => {
    this.setState({ selCatId1: prd.id });
    this.getAllCategory(prd.id);
    this.setState({ showMegaMenu: true });
  }
  onMouseLeave = () => {
    this.setState({ showMegaMenu: false })
  }
  handleNavigatation = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  navigateToMyCart = () => {
    if (this.props.handleNavigate && this.userType !== "seller") {
      this.props.handleNavigate("MyCart")
    }
    else {
      this.handleNavigatation("MyCart")
    }
  }


  getCategory = async () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatList = getValidationsMsg.messageId
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/get_categories")
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  getSuggesionCategory = async () => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList = getValidationsMsg.messageId


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_productdescription/products/search?query[]=${this.state.selectOption}`)


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getSuggesionCategory2 = async () => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList2 = getValidationsMsg.messageId


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories/get_sub_categories?parent_category_id=${this.state.selCatId1}`)


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }
  getSuggesionCategory2Burger = async (id: any) => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList2BurgerReceive = getValidationsMsg.messageId


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories/get_sub_categories?parent_category_id=${id}`)


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getSuggesionCategory3 = async (id: string) => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getSugCatList3 = getValidationsMsg.messageId


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories/get_sub_categories?parent_category_id=${id}`)


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  getCompanyInformation = async () => {
    const userId = await getStorageData("user-id")
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyInfo = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userId}/company_informations`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };


  getProjectInformation = async () => {
    const userId = await getStorageData("user-id")
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProjectInfo = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userId}/projects?company_information_id=${this.state.SelectedCompanyId}`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getAllCategory = async (id: number) => {
    const headers = {

      "Content-Type": "application/json",

      token: this.state.token,

    };

    const getValidationsMsg = new Message(

      getName(MessageEnum.RestAPIRequestMessage)

    );

    this.getAllCategoryInfoAPICallId = getValidationsMsg.messageId


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `bx_block_categories/categories/get_level_wise_categories?id=${id}`)


    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(headers)

    );

    getValidationsMsg.addData(

      getName(MessageEnum.RestAPIRequestMethodMessage),

      "GET"

    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

  }

  checkActivePage = (pageLocation: string, className: string) => {
    const activePage = this.props.history.location.pathname;
    if (activePage == pageLocation) {
      className = `${className}-active`
    }
    return className
  }

  handleDropdownCloses = () => {
    this.setState({ projectAnchorEl: false });
  };

  handleProjectSelect = (projectName: string, project_id: string) => {

    this.props.onSelectionChange?.(this.state.SelectedCompanyId, project_id);
    this.setState({
      projectAnchorEl: null,
      isProjectSelected: true,
      open: false,
      projectName: projectName
    });


  };


  handleOpen = () => {
    this.setState({ open: true, projectName: "",inputValue: "",});
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const menuItems = [
      { label: "India" },
      { label: "USA" },
      { label: "Canada" },
    ];

    const languageItems = [
      { label: "English" },
      { label: "Spanish" },
    ];

    const accountItems = [
      {
        label: "My Profile", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183726)">
              <path opacity="0.2" d="M127.998 172.286C165.08 172.286 195.141 142.225 195.141 105.143C195.141 68.0609 165.08 38 127.998 38C90.9164 38 60.8555 68.0609 60.8555 105.143C60.8555 142.225 90.9164 172.286 127.998 172.286Z" fill="black" />
              <path d="M127.998 172.286C165.08 172.286 195.141 142.225 195.141 105.143C195.141 68.0609 165.08 38 127.998 38C90.9164 38 60.8555 68.0609 60.8555 105.143C60.8555 142.225 90.9164 172.286 127.998 172.286Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M34 217.047C56.9964 189.686 89.46 172.285 128 172.285C166.54 172.285 199.004 189.686 222 217.047" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183726">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
      {
        label: "Dashboard", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183739)">
              <path opacity="0.2" d="M40 128H128V216H48C45.8783 216 43.8434 215.157 42.3431 213.657C40.8429 212.157 40 210.122 40 208V128Z" fill="black" />
              <path opacity="0.2" d="M128 40H208C210.122 40 212.157 40.8429 213.657 42.3431C215.157 43.8434 216 45.8783 216 48V128H128V40Z" fill="black" />
              <path d="M208 40H48C43.5817 40 40 43.5817 40 48V208C40 212.418 43.5817 216 48 216H208C212.418 216 216 212.418 216 208V48C216 43.5817 212.418 40 208 40Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M128 40V216" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M216 128H40" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183739">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>
      },
      {
        label: "Settings", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183691)">
              <path opacity="0.2" d="M230.101 108.76L198.251 90.62C197.611 89.46 196.941 88.33 196.251 87.21L196.131 51.21C186.065 42.7151 174.485 36.1976 162.001 32L130.001 49.89C128.661 49.89 127.311 49.89 126.001 49.89L94.0005 32C81.5197 36.2113 69.9467 42.7425 59.8905 51.25L59.7305 87.25C59.0305 88.37 58.3605 89.51 57.7305 90.66L25.8905 108.76C23.3802 121.457 23.3802 134.523 25.8905 147.22L57.7405 165.36C58.3805 166.52 59.0505 167.65 59.7405 168.77L59.8605 204.77C69.9277 213.273 81.5109 219.798 94.0005 224L126.001 206.13C127.34 206.13 128.691 206.13 130.001 206.13L162.001 224C174.471 219.785 186.033 213.254 196.081 204.75L196.241 168.75C196.941 167.63 197.611 166.49 198.241 165.34L230.081 147.24C232.6 134.538 232.607 121.465 230.101 108.76ZM128.001 168C120.089 168 112.356 165.654 105.778 161.259C99.1997 156.864 94.0728 150.616 91.0453 143.307C88.0178 135.998 87.2257 127.956 88.7691 120.196C90.3125 112.437 94.1221 105.31 99.7162 99.7157C105.31 94.1216 112.438 90.312 120.197 88.7686C127.956 87.2252 135.999 88.0173 143.308 91.0448C150.617 94.0723 156.864 99.1992 161.259 105.777C165.655 112.355 168.001 120.089 168.001 128C168.001 138.609 163.786 148.783 156.285 156.284C148.783 163.786 138.609 168 128.001 168Z" fill="black" />
              <path d="M128 168C150.091 168 168 150.091 168 128C168 105.909 150.091 88 128 88C105.909 88 88 105.909 88 128C88 150.091 105.909 168 128 168Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M130.048 206.11C128.708 206.11 127.358 206.11 126.048 206.11L93.9983 224C81.5216 219.803 69.949 213.289 59.8883 204.8L59.7683 168.8C59.0583 167.68 58.3883 166.55 57.7683 165.39L25.8983 147.24C23.3881 134.543 23.3881 121.477 25.8983 108.78L57.7383 90.68C58.3883 89.53 59.0583 88.39 59.7383 87.27L59.8983 51.27C69.95 42.7564 81.5195 36.2184 93.9983 32L125.998 49.89C127.338 49.89 128.688 49.89 129.998 49.89L161.998 32C174.475 36.1969 186.048 42.7109 196.108 51.2L196.228 87.2C196.938 88.32 197.608 89.45 198.228 90.61L230.078 108.75C232.589 121.447 232.589 134.513 230.078 147.21L198.238 165.31C197.588 166.46 196.918 167.6 196.238 168.72L196.078 204.72C186.033 213.235 174.471 219.776 161.998 224L130.048 206.11Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183691">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
      {
        label: "Logout", image:
          <svg width="18" height="18" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_22326_183710)">
              <path opacity="0.2" d="M216 48H40C35.5817 48 32 51.5817 32 56V200C32 204.418 35.5817 208 40 208H216C220.418 208 224 204.418 224 200V56C224 51.5817 220.418 48 216 48Z" fill="black" />
              <path d="M176 104V136H80" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M104 112L80 136L104 160" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M216 48H40C35.5817 48 32 51.5817 32 56V200C32 204.418 35.5817 208 40 208H216C220.418 208 224 204.418 224 200V56C224 51.5817 220.418 48 216 48Z" stroke="black" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_22326_183710">
                <rect width="256" height="256" fill="white" />
              </clipPath>
            </defs>
          </svg>

      },
    ];
    const { openedItems } = this.state;

    const CustomOption = ({ data, ...props }: any) => {
      const isHeading = data.value === 'heading';
      return (
        <components.Option
          {...props}
          className={isHeading ? 'heading-option' : ''}
          isDisabled={isHeading}
        >
          <div className="menu-options">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
              {isHeading ? null : <img src={require("./search_sug.png")} alt="search icon" />}
              <span style={{ fontSize: "14px", fontWeight: 400, fontFamily: 'Inter, sans-serif', color: isHeading ? "black" : "inherit" }}>{data.label}</span>
            </div>
            {isHeading ? null : <img style={{ height: "14px", width: "14px" }} src={require("./receive.png")} alt="receive icon" />}
          </div>
        </components.Option>
      );

    };

    const ClearableMultiValueRemove: React.FC<ComponentProps<typeof components.MultiValueRemove>> = (props) => {
      const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.setState({ selectOption: "" }, () => {
          this.getSuggesionCategory();
        });
        e.stopPropagation();
        props.innerProps.onClick(e);
      };

      return (
        <components.MultiValueRemove {...props}>
          <div onMouseDown={handleMouseDown}>
            &#10005;
          </div>
        </components.MultiValueRemove>
      );
    };
    // Added this check to ensure the existence of 'catSug' property in order to prevent rendering errors and the blank screen issue on the site
    let optionsWithHeading;
    if (this.state.catSug) {
      optionsWithHeading = [
        { value: 'heading', label: 'Suggestions', isDisabled: true, isHeading: true },
        ...this.state.catSug
      ];
    } else {
      optionsWithHeading = [
        { value: 'heading', label: 'Suggestions', isDisabled: true, isHeading: true }
      ];
    }
    const images = [
      { id: 'notification', src: notificationIcon, alt: "Notification" },
      { id: 'rfq', src: UploadRFQ, alt: "RFQ" },
      { id: 'flash', src: QuickQuote, alt: "Flash" },
      { id: 'cart', src: Cart, alt: "Cart", onClick: () => this.navigateToMyCart() },
      { id: 'chat', src: KratexAiAssitant, alt: "KratexAiAssitant" }
    ];
    return (
      <>
        {this.state.token ? (
          <>
            <NavigationBlock>
              <Box sx={webStyle.navbar}>
                <Box sx={{ display: 'flex', alignItems: "center", width: { xs: '100%', sm: '80%', md: '80%', lg: '65%', xl: '65%' } }} className='firstDivOfHeader'>
                  <Box sx={{ width: "81px", height: "48px", marginLeft: { xs: '0px', sm: '0px', md: '40px' }, display: "flex", alignItems: "center" }}>
                    <svg width="214" height="90" viewBox="0 0 214 90" fill="none" xmlns="http://www.w3.org/2000/svg" className='logo' onClick={() => { this.handlePagesClick("logo") }}>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M62.3359 89.9789H86.6248L63.1464 48.1662L46.3133 62.5845L62.3359 89.9789ZM82.5675 19.4618L105.105 0.0195312H81.9726L32.4795 42.0431L42.1331 0.0195312H21.4415L0.777344 89.9789H21.4688L26.6934 67.2322L32.1911 62.6073L82.5675 19.4618Z" fill="#1B1918" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M194.745 89.9789L173.919 47.591L159.447 61.6677L158.176 63.061L170.714 89.9789H194.745ZM175.817 39.3307L213.224 0.0195312H190.734L167.691 24.9125L168.326 26.2377L175.817 39.3307Z" fill="#1B1918" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M141.1 43.4578L132.939 29.1929L118.949 0.0195312H95.3036L116.763 44.2289L72.9688 89.9789H96.8722L125.379 58.7503L141.1 43.4578Z" fill="#FF6600" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M171.889 43.4578L163.728 29.1929L149.738 0.0195312H126.093L147.552 44.2289L103.758 89.9789H127.661L156.168 58.7503L171.889 43.4578Z" fill="#FF6600" />
                    </svg>
                  </Box>
                  <Box className='dropdown-list'>
                    <img src={locationIconBordered} height={20} width={20}></img>
                  </Box >
                 <Box style={{ display: "flex", width: "200px", height: "40px" }} className='selectCompany'>
                    <Box className="selectBtn" style={{ display: "flex", flex: 1, alignItems: "center", position: "relative" }}>
                      <Autocomplete
                        options={this.state.companyName}
                        sx={{ width: 300, marginBottom: "18px" }}
                        componentsProps={{
                          paper: {
                            sx: {
                              width: "200px",
                              height: "158px",
                              overflowY: "auto",
                              backgroundColor: "#fff",
                            },
                          },
                        }}
                        getOptionLabel={(option: Company) => 
                          `${option.companyName} ${this.state.projectName?"&":""} ${this.state.projectName}`
                        }
                        open={this.state.open}
                        onOpen={this.handleOpen}
                        inputValue={this.state.inputValue} 
                        onInputChange={(event, newInputValue) => {
                          this.setState({ inputValue: newInputValue });
                        }}
                        onClose={(event, reason) => {
                          if (this.state.projectAnchorEl) {
                            this.setState({ open: true });
                          } else if (reason === "blur" || reason === "escape") {
                            this.setState({ open: false });
                          }
                          else {
                            this.setState({ open: true });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                          {...params}
                          label={!this.state.SelectedCompanyId ? "Select Company & Project" : ""}
                          InputLabelProps={{
                            style: { 
                              fontSize: "12px", 
                              color: "#171A1F", 
                              fontFamily: "Inter, sans-serif", 
                              width: "100%", 
                              textAlign: "center", 
                              paddingTop:"6px",
                              display: "flex",
                              justifyContent: "center",
                            },
                            shrink: false
                          }}
                          InputProps={{
                            disableUnderline: true,
                            ...params.InputProps,
                            style: {  
                              borderRadius: "8px",
                              paddingRight: "10px",
                              paddingLeft: "12px",
                              color: "#000",
                              height: "40px",  
                              display: "flex",
                              paddingTop:"13px",
                              fontSize:"14px",
                              justifyContent: "center", // ✅ Item horizontal center
                              textAlign: "center",
                              fontFamily: "Inter, sans-serif", 
                            },
                            endAdornment: null,
                          }}
                        />
                        
                        )}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({ SelectedCompanyId: value.id, open: true });
                            this.getProjectInformation();
                          }
                        }}
                      />
                      <img className='down-icon' style={{ paddingRight: "10px", width: "8px" }} onClick={this.handleOpen} src={require("./down_arrow.jpg")} alt="down_arrow" />
                    </Box>

                    <Menu
                      anchorEl={this.state.projectAnchorEl}
                      open={Boolean(this.state.projectAnchorEl)}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      getContentAnchorEl={null}
                      PaperProps={{
                        style: {
                          width: "200px",
                          height: "110px",
                          marginLeft: "380px",
                          marginTop: "40px"
                        },
                      }}
                    >
                      {this.state.SelectedCompanyId &&
                        this.state.projectData?.map((projectName: any, index: number) => (
                          <MenuItem key={index} style={{ fontSize:"14px",}} onClick={() => this.handleProjectSelect(projectName.attributes.name, projectName.id)}>
                            {projectName.attributes.name}
                          </MenuItem>
                        ))}
                    </Menu>

                  </Box> 
                </Box>

                <Box sx={webStyle.searchContent} className='searchContent'>
                  <Box className='selectCategoryBtn' >
                    <Typography className='selectCategoryBtn-text'>Category</Typography>
                    <img className='down-icon' style={{width: "8px"}} src={require("./down_arrow.jpg")} alt="down_arrow" />
                  </Box>
                  <Select
                    isMulti
                    name="select"
                    options={optionsWithHeading}
                    className="basic-multi-select"
                    components={{ Option: CustomOption, MultiValueRemove: ClearableMultiValueRemove }}
                    placeholder="Search the marketplace"

                    onChange={this.handleSelectChange}
                  />
                  <Box className='searchIco'>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className='searchIco'>
                      <path d="M7.3577 12.0001C4.51713 12.0001 2.21484 9.69778 2.21484 6.85721C2.21484 4.01664 4.51713 1.71436 7.3577 1.71436C10.1983 1.71436 12.5006 4.01664 12.5006 6.85721C12.4971 9.69607 10.1966 11.9966 7.3577 12.0001ZM7.3577 2.85721C5.14856 2.85721 3.3577 4.64807 3.3577 6.85721C3.3577 9.06636 5.14856 10.8572 7.3577 10.8572C9.56684 10.8572 11.3577 9.06636 11.3577 6.85721C11.3554 4.64921 9.5657 2.8595 7.3577 2.85721Z" fill="#9095A0" />
                      <path d="M14.6189 13.3104L12.1869 10.8784C11.9429 11.1716 11.672 11.4424 11.3789 11.6864L13.8109 14.1184C14.0378 14.3378 14.3995 14.3316 14.6189 14.1041C14.8326 13.8824 14.8326 13.5316 14.6189 13.3104Z" fill="#9095A0" />
                      <path d="M5.07143 7.42857C4.756 7.42857 4.5 7.17257 4.5 6.85714C4.5 5.28171 5.78171 4 7.35714 4C7.67257 4 7.92857 4.256 7.92857 4.57143C7.92857 4.88686 7.67257 5.14286 7.35714 5.14286C6.412 5.14286 5.64286 5.912 5.64286 6.85714C5.64286 7.17257 5.38686 7.42857 5.07143 7.42857Z" fill="#9095A0" />
                    </svg>
                  </Box>

                </Box>

                <Box className='userTools'>
                  {this.handleBurgerMenu()}
                  <img className='userTool' src={homeIcon} alt="" onClick={() => { this.handlePagesClick("logo") }} />
                  {images.map((img) => {
                    return <Box key={img.id} className="userTool">
                      <img
                        src={img.src}
                        alt={img.alt}
                        onClick={img.onClick}
                        className="userTool"
                      />
                    </Box>
                  })}
                </Box>
                <Box sx={{ display: "flex", justifyContent: 'space-around', alignItems: 'center' }}>
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
                    <div
                      className="user-data"
                      onClick={this.handleAccountOpen}
                      ref={this.boxRef}
                    >
                      <Typography className='userInitials'>
                        MG
                      </Typography>
                      <Typography className='userName'>
                        Mihir Group
                      </Typography>
                    </div>
                    <Hidden>
                      {this.state.dropAccount && (
                        <ul className="dropdown-list-account" ref={this.dropdownRef}>
                          {accountItems.map((item) => (
                            <li
                              key={item.label}
                              id="dropdowndata"
                              className={`dropdown-item ${this.state.selectedItem.label == item.label ? 'selected' : ''}`}
                              data-test-id="dropdowndata"
                              onClick={() => this.handleAccountClick(item.label)}
                            >
                              <span>{item.image}</span>
                              <span>{item.label}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </Hidden>
                  </Box>
                </Box>
              </Box>
              <Hidden>
                {this.state.showDropDownCountry && (
                  <ul className="dropdown-list-data">
                    {menuItems.map((item) => (
                      <li
                        key={item.label}
                        id="dropdowndata"
                        className="dropdown-item"
                        data-test-id="dropdowndata"
                        onClick={() => this.handleDropdownClick(item.label)}
                      >
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </Hidden>

              <DrawerStyled
                anchor="left"
                open={this.state.showBurgerMenu}
                onClose={this.handleToggleBurger}
                className="burger-menu"
              >

                {this.state.showBurgerMenu && (
                  <div className="burgerShadow" data-testid='burger-menu'>
                    <Box>
                      <div className="burgerItems">
                        <CloseIco fontSize="large" className="closeBurger" data-testid='close-burger-icon' onClick={this.handleToggleBurger} />

                        <div className="opened-for-codepen">

                          <h2 className='burgerCategory subNavBtn' style={{ fontSize: "20px", margin: "10px 20px 10px 0px" }} onClick={() => { this.toggleItem(0) }}>Categories {this.handleArrowcategory(this.state.openedItems[0], 'large')}</h2>
                          {this.state.openedItems[0] && (this.state.catData?.map((elem1: any, i: number) => {
                            return (
                              <div key={elem1.id} className={this.state.openedItems[i + 1] ? "opened-for-codepen" : ""}>
                                <h3 className='burgerCategory1' onClick={() => this.toggleItem(elem1.id)}><Typography className='burgerCategory1Typo' onClick={() => this.handleClick(elem1.name, elem1.id)}>{elem1.name} </Typography>{this.handleArrowcategory(this.state.openedItems[elem1.id], 'medium')}</h3>
                                {this.state.openedItems[elem1.id] && (
                                  <div>
                                    {this.state.subCat2Burger?.filter((elem2: any) => elem2?.attributes?.parent_category_id === elem1.id).map((elem: any, id: number) => {
                                      return (
                                        <Grid item key={id} xs={11} sm={11} md={2} lg={2} className="megamenu-cat">
                                          <h4 className='burgerCategory2' onClick={() => this.toggleItem(elem.attributes.id)}> <Typography className='burgerCategory2Typo' onClick={() => this.handleSubCateClick(elem.attributes.name, elem.attributes.parent_category_id, elem.attributes.id)}>{elem.attributes.name}</Typography> {this.handleArrowcategory(this.state.openedItems[elem.attributes.id], 'small')}</h4>
                                          {this.state.subCat3 &&
                                            this.state.subCat3
                                              .filter((elem3: any) => elem3?.attributes?.parent_category_id === elem.attributes.id)
                                              .map((e: any) => {
                                                return this.state.openedItems[elem.attributes.id] && <p key={e.id} className='burgerCategory3' onClick={() => { this.handleClick(elem.attributes.name); this.setState({ showBurgerMenu: false }) }}><Typography className='burgerCategory3Typo' onClick={() => {
                                                  this.navigateWithSub3(elem1.id, e.attributes.parent_category_id, e.id)
                                                }}>{e.attributes.name}</Typography></p>;
                                              })}
                                        </Grid>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            );
                          }))}
                        </div>
                        <Box className="subNavBtn">
                          <Box sx={webStyle.bulkOrder}>Customized Products</Box>
                          <ExpandMore className="down-icon" />
                        </Box>
                        <Box className="subNavBtn">
                          <Box sx={webStyle.bulkOrder} className='bulkOrder'>Hire Pro</Box>
                          <ExpandMore className="down-icon" />
                        </Box>
                        <Box className="subNavBtn">
                          <Box sx={webStyle.bulkOrder}>Audit Report</Box>
                        </Box>
                      </div>
                    </Box>
                  </div>
                )}
              </DrawerStyled>

              <Hidden>
                {this.state.showDropDownCategori && (
                  <ClickAwayListener onClickAway={() => this.setState({ showDropDownCategori: false })}>
                    <ul className="dropdown-list-cat" >
                      {this.state.catAllData.map((item: any) => (
                        <li
                          key={item}
                          id="dropdowndata"
                          className="dropdown-item"
                          data-test-id="dropdowndata"
                          onMouseOver={() => this.handleCategoryClick(item)}
                        >
                          <img style={{ marginRight: "7px", mixBlendMode: "multiply" }} src={require("./right.jpg")} alt={item.label} className="img" />
                          <Box component={"a"} onClick={() => this.handleClick(item.name, item.id)
                          }>{item.name}</Box>
                        </li>
                      ))}
                    </ul>
                  </ClickAwayListener>
                )}
              </Hidden>

              {this.state.isSubMenuOpen && (
                <ul className="sub-menu">
                  <div style={{ display: "flex" }}>
                    {this.state.subCat?.slice(0, 5).map((data: any, index: number) => {
                      return (
                        <>
                          <Box key={index}>
                            <Box style={{ display: "flex", flexDirection: "column", marginTop: "15px", marginBottom: "20px" }}>
                              <li>
                                <div className="menu-item-label-sub" style={{ fontSize: "13px", fontWeight: 600 }}>{data.name}</div>
                                {
                                  data.products.slice(0, 5).map((product: any) => {
                                    return (
                                      <div className="menu-item-label-sub-data" >{product.name}</div>
                                    )
                                  })
                                }

                              </li>
                            </Box>
                          </Box>
                          <div style={{ background: "#CBD5E1", height: "200px", width: "2px", margin: "auto" }}></div>
                        </>
                      )
                    })}
                  </div>
                  <Box style={webStyle.viewBox}>
                    <Button style={{ textTransform: "none", ...webStyle.viewButton }}>View All</Button>
                  </Box>
                </ul>
              )}
            </NavigationBlock>

            {this.state.platform !== 'mobile' ? !this.state.excludedRoutes.some((route: string) => location.pathname.includes(route)) && (<SubNavigationBlock>
              <Box className='main-block'>
                <Box className='category-content' onClick={this.handleDrodownCat}>
                  <Box sx={{ fontSize: "12px", fontWeight: 700, fontFamily: 'Inter, sans-serif', whiteSpace: "nowrap", display: "flex", color: "#5F5F5F", alignItems: "center" }}>
                    <Typography className='category-content-text'>Top Categories</Typography>
                    <ExpandMore className='down-icon' />
                  </Box>
                </Box>

                <Box className="horizontal-scroll-menu">
                  {this.state.catData?.length > 0 ? (
                    <Box className="scroll-buttons">
                      <ChevronLeft className='right-left-icon' style={{ marginRight: "16px" }} onClick={() => this.handleScroll('left')} />
                    </Box>
                  ) : ""}
                  <Box id="scroll-container" className="scroll-container">
                    {this.state.catData?.map((data: any) => {
                      return (
                        <div
                          {...(this.state.platform === 'mobile'
                            ? { onClick: () => this.onMouseEnter(data) }
                            : { onMouseEnter: () => this.onMouseEnter(data) }
                          )}
                        >
                          <Typography key={data.name}
                            {...(this.state.platform === 'mobile'
                              ? {}
                              : { onClick: () => this.handleClick(data.name, data.id) }
                            )}
                            className={this.state.selCatId1 == data.id ? "menu-item-scr-active" : "menu-item-scr"}>{data.name}</Typography>
                        </div>
                      )
                    })}
                  </Box>
                  {this.state.catData?.length > 0 ? (
                    <div className="scroll-buttons">
                      <ChevronRight className='right-left-icon' style={{ marginLeft: "16px" }} onClick={() => this.handleScroll('right')} />
                    </div>
                  ) : ""}
                </Box>
                <Hidden>
                  {this.state.showMegaMenu && (
                    <div className='mobileShadow'>
                      <Grid container className="dropdown-megamenu" onMouseLeave={() => { this.onMouseLeave() }}>
                        {this.state.subCate2AllCate.length > 0 && this.state.subCate2AllCate?.map((elem: any, id: number) => {
                          return (
                            <Grid item key={id} xs={10} sm={10} md={2} lg={2} className='megamenu-cat'>
                              <Typography className='megamenu-main-cat' onClick={() => this.handleSubCateClick(elem.data.attributes.name, +this.state.selCatId1, elem.data.attributes.id)}>
                                {elem.data.attributes.name}
                              </Typography>
                              {elem.data.attributes.sub_categories.length > 0 && elem.data.attributes.sub_categories.map((e: any) => {
                                return (
                                  <Typography className='megamenu-sub-cat' onClick={() => this.navigateWithSub3(+this.state.selCatId1, elem.data.attributes.id, e.data.attributes.id)}>
                                    {e.data.attributes.name}
                                  </Typography>
                                )
                              })}
                            </Grid>
                          )
                        })}
                      </Grid>
                    </div>
                  )}
                </Hidden>
                <Box sx={webStyle.bulkOrderDiv}>
                  <Box className='subNavBtn'>
                    <Typography className='bulkOrder'>
                      Customized Products
                    </Typography>
                    <ExpandMore className='down-icon' />
                  </Box>
                  <Box className='subNavBtn'>
                    <Typography className='bulkOrder'>
                      Hire Pro
                    </Typography>
                    <ExpandMore className='down-icon' />
                  </Box>
                  <Box className='subNavBtn'>
                    <Box sx={webStyle.bulkOrder}>Audit Report</Box>
                  </Box>
                </Box>
              </Box>
            </SubNavigationBlock>) : <MenuIcon className='userTool' data-testid='burger-icon' style={{ marginLeft: "15px" }} onClick={() => { this.setState({ showBurgerMenu: true }) }} />}
          </>
        ) : (
          < NavigationBlockWOLogin>
            <nav className="navMenu">
              <Grid container justifyContent="space-between" direction="row" alignItems="center">
                <Grid item direction="row" className="navTextAndLogo">
                  <Grid container direction="row" alignItems="center" >
                    <Grid item className="logo" onClick={() => { this.handlePagesClick("logo") }}>
                      <svg width="214" height="90" viewBox="0 0 214 90" fill="none" xmlns="http://www.w3.org/2000/svg" className='logo'>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.3359 89.9789H86.6248L63.1464 48.1662L46.3133 62.5845L62.3359 89.9789ZM82.5675 19.4618L105.105 0.0195312H81.9726L32.4795 42.0431L42.1331 0.0195312H21.4415L0.777344 89.9789H21.4688L26.6934 67.2322L32.1911 62.6073L82.5675 19.4618Z" fill="#1B1918" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M194.745 89.9789L173.919 47.591L159.447 61.6677L158.176 63.061L170.714 89.9789H194.745ZM175.817 39.3307L213.224 0.0195312H190.734L167.691 24.9125L168.326 26.2377L175.817 39.3307Z" fill="#1B1918" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M141.1 43.4578L132.939 29.1929L118.949 0.0195312H95.3036L116.763 44.2289L72.9688 89.9789H96.8722L125.379 58.7503L141.1 43.4578Z" fill="#FF6600" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M171.889 43.4578L163.728 29.1929L149.738 0.0195312H126.093L147.552 44.2289L103.758 89.9789H127.661L156.168 58.7503L171.889 43.4578Z" fill="#FF6600" />
                      </svg>
                    </Grid>
                    <Grid item>
                      <Grid container className="navText" alignItems="center" spacing={2}>
                        <Grid item xs={3} sm={3} md={4} lg={4} className={this.checkActivePage("/Platform", "navText__text")} onClick={() => { this.handlePagesClick("platform") }}>
                          <Typography className='navTextTypo'> Platform</Typography>
                        </Grid>
                        <Grid item xs={4} sm={5} md={5} lg={5} className={this.checkActivePage("/Price", "navText__text")} onClick={() => { this.handlePagesClick("materials") }}>
                          <Typography className='navTextTypo'> Material Prices</Typography>
                        </Grid>
                        <Grid item xs={3} sm={2} md={3} lg={3} className={this.checkActivePage("/News", "navText__text")} onClick={() => { this.handlePagesClick("news") }}>
                          <Typography className='navTextTypo'> News</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <button className="loginBtn" onClick={this.handleloginClick}>
                    Login
                    <ArrowDown />
                  </button>
                </Grid>
              </Grid>
            </nav>
          </NavigationBlockWOLogin>
        )}
      </>
    )
  }
}
const webStyle = {
  viewButton: {
    color: "#FF6600", backgroundColor: "transparent",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  viewBox: { display: "flex", justifyContent: 'end', marginRight: "40px" },
  bulkOrderDiv: {
    display: "flex",
    gap: "20px",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      gap: "10px !important",
      width: "100vw"
    }
  },
  loginText: {
    marginLeft: "10px",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: 'Inter, sans-serif',
    cursor: "pointer"
  },
  profileIcon: {
    color: "#FFFFFF",
    fontSize: "30px",
  },
  profileIconContainer: {
    height: "44px",
    width: '44px',
    borderRadius: "50%",
    bgcolor: "#FF6600",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    '@media (max-width: 600px)': {
      width: "30px",
      height: "30px",
      padding: "3px"
    }
  },
  bulkOrder: {
    fontWeight: 700, fontFamily: 'Inter, sans-serif', fontSize: "12px", color: "#1E293B",
    whiteSpace: "nowrap",
    cursor: "pointer",
    "@media (max-width:900px)": {
      fontSize: "20px",
      whiteSpace: "wrap",
    },
  },
  navbar: {
    display: 'flex',
    justifyContent: { xs: "space-between", },
    alignItems: "center",
    flexDirection: "row",
    padding: "5px",
    '@media (max-width: 900px)': {
      flexWrap: 'wrap',
      padding: '5px 0px',
      height: 'auto',
      width: '100vw',
    }
  },
  languageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  displayBox: {
    display: { xs: "none", md: 'block', lg: "block" }
  },
  displayUploadBox: {
    display: { xs: "none", sm: "block", md: 'block', lg: "block" }
  },
  searchContent: {
    margin: "0 10px",
    display: "flex",
    flexDirection: "row",
    padding: "0px 20px",
    fontSize: '12px',
    height: "40px",
    width: "100%",
    "@media (min-width:1500px)": {
      width: "70%"
    },
    justifyContent: "space-between",
    borderRadius: "9px",
    alignItems: "center",
    backgroundColor: "#F3F4F6",
    marginLeft: "34px",
    '@media (max-width: 600px)': {
      '& .MuiTypography-body1': {
        fontSize: "10px !important"
      },
      padding: "1px 0px",
      marginLeft: "10px",
    },
    "& .basic-multi-select": {
      fontSize: '12px',
      fontWeight: 400,
      minWidth: "30px",
      width: "100%",
      boxSizing: "border-box",
      fontFamily: 'Inter, sans-serif',
      "@media (max-width:900px)": {
        fontSize: '8px',
      },
    },
    "& [class*=' css-yk16xz-control']": {
      borderColor: "#F3F4F6",
    },
    "& .MuiBox-root-6": {
      fontSize: "12px"
    },
    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#fff !important",
      border: "1px solid orange",
      color: "orange !important"
    },
    "& .css-12jo7m5": {
      color: "orange",
      fontSize: "14px"
    },
    "& .css-xb97g8:hover": {
      backgroundColor: "transparent !important",
      color: "orange !important"
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none"
    },
    "& .css-1xc3v61-indicatorContainer .css-tj5bde-Svgg": {
      display: "none !important"
    },
    "& .css-tlfecz-indicatorContainer .css-6q0nyr-Svg .css-1gtu0rj-indicatorContainer": {
      display: "none !important"
    },
    "& .css-6q0nyr-Svg": {
      display: "none !important"
    },
    '& [class=" css-tlfecz-indicatorContainer"]': {
      display: "none !important"
    },
    '& [class=" css-1gtu0rj-indicatorContainer"]': {
      display: "none !important"
    },
    '& .css-1gtu0rj-indicatorContainer': {
      display: "none !important"
    },
    "& .css-lgtu0rj-indicatorContainer .css-6q0nyr-Svg": {
      display: "none !important"
    },
    "& .css-1n7v3ny-option": {
      backgroundColor: "rgb(236, 239, 242)"
    },
    "& .menu-options": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    "& .css-yk16xz-control": {
      backgroundColor: "#F6F6F6"
    },
  },
  product: {
    '@media (max-width: 600px)': {
      display: "none",
    }
  },
  navItems: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "30%",
    padding: '0 5px'
  }
};
export default Navbar