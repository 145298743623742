import React, { ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  isEdit: boolean;
  bank: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string};
  passState: Function;
  bankNameErr: string;
  accNoErr:string; 
  ifscErr:string;
  branchNameErr:string
  // Customizable Area End
}

interface S {
    // Customizable Area Start
    bankData:{banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string}
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BankDetailsBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {     
            // Customizable Area Start
    bankData:{banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""}
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({ bankData: this.props.bank})
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined){
    if(prevProps.bank.banknane !== this.props.bank.banknane){  
    this.setState({ bankData: this.props.bank})
    }
  }
 

  // Customizable Area Start
  handleBankInfoChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({bankData:{...this.state.bankData, [event.target.name]:event.target.value}});
        this.props.passState({...this.state.bankData, [event.target.name]:event.target.value})
  } 

  // Customizable Area End
}
