import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message"


// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  value: number | 0;
  totalResults: number;
  allData:any;
  editdata:any;
  selectedId:any;
  selectedIdd:any;
  productTags:any;
  Codes:any;
  activeTab:any;
  tabData:any;
  resultperPage : number;
  currentPage: number;
  selectedRow:number | null;
  branddetail :{
    brandName: string;
    industrySector: string;
    brandTagline: string;
    brandDescription: string;
    selectedFile: File | null;
  }

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddLogisticalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLogisticalTagListApiCallId:any;
  addlogisticalTagCallId:any;
  editlogisticalTagCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
   // Customizable Area Start
   sidebarToggle: false,
   openDialog: true,
   selectedIdd:"",
   editdata:"",
   activeComponent: "Company",
   tabData:{},
   anchorEl: null,
   Codes:[],
   activeTab:"sow",
   selectedId:[],
   value: 0,
   totalResults: 140, 
   resultperPage: 12, 
   currentPage: 1 ,
   selectedRow:null,
   allData:{},
   productTags:"",
   branddetail :{
    brandName: "",
    industrySector: "",
    brandTagline: "",
    brandDescription: "",
    selectedFile: null,
   }
  // Customizable Area End
  
 // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
     // Customizable Area Start  
    await super.componentDidMount(); 
    const productTag = sessionStorage.getItem("productTag");
    const editData=sessionStorage.getItem('editdata');
    const itemid=sessionStorage.getItem('itemId');
    const code =sessionStorage.getItem('codes')
    this.setState({productTags:productTag,editdata:editData,selectedIdd:itemid,Codes:code})
    this.getLogisticalTagListAPICall();
// Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (apiRequestCallId === this.getLogisticalTagListApiCallId) {
        this.handleGetTagLogisticalResponse(responseJson);
      }
      else if (apiRequestCallId === this.addlogisticalTagCallId) {
        this.handleAddTagLogisticalResponse(responseJson);
      }
      else if (apiRequestCallId === this.editlogisticalTagCallId) {
        this.handleEditTagLogisticalResponse(responseJson);
      }
    }
  }

  handleEditTagLogisticalResponse(responseJson:any){
    if(responseJson.data){
      window.location.href = '/LogisticalList';
     }
  }
  handleAddTagLogisticalResponse(responseJson:any){
   if(responseJson.data){
    window.location.href = '/LogisticalList';
   }
   
  }
  handleGetTagLogisticalResponse(responseJson:any){
    const { count, items, page } = responseJson.meta?.pagy || {}; 
    this.setState({
      allData:responseJson.data,
      totalResults: count || 140,       
      resultperPage: items || 12,    
      currentPage: page || 1,    

    })
  }
  handleTabChange = (newValue: number): void => {
    const tabKeys = Object.keys(this.state.allData); 
    this.setState({
        value: newValue,                  
        selectedRow: null,                
        activeTab: tabKeys[newValue],  
    });
};
handleEditChange = (value:any) => {
  if(this.state.productTags){
  this.setState({ productTags: value}); 
  }
  else{
    this.setState({ editdata: value}); 
  }
};


  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }
  handleRadioClick = (index: number, id: any) => {
    this.setState((prevState) => ({
      selectedId: prevState.selectedId.includes(id)
        ? prevState.selectedId.filter((existingId:any) => existingId !== id) 
        : [...prevState.selectedId, id],
      selectedRow: index,
    }));
  };
  
  getLogisticalTagListAPICall = async () => {

  const header = {
    token: await getStorageData("authToken"),
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getLogisticalTagListApiCallId = requestMessage.messageId;
  const queryParams = `?page=${this.state.currentPage}&size=${this.state.resultperPage}`;
  const endPoint = `${configJSON.getLogisticalTagListApiEndPoint}${queryParams}`;


  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addLogisticalTagApiCall = async () => {
    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("logistical_code[product_tag]",this.state.productTags);
    formData.append("logistical_code[logistical_tag_ids][]",this.state.selectedId);

    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addlogisticalTagCallId = requestMessage2.messageId;
    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.addLogisticalTagListApiEndPoint
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);

    return true;
  }
  editLogisticalCodeApiCall = async()=>{

    const token = await getStorageData('authToken');

    const header = {
      token: token,
    };
    const formData = new FormData();
    formData.append("logistical_code[product_tag]",this.state.editdata);
    formData.append("logistical_code[logistical_tag_ids][]",this.state.selectedId);

    const requestMessage2 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editlogisticalTagCallId = requestMessage2.messageId;
    const endPoint = `${configJSON.editLogisticalTagListApiEndPoint}/${this.state.selectedIdd}`
    requestMessage2.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage2.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage2.id, requestMessage2);

    return true;
  }

  // Customizable Area End
}
