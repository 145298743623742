import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { ISubcategory } from '../../../packages/blocks/catalogue/src/ProductCatalogueController';
import { back, defaultImage } from '../../../packages/blocks/catalogue/src/assets';

interface SubCategoryCarouselProps {
  images: ISubcategory[];
  navigateWithSub:(parentCat:number,subCate:number)=>void;
}

const SubCategoryCarousel: React.FC<SubCategoryCarouselProps> = ({ images,navigateWithSub }) => {
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(8);
  let pathNames = new URL(document.URL).pathname.split('/')
  let lastElement = pathNames[pathNames.length - 1];
  const [currentIndex, setCurrentIndex] = useState<number>(0); 
  useEffect(() => {
    const updateItemsPerSlide = () => {
      const width = window.innerWidth;
      if (width <= 478) {
        setItemsPerSlide(2);
      }
      else if (width <= 518) {
        setItemsPerSlide(3);
      }
      else if (width <= 590) {
        setItemsPerSlide(4);
      } else if (width <= 960) {
        setItemsPerSlide(5);
      } else if (width <= 1200) {
        setItemsPerSlide(4);
      } else if (width <= 1400) {
        setItemsPerSlide(6);
      } else {
        setItemsPerSlide(8);
      }
    };
    updateItemsPerSlide();

    window.addEventListener('resize', updateItemsPerSlide);
    return () => {
      window.removeEventListener('resize', updateItemsPerSlide);
    };
  }, []);
  const chunkImages = (arr: ISubcategory[], size: number) => {
    const result: ISubcategory[][] = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const imageChunks = chunkImages(images?images:[], itemsPerSlide);

  const handleChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <CarouselContainer>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        dynamicHeight={false}
        useKeyboardArrows={true}
        autoPlay={false}
        interval={3000}
        transitionTime={500}
        swipeable={true}
        emulateTouch={true}
        stopOnHover={false}
        onChange={handleChange} 
        className="custom-carousel"
        renderArrowPrev={(clickHandler) => (
          <CustomArrow onClick={clickHandler}  disabled={currentIndex === 0} >
            <ArrowBackIos className='arrowBack' />
          </CustomArrow>
        )}
        renderArrowNext={(clickHandler) => (
          <CustomArrow onClick={clickHandler}  disabled={currentIndex === imageChunks.length - 1}>
            <ArrowForwardIos className='arrowFor'/>
          </CustomArrow>
        )}
      >
        {imageChunks.map((chunk, index) => (
          <CarouselGrid key={index} style={{}} className="carouselGrid">
            {chunk.length > 0 && chunk.map((image, idx) => (
              <CarouselItem key={idx} onClick={()=>navigateWithSub(image.attributes.parent_category_id,image.attributes.id)}>
                <Box className="slide" style={{ height: '85px', width: '85px' }}>
                  <img src={image.attributes.image||defaultImage} style={{ height: '78px', width: '78px',objectFit:"contain" }} alt={`Slide ${index}-${idx}`} className="third-level-categories-img-active" />
                </Box>
                <Typography className="third-level-categories-text-active" title={image.attributes.name}>{image.attributes.name}</Typography>
              </CarouselItem>
            ))}
          </CarouselGrid>
        ))}
      </Carousel>
    </CarouselContainer>
  );
};
const CarouselContainer = styled('div')({
  width: '100%',
  margin: '0 auto',
  padding: '20px 0',
  "& .carousel-status": {
    display: "none"
  },
  "& .arrowBack":{
   fontSize:"1rem !important"
  },
  "& .arrowFor":{
    fontSize:"1rem !important"
   },
  "& .control-dots": {
    display: "none"
  },
  "& .carouselGrid": {
    width: '95%', margin: 'auto',
    "@media (max-width:1500px)": {
      width: '89%',
    },
  },
  "& .third-level-categories-text-active": {
    fontFamily: "Inter, sans-serif !important",
    fontWeight: 400,
    fontSize: "14px",
    color: "#171A1F",
    textAlign: "center",
    maxWidth:"75px",
    textOverflow:"ellipsis",
    overflow:"hidden",
    whiteSpace:"nowrap"
  },
  
});

const CarouselGrid = styled('div')({
  display: 'flex',
  justifyContent: "center",
  gap: "25px",

  "@media (max-width:1440px)": {
    gap: "26px",
  },

});

const CarouselItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const CustomArrow = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'left',
})<{ left?: boolean }>(({ left }) => ({
  color: 'black',
  padding: '10px',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: "white"
  },
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 2,
  height:"20px",
  width:"20px",
  '&:first-of-type': {
    left: '10px',
  },
  '&:last-of-type': {
    right: '10px',
    "@media (max-width:1440px)": {
      right: '6px',
    },
  },
}));

export default SubCategoryCarousel;
