import React, { useState, ChangeEvent, useEffect } from 'react';
import { Box, Drawer, Grid, IconButton, styled } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { FilterBox } from './Filter';

interface IFilter {
  handleToggleSidebar: () => void;
  catBlock: boolean;
}

const FilterCatalogue: React.FC<IFilter> = (props) => {
  const [priceRangeValue, setPriceRangeValue] = useState<number[]>([130, 160]);

  const valuetext = (value: number) => {
    return `$ ${value}`;
  };
  const handleChange1 = (event: React.ChangeEvent<{}>, value: number | number[]) => {
    const newValue = value as number[];
    setPriceRangeValue(newValue)
  }
  useEffect(()=>{
  },[priceRangeValue])

  return (
    <>
      <MasterFinances>
        <IconButton onClick={props.handleToggleSidebar}>
          <Menu />
        </IconButton>
      </MasterFinances>
      <Grid item>
        <CategoryMenu>
          <Drawer
            open={props.catBlock}
            anchor="left"
            onClose={props.handleToggleSidebar}
          >
            <FilterBox valuetext={valuetext} handleChange1={handleChange1} handleToggelBar={props.handleToggleSidebar} priceRangeValue={priceRangeValue}/>
          </Drawer>
        </CategoryMenu>
        <CategoryBLock>
          <FilterBox valuetext={valuetext} handleChange1={handleChange1} handleToggelBar={props.handleToggleSidebar}  priceRangeValue={priceRangeValue}/>
        </CategoryBLock>
      </Grid>
    </>
  );
};

export default FilterCatalogue;


const MasterFinances = styled(Box)({
    display: "none !important",

    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "block !important",
        marginLeft: "15px"
    },

});

const CategoryMenu = styled(Box)({
    display: "none",
    "@media (max-width: 600px)": {
        display: "block !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "block !important",
    },
});

const CategoryBLock = styled(Box)({
    display: "block",
    margin: "30px 30px 0px 30px",

    height: "100%",
    "@media (max-width: 600px)": {
        display: "none !important",
    },
    "@media only screen and (min-width: 600px) and (max-width: 960px) ": {
        display: "none !important",
    },
});
