import React from "react";

import {
  Box,
  Typography,
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { pdfData, download_img } from "./assets";

const DocumentBlock = styled("div")(({ theme }) => ({
  // backgroundColor:"#CBD5E1",
  cursor:"default",
    paddingLeft:"24px",
    "& .buttonIn": {
        position: "relative",
        fontFamily:"Inter,sans-serif"
    },
   "& .label-text": {
        fontSize:"14px",
        fontWeight: "700 !important",
        fontFamily:"Inter,sans-serif"
    },
    "& .input-data-text-edit": {
        margin: "0px",
        // padding: "0px",
        outline: "none",
        fontSize:"16px",
        fontWeight:400,
        width: "570px",
        height: "44px",
        borderRadius: "8px",
        border: "1px solid transparent",
        marginTop: "10px",
        pointerEvents: "none",
        backgroundColor: "#F3F4F6", 
        padding:"10px",
        color: "#BCC1CA", 
        "@media (max-width:900px)": {
          width:"120%",
        },
        boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
      },
    "& .input-data-text": {
      margin: "0px",
      padding: "10px",
      outline: "none",
      fontSize:"16px",
      fontWeight:400,
      width: "570px",
      height: "44px",
      color:"#171A1F",
      borderRadius: "8px",
      backgroundColor: "#F3F4F6", 
      border: "1px solid transparent",
      marginTop: "10px",
      "@media (max-width:900px)": {
        width:"120%",
      },
      boxShadow:"0 0 0 1000px #F3F4F6 inset !important"
      },
    "& .input-data-text:focus": {
        borderColor:"#FF6600",
        outline: "none", 
      },
      "& .btn-data": {
        position: "absolute",
        top: "30px",
        right:"0px",
        borderRadius: "5px",
        zIndex: 2,
        border: "none",
        height: "44px",
        cursor: "pointer",
        color: "#FFFFFF",
        backgroundColor: "#FFA76C",
        padding:"6px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "7px",
        fontSize:"14px",
        fontWeight:400,
        "@media (max-width:900px)": {
          width:"80px",
          right:"0px",
          marginRight:"-40px",
          fontSize:"11px",
        },
    },
    "& .pdf-img": {
        top: "37px",
        left: "9px",
        position: "absolute",
    },
    "& .upl-btn":{
      color:"#FFF",
      backgroundColor:"#EE6900",
      borderRadius:"12px",
      padding:"8px 10px",
      fontWeight:500
    },
    "& .download-btn": {
        position: "absolute",
        top: "38px",
        right:"10px",
        borderRadius: "5px",
        zIndex: 2,
        border: "none",
        height: "30px",
        cursor: "pointer",
        color: "#EE6900",
        backgroundColor: "transparent",
        padding:"6px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "7px",
        fontSize:"14px",
        fontWeight:600
    },
    "& .upload-doc":{
          border: "1px solid #CBD5E1",
          height: "180px",
          width: "42%",
          borderRadius:"8px",
          marginTop:"3px",
          display: "flex",
          flexDirection:"column",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width:900px)": {
            "white-space": "nowrap",
            width:"80%",
          },
        },
        "& .file-size": {
          fontSize:"12px",
          fontWeight:400,
          color:"#64748B",
          marginTop:"10px"
        },
        "& .size-err": {
          fontSize: "13px",
          marginTop: "4px",
          color: "red",
        },
        "& .error-msg": {
          color: "red",
          marginTop: "5px",
          fontSize: "12px"
        },
        "& .main-div":{
          display:"flex", gap:"22px", marginTop:"35px"
        }
  }))
// Customizable Area End

import DocumentsBuyerController, {
  Props,
} from "./DocumentsBuyerController";

export default class DocumentsBuyer extends DocumentsBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() { 
        return (
      // Customizable Area Start
      <DocumentBlock>
        <Box className="main-div" data-test-id="main-div">
            <div className="buttonIn">
                <Typography className="label-text">GSTIN / PAN Verification</Typography>
                <input className={this.props.isEdit ? "input-data-text" : "input-data-text-edit"} type="text" name="gstin_number" id="enter" value={this.state.uploadCertificate.gstin_number}/>
                <button className="btn-data" id="clear">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 7L5.5 10.5L12 4" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
                </svg>
                  <p style={{fontFamily:"Inter,sans-serif"}}>Verified</p>
                </button>
            </div>
        </Box>
        
        {this.props.isEdit ? (
          <Box style={{ marginTop:"30px"}}>
          <Typography className="label-text">Upload GST Document<span style={{ color: "red"}}>*</span></Typography>
          <Box className="upload-doc">
            { this.state.uploadCertificate.file ? (<Typography>{(this.state.uploadCertificate.file  as File).name}</Typography>) : (
              <>
                <Box style={{ display:"flex", alignItems:"center"}}>
                  <Button
                    style={{ textTransform:"lowercase"}}
                    className="upl-btn"
                    component="label"
                  >
                    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                      <g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                        <path d="m4.75 14.75v1.5c0 1.6569 1.34315 3 3 3h8.5c1.6569 0 3-1.3431 3-3v-1.5"/>
                        <path d="m12 14.25v-9.25"/>
                        <path d="m8.75 8.25 3.25-3.5 3.25 3.5"/>
                      </g>
                    </svg>
                  <span style={{ textTransform:"uppercase", marginLeft:"6px"}}>U</span>pload
                  <input
                      id={`fileInput`}
                      data-test-id="file-input"
                      type="file"
                      accept=".pdf" 
                      onChange={this.onFileChange}
                      hidden
                  />
                  </Button>
                  <Typography variant="subtitle2" style={{paddingRight: '10px'}}></Typography>
                  <Box>
                  <div
                      data-test-id="fileDragOrBrowse"
                      onDragOver={this.handleDragOver}
                      onDragLeave={this.handleDragLeave}
                      onDrop={this.handleDrop}
                      style={{ fontSize:"14px", fontWeight:400,height:"48px", display:"flex", alignItems:"center"}}
                  >
                  </div>
                  </Box>       
                </Box>
              <Typography className="file-size"> (Max. File size: 25 MB)</Typography>
              </>
            )}
          </Box>
          <Typography className="error-msg">{this.props.sizeErr}</Typography>
        </Box>
        ) : (
          <Box style={{ display:"flex", gap:"22px", marginTop:"35px"}}>
            <div className="buttonIn">
                <Typography className="label-text">Uploaded Document<span style={{ color:"red"}}>*</span></Typography>
                <img src={pdfData} className="pdf-img"/>
                <input className={this.props.isEdit ? "input-data-text" : "input-data-text-edit"} type="text" id="enter"/>
                <button className="download-btn" data-test-id="download-btn" id="clear" onClick={this.handleDownload}><img src={download_img}/>Download</button>
            </div>
          </Box>
        )}
      </DocumentBlock>
    
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End