import React from "react";
// Customizable Area Start
import { Pagination, PaginationItem } from "@mui/material";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Navbar from "../../../components/src/Navbar.web";

// Customizable Area End

import QuatationsController, {
  Props,
} from "./QuotationsController";
import { Badge, Box, Button, Chip, InputAdornment, InputBase, Menu, MenuItem, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, Typography } from "@material-ui/core";
import SideBar from "../../../components/src/SideBar.web";
import { ExpandMore } from "@material-ui/icons";
import { filterIcon, seacrhIcon, sortingIcon, ViewIcon, rejectIcon, acceptIcon } from "./assets";
import Done from "@material-ui/icons/Done";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { color } from "react-native-reanimated";

export default class Quotations extends QuatationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  quotationtable = () => {
    const tabLabels = [
      "All Quotations",
      "By Individual Purchase",
      "By RFQ Import",
      "Via Cart Links",
    ];

    const additionalDetail = [
      {
        first: {
          customer: "Customer"
        },
        second: {
          Brands: "Brands"
        },
        third: {
          amount: "Amount"
        },
        fourth: {
          lastupdated: "Last Updated",
        },
        fifth: {
          validity: "Validity",
        },
      },
    ];

    const includedprod = [
      {
        prod_included: "Products Included",
        value: "Rectangular Pipe, TMT, Square Pipe, Elbow 3, TMT Bar "
      }
    ]

    const headers = [
      { id: 'quatationId', label: 'Quatation ID' },
      { id: 'customer', label: 'Customer' },
      { id: 'brand', label: 'Brand' },
      { id: 'amount', label: 'Amount' },
      { id: 'status', label: 'Status' },
      { id: 'lastupdated', label: 'Last Updated' },
      { id: 'validity', label: 'Validity' },
      { id: 'action', label: 'Actions' },
    ];

    const { value, currentPage } = this.state;

    return (
      <Box component="nav" style={{ backgroundColor: "#fff" }}>
        <Box style={styles.Maincontainer}>
          <Box style={styles.header}>
            <Typography variant="h6" component="div" style={styles.heading}>
              My Quotation
            </Typography>
          </Box>

          <Box style={{ border: "1px solid #E7E2F0", borderRadius: "6px" }}>
            <Box style={styles.navTabContainer}>
              <Box
                style={{
                  flexGrow: 1,
                  display: "flex",
                  overflowX: "auto",
                  borderRadius: "10px 0px"
                }}
              >
                <Tabs value={value} onChange={this.handleTabChange} TabIndicatorProps={{
                  style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
                }} >
                  {tabLabels.map((label, index) => (
                    <Tab
                      key={index}
                      style={{
                        textTransform: "none",
                        fontFamily: "Inter, sans-serif",
                        minWidth: "80px",
                        padding: "6px 25px",
                        height: "58px",
                      }}
                      label={
                        <Box style={{ display: "flex" }} alignItems="center">
                          <Typography
                            style={
                              value === index
                                ? { ...styles.activeTab, textAlign: 'center' }
                                : { ...styles.inactiveTab, textAlign: 'center' }
                            }
                          >
                            {label}
                          </Typography>
                          {index < 1 && (
                            <Badge badgeContent={this.badgecount(this.state.totalResults)} color="error" style={styles.badge} />
                          )}
                        </Box>
                      }
                    />
                  ))}
                </Tabs>
              </Box>
              <Box style={styles.searchContainer2}>
                <InputBase
                  placeholder="Search"
                  style={styles.searchInput1}
                  inputProps={{
                    style: {
                      fontFamily: 'Inter, sans-serif',
                      fontSize: 12,
                      fontWeight: 400,
                      color: "rgba(0, 0, 0, 0.87)",
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <img src={seacrhIcon} alt="search icon" style={{ width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
                <img src={sortingIcon} data-testId="filterIcon"
                  alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "18px" }} />
                <IconButton>
                  <img src={filterIcon} data-testId="MoreVertIcon"
                    alt="filter icon" style={{ width: 20, height: 20, marginRight: "1px" }} />
                </IconButton>
              </Box>
            </Box>

            <TableContainer style={styles.tableContainer}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={styles.TableHead}>
                    <TableCell style={{ border: 'none', width: '1%' }}></TableCell>
                    {headers.map(({ id, label }) => (
                      <TableCell key={id} align="left" style={{ ...styles.TableCellHead, borderTop: "none", borderBottom: "none" }}>
                        {label}
                      </TableCell>
                    ))}
                    <TableCell style={{ width: "1%", borderBottom: "none" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={styles.TBody}>
                  <TableRow style={{ height: "40px" }}></TableRow>
                  {this.state.QuotationitemList.map((row, rowIndex) => (
                    <React.Fragment >
                      <TableRow
                        style={{
                          ...styles.TableRow,
                          border: "none",
                        }}
                        key={row.id}
                      >
                        <TableCell style={{ ...styles.tableCell, width: "1%", border: "none" }}></TableCell>
                        <TableCell style={{ ...styles.purchaseId, fontFamily: "Inter, sans-serif", borderLeft: "1px solid #E7E2F0", borderTop: "1px solid #E7E2F0" }}>
                          {row.attributes.quotation_id}
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Box>
                            <Typography style={{ ...styles.projectname, fontFamily: "Inter, sans-serif" }}>
                              {row.attributes.Project}
                            </Typography>
                            <Typography variant="caption" style={{ ...styles.companyName, fontFamily: "Inter, sans-serif" }}>
                              {row.attributes.Company}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ ...styles.brand, fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>{row.attributes.brand}</TableCell>
                        <TableCell style={{ ...styles.tableCell }}>
                          <Chip label={row.attributes.amount} style={{ ...styles.amount, fontFamily: "Inter, sans-serif", textTransform: "none", backgroundColor: "white" }} />                        </TableCell>
                        <TableCell style={{ fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>
                          <Chip label={row.attributes.status} style={styles.Statusbutton} />
                        </TableCell>

                        <TableCell style={styles.tableCell}>
                          <Box>
                            <Typography style={{ ...styles.date, fontFamily: "Inter, sans-serif" }}>
                              {row.attributes.last_updated_date}
                            </Typography>
                            <Typography style={{ ...styles.time1, fontFamily: "Inter, sans-serif" }}>
                              {row.attributes.last_updated_time}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell style={{ fontFamily: "Inter, sans-serif", borderTop: "1px solid #E7E2F0" }}>{row.attributes.validity}</TableCell>
                        <TableCell style={{
                          ...styles.tableCell,
                          borderRight: "1px solid #E7E2F0",
                        }}>
                          <IconButton
                            aria-label="more"
                            onClick={(event) => this.handleMenuClick(event, row.id)}
                            data-testId="MoreVertIcon"
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={this.state.anchorEL}
                            keepMounted
                            open={Boolean(this.state.anchorEL)}
                            onClose={this.handleMenuClose}
                            data-testId="menuIcon"
                            PaperProps={{
                              style: { boxShadow: 'none', border: "1px solid #E7E2Ef" },
                            }}
                          >
                            <MenuItem data-testId="negotiatemenuItem" onClick={this.handlepopup} style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
                              Negotiate
                            </MenuItem>
                            <Modal open={this.state.openp} onClose={this.handleclose} BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                            }}>
                              <Box
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "509px",
                                  height: "266px",
                                  boxShadow: "4",
                                  backgroundColor: "white",
                                  padding: 3,
                                  borderRadius: "8px",
                                  textAlign: "center",
                                }}
                              >
                                <Box style={{ marginTop: "50px" }}>
                                  <IconButton
                                    onClick={this.handleclose}
                                    sx={{ position: "absolute", top: 8, right: 8 }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  <div style={{
                                    position: "relative",
                                    width: 64,
                                    height: 64,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "225px"
                                  }}>

                                    <div style={{
                                      position: "absolute",
                                      width: 60,
                                      height: 60,
                                      backgroundColor: "#FFEFE4",
                                      borderRadius: "50%",
                                    }}></div>

                                    <div style={{
                                      position: "absolute",
                                      width: 40,
                                      height: 40,
                                      backgroundColor: "#EE6900",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}>
                                      <Done style={{ color: "white", fontSize: 24 }} />
                                    </div>
                                  </div>
                                  <Typography variant="h6" style={{ marginTop: "20px", fontSize: "24px", fontWeight: "bolder" }}>
                                    Negotiation Request Sent
                                  </Typography>
                                  <Typography variant="body2" style={{ marginTop: "8px", width: "70%", marginLeft: "75px", color: "gray" }}>
                                    Negotiation request has been sent for QID52421458.
                                    You will get notified once vendor updates the price.
                                  </Typography>
                                </Box>
                              </Box>
                            </Modal>
                            <MenuItem data-testId="acceptmenuItem" onClick={this.handleAcpopups} style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
                              Accept
                            </MenuItem>
                            <Modal open={this.state.openAc} onClose={this.handleAccloses} BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                            }}>
                              <Box
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "600px",
                                  backgroundColor: "white",
                                  boxShadow: "24",
                                  padding: 3,
                                  borderRadius: 2,
                                  textAlign: "center",
                                  height: "322px"
                                }}
                              >
                                <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center", borderBottom: "1px solid #E2E8F0" }}>
                                  <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                                    Accept
                                  </Typography>
                                  <IconButton
                                    onClick={this.handleAccloses}
                                    style={{ marginRight: "20px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>



                                </Box>

                                <Typography variant="body1" style={{ marginTop: "10px", color: "gray", marginLeft: "20px", textAlign: "initial", width: "85%" }}>
                                  Are you sure want to accept partial quotations from{" "}
                                  <strong>KEI Cable vendor?</strong> Once
                                  accepted quotation will be sent for PO generation.
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 2,
                                    p: 2,
                                    bgcolor: "#f8f9fa",
                                    width: "80%",
                                    borderRadius: 1,
                                  }}
                                >
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Vendor Name
                                    </Typography>

                                    <Typography variant="body2" >
                                      Kei
                                    </Typography>

                                  </Box>
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Accepted Items
                                    </Typography>

                                    <Typography variant="body2">
                                      1
                                    </Typography>

                                  </Box>
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Quantity
                                    </Typography>

                                    <Typography variant="body2">
                                      4
                                    </Typography>

                                  </Box>
                                </Box>
                                <Box style={{ height: "104px", display: "flex", justifyContent: "end", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                                  <img src={acceptIcon} style={{ marginRight: "20px" }} onClick={() => this.acceptQuotationComparisonItems(this.state.selectedId, "partially_converted")} data-testId="Rejectbutton" alt="reject" />
                                </Box>
                              </Box>
                            </Modal>
                            <MenuItem data-testId="RejectmenuItem" onClick={this.handlepopups} style={{ ...styles.menuitem, fontFamily: "Inter, sans-serif" }}>
                              Reject
                            </MenuItem>
                            <Modal open={this.state.opens} BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                            }} onClose={this.handlecloses}>
                              <Box
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "600px",
                                  backgroundColor: "white",
                                  boxShadow: "24",
                                  padding: 3,
                                  borderRadius: 2,
                                  textAlign: "center",
                                  height: "322px"
                                }}
                              >
                                <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center", borderBottom: "1px solid #E2E8F0" }}>
                                  <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                                    Reject
                                  </Typography>
                                  <IconButton
                                    onClick={this.handlecloses}
                                    style={{ marginRight: "20px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>

                                <Typography variant="body1" style={{ marginTop: "10px", color: "gray", marginLeft: "20px", textAlign: "initial", width: "85%" }}>
                                  Are you sure you want to reject all quotations for{" "}
                                  <strong>EST-240218-0010-2?</strong> Once rejected, this quotation ID
                                  won’t be available for further actions.
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    mt: 2,
                                    p: 2,
                                    bgcolor: "#f8f9fa",
                                    width: "80%",
                                    borderRadius: 1,
                                  }}
                                >
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Quotation ID
                                    </Typography>
                                    <Typography variant="body2" >
                                      QID52421458
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Rejected Vendors
                                    </Typography>
                                    <Typography variant="body2" >
                                      04
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                                      Quantity
                                    </Typography>
                                    <Typography variant="body2">
                                      80
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box style={{ height: "104px", display: "flex", justifyContent: "end", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                                  <img src={rejectIcon} style={{ marginRight: "20px" }} onClick={this.handleReject} data-testId="Rejectbutton" alt="reject" />
                                </Box>
                              </Box>
                            </Modal>

                            <Modal open={this.state.openss} onClose={this.handleActcloses} BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                            }}>
                              <Box
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "600px",
                                  backgroundColor: "white",
                                  boxShadow: "24",
                                  padding: 3,
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  height: "590px",
                                  fontFamily: "Inter, sans-serif"

                                }}
                              >
                                <Box style={{ display: "flex", justifyContent: "space-between", height: "80px", alignItems: "center", fontFamily: "Inter, sans-serif, sans-serif", borderBottom: "1px solid #E2E8F0" }}>
                                  <Typography variant="h6" style={{ fontWeight: "bold", marginLeft: "20px" }}>
                                    Add Details
                                  </Typography>
                                  <IconButton
                                    onClick={this.handleActcloses}
                                    style={{ marginRight: "20px" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                                <Typography style={{ marginLeft: "20px", display: "flex", fontFamily: "Inter, sans-serif", justifyContent: "space-between", height: "40px", alignItems: "center", }}>
                                  Please and natoes and Terms & Conditions,
                                </Typography>

                                <Typography style={{ marginLeft: "20px", display: "flex", fontFamily: "Inter, sans-serif", justifyContent: "space-between", fontWeight: "bolder", height: "30px", alignItems: "center", }}>
                                  Notes
                                </Typography>
                                <Box style={{ backgroundColor: "#F3F4F6", borderRadius: "8px", fontFamily: "Inter, sans-serif", height: "140px", width: "552px", display: "flex", marginLeft: "20px" }}>
                                  <Typography variant="body1" style={{ marginTop: "5px", color: "black", marginLeft: "10px", textAlign: "initial", width: "528px", height: "100px" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing erit. Integer malesuada condimentum odio vitae malesuada. Proin quis ligula eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                                    Nullam molis odio sem, vel lacinia massa
                                  </Typography>
                                </Box>

                                <Typography style={{ marginLeft: "20px", fontFamily: "Inter, sans-serif", fontWeight: "bolder", display: "flex", justifyContent: "space-between", marginTop: "10px", height: "30px", alignItems: "center", }}>
                                  Terms and Conditions
                                </Typography>
                                <Box style={{ backgroundColor: "#F3F4F6", borderRadius: "8px", height: "140px", width: "552px", fontFamily: "Inter, sans-serif", display: "flex", marginLeft: "20px" }}>

                                  <Typography variant="body1" style={{ marginTop: "5px", color: "black", marginLeft: "10px", textAlign: "initial", width: "528px", height: "100px" }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing erit. Integer malesuada condimentum odio vitae malesuada. Proin quis ligula eget lacus luctus pretium. Mauris hendrerit justo vel posuere eleifend.
                                    Nullam molis odio sem, vel lacinia massa
                                  </Typography>
                                </Box>


                                <Box style={{ height: "90px", fontFamily: "Inter, sans-serif", marginTop: "30px", display: "flex", justifyContent: "end", alignItems: "center", borderTop: "1px solid #E2E8F0" }} >

                                  <Button onClick={this.handleSuccesspopups} variant="contained" size="large" style={{ marginRight: "20px", fontWeight: "bold", width: "120px", height: "44px", backgroundColor: "#FD8C3F", borderRadius: "12px", color: "white" }} >
                                    Add
                                  </Button>
                                </Box>
                              </Box>
                            </Modal>
                            <Modal open={this.state.openssc} onClose={this.handleSuccesscloses} BackdropProps={{
                              style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
                            }}>
                              <Box
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  width: "509px",
                                  height: "266px",
                                  backgroundColor: "background.paper",
                                  boxShadow: "4",
                                  background: "white",
                                  padding: 3,
                                  borderRadius: "8px",
                                  textAlign: "center",
                                }}
                              ><Box style={{ marginTop: "50px" }}>
                                  <IconButton
                                    onClick={this.handleSuccesscloses}
                                    sx={{ position: "absolute", top: 8, right: 8 }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                  <div style={{
                                    position: "relative",
                                    width: 64,
                                    height: 64,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "225px"
                                  }}>

                                    <div style={{
                                      position: "absolute",
                                      width: 60,
                                      height: 60,
                                      backgroundColor: "#FFEFE4",
                                      borderRadius: "50%",
                                    }}></div>

                                    <div style={{
                                      position: "absolute",
                                      width: 40,
                                      height: 40,
                                      backgroundColor: "#EE6900",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}>
                                      <Done style={{ color: "white", fontSize: 24 }} />
                                    </div>
                                  </div>
                                  <Typography variant="h6" style={{ marginTop: "20px", fontSize: "24px", fontWeight: "bolder" }}>
                                    Quotation Request Accepted
                                  </Typography>
                                  <Typography variant="body2" style={{ marginTop: "8px", width: "70%", marginLeft: "75px", color: "gray" }}>
                                    Quotation request has been accepted for QID52421458 and PO is generated.
                                    Please submit relevant PO to proceed further.
                                  </Typography>
                                </Box>
                              </Box>
                            </Modal>
                          </Menu>
                        </TableCell>
                        <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                      </TableRow>
                      <TableRow
                        style={{
                          ...styles.TableRow,
                          border: "none",
                        }}
                      >
                        <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                        <TableCell colSpan={8} style={{ margin: "0px 20px", borderLeft: "1px solid #E7E2F0" }}>
                          <Box style={styles.flexContainer}>
                            <Box>
                              <Typography style={{ ...styles.poid, fontFamily: "Inter, sans-serif", float: "left" }}>{row.attributes.quotation_id}</Typography>
                              <Box style={styles.buttonContainer}>
                                <Chip label="Negotiation" style={styles.firstChip} />
                                <Chip label={`Received: ${new Date().toLocaleDateString()}`} style={styles.secondchip} />
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  marginRight: "22px"
                                }}
                              >
                                <IconButton
                                  onClick={() => this.handleExpandClick(row.id)}
                                  data-testId="ExpandMoreButton"
                                  style={{
                                    transition: 'transform 0.3s ease',
                                    transform: this.state.expandedRows[row.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                                  }}
                                >
                                  <ExpandMore />
                                </IconButton>
                                <Button
                                  variant="contained"
                                  style={{ ...styles.viewbutton, fontFamily: "Inter, sans-serif", textTransform: "none", boxShadow: "none" }}
                                  data-testId="viewButton"
                                  onClick={() => this.handleViewClick(row.id)}
                                >
                                  <img src={ViewIcon} alt="viewicon" style={{ marginRight: "5px" }} />
                                  View
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                      </TableRow>
                      {this.state.expandedRows[row.id] && this.state.QuotationitemList &&
                        additionalDetail.map((column, index) => (
                          <>
                            <TableRow key={index} style={{ ...styles.TableRow, border: "none" }}>
                              <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                              <TableCell
                                colSpan={8}
                                style={{
                                  ...styles.TableCell,
                                  borderRight: "1px solid #E7E2F0",
                                  borderLeft: "1px solid #E7E2F0",
                                }}
                              >
                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0px 2px", }}>
                                  <Box style={{ ...styles.Product, marginLeft: "50px" }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.first.customer}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.Project}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue1, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.Company}
                                    </Typography>
                                  </Box>
                                  <Box style={{ ...styles.Product }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.second.Brands}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue1, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.brand}
                                    </Typography>
                                  </Box>
                                  <Box style={{ ...styles.Product }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.third.amount}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue1, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.amount}
                                    </Typography>
                                  </Box>
                                  <Box style={{ ...styles.Product }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.fourth.lastupdated}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.last_updated_date}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue1, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.last_updated_time}
                                    </Typography>
                                  </Box>
                                  <Box style={{ ...styles.Product }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.fifth.validity}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue1, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.validity}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                            </TableRow>
                          </>
                        ))}
                      {this.state.expandedRows[row.id] && this.state.QuotationitemList &&
                        includedprod.map((column, index) => (
                          <>
                            <TableRow key={index} style={{ ...styles.TableRow, border: "none" }}>
                              <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                              <TableCell
                                colSpan={8}
                                style={{
                                  ...styles.TableCell,
                                  borderRight: "1px solid #E7E2F0",
                                  borderLeft: "1px solid #E7E2F0",
                                }}
                              >
                                <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", margin: "0px 2px", }}>
                                  <Box style={{ ...styles.Product, marginLeft: "50px" }}>
                                    <Typography style={{ ...styles.producttitle, fontFamily: "Inter, sans-serif" }}>
                                      {column.prod_included}
                                    </Typography>
                                    <Typography style={{ ...styles.productvalue, fontFamily: "Inter, sans-serif" }}>
                                      {row.attributes.quotation_items.product_name}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell style={{ width: "0%", border: "none" }}></TableCell>
                            </TableRow>
                          </>
                        ))}
                    </React.Fragment>
                  ))}
                  <TableRow>
                    <TableCell style={{ width: "1%", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderLeft: "1px solid #E7E2F0" }} colSpan={8}>
                      <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between" }}>
                         <Typography style={{ fontSize: "12px", fontWeight: "normal", color: "#64748B" }}>
                                                    {this.getResultTextApplication(this.state.currentPage)}
                          </Typography>

                        <Pagination
                          count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                          page={this.state.currentPage}
                          onChange={this.handlePageChange}
                          siblingCount={0}
                          boundaryCount={1}
                          shape="rounded"
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              style={{
                                minWidth: "31px",
                                height: "36px",
                                borderRadius: "12px",
                                fontFamily: "Inter, sans-serif",
                                fontSize: "12px",
                                fontWeight: 500,
                                color: item.selected ? "#FFFFFF" : "#475569",
                                backgroundColor: item.selected ? "#FF6600" : "#F3F4F6",
                                border: "1px solid #E2E8F0",
                                boxShadow: item.selected ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
                              }}
                            />
                          )}
                        />



                      </div>
                    </TableCell>
                    <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderTop: "none", borderRight: "none", borderBottom: "none" }}></TableCell>
                  </TableRow>

                  <TableRow style={{ height: "40px" }}></TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box >
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (<Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} />
      <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
        {this.quotationtable()}
      </SideBar>
    </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  Maincontainer: {
    width: "94%",
    margin: "0px 20px 0px 45px",
    fontFamily: "Inter, sans-serif",
  },
  header: {
    alignItems: "left",
    margin: "45px 0px"
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    borderRadius: "8px",
    backgroundColor: "F3F4F6"
  },
  navTabContainer: {
    fontfamily: "Inter, sans-serif",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  badge: {
    backgroundColor: "#DE3B40",
    color: "white",
    marginLeft: "10px",
    fontSize: "10px",
    fontWeight: 400,
    padding: "0px 5px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  TableHead: {
    backgroundColor: "#F3F4F6",
  },
  TableCellHead: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableCell: {
    borderTop: "1px solid #E7E2F0"
  },
  firstChip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: 400,
    backgroundColor: "#DE3B409E",
    color: "#ffffff"
  },

  secondchip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
    color: "#E7A573",
    border: "1px solid #E7A573",
  },
  thirdchip: {
    fontfamily: "Inter, sans-serif",
    height: "23px",
    borderRadius: "6px",
    fontSize: "11px",
    fontWeight: "400",
    backgroundColor: "#E7A573",
    color: "#FFFFFF",
  },
  TableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F9F9F9",
    },
  },
  TableCell: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    color: "#101828",
    borderTop: "1px solid #E2E8F0",
    width: "100%",
  },
  searchContainer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 12px 0 0",
  },
  searchInput1: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",
  },
  purchaseId: {
    fontSize: "12px",
    fontWeight: 700,
  },
  Project: {
    fontSize: "13px",
    fontWeight: 700,
  },
  companyName: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#64748B"
  },
  amount: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "26px",
    color: "#323842"
  },
  projectname: {
    fontSize: "13px",
    fontWeight: 700,
  },
  Statusbutton: {
    height: "32px",
    borderRadius: "9px",
    backgroundColor: "#C3C7031A",
    color: "#C3C703",
    fontSize: "12px",
    fontWeight: 400,
  },
  brand: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842"
  },
  date: {
    fontSize: "13px",
    fontWeight: 700,
    width: "120px"
  },
  time: {
    fontSize: "12px",
    fontWeight: 300,
    color: "#64748B"
  },
  time1: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#BCC1CA"
  },
  imagedropdown: {
    width: "18px",
    height: "18px",
    marginRight: "5px"
  },
  menu: {
    hight: "120px",
  },
  menuitem: {
    fontSize: "14px",
    fontWeight: 400,
  },
  tableContainer: {
    borderRadius: "4px",
    boxShadow: "none",
  },
  TBody: {
    width: "94%",
    margin: "0 auto",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    marginTop: "42px",
    marginBottom: "0px",
    display: "flex",
    width: "474px",
    gap: "8px",
  },
  poid: {
    fontSize: "12px",
    fontWeight: 700,
  },
  viewbutton: {
    fontSize: "13px",
    fontWeight: 400,
    width: "130px",
    height: "36px",
    borderRadius: "12px",
    color: "#E7A573",
    backgroundColor: "#FFFFFF",
    marginTop: "5px"
  },
  Product: {
    width: "100%",
    marginTop: "10px",
    marginRight: "0px",
  },
  producttitle: {
    fontSize: "12px",
    lineHeight: '22px',
    fontWeight: 500,
    color: "#323842",
    marginLeft: "-43px",
  },
  productvalue: {
    fontSize: "10px",
    lineHeight: '18px',
    fontWeight: 400,
    color: "#323842",
    marginLeft: "-43px"
  },
  productvalue1: {
    lineHeight: '18px',
    fontSize: "10px",
    fontWeight: 400,
    color: "#9095A0",
    marginLeft: "-43px"
  },
  validity: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#323842"
  },
  purchaseID: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842",
    marginLeft: "50px"
  },
  Purchasehead: {
    marginRight: "20px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B",
  },
  Scheduled: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#323842",
    marginLeft: "50px"
  },
  DeliveryDate: {
    marginTop: "8px",
    marginRight: "30px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#67748B"
  },
  Floorchip: {
    backgroundColor: "#F0F6FF",
    fontSize: "11px",
    fontWeight: 400,
    color: "#579AFF"
  }
}
// Customizable Area End
