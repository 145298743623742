import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../framework/src/Utilities";
import React, { ChangeEvent,MouseEvent } from "react";
interface IndustryItem {
  id: string;
  type: string;
  attributes: {
    [x: string]: any;
    application_group_name:string
  };
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  children?: React.ReactNode;
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  selectedFile:any;
  openDialog: boolean;
  alertmessage: string;
  industryData3: IndustryItem[];
  anchorEl: HTMLElement | null;
  totalResults2: number;
  resultperPage2 : number;
  currentPage2: number;
  imageUrl:any;
  industry2: string;
  selectedIndustryId:string;
  selectedIndustryName:string;
  IndustryId:any;
  dialogMode2:string;
  industryName2:any;
  token: string;
  alertVisible: boolean;
  value: number | 0;

  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationGroupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addGroupApiCallId: any;
  getGroupApiCallId: any;
  editGroupApiCallId:any;
  deleteGroupApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      industryData3: [],
      IndustryId:0,
      dialogMode2: "",
      imageUrl:"",
      alertmessage: "",
      alertVisible: false,
      industryName2:"",
      openDialog: false,
      anchorEl: null,
      selectedFile:"",
      industry2: "",
      selectedIndustryId:"",
      selectedIndustryName:"",
      token: "",
      totalResults2: 0, 
      resultperPage2: 12, 
      currentPage2: 1 ,
      value: 0,
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount?.();
    const Industry=localStorage.getItem('industryname')
    this.setState({industryName2:Industry})
    this.getApplicationGroup();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
  
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
        if (apiRequestCallId === this.addGroupApiCallId) {
          this.handleAddGroupResponse(responseJson);
        } 
        else if (apiRequestCallId === this.getGroupApiCallId) {
          this.handleGetGroupResponse(responseJson);
        }
        else if (apiRequestCallId === this.editGroupApiCallId) {
          this.handleEditGroupResponse(responseJson);
        }
        else if (apiRequestCallId === this.deleteGroupApiCallId) {
          this.handleDeleteGroupResponse(responseJson);
        }
      }
  }

  handleEditGroupResponse(responseJson: any) {
    if (responseJson?.product?.data) {
      this.setState({ 
        openDialog: false,
        anchorEl: null,
        industry2: "",
      });
      this.getApplicationGroup(); 
      this.showAlert(
        configJSON.successTitle,
        configJSON.successEditIndustry
      );
    } else {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorEditIndustry
      );
    }
  }
  
  handleDeleteGroupResponse(responseJson: any) {
      this.setState({ 
        openDialog: false, 
        anchorEl: null,
        industry2:"",
      });
      this.getApplicationGroup(); 
    
  }

  handleToggleSidebar = () => { }
  handleAddGroupResponse(responseJson: any) {
    if (responseJson?.data) {
      this.setState({ 
        industryData3: responseJson?.data, 
        openDialog: false, 
        industry2: "" 
      });
      this.getApplicationGroup(); 
    }
  }
  
  handleGetGroupResponse(responseJson: any) {
    if (responseJson?.data) {
      const { count, items, page } = responseJson.meta?.pagy || {};     
      this.setState({
        industryData3: responseJson.data, 
        totalResults2: count || 0,       
        resultperPage2: items || 12,    
        currentPage2: page || 1,       
      });
    }
  }
  handleMenuClickApplicationGroup = (event: MouseEvent<HTMLElement>,selectedIndustryId:string,selectedIndustryName:string,selectedImageUrl: string) => {
    console.log("imageURL: ",selectedImageUrl)
    this.setState({ anchorEl: event.currentTarget, IndustryId:selectedIndustryId,industry2: selectedIndustryName,  imageUrl: selectedImageUrl});

  };

  handleMenuCloseApplicationGroup = (): void => {
    this.setState({ anchorEl: null });
  };


  handlePageChangeApplicationGroup = (event: React.ChangeEvent<unknown> | null, value: number) => {
    this.setState({ currentPage2: value });
    this.getApplicationGroup();
  };

  getResultTextApplicationGroup = (page: number): string => {
    const { resultperPage2, totalResults2 } = this.state;
    const start = (page - 1) * resultperPage2 + 1;
    const end = Math.min(start + resultperPage2 - 1, totalResults2);
    return `${start}-${end} of ${totalResults2} results`;
  }


  handleComOpenApplicationGroup = (mode: string ) => {
    

    this.setState({
      openDialog: true,
      dialogMode2: mode, 
     
    });
    
  };
  
  handleCloseApplicationGroup = () => {
    this.setState({
      openDialog: false,
      anchorEl: null,
      dialogMode2: "",
      alertmessage: "",
      alertVisible: false,
      industry2:"",
    });
  };
  handleIndustryMarketApplicationGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value
    this.setState({ industry2: name })
  }

  handleNavigateApplicationGroup = (route?: string) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      route
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)

  }
 
  showAlert = (title: string, message: string) => {
    this.setState({ alertVisible: true, alertmessage: message });
  };

  handleFileChangeApplicationGroup = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file) {
      this.setState({selectedFile:file});
      console.log("Selected File:", file.name); 
    }
  }; 

  navigateToApplicationGroup = (path:string ,id:string,application_group:string)=> {
  localStorage.setItem("application_group",application_group)
  const fullPath = `${path}?id=${id}`;
  console.log(fullPath, "Navigating to");
  window.location.href = fullPath;
  }

  navigateTooApplicationGroup = (path:string)=> {
    window.location.href = path;
    }
    

  addNewApplicationGroup = async () => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
   
    const formData = new FormData();

    formData.append('application_group[application_group_name]',this.state.industry2);
    formData.append('application_group[industry_market_id]', id || "1");
    formData.append('application_group[application_group_file]',this.state.selectedFile); 
    
      const header = {
        token: await getStorageData('authToken'),
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.applicationGroupAddAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };

  getApplicationGroup = async () => {
   
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    
  
    const header = {
      "Content-Type": configJSON.industryApiContentType,
      token: await getStorageData("authToken"),
    };

    const queryParams = `?id=${id}&page=${this.state.currentPage2}&items=${this.state.resultperPage2}`;
    const apiEndpoint = `${configJSON.applicationGroupGetAPIEndPoint}${queryParams}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getGroupApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  editApplicationGroup = async () => {
  
    const formData = new FormData();

    formData.append('application_group[application_group_name]',this.state.industry2);
    
    formData.append('application_group[application_group_file]',this.state.selectedFile); 
    

      const header = {
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.applicationGroupEditAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.editGroupApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpUpdateType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };

  deleteApplicationGroup = async () => {
      const header = {
        "Content-Type": configJSON.industryApiContentType,
        token: await getStorageData("authToken"),
      };
  
      const apiEndpoint = `${configJSON.applicationGroupDeleteAPIEndPoint}/${this.state.IndustryId}`;
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.deleteGroupApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiEndpoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    
  };
  
  
   
  // Customizable Area End
}
