import React from "react";


import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
  TableCell,
  MenuItem,
  InputAdornment,
  Select,
  TableFooter,
  IconButton,
  styled,
  Chip,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import ProductVariationController, { Field, IUpiList, Props } from "./ProductVariationController";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChipInput from 'material-ui-chip-input';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from "@mui/material";
import { seacrhIcon } from "../../../../packages/blocks/brandpages/src/assets";

const StyledSelect = styled(Select)({
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent !important',
  },
  "& .MuiSelect-icon": {
    right: 10
  }
});

const StyledMenuItem = styled(MenuItem)({
  borderBottom: "1px solid #CBD5E1"
});

const StyledContainer = styled(Box)({
  marginLeft: "47px",
  fontFamily: "Inter, sans-serif",
  width: "94%",
  "@media(max-width:1200px)": {
    marginLeft: "10px"
  },
  "@media(max-width:660px)": {
    margin: "auto"
  },
  "& .formItem": {
    width: '100%',
    marginTop: "20px",
    marginLeft: '20px',
    marginBottom: '15px',
    "@media(max-width:1057px)": {
      marginLeft: '10px',
      width: "auto"
    },
    "@media(max-width:374px)": {
      width: "94%"
    }
  },
  "& .formItems": {
    display: "flex",
    justifyContent: "space-between",
    width: '100%' as string,
    marginTop: "20px",
    marginLeft: '20px',
    marginBottom: '15px',
    "@media(max-width:1057px)": {
      marginLeft: '10px',
      width: "auto",
      flexWrap: "wrap"
    },
  },
  "& .productCriteria": {
    width: "46%",
    "@media(max-width:1057px)": {
      width: "98%",
    }
  },
  "& .addAnotherVariation": {
    display: "flex",
    marginLeft: "17px",
    marginTop: "16px",
    "@media(max-width:1057px)": {
      marginLeft: "10px",
    }
  },
  "& .errorText": {
    color: "#DC2626",
    fontSize: "12px",
    paddingTop: "4px",
    paddingLeft: "7px"
  },
  "& .productVariationHead": {
    marginLeft: "20px",
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
    marginBottom: "30px",
    marginTop: "20px",
    "@media(max-width:600px)": {
      marginLeft: "10px",
    },
  },
  "& .attachedUpiVersion": {
    height: "49px",
    "@media(max-width:600px)": {
      fontSize: "10px",
    },
  },
  "& .TableContainer": {
    border: "1px solid #E2E7F0",
    borderBottom: "none",
    borderRadius: "8px",
    width: "96%",
    marginLeft: "20px",
    fontFamily: "Inter, sans-serif",
    overflowX: "scroll",
    "@media(max-width:600px)": {
      marginLeft: "10px",

    },
  },
  "& .menuItemTable": {
    "@media(max-width:600px)": {
      marginLeft: "8px"
    },
  },
  "& .cancelOrder": {
    backgroundColor: "#BDC1CA",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "12px",
    height: "40px",
    textTransform: "none",
    boxShadow: "none",
  },
  "& .SaveButton": {
    backgroundColor: "#ff6600",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "12px",
    height: "40px",
    textTransform: "none",
    boxShadow: "none",
    marginLeft: "20px"
  },
  "& .searchIcon": {
    height: 24,
    width: 24
  },
  "& .StyledAutoComplete": {
    width: "96% !important",
    height: "44px !important",
    borderRadius: "8px"
  }
})

const StyledChipInput = styled(ChipInput)({
  width: "100% !important",
  backgroundColor: "#F3F4F6",
  padding: "6px 8px",
  borderRadius: "8px",
  outline: "none",
  border: "none",
  '& input': {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Inter, sans-serif',
    color: '#BCC1CA',
    borderRadius: '8px',
    width: "100%",
    outline: "none",
    border: "none",
  },
  '& .MuiChip-root': {
    borderRadius: '4px',
    margin: '4px',
    backgroundColor: '##DDDFE1',
    color: '#171A1F',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    height: 24
  },
  '& .MuiChip-deleteIcon': {
    color: 'black',
  },
  '& .MuiInputBase-root': {
    borderRadius: '8px',
    borderBottom: 'none',
  },
  '& .MuiInput-underline:before': {
    borderBottom: 'none',
  },
  '& .MuiInput-underline:after': {
    borderBottom: 'none',
  },
});

const StyledTableCell = styled(TableCell)({
  "@media(max-width:600px)": {
    padding: "5px"
  },
})

const StyledTextField = styled(TextField)({
  fontSize: "16px",
  fontWeight: 400,
  fontFamily: "Inter, sans-serif",
  padding: "6px  7px",
  '& .MuiInputBase-input': {
    padding: "6px 7px",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Inter, sans-serif",
    color: "#171A1F",
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingRight: "15px !important",
  },
})
// Customizable Area End

export default class ProductVariation extends ProductVariationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  cartTable = () => {
    const { showVariations, showVariations1 } = this.state;
    const UPIDatas = this.state.upiListData.map((item) => ({
      id: item.id,
      name: item.name,
    }));

    return (
      <StyledContainer>
        <Box style={webStyle.header}>
          <Typography variant="h6" style={webStyle.heading}>
            Add Product Variation
          </Typography>
          {(this.state.showVariations && this.state.notshow && !this.state.regenBtn) && (
            <Button
              variant="contained"
              style={{
                ...webStyle.SaveButton,
                textTransform: "none",
                boxShadow: "none",
              }}
              onClick={this.handleSave}
            >
              Save
            </Button>
          )}
          {this.state.showEdit && !this.state.regenBtn && (
            <Button
              variant="contained"
              style={{
                ...webStyle.EditButton,
                textTransform: "none",
                boxShadow: "none",
              }}

            >
              Edit
            </Button>
          )}
          {this.state.regenBtn && (
            <Button
              variant="contained"
              className="cancelOrder"
              onClick={this.handleCancelChanges}
              data-test-id="CancelBtn"
            >
              Cancel Changes
            </Button>
          )}
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <TableContainer style={{ overflowX: "hidden" }}>
            <Table aria-label="Industry Market Table" style={{ overflow: 'hidden', height: '340px' }}>
              <TableBody style={webStyle.Tbody}>
                <Box className="formItem">
                  <Box style={{ display: "flex" }}>
                    <Typography style={webStyle.label}>UPID Name</Typography>
                    <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                  </Box>
                  <Autocomplete
                    disablePortal
                    id="catalogue-field"
                    options={UPIDatas}
                    style={{
                      ...webStyle.formField,
                    }}
                    className="StyledAutoComplete"
                    data-test-id="autoComplete"
                    value={this.state.values}
                    open={this.state.openMenuItem}
                    onOpen={this.toggleOpen}
                    onClose={this.toggleOpen}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder="Search UPID Name"
                        value={this.state.values}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style:{
                            ...webStyle.formField,
                          },
                          endAdornment: (
                            <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" className="searchIcon" onClick={this.toggleOpen} />
                          ),
                        }}
                      />
                    )}
                    onChange={(
                      event: React.SyntheticEvent,
                      value: IUpiList | null,
                    ) => {
                      this.handleUPIChanges(value as IUpiList);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  {this.state.errors.upid && <Typography className="errorText">{this.state.errors.upid}</Typography>}
                </Box>
                <Box style={webStyle.formGrid}>
                  {this.state.fields.map((field: Field, index: number) => (
                    <Box className="formItems" key={index}>
                      <Box className="productCriteria">
                        <Typography style={webStyle.label}>Product Criteria <span style={{ color: "#DC2626", fontWeight: 700, marginTop: "2px" }}>*</span>
                        </Typography>
                        <StyledSelect
                          value={field.productCriteria || ""}
                          onChange={(e) => this.handleCriteriaChange(index, e.target.value as string)}
                          data-test-id="selectCategory"
                          displayEmpty
                          inputProps={{
                            "data-testid": `product-criteria-select-${index}`,
                            placeholder: "Select Product Criteria",
                            style: {
                              fontWeight: 400,
                              fontSize: "16px",
                              fontFamily: "Inter, sans-serif",
                              color: "black",
                            },
                          }}
                          style={{
                            ...webStyle.formField,
                            width: "100%",
                            borderRadius: "8px",
                            padding: "6px 8px"
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                border: "1px solid #CBD5E1",
                                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",

                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          disableUnderline
                          IconComponent={KeyboardArrowDownIcon}
                        >
                          <StyledMenuItem value="" disabled selected> Select Product Criteria</StyledMenuItem>
                          <StyledMenuItem value="Height" >Height</StyledMenuItem>
                          <StyledMenuItem value="Weight" >Weight</StyledMenuItem>
                          <StyledMenuItem value="Length">Length</StyledMenuItem>
                        </StyledSelect>
                        {this.state.errors.productCriteria[index] && (
                          <Typography className="errorText">
                            {this.state.errors.productCriteria[index]}
                          </Typography>
                        )}
                      </Box>
                      <Box className="productCriteria">
                        <Typography style={webStyle.label}>Values
                        </Typography>
                        <StyledChipInput
                          key={index}
                          disableUnderline
                          data-test-id="categoryValues"
                          value={field.chips}
                          onAdd={(chip) => this.handleAddChip(index, chip)}
                          onDelete={(chipIndex) => this.handleDeleteChip(index, chipIndex)}
                          chipRenderer={({ chip }) => {
                            const chipLabel = String(chip);
                            const chipIndex = field.chips.indexOf(chip);
                            return (
                              <Chip
                                key={chipIndex}
                                label={chipLabel}
                                data-test-id="chipsStyled"
                                onDelete={() => this.handleDeleteChip(index, chipIndex)}
                                deleteIcon={<CloseIcon style={{ height: 12, width: 12 }} />}
                              />
                            );
                          }}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              fontSize: '14px',
                              fontFamily: 'Inter, sans-serif',
                              color: '#1E293B',
                              height: "auto",
                              borderBottom: "none"
                            },
                            placeholder: "Enter values"
                          }}
                        />
                      </Box>
                      <Box style={{ marginTop: "12px",display:"flex",alignItems:"center",height:"75px" }}>
                        {this.state.fields.length > 1 && (
                          <IconButton onClick={() => this.removeField(index)} data-test-id="deleteField">
                            {!this.state.showEdit && <DeleteIcon style={{ color: '#DC2626' }} />}

                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
                {((this.state.fields.length < 3) && !this.state.showVariations) && (
                  <Box className="addAnotherVariation">
                    <Button
                      style={{
                        textTransform: "none",
                        color: "#F97316",
                        fontWeight: 600,
                        fontSize: "14px",
                        fontFamily: "Inter, sans-serif",
                      }}
                      data-test-id="addNewField"
                      onClick={this.addField}
                    >
                      <AddCircleOutlineIcon style={{ marginRight: "8px" }} />
                      Add another Variation
                    </Button>
                  </Box>
                )}

                {this.state.regenBtn && (
                  <Button
                    variant="contained"
                    className="SaveButton"
                    onClick={this.handleRegenerate}
                    data-test-id="regenBtn"
                  >
                    Re-Generate Variations
                  </Button>
                )}

                {showVariations1 && (
                  <Box style={{ fontFamily: "Inter, sans-serif", }}>
                    <Typography variant="h6" className="productVariationHead">
                      Product Variations
                    </Typography>
                    <TableContainer className="TableContainer">
                      <Table>
                        <TableRow style={webStyle.tableHeaderRow}>
                          <StyledTableCell>#</StyledTableCell>
                          <StyledTableCell>Length</StyledTableCell>
                          <StyledTableCell>Width</StyledTableCell>
                          <StyledTableCell>Height</StyledTableCell>
                          <StyledTableCell>Attach UPID Version</StyledTableCell>
                        </TableRow>
                        <TableBody>
                          {this.state.productListVarition.map((row) => (
                            <TableRow
                              key={row.id}
                              style={{
                                borderBottom: "none",
                                border: "1px solid #E2E7F0",
                                fontFamily: "Inter, sans-serif",
                                marginLeft: "10px"

                              }}
                            >
                              <StyledTableCell>{row.id}</StyledTableCell>
                              <StyledTableCell>{row.length}</StyledTableCell>
                              <StyledTableCell>{row.width}</StyledTableCell>
                              <StyledTableCell>{row.height}</StyledTableCell>
                              <StyledTableCell>
                                <StyledSelect
                                  value={row.version}
                                  IconComponent={KeyboardArrowDownIcon}
                                  displayEmpty
                                  inputProps={{
                                    "data-testid": `product-criteria-select`,
                                    style: {
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      fontFamily: "Inter, sans-serif",
                                      backgroundColor: "transparent",
                                      marginLeft: "10px",
                                    },
                                  }}
                                  style={{
                                    width: "100%",
                                    borderRadius: "8px",
                                    backgroundColor: "#F3F4F6",
                                    border: "none",
                                    outline: "none",
                                    boxShadow: "none",
                                    paddingLeft: "10px"
                                  }}
                                  MenuProps={{
                                    PaperProps: {
                                      style: {
                                        maxHeight: 200,
                                        border: "1px solid #E0E0E0",
                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "8px",
                                        marginLeft: "10px"
                                      },
                                    },
                                  }}
                                  disableUnderline
                                  className="attachedUpiVersion"
                                >
                                  {this.state.productListVarition.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      value={item.version}
                                      style={{
                                        marginLeft: "10px",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        fontFamily: "Inter, sans-serif",
                                      }}
                                      className="menuItemTable"
                                    >
                                      {item.version}
                                    </MenuItem>
                                  ))}
                                </StyledSelect>
                              </StyledTableCell>


                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                )}
              </TableBody>
              <TableFooter style={{ borderTop: "1px solid #E1E7EF" }}>
                <TableRow>
                  <TableCell colSpan={3} align="right">
                    {!showVariations && (
                      <Box style={{ display: "flex", justifyContent: "flex-end", margin: "16px" }}>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "#F97316",
                            color: "#fff",
                            fontWeight: 700,
                            fontSize: "16px",
                            fontFamily: "Inter, sans-serif",
                            padding: "8px 16px",
                            borderRadius: "12px",
                            width: "277px",
                            height: "44px",
                            textTransform: "none",
                          }}
                          onClick={this.handleGenerateVariations}
                          data-test-id="generate-btn"
                        >
                          Generate Variations
                        </Button>
                      </Box>
                    )}

                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      </StyledContainer>
    );
  };

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.cartTable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number
  }
} = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",

  },
  formGrid: {

    width: "96%",
  },
  tableHeaderRow: {
    backgroundColor: "#F3F4F6",
    fontWeight: "bold",
    color: "#334155",
  },
  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",

  },
  Tbody: {
    padding: "20px",
    height: "100vh"
  },
  SaveButton: {
    backgroundColor: "#ff6600",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "12px",
    height: "40px",
    width: "99px",
  },
  EditButton: {
    backgroundColor: "#BDC1CA",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "12px",
    height: "40px",
    width: "90px",
  },
  TableCell: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    textAlign: "left",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableBody: {
    width: "1045px",
    padding: "15px",
  },
  label: {
    marginBottom: "5px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "Inter, sans-serif",
  },
  formField: {
    width: '100%',
    height: '49px',
    borderRadius: '8px',
    background: '#F3F4F6',
    boxSizing: 'border-box' as 'border-box',
    color: "#171A1F",
    weight: 400,
    fontFamily: "Inter, sans-serif"
  },



};

// Customizable Area End