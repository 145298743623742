import React from "react";


import {
  // Customizable Area Start
  Typography,
  Box,
  Button,
  Fab,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  InputAdornment,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import SideBar from "../../../components/src/SideBar.web"
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/Add";
import MyCartController, {
  Props
} from "./MyCartController";
import Navbar from "../../../components/src/Navbar.web";
import { filterIcon, seacrhIcon, sortingIcon } from "./assets";
import { defaultImage } from "../../../../packages/blocks/catalogue/src/assets";


// Customizable Area End



export default class Mycart extends MyCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  cartTable = () => {
    const tabData = [
      { label: 'All Results', value: 0 },
      { label: 'By Individual Purchase', value: 1 },
      { label: 'By RFQ Import', value: 2 },
      { label: 'Via Cart Links', value: 3 },
    ];

    return (
      <Box style={{ width: "94%", ...webStyle.mainContainer }}>
        <Box style={webStyle.header}>
          <Typography variant="h6" style={webStyle.heading}>
            My Cart
          </Typography>
          <Button variant="contained" onClick={this.checkoutNow} style={{ ...webStyle.checkoutButton, textTransform: "none", boxShadow: "none" }}>
            Checkout Now
          </Button>
        </Box>
        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
          <Box style={{ ...webStyle.navTabContainer }}>
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                overflowX: "auto",
              }}
            >
              <Tabs value={this.state.value} onChange={(event, newValue) => this.handleTabChange(newValue)} TabIndicatorProps={{
                style: { background: "#EE6900", height: "4px", borderRadius: "8px" },
              }}>
                {tabData.map(({ label, value }) => (
                  <Tab
                    key={value}
                    style={{ textTransform: "none", height: "58px" }}
                    label={
                      <Typography
                        style={this.state.value === value ? webStyle.activeTab : webStyle.inactiveTab}
                      >
                        {label}
                      </Typography>
                    }
                  />
                ))}

              </Tabs>
            </Box>
            <Box style={webStyle.searchContainer}>
              <InputBase
                placeholder="Search"
                style={webStyle.searchInput}
                inputProps={{
                  style: {
                    fontFamily: 'Inter, sans-serif',
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.87)",
                    fontWeight: 400,
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={seacrhIcon} alt="search icon" style={{ width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
              <img src={sortingIcon} alt="sorting icon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
              <IconButton>
                <img src={filterIcon} alt="filter icon" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Box>
          </Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead style={{ ...webStyle.tableHead }}>
                <TableRow style={{ borderBottom: "none" }}>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCell, borderBottom: "none" }}>
                    Product
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCell, borderBottom: "none" }}>
                    RFQ Name
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCell,  borderBottom: "none" }}>
                    Brand
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCell, borderBottom: "none" }}>
                    Quantity
                  </TableCell>
                  <TableCell align="left" style={{ ...webStyle.TableCell, borderBottom: "none" }}>
                    Actions
                  </TableCell>
                  <TableCell style={{ width: "0%", borderBottom: "none" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={webStyle.Tbody}>
  <TableRow style={{ height: "32px" }}></TableRow>
  { this.state.CartitemList && this.state.CartitemList.length > 0?((() => {
    switch (this.state.value) {
      case 0:
      case 1:
      case 2:
        return this.state.CartitemList; 
      default:
        return this.state.CartitemList;
    }
  })().map((item, index) => {
    const attributes = item.attributes; 
    const productImage = attributes.product_image?.url;
    const productName = attributes.product_name;
    const rfqName = attributes.rfq_name || 'NA'; 
    const brandName = attributes.brand_name;
    const measurement = attributes.unit_of_measurement;
    return (
      <TableRow key={index} style={{ height: "74px" }}>
        <TableCell style={{ width: "0%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
        <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <img src={productImage?productImage:defaultImage} alt={productName} style={webStyle.productImage} />
            <Box style={webStyle.productInfo}>
              <Typography style={webStyle.productName}>{productName}</Typography>
              <Typography style={webStyle.Upidname} variant="caption">{attributes.upid_id}</Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell align="left" style={{ ...webStyle.rfqname, borderTop: "1px solid #E7E2F0" }}>
          {rfqName}
        </TableCell>
        <TableCell align="left" style={{ borderTop: "1px solid #E7E2F0" }}>
          <Button style={{ ...webStyle.brandButton, textTransform: "none" }}>{brandName}</Button>
          <Fab
           size="small"
           color="primary"
            aria-label="add"
            style={{ ...webStyle.addButton, boxShadow: "none" }}
            onClick={this.handleBrandMenuClick}
            data-testId="filterIcons"
          >
            <AddIcon />
          </Fab>
          <Menu
            anchorEl={this.state.brandAnchorEl}
            keepMounted
            data-testId="menuIcon"
            open={Boolean(this.state.brandAnchorEl)}
            onClose={this.handleBrandMenuClose}
            PaperProps={{
              style: { boxShadow: 'none', border:"1px solid #E7E2Ef" },
            }}
            style={{width:"120px"}}
          >
            <MenuItem style={webStyle.menuHeader}> Polycab</MenuItem>
          </Menu>
        </TableCell>
        <TableCell align="left" style={{ borderTop: "1px solid #E7E2F0" }}>
          <Box style={webStyle.quantityContainer}>
            <InputBase
              style={webStyle.quantityField}
              value={attributes.quantity} 
              inputProps={{ 'aria-label': 'quantity' }}
              disabled={true}
            />
            <Typography style={webStyle.nosLabel}>{measurement}</Typography>
          </Box>
        </TableCell>
        <TableCell align="left" style={{ borderTop: "1px solid #E7E2F0" }}>
          <IconButton
            data-testId="MoreVertIcon"
            aria-label="more"
            onClick={(event) => this.handleMenuClick(event, "action",item.id)}
          >
            <MoreVertIcon />
          </IconButton>
          {this.renderMenu(item)}
        </TableCell>
        <TableCell style={{ width: "0%", borderBottom: "none", borderLeft: "1px solid #E7E2F0" }}></TableCell>
      </TableRow>
    );
  })): (<TableRow style={{ height: "45px" }}  data-testid="empty-cart-message">
    <TableCell
      colSpan={7} 
      style={{
        textAlign: "center",
        borderBottom: "none",
        padding: "16px 0", 
      }}
    >
      Cart Items Not Present
    </TableCell>
  </TableRow>)}
  <TableRow style={{ height: "32px" }}></TableRow>
</TableBody>

            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  }

    renderMenu = (item:{
      id: string;
      type: string;
      attributes: {
          product_id: number;
          quantity: number;
          price: number;
          total_price: number;
          rfq_name: string;
          cart_id: number;
          product_name: string;
          product_image: {
              url: string;
          };
          unit_of_measurement: string;
          brand_name: string;
          upid_id: number;
          item_count: number;
      };
  }) => {
    return (
      <Menu
        anchorEl={this.state.anchorEl}
        keepMounted
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center', 
        }}
        transformOrigin={{
          vertical: 'top', 
          horizontal: 'center', 
        }}
        PaperProps={{
          style: {
            marginRight:10,
            marginTop:"-10px",
            boxShadow: 'none', border:"1px solid #E7E2Ef" 
          },
        }}
      >
          <>
            <MenuItem  data-testId="EditMenuItem">Edit</MenuItem>
            <MenuItem onClick={() => this.handleDelete()} data-testId="DeleteMenuItem">Delete</MenuItem>
          </>
      </Menu>
    );
  };
  // Customizable Area End

  render() { 
   
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Navbar {...this.props} history={this.props.history} handleNavigate={this.handleNavigate} onSelectionChange={this.handleSelectionChange} />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.cartTable()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: {
  [key: string]: {
    [property: string]: string | number
  }
} = {
  mainContainer: {
    marginLeft: "47px",
    fontFamily: "Inter, sans-serif",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
    fontFamily: "Inter, sans-serif",

  },
  menuHeader: { fontSize: "14px", fontWeight: 500, fontFamily: "Inter, sans-serif",color:'#323842' , lineHeight:'22px',   width: "1085px"},

  heading: {
    fontFamily: "Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    textAlign: "left",
 
  },
  checkoutButton: {
    backgroundColor: "#ff6600",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "max-content",
  },
  Tbody: {
    padding: "20px",
    paddingTop:"0px"
  },
  productImage: {
    width: "50px",
    height: "50px",
    marginRight: "10px",
    objectFit:"contain"    
  },
  productInfo: {
    display: "flex",
    flexDirection: "column",
  },
  rfqname: {
    fontFamily: "Inter, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    color:"#323842"
  },
  brandButton: {
    fontWeight: 500,
    fontSize: "12px",
    margin: "0 5px",
    color: "#FF6600",
    backgroundColor: "#FFF6F0",
    minWidth: "66px",
    height: "32px",
    fontFamily: "Inter, sans-serif",
    padding:"6px 13px"
  },
  quantityContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8.5px",
  },
  quantityField: {
    width: "100px",
    height: "38px",
    borderRadius: "9px",
    backgroundColor: "#F3F4F6",
    padding: "6px 12px",
    fontSize: "12px",
    fontFamily: "Inter, sans-serif",
    color:"#323842",
    fontWeight:400
  },
  nosLabel: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "26px",
    color:"#323842"
  },
  addButton: {
    height: "30px",
    width: "30px",
    fontFamily: "Inter, sans-serif",
    minHeight: "20px",
    backgroundColor: "#FF6600",
    gap: "1",
    display:"none"
  },
  productName: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    fontFamily: "Inter, sans-serif",
    color:"#323842"
  },
  Upidname: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#BDC1CA",
    fontFamily: "Inter, sans-serif",

  },
  tableHead: {
    backgroundColor: "#f3f4f6",
    fontFamily: "Inter, sans-serif",
  },
  TableCell: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    textAlign: "left",
    fontFamily: "Inter, sans-serif",
    color: "#565E6C",
    backgroundColor: "#F3F4F6",
  },
  tableBody: {
    width: "1045px",
    padding: "15px",
  },
  singleBox: { display: "flex", gap: "15px" },
  toggleBox: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", cursor: "poInter, sans-serif", backgroundColor: "#f4f4f4", margin: "19px", border: "8px" },
  mainContent: { display: "flex", alignItems: "center", justifyContent: "space-between", padding: "17px", margin: "19px" },
  mainWrapper: {
    display: "flex",
    fontFamily: "Inter, sans-serif",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  Tabfont: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
  },
  navTabContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "58px"
  },

  icon: {
    margin: "auto 5px",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0px 0 0",
    width: "265px",
    fontFamily: "Inter, sans-serif",
  },
  actionmenu: {
    width: "150px",
    boxShadow: "none",
  },
  searchInput: {
    width: "173px",
    height: "35px",
    opacity: 1,
    borderRadius: "9px",
    padding: "0px 10px",
    backgroundColor: "#F3F4F6",
    fontFamily: "Inter, sans-serif",

  },
  inactiveTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    textAlign: "left",
    color: "#000000",
  },
  activeTab: {
    fontFamily: "Inter, sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "left",
    color: "#EE6900",
  },
  bottomspacing: {
    height: "45px",
    borderLeft: "1px solid #E7E2F0",
    borderRight: "1px solid #E7E2F0",
    borderBottom: "1px solid #E7E2F0"

  }
};

// Customizable Area End