import React, {DragEvent, ChangeEvent } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface File {
  type: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: {
    location: {
      state:{
        user_id: string;
        phone: string
      }
    }
  }
  handleNext: () => void
  // Customizable Area Start
  passState:Function,
  companyStateData: Certificate,
  companyName:string
  // Customizable Area End
}

type Certificate = {
  name: string;
  file: File | null;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  otpText: string;
  otpTextError: string;
  openDialog: boolean;
  currentStep: number;
  isOver: boolean;
  uploadCertificate: Certificate,
  sizeErr: string;
  // Customizable Area Start
  isVerified:boolean;
  isGstin:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CompanyInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      otpText: "",
      otpTextError: "",
      openDialog: false,
      currentStep: 1,
      isOver: false,
      uploadCertificate: {
        name: "",
        file: null,
      },
      sizeErr: ""
      // Customizable Area Start
      ,isVerified:false,
      isGstin:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    await super.componentDidMount(); 
    this.setState({uploadCertificate: {...this.props.companyStateData}})
  }

    handleDragOver = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      this.setState({...this.state,isOver:true})
    };

    handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        this.setState({...this.state,isOver:false})
    };

    handleDrop = (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      this.setState({...this.state,isOver:false})

      const droppedFiles = Array.from(event.dataTransfer.files);

      if (droppedFiles.length > 0 && this.isPDF(droppedFiles[0])) {
        
        this.setState(
          (prevState) => ({
            uploadCertificate: { ...prevState.uploadCertificate, file: selectedFile },
            sizeErr: "", 
          }),
          () => {
            this.props.passState({ ...this.state.uploadCertificate, uploadCertificate: this.state.uploadCertificate.file });
          }
        );

        } else {
          this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
        }

        const selectedFile = droppedFiles[0];
        if (selectedFile) {
          const maxSizeInBytes =  25 * 1024 * 1024; 
          if (selectedFile.size > maxSizeInBytes) {
            this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
            return;
          }
        }  
    }

    isPDF = (file: File) => {
      return file.type === 'application/pdf';
    };

    onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const selectedFile = event.target.files && event.target.files[0];;
      if (selectedFile) {
      
        const maxSizeInBytes =  25 * 1024 * 1024;
        if (selectedFile.size > maxSizeInBytes) {
          this.setState({ sizeErr: "File size exceeds the maximum limit of 25MB. Please select a smaller file."})
          return;
        }
      }  
 
      this.setState(
        (prevState) => ({
          uploadCertificate: { ...prevState.uploadCertificate, file: selectedFile },
          sizeErr: "", 
        }),
        () => {
          this.props.passState({ ...this.state.uploadCertificate, uploadCertificate: this.state.uploadCertificate.file });
        }
      );
      this.props.passState({...this.state.uploadCertificate, uploadCertificate: this?.state?.uploadCertificate?.file})
      }

      handleValidation = () => {
          if(this.state.uploadCertificate.file && this.state.isVerified){
            this.props.handleNext()      
          }
          else{
            if(!this.state.uploadCertificate.file){
              this.setState({ sizeErr: "Required field"})
            }
            if(!this.state.isVerified){
              this.setState({isGstin: "Required field"})
            }          
          }
      }

  // Customizable Area End
}
