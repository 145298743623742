import React,{ ChangeEvent } from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from 'framework/src/Utilities';


// Customizable Area Start
import GeneralInfoBuyer from "../src/GeneralInfoBuyer.web";


interface GeneralInformation {
  attributes: {
    company_name: string;
    company_type: string;
    email: string;
    full_phone_number: string;
    contact_name: string;
  };
}

interface GeneralData {
  companyname: string;
  // companytype: string;
  selectedComOption: string;
  contact: string;
  email: string;
  phonenumber: string;
  [key: string]: string; 
}

interface GeneralEditData {
  companyname: string;
  selectedComOption: string;
  contact: string;
  email: string;
  phonenumber: string;
  [key: string]: string; 
}

interface DocumentData {
  attributes: {
    document_details: {
      gstin_number: string;
      pdf: string;
    };
  };
};

interface BankDetail {
  attributes: {
    bank_details: {
      bank_name: string;
      beneficiary_name: string;
      account_number: string;
      ifsc_code: string;
      branch_name: string;
    };
  };
};
interface DocumentDetails {
  gstin_number: string;
  pdf: string;
}

interface Address {
  biling_address: string;
  street: string;
  city: string;
  country: string;
  state: string;
  zipcode: number;
}

interface BankDetails {
  bank_name: string;
  beneficiary_name: string;
  account_number: string;
  branch_name: string;
  ifsc_code: string;
}

export interface CompanyInformation {
  id: string;
  type: string;
  attributes: {
      company_name: string;
      company_type: string;
      email: string;
      full_phone_number: string;
      contact_name: string;
      account_id: number;
      document_details: DocumentDetails;
      address: Address;
      bank_details: BankDetails;
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  
  // Customizable Area End
}

type Certificate = {
  name: string;
  file: File | null;
  gstin_number: string
}
interface S {
    // Customizable Area Start
    selectedImage: {
      preview: string;
      raw: File | string; 
    }
    showComData: boolean;
    isEdit: boolean;
    openDialog: boolean;
    value: number;
    userId: number;

    general: GeneralData;
    docData: Certificate;
    bank:{banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string};
    selectedCompany: {id:number | null,companyName:string};
    allCompanyData: CompanyInformation[];
    companyNamesList:{id:number,companyName:string}[]
    newCompanyName:string;
    generalStateData: GeneralEditData;
    documentStateData: Certificate;
    bankStateData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string};
    fieldRequiredData: {companyname: string, contact:string, email:string, phonenumber: string},
    sizeErr: string;
    bankNameErr: string;
    accNoErr: string;
    ifscErr: string;
    branchNameErr: string;
    selectCompanyName: {id:number | null,projectName:string};
    groupName: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  generalBuyerRef = React.createRef<GeneralInfoBuyer>();

  getCompanyInfo: string = "";
  createCompanyMsgInfo:string="";
  editGroupData: string="";
  getSpecificCompanyInfo: string="";
  validationData = {};
  dropdownRef = React.createRef<HTMLUListElement>();
  boxRef = React.createRef<HTMLDivElement>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {  
            // Customizable Area Start
      selectedImage: { preview: "", raw: "" },
      showComData: false,
      isEdit: false,   
      openDialog: false,
      value: 0,
      userId: 0,

      general: { companyname: "",selectedComOption:"",contact: "", email: "", phonenumber:""},
      docData: {gstin_number: "", name: "", file: null},
      bank:{banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""},
      selectedCompany: {id:null,companyName:''},
      allCompanyData:[],
      companyNamesList:[],
      newCompanyName:'',
      generalStateData:{ companyname: "",selectedComOption:"",contact: "", email: "", phonenumber:""},
      documentStateData: {gstin_number: "", name: "", file: null},
      bankStateData: {banknane: "", beneficiaryname: "", accountnumber: "", ifsccode: "", branchname:""},
      fieldRequiredData: {companyname:'', contact:'', email:'', phonenumber:""},
      sizeErr:"",
      bankNameErr:"",
      accNoErr: "",
      ifscErr: "",
      branchNameErr: "",
      selectCompanyName:{id: null,projectName:""},
      groupName:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    await super.componentDidMount(); 
    const dataUser = await getStorageData('user-id');

    const number = parseInt(dataUser)
    this.setState({ userId: number}) 
    this.getCompanyInformation();
    document.addEventListener('click', this.handleOutsideClick);
      }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (responseJson && !responseJson.errors) {
        switch (apiRequestCallId) {
          case this.getCompanyInfo:
          
            const companyData = responseJson.CompanyInformations.data.map((item: CompanyInformation) => ({
              id: item.id,
              companyName: item.attributes.company_name
            }));
    
            this.setState({
              allCompanyData: responseJson.CompanyInformations.data,
              companyNamesList: companyData,
            });
            this.setState({ selectedCompany: companyData[0]}, () => {
              this.getSpecificCompanyInformation();
            })
            this.forceUpdate();
            break;
    
          case this.createCompanyMsgInfo:
            this.getCompanyInformation();
            this.setState({ openDialog: false });
            break;

          case this.getSpecificCompanyInfo:
            const generalData = {
              companyname: responseJson.company_information.data.attributes.company_name,
              selectedComOption: responseJson.company_information.data.attributes.company_type,
              email: responseJson.company_information.data.attributes.email,
              phonenumber: responseJson.company_information.data.attributes.full_phone_number,
              contact: responseJson.company_information.data.attributes.contact_name
            }

            const docData = {
              gstin_number: responseJson.company_information.data.attributes.document_details.gstin_number , 
              name: responseJson.company_information.data.attributes.document_details.pdf,
              file:null 
            }

            const bankData = {
              banknane: responseJson.company_information?.data.attributes?.bank_details?.bank_name , 
              beneficiaryname: responseJson.company_information?.data.attributes?.bank_details?.beneficiary_name , 
              accountnumber: responseJson.company_information?.data.attributes.bank_details.account_number ,
              ifsccode: responseJson.company_information.data?.attributes.bank_details?.ifsc_code, 
              branchname: responseJson.company_information.data?.attributes.bank_details?.branch_name
            }

            this.setState({ general: generalData, docData: docData, bank:bankData, selectedImage: { preview:responseJson.company_information?.data.attributes.group.image, raw: responseJson.company_information?.data.attributes.group.image}, groupName: responseJson.company_information?.data.attributes.group.group_name});
            break;  
          default:
            break;
        }
      }
    }
    
    // Customizable Area End
  }

 

  // Customizable Area Start
  async componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }
    handleOutsideClick = (event: MouseEvent) => {
    if (
      this.dropdownRef.current && !this.dropdownRef.current.contains(event.target as Node) &&
      this.boxRef.current && !this.boxRef.current.contains(event.target as Node)
    ) {
      this.setState({ showComData: false });
    }
  };
  handleGeneralBuyerState = (generalData:{ companyname: "",selectedComOption:"",contact: "", email: "", phonenumber:""}) => {
    this.setState({...this.state, general:generalData,generalStateData:generalData})
  }

  handleBuyerCompanyState = (companyData:Certificate) => {
    this.setState({...this.state, docData:companyData,documentStateData:companyData})
  }

  handleBuyerBankState = (bankData: {banknane: string, beneficiaryname: string, accountnumber: string, ifsccode: string, branchname:string}) => {
    this.setState({...this.state, bank:bankData,bankStateData:bankData})
  }

  getCompanyInformation = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCompanyInfo = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/company_informations`
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  getSpecificCompanyInformation = async () => { 
    const currenProjectId = this.state.selectedCompany?.id ?? '';
    const getCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpecificCompanyInfo = getCompanyMsg.messageId;
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/company_informations/${currenProjectId}`
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
      })
    );
    getCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCompanyApiMethodType
    );
    runEngine.sendMessage(getCompanyMsg.id, getCompanyMsg);
  };

  createNewCompany = async () => {
    const formDataObject = new FormData();
    formDataObject.append("data[attributes][company_name]", this.state.newCompanyName);
    let tokenData = await getStorageData("otp-token")
    const headers = {
      token:tokenData
    };
    const createCompanyMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCompanyMsgInfo = createCompanyMsg.messageId;
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${this.state.userId}/company_informations`
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    createCompanyMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formDataObject)
    runEngine.sendMessage(createCompanyMsg.id, createCompanyMsg);
  };

  handleGeneralRequire = () => {
    const field = this.state.general;
    const message = "Required field";
    const requiredFields: any[] = ["companyname", "contact", "email", "phonenumber"];
  
    const missingFields = requiredFields.filter(fieldKey => !field[fieldKey]);
  
    const fieldRequiredData = requiredFields.reduce((accumulater:Record<string, string>, fieldKey:string) => {
      
      accumulater[fieldKey] = missingFields.includes(fieldKey) ? message : "";
      return accumulater;
    }, {});
    this.validationData = {...this.validationData,...fieldRequiredData}
    this.setState({ fieldRequiredData });

    return missingFields.length === 0;
  };

  handleDocRequire = () => {
    const field = this.state.docData.file;
    const pdfUrl = this.state.docData.name;
    if(pdfUrl){
      this.setState({ sizeErr:""})
    }
    else if(!field){
      this.validationData = {...this.validationData,sizeErr:"Required field"}
      this.setState({ sizeErr:"Required field"})
    }
  }

  handleBankRequire = () => {
    const field =  this.state.bank.banknane;
    if(!field){
      this.setState({ bankNameErr:"Required field"})
    }else{
      this.setState({ bankNameErr:""})
    }
  }

  handleAccNoRequire = () => {
    const field =  this.state.bank.accountnumber;
    if(!field){
      this.setState({ accNoErr:"Required field"})
    }else{
      this.setState({ accNoErr:""})
    }
  }
  handleIfscRequire = () => {
    const field =  this.state.bank.ifsccode;
    if(!field){
      this.setState({ ifscErr:"Required field"})
    }else{
      this.setState({ ifscErr:""})
    }
  }
  handleBranchNameRequire = () => {
    const field =  this.state.bank.branchname;
    if(!field){
      this.setState({ branchNameErr:"Required field"})
    }else{
      this.setState({ branchNameErr:""})
    }
  }

  handleSave = () => {
    this.handleGeneralRequire();
    this.handleDocRequire();
    this.handleBankRequire();
    this.handleAccNoRequire();
    this.handleBranchNameRequire();
    this.handleIfscRequire();
   let isRequiredError =  this.hasValue(this.validationData)
   if(!isRequiredError){
      this.submitForm();
      this.setState({ isEdit: false})
    }
  }

    hasValue = (object: { [key: string]: string },) => {
      for (let keyData in object) {
        if (object.hasOwnProperty(keyData) && (object[keyData] !== null && object[keyData] !== undefined && object[keyData] !== '')) {
          return true; 
        }
      }
      return false;
    }
  submitForm = async() => {
   
     const formDataObject = new FormData();
 
     formDataObject.append("data[attributes][company_name]", this.state.general.companyname);
     formDataObject.append("data[attributes][company_type]", this.state.general.selectedComOption);
     formDataObject.append("data[attributes][contact_name]", this.state.general.contact);
     formDataObject.append("data[attributes][email]", this.state.general.email);
     formDataObject.append("data[attributes][full_phone_number]", this.state.general.phonenumber);
 
     formDataObject.append("data[document_detail_attributes][pdf]", this.state.docData.file  || "");
     formDataObject.append("data[document_detail_attributes][gstin]", "785489545");
 
     formDataObject.append("data[bank_detail_attributes][bank_name]", this.state.bank.banknane);
     formDataObject.append("data[bank_detail_attributes][beneficiary_name]", this.state.bank.beneficiaryname);
     formDataObject.append("data[bank_detail_attributes][account_number]", this.state.bank.accountnumber);
     formDataObject.append("data[bank_detail_attributes][ifsc_code]", this.state.bank.ifsccode);
     formDataObject.append("data[bank_detail_attributes][branch_name]", this.state.bank.branchname) ;

     formDataObject.append("data[group][image]", this.state.selectedImage.raw);
     formDataObject.append("data[group][name]", this.state.groupName);
 
 
     const requestSubmitMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage));
     const tokenData = await getStorageData('otp-token');
 
      const requestHeader = {
          token:tokenData
      };
      this.editGroupData = requestSubmitMessage.messageId;
      requestSubmitMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_block/accounts/${this.state.userId}/company_informations/${this.state.companyNamesList[0].id}`
      );
      requestSubmitMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(requestHeader)
      );
      requestSubmitMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "PATCH"
      );
      requestSubmitMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formDataObject);
      runEngine.sendMessage(requestSubmitMessage.id, requestSubmitMessage);
   }
   

  handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {

    if (event.target.files && event.target.files.length) {
      this.setState({ selectedImage: { preview: URL.createObjectURL(event.target.files[0]), raw: event.target.files[0]}})
    }
  };

  handleOpen = () => {
    this.setState({ showComData: !this.state.showComData })
  }

  handleEdit = () => {
    this.setState({ isEdit: true})
  }

  handleClose = () => {
    this.setState({ openDialog: false})
  };

  handleComOpen = () => {
    this.setState({ openDialog: true})
  };

  handleChange = (event:React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue})
  };

  selectComName = (companyData:{id:number,companyName:string}) => {
    this.setState({ selectedCompany: companyData, showComData: false},()=>{
      this.getSpecificCompanyInformation()
    })
  }
  handleCompanyName=(event:React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.value
    this.setState({newCompanyName:name})
  }
  addNewCompany=() => {
    this.createNewCompany()
  }

  handleGroupNameChange = (event:ChangeEvent<HTMLInputElement>) => {
    this.setState({groupName: event.target.value});
  }
  // Customizable Area End
}
