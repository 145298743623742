import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    styled,
    ListItem,
    ListItemIcon,
    List,
    Grid,
    createTheme,
    ThemeProvider,
    Switch,
    Breadcrumbs
} from "@material-ui/core";

import PricingController, {
    Props,
} from "./PricingController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { ListItemText, Paper, Tab, Tabs, TextField } from "@mui/material";
import { AddImg, gropdelivery, groupBox, grouptime, groupworld, lbow, tick, View } from "./assets";
const CustomTabs = styled(Tabs)({
    color: '#FF6600',
    borderRadius: "12px",
    '& .MuiTabs-indicator': {
        backgroundColor: '#FF7B00', // Orange indicator
        height: '3px',
        color: 'white',
    },
});

const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            border: 'none',
        },
        '&:hover fieldset': {
            border: 'none',
        },
        '&.Mui-focused fieldset': {
            border: 'none',
        }
    }
});

const BreadcrumBlock = styled(Breadcrumbs)({
    display: "flex",
    "@media (max-width:600px)": {
        width: "100%"
    },
    '& .breadcrumcontent': {
        color: "white",
        fontSize: "14px",
        fontFamily: "Inter, sans-serif",
        fontWeight: 500,
        height: "20px",
        textDecoration: "none",
        "@media (max-width:600px)": {
            marginTop: 0,
            height: 0,
        },
    },
    '& .linkcolor': {
        fontFamily: "Inter, sans-serif",
        color: "rgba(250, 250, 250, 0.6)",
        textDecoration: "none",
        display: "flex",
        fontWeight: 500,
        alignItems: "center",
        fontSize: "14px",
        cursor: "grab",
        "@media(max-width:600px)": {
            fontSize: "12px"
        },
    },
    '& .rigthArrow': {
        marginRight: "10px"
    },
    "& .breadcrumItem": {
        cursor: "grab"
    },
    '& .active': {
        fontFamily: "Inter, sans-serif",
        color: "rgba(250, 250, 250, 1)",
        textDecoration: "none",
    },
    '& .sublinkcolor': {
        fontFamily: "Inter, sans-serif",
        color: "#FAFAFA",
        textDecoration: "none",
    },

})
const OrangeSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#FFFFFF',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#FF6600',
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: "#FFFFFF",
    },
    '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
        backgroundColor: "#FFFFFF",
    },
}));
const theme = createTheme({
    typography: {
        fontFamily: "Inter, sans-serif",
    },
});
const CustomTab = styled(Tab)({
    textTransform: 'none',
    fontWeight: 'bold',
    borderRadius: "12px",
    fontFamily: "Inter, sans-serif",
    color: '#FF7B00 !important',
    borderRight: " 1px solid #FFE4D2",
    '&.Mui-selected': {
        backgroundColor: '#FF7B00',
        color: '#FFFFFF !important',
        borderRadius: '12px',
    },
});

const tabContent = `The Bosch 1300W Professional Router is a powerful and versatile tool that can handle a variety of routing tasks. With its 1300 watt motor, it can easily handle even the most challenging routing jobs.

Power 1300W, Collet and bit size (6mm, 8mm, 1/4 inch), Plunge length 55 mm, Weight 3.5 kg

Quick carbon brush change, constant Speed: maximum cutting, Lock on and lock off switch, Overload protection and Restart protection, Flexible options for fast and easy bit changes, 2.5 m long cord

Warranty 6 Months from the date of invoice (provide invoice copy to claim warranty)
Power 1300W, Collet and bit size (6mm, 8mm, 1/4 inch), Plunge length 55 mm, Weight 3.5 kg
`;

// Customizable Area End

export default class Pricing extends PricingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    /*istanbul ignore next*/
    MyProducts = () => {
        const { tabLabels, value, } = this.state
        const breadcrumbData = [
            "Home",
            "Plumbing",
            "Internal Plumbing Systems",
            "90 degree UPVC plain Elbow"
        ];
        return (
            <Box style={{ width: "94%", ...webStyle.MainContainer }}>
                <Box p={3}>
                    <BreadcrumBlock
                        maxItems={15}
                        style={{ color: "#1E293B", fontWeight: 700, fontFamily: "Inter, sans-serif" }}
                        separator={
                            <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.49961 6.09246C7.17461 6.41746 7.17461 6.94246 7.49961 7.26746L10.7329 10.5008L7.49961 13.7341C7.17461 14.0591 7.17461 14.5841 7.49961 14.9091C7.82461 15.2341 8.34961 15.2341 8.67461 14.9091L12.4996 11.0841C12.8246 10.7591 12.8246 10.2341 12.4996 9.90913L8.67461 6.08412C8.35794 5.76746 7.82461 5.76746 7.49961 6.09246Z"
                                    fill="#64748B"
                                />
                            </svg>
                        }
                        aria-label="breadcrumb"
                        className="breadcrumcontent"
                    >
                        {breadcrumbData.map((name: string, index: number) => {
                            return (
                                <Typography
                                    style={
                                        breadcrumbData?.length === index + 1
                                            ? { fontWeight: 500, color: "#565E6C", fontSize: "14px" }
                                            : { color: "#1E293B", fontWeight: 700, fontFamily: "Inter, sans-serif", fontSize: "14px" }
                                    }
                                    color="inherit"
                                    key={name || `breadcrumb-${index}`}
                                    onClick={() => {
                                        console.log(`Clicked breadcrumb: ${breadcrumbData[index]}`);

                                        this.setState(
                                            {
                                                selectedCategory: breadcrumbData.slice(0, index + 1), // Simulating state update
                                            },
                                            () => {
                                                const currentCategory = breadcrumbData[index]; // Dummy category value

                                                if (index === 0) {
                                                    console.log("Fetching Main Category Data...");
                                                } else if (index > 0) {
                                                    console.log(`Fetching Sub Category Data for: ${currentCategory}`);
                                                }
                                            }
                                        );
                                    }}

                                    data-test-id="breadcrumItem"
                                    className="breadcrumItem"
                                >
                                    {name ? name.slice(0, 66) : "Unnamed"}
                                    {name && name.length > 66 ? "..." : ""}
                                </Typography>
                            );
                        })}
                    </BreadcrumBlock>

                    <Box style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                        <Box>
                            <Box style={webStyle.ProductImg} >
                                <img
                                    src={lbow}
                                    alt="product"
                                    style={webStyle.bigImg1}
                                />
                            </Box>
                            <Box style={webStyle.Productgroupimg}>
                                <Box style={webStyle.LbowImg} >
                                    <img
                                        src={lbow}
                                        alt="product"
                                        style={webStyle.img1}
                                    />
                                </Box>
                                <Box style={webStyle.LbowImg} >
                                    <img
                                        src={lbow}
                                        alt="product"
                                        style={webStyle.img1}
                                    />
                                </Box>
                                <Box style={webStyle.LbowImg} >
                                    <img
                                        src={lbow}
                                        alt="product"
                                        style={webStyle.img1}
                                    />
                                </Box>
                                <Box style={webStyle.LbowImg} >
                                    <img
                                        src={lbow}
                                        alt="product"
                                        style={webStyle.img1}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ height: "433px", width: "353px" }}>

                            <Typography style={{ fontWeight: 700, fontSize: "24px", width: "max-content" }} >
                                90° UPVC Elbow SOC SCH 80
                            </Typography>

                            <Typography variant="body1" style={webStyle.type}>
                                Description:{' '}
                                <Typography variant="body1" style={webStyle.typet} component="span">
                                    uPVC fitting used to give a 90° turn to a pipeline.
                                </Typography>
                            </Typography>
                            <Typography variant="body1" style={webStyle.type}>
                                UPID: <Typography variant="body1" style={webStyle.typet} component="span">XXXXXXXXX</Typography>
                            </Typography>

                            {/* Brand */}
                            <Typography variant="body1" style={webStyle.type}>
                                Selected Brand: <Typography variant="body1" style={webStyle.typet} component="span">Astral</Typography>
                            </Typography>

                            <Box style={{ marginTop: "8px" }}>
                                <Typography style={{ fontSize: "12px", fontWeight: 600 }} >Features</Typography>
                                <List dense disablePadding>
                                    {[
                                        'Brand Name :',
                                        'GSM :',
                                        'No. of Sheets :',
                                        'No of Ream :',
                                        'GSM :',
                                        'Ideal For :',
                                        'No. of Sheets :',
                                        'No of Ream :',
                                        'Ideal For :',
                                    ].map((feature, index) => (
                                        <ListItem key={index} style={{ padding: '0px 0' }}>
                                            <ListItemIcon style={{ minWidth: '24px' }}>
                                                <img src={tick} alt="" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={feature}
                                                primaryTypographyProps={{ style: { fontSize: "12px" } }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>

                            {/* Brand Details */}
                            <Box style={{ marginTop: "8px" }}>
                                <Typography style={{ fontSize: "12px", fontWeight: 600 }} >Brand Details</Typography>
                                <List dense disablePadding>
                                    {[
                                        'Brand :',
                                        'Product Brand Name :',
                                        'Brand SKU :',
                                    ].map((detail, index) => (
                                        <ListItem key={index} style={{ padding: '0px 0' }}>
                                            <ListItemIcon style={{ minWidth: '24px' }}>
                                                <img src={tick} alt="" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={detail}
                                                primaryTypographyProps={{ style: { fontSize: "12px" } }}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Box p={2} border="1px solid #e0e0e0" borderRadius="8px" width="300px">
                                <Typography variant="subtitle1" style={{fontWeight:700,fontSize:"16px"}} >Details</Typography>
                                <Typography variant="body2" style={{ marginTop: "10px",color:"#323743" }}  >
                                    <strong>Unit Conversion</strong> - 1 bag = 20 kg
                                </Typography>

                                {/* Details List */}
                                <Box style={webStyle.gr} >
                                    <Grid container style={webStyle.gr} spacing={1} alignItems="center">
                                        <Grid item><img src={groupBox} alt="" /></Grid>
                                        <Grid item xs><Typography variant="body2"><strong>MOQ</strong> <br />20 No.s</Typography></Grid>
                                    </Grid>

                                    <Grid container style={webStyle.gr} spacing={1} alignItems="center" >
                                        <Grid item><img src={grouptime} alt="" /></Grid>
                                        <Grid item xs><Typography variant="body2"><strong>Lead Time</strong> <br />Quote Required</Typography></Grid>
                                    </Grid>

                                    <Grid container style={webStyle.gr} spacing={1} alignItems="center">
                                        <Grid item><img src={groupworld} alt="" /></Grid>
                                        <Grid item xs><Typography variant="body2"><strong>Regional Availability</strong> <br />Available for your Project</Typography></Grid>
                                    </Grid>

                                    <Grid container style={webStyle.gr} spacing={1} alignItems="center" >
                                        <Grid item><img src={gropdelivery} alt="" /></Grid>
                                        <Grid item xs><Typography variant="body2"><strong>Transportation</strong> <br />Quote Required</Typography></Grid>
                                    </Grid>
                                </Box>


                            </Box>
                            <Box mt={5}>
                                <Typography variant="subtitle1" >View Documents</Typography>
                                <Table size="small" style={{ border: "1px solid #DEDFE3" }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ borderRight: "1px solid #DEDFE3" }}>Technical Data Sheet (TDS)</TableCell>
                                            <TableCell align="right">
                                                <Button size="small" startIcon={<img src={View} alt="" />}>
                                                    View
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderRight: "1px solid #DEDFE3" }}>Brochure</TableCell>
                                            <TableCell align="right">
                                                <Button size="small" startIcon={<img src={View} alt="" />}>
                                                    View
                                                </Button>
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>

                    </Box>

                    <Box style={webStyle.ctab}>
                        <CustomTabs
                            value={value}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            TabIndicatorProps={{ style: { display: 'none' } }}
                        >
                            {tabLabels.map((label: any, index: number) => (
                                <CustomTab key={index} label={label} style={{ textTransform: "none", fontFamily: "Inter, sans-serif", }} />
                            ))}
                        </CustomTabs>

                        <Box sx={{ mt: 2 }}>
                            {value === 0 && (
                                <Typography variant="body1" style={{ whiteSpace: 'pre-line', fontSize: '14px' }}>
                                    {tabContent}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    <Box style={{ marginTop: "40px" }}>
                        <Typography variant="h6">Add Bulk Pricing</Typography>

                        <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px", marginTop: "20px" }}>

                            <TableContainer >
                                <Table>
                                    <TableHead style={{ ...webStyle.TableHeads, borderBottom: 'none' }}>
                                        <TableRow style={{ borderBottom: 'none' }}>
                                            <TableCell style={{ width: '1%', borderBottom: 'none' }} />
                                            <TableCell colSpan={1} align="left" style={{ borderBottom: 'none', ...webStyle.tab1 }}>
                                                Sr No
                                            </TableCell>
                                            <TableCell align="left" colSpan={3} style={{ borderBottom: 'none', ...webStyle.tab1 }}>
                                                Quantity Range
                                            </TableCell>
                                            <TableCell align="left" colSpan={2} style={{ ...webStyle.tab1, borderBottom: 'none' }}>
                                                Advance Price
                                            </TableCell>
                                            <TableCell align="left" colSpan={2} style={{ ...webStyle.tab1, borderBottom: 'none' }}>
                                                Discount
                                            </TableCell>
                                            <TableCell align="left" colSpan={1} style={{ borderBottom: 'none', ...webStyle.tab1, }}>
                                                Credit 15
                                            </TableCell>
                                            <TableCell align="left" style={{ borderBottom: 'none', ...webStyle.tab1, }}>
                                                Credit 30
                                            </TableCell>
                                            <TableCell style={{ width: '1%', borderBottom: 'none' }} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow style={{ height: '30px' }} />

                                        <TableRow style={{ height: '60px', }}>
                                            <TableCell style={{ width: '1%', borderBottom: 'none', borderRight: '1px solid #E7E2F0' }} />

                                            <TableCell colSpan={8} style={{ ...webStyle.tab1, borderTop: '1px solid #E7E2F0', paddingLeft: '16px', backgroundColor: '#F3F4F6' }}>
                                                AAAA
                                            </TableCell>

                                            <TableCell colSpan={2} style={{ borderTop: '1px solid #E7E2F0', backgroundColor: '#F3F4F6' }}>
                                                <OrangeSwitch
                                                    checked={this.state.samePricing}
                                                    onChange={() => this.setState({ samePricing: !this.state.samePricing })}
                                                />
                                                <span style={{ marginLeft: '8px', fontWeight: 500 }}>Same Pricing for all Customer Levels</span>
                                            </TableCell>

                                            <TableCell style={{ width: '1%', borderBottom: 'none', borderLeft: '1px solid #E7E2F0' }} />
                                        </TableRow>

                                        <TableRow>
                                            <TableCell colSpan={1} style={{ width: '1%', borderBottom: 'none', borderRight: '1px solid #E7E2F0' }} />

                                            <TableCell colSpan={1} style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }} />
                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>From</TableCell>

                                            <TableCell colSpan={3} style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0', textAlign: 'center' }}>
                                                -
                                            </TableCell>
                                            <TableCell colSpan={5} style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>
                                                To
                                            </TableCell>

                                            <TableCell style={{ width: '1%', borderBottom: 'none', borderLeft: '1px solid #E7E2F0' }} />
                                        </TableRow>

                                        {/* MOQ Row */}
                                        <TableRow>
                                            <TableCell style={{ width: '1%', borderBottom: 'none', borderRight: '1px solid #E7E2F0' }} />

                                            <TableCell colSpan={3} style={{ ...webStyle.tab1, borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>
                                                MOQ
                                            </TableCell>

                                            <TableCell colSpan={2} style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>
                                                <CustomTextField
                                                    value="50" size="small" variant="outlined" style={{ ...webStyle.text1 }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>
                                            <CustomTextField
                                                    value="100" size="small" variant="outlined" style={{ ...webStyle.text1 }}
                                                />                                            </TableCell>

                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0', textAlign: 'center' }}>
                                                =
                                            </TableCell>

                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0' }}>
                                            <CustomTextField
                                                    value="35%" size="small" variant="outlined" style={{ ...webStyle.text1 }}
                                                />                                            </TableCell>
                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0', textAlign: 'center' }}>
                                            <CustomTextField
                                                    value="" size="small" variant="outlined" style={{ ...webStyle.text1 }}
                                                />                                            </TableCell>
                                            <TableCell style={{ borderBottom: 'none', borderTop: '1px solid #E7E2F0', }}>
                                            <CustomTextField
                                                    value="" size="small" variant="outlined" style={{ ...webStyle.text1 }}
                                                />                                            </TableCell>
                                            <TableCell style={{ width: '1%', borderBottom: 'none', borderLeft: '1px solid #E7E2F0' }} />
                                        </TableRow>
                                        {/* Dynamic Rows */}
                                        {this.state.bulkPricingRows.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell style={{ width: "1%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }} />

                                                <TableCell colSpan={2} style={{ borderTop: "1px solid #E7E2F0" }}>{index + 1}.</TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
                                                <CustomTextField
                                                   value={row.from} size="small"  placeholder="From" variant="outlined" style={{ ...webStyle.text1 }}
                                                   onChange={(e) => this.handleInputChange(row.id, 'from', e.target.value)}
                                                />    
                                                   
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0", textAlign: "center" }}>-</TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0", textAlign: "center" }}>
                                                <CustomTextField
                                                   value={row.to} size="small"   placeholder="To" variant="outlined" style={{ ...webStyle.text1 }}
                                                   onChange={(e) => this.handleInputChange(row.id, 'to', e.target.value)}
                                                />   
                                                  
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
                                                <CustomTextField
                                                   value={row.advancePrice} size="small"   placeholder="Price" variant="outlined" style={{ ...webStyle.text1 }}
                                                   onChange={(e) => this.handleInputChange(row.id, 'advancePrice', e.target.value)}
                                                />   
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0", textAlign: "center" }}>
                                                    =
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0" }}>
                                                <CustomTextField
                                                   size="small"
                                                   value={row.credit15}
                                                   placeholder="Credit 15"
                                                    variant="outlined" style={{ ...webStyle.text1 }}
                                                   onChange={(e) => this.handleInputChange(row.id, 'credit15', e.target.value)}
                                                />   
                                                   
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0", textAlign: "center" }}>
                                                <CustomTextField
                                                     size="small"
                                                     value={row.credit30}
                                                     style={{ ...webStyle.text1 }}
                                                     placeholder=""
                                                     variant="outlined"
                                                    onChange={(e) => this.handleInputChange(row.id, 'credit30', e.target.value)}
                                                />   
                                                   
                                                </TableCell>
                                                <TableCell style={{ borderTop: "1px solid #E7E2F0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <CustomTextField
                                                     size="small"
                                                     value={row.credit30}
                                                     style={{ ...webStyle.text1 }}
                                                     placeholder=""
                                                     variant="outlined"
                                                    onChange={(e) => this.handleInputChange(row.id, 'credit30', e.target.value)}
                                                />   
                                                    <IconButton onClick={() => this.handleDeleteRow(row.id)}>
                                                        <DeleteIcon style={{ color: '#DC2626' }} />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell style={{ width: '1%', borderBottom: 'none', borderLeft: '1px solid #E7E2F0' }} />
                                            </TableRow>
                                        ))}

                                        <TableRow>
                                            <TableCell style={{ width: "1%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>
                                            <TableCell colSpan={10} style={{ borderTop: "1px solid #E7E2F0", borderRight: "1px solid #E7E2F0" }} >
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <Button
                                                        startIcon={<img src={AddImg} alt="AddImg" />}
                                                        style={{ color: '#F4821E', fontSize: "16px", fontWeight: 700, textTransform: "none" }}
                                                        onClick={this.handleAddRow}
                                                    >
                                                        Add another Range
                                                    </Button>
                                                    <Button style={{ borderRadius: "8px", fontSize: "16px", fontWeight: 700, textTransform: "none", backgroundColor: '#FF6600', color: '#fff' }}>
                                                        Save
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "30px" }}></TableRow>
                                    </TableBody>

                                </Table>

                            </TableContainer>
                            <Box style={{ display: "flex", justifyContent: "flex-end", borderTop: "1px solid #E7E2F0", alignItems: "center", cursor: "pointer", height: "65px" }}>
                                <Box style={{ marginRight: "38px" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#FF6600",
                                            color: "#FFFFFF",
                                            fontFamily: "Inter, sans-serif",
                                            fontWeight: 700,
                                            fontSize: "16px",
                                            lineHeight: "24px",
                                            textTransform: "none",
                                            borderRadius: "12px",
                                            padding: "16px 24px 16px 24px",
                                            height: "40px",
                                            width: "87px",
                                            gap: "8px",
                                            cursor: "pointer"
                                        }}

                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <Usernavbar history={this.props.history} />
                    <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                        {this.MyProducts()}
                    </SideBar>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle: {
    [key: string]: {
        [property: string]: string | number;
    };
} = {
    MainContainer: {
        marginLeft: "47px",
        zIndex: 1
    },

    Cells1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842",
        backgroundColor: "#f3f4f6",
        fontFamily: "Inter, sans-serif",
    },
    TableHeads: {
        backgroundColor: "#f3f4f6",
        fontFamily: "Inter, sans-serif",
        fontWeight: 700,
    },
    Cells: {
        fontSize: "12px",
        fontWeight: 400,
        color: "#323842",
        fontFamily: "Inter, sans-serif",
    },
    ProductImg: {
        height: "377px",
        width: "387px",
        borderRadius: "12px",
        textAlign: "center",
        border: "1px solid #DEDFE3"
    },
    Productgroupimg: {
        marginTop: "10px",
        gap: "8px",
        display: "flex",
        width: "387px"
    },
    LbowImg: {
        height: "85px",
        borderRadius: "9px",
        width: "90px",
        textAlign: "center",
        border: "1px solid #DEDFE3"
    },
    img1: {
        height: "85px",
        width: "90px"
    },
    TBodys: {
        padding: "20px",
        fontFamily: "Inter, sans-serif",
    },
    bigImg1: {
        borderRadius: 8,
        height: "335px",
        width: "296px"
    },
    type: {
        fontWeight: 700,
        fontSize: "12px"
    },
    typet: {
        fontSize: "12px"
    },
    gr: {
        marginTop: "10px",
        fontFamily: "Inter, sans-serif",
    },
    TableCellss: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        backgroundColor: "#F3F4F6",
        textAlign: "left",
        color: "#565E6C",
        fontFamily: "Inter, sans-serif",
    },
    ctab: {
        width: '96%',
        marginTop: "40px",
        backgroundColor: '#fff',
        padding: "25px",
        borderRadius: '10px',
        border: '1px solid #eee',
        fontFamily: "Inter, sans-serif",
    },
    tab1: {
        fontWeight: 700,
    },
    text1: {
        width: 78,
        color: "#000000 !importent",
        background: "#F3F4F6",
        border: "none",
        outline: "none"
    }

};
// Customizable Area End