import React from "react";

import {
  Box,
  Typography,
  Button,
  // Customizable Area Start
  Grid
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import { uploadIcon } from "./assets";

const CompanyBlock = styled("div")(({ theme }) => ({
  cursor:"default",
  "& .gridBoxLeft":{
    padding:"0px 10px 0px 0px",
    marginBottom:24
   },
   "& .gridBoxRight":{
    padding:"0px 0px 0px 10px",
    marginBottom:24
   },
  "& .label-text": {
    fontSize: "14px",
    fontWeight: "700 !important",
    fontFamily: 'Inter, sans-serif',
    color:"#323842"
  },
  "& .input-data": {
    width: "570px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    marginTop: "10px",
    padding: "10px 8px",
    fontFamily:"Inter,sans-serif",
  },
  "& .input-data:focus": {
    borderColor: "#FF6600",
    outline: "none",
  },
  "& .input-data-company": {
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    marginTop: "4px",
    padding: "10px",
    pointerEvents: "none",
    backgroundColor: "#f4f4f4",
    color:"#BCC1CA",
    fontSize: "16px",
    fontWeight:400
  },
  "& .buttonIn": {
    position: "relative",
  },
  "& .input-data-text": {
    margin: "0px",
    outline: "none",
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    marginTop: "4px",
    padding: "10px 8px",
    backgroundColor:"#F3F4F6",
    fontSize:16,
    fontFamily:"Inter,sans-serif",
    fontWeight:400,
    color:"#BCC1CA",
  },
  "& .input-data-text:focus": {
    borderColor: "#FF6600",
    outline: "none",
  },

  "& .btn-data": {
    position: "absolute",
    right: "1px",
    borderRadius: "8px",
    zIndex: 2,
    border: "none",
    height: "44px",
    width:"113px",
    cursor: "pointer",
    color: "#FFFFFF",
    backgroundColor: "#FFA76C",
    padding: "6px 10px",
    top: "25px"
  },
  "& .upload-doc": {
    border: "1px solid #CBD5E1",
    height: "180px",
    borderRadius: "8px",
    marginTop: "3px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width:"49%",
    "@media (max-width:900px)": {
      width:"100%",
    },
  },
  "& .uploadBtn":{
    "@media (max-width:900px)": {
      "white-space": "nowrap"
    },
    
  },
  "& .file-size": {
    fontSize: "12px",
    fontWeight: 400,
    color: "#94A3B8"
  },
  "& .size-err": {
    fontSize: "13px",
    marginTop: "4px",
    color: "red",
  },
  "& .btn-data-active":{
    position: "absolute",
        top: "25px",
        right: "1px",
        width:"113px",
        borderRadius: "8px",
        zIndex: 2,
        border: "none",
        height: "44px",
        cursor: "pointer",
        color: "#FFFFFF",
        backgroundColor: "#FFA76C",
        padding:"6px 10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "7px",
        fontSize:"14px",
        fontWeight:400,
        "@media (max-width:900px)": {
          width:"80px",
          right:"0px",
          marginRight:"-40px",
          fontSize:"11px",
        },
  }
}))
// Customizable Area End

import CompanyInfoController, {
  Props,
} from "./CompanyInfoController";

export default class CompanyInfo extends CompanyInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <CompanyBlock>
        <Grid container spacing={2} data-test-id="main-div">
          <Grid item lg={6} md={6} sm={12} xs={12} className="gridBoxLeft">
            <div className="buttonIn">
              <Typography className="label-text">GSTIN / PAN Verification<span style={{ color: "red" }}>&nbsp;*</span></Typography>
              <input className="input-data-text" type="text" id="enter"/>
              {
              this.state.isVerified ? <button className="btn-data-active" id="verified"><svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 4L4.5 7.5L11 1" stroke="white" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="square"/>
              </svg>
               Verified</button>
              : <button className="btn-data" id="clear" onClick={()=>{this.setState({isVerified:true, isGstin:''})}}>Verify</button>
              }
              <Typography className="size-err">{this.state.isGstin}</Typography>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12} className="gridBoxRight">
            <Box>
              <Typography className="label-text">Company Name / Person Name</Typography>
              <input className="input-data-company" type="text" value={this.props.companyName} placeholder="Company Name / Person Name"></input>
            </Box>
          </Grid>
        </Grid>

        <Box style={{marginLeft:"-10px"}}>
          <Typography className="label-text">Upload Document<span style={{ color: "red" }}>*</span></Typography>
          <Box className="upload-doc">
            {this.state.uploadCertificate.file ? (<Typography>{(this.state.uploadCertificate.file as File)?.name}</Typography>) : (
              <>
                <img src={uploadIcon} alt="uploadIcon" />
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    style={{ textTransform: "lowercase", color: "#FF6600" }}
                    component="label"
                    className="uploadBtn"
                  >
                    <span style={{ textTransform: "uppercase" }}>C</span>lick to <span style={{ textTransform: "uppercase", marginLeft: "6px" }}>U</span>pload
                    <input
                      id={`fileInput`}
                      data-test-id="file-input"
                      type="file"
                      accept=".pdf"
                      onChange={this.onFileChange}
                      hidden
                    />
                  </Button>
                  <Typography variant="subtitle2" style={{ paddingRight: '10px' }}>or</Typography>
                  <Box>
                    <div
                      data-test-id="fileDragOrBrowse"
                      onDragOver={this.handleDragOver}
                      onDragLeave={this.handleDragLeave}
                      onDrop={this.handleDrop}
                      style={{ fontSize: "0.875rem", fontWeight: 500, height: "48px", display: "flex", alignItems: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', marginLeft:"-5px"}}
                    >
                      Drag and Drop
                    </div>
                  </Box>
                </Box>
                <Typography className="file-size"> (Max. File size: 25 MB)</Typography>
              </>
            )}
          </Box>
          <Typography className="size-err">{this.state.sizeErr}</Typography>
        </Box>
      </CompanyBlock>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End