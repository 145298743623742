import React from "react";

import {
  // Customizable Area Start
  Backdrop,
  Box,
  Button,
  Fade,
  MenuItem,
  Modal,
  Select,
  styled,
  TextField,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import CatalougesController, {
  Props
} from "./CatalougesController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import { Deleteimg, UploadImg } from "./assets";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const StyledContainer = styled(Box)({
  width: "94%",
  margin: "0px 20px 0px 45px",
})

const StyledError=styled(Typography)({
  color: "#DC2626 !important",
    fontSize: "12px",
    paddingTop: "4px",
    paddingLeft: "7px"
})

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)', 
  borderRadius: '12px',
  zIndex: 1, 
});

const StyledSelect = styled(Select)({
  color:"#BCC1CA",
  '& .MuiSelect-select:focus': {
    backgroundColor: 'transparent !important',
  },
  "& .MuiSelect-icon": {
    right: 10
  }
});

const StyledModal =styled(Modal)({
 "& .modalTitle":{
  fontWeight: 700,
  fontSize: "30px",
  fontFamily:"Inter, sans-serif",
  "@media(max-width:900px)":{
    fontSize: "15px",
  }
 },
 "& .headerTitle":{
  display: "flex",
  justifyContent: "space-between",
  padding: "24px 24px 24px 24px",
  borderBottom: "1px solid #E2E8F0",
  "@media(max-width:900px)":{
    padding: "15px !important",
  }
 },
 "& .fieldBox":{
  padding: "24px 24px 0px 24px",
  "@media(max-width:900px)":{
    padding: "15px !important",
  }
 },
 "& .modalContainer":{
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 708,
  backgroundColor: "#ffffff",
  borderRadius: "8px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  "@media(max-width:900px)":{
    width: "85vw !important",
  }
 },
 "& .deleteIcon":{
  width: "14px", height: "14px", cursor: "pointer", margin: "auto 0px"
 },
 "& .selectInput":{
  background: "#F3F4F6",
  width: "100%",
  borderRadius: "8px",
  padding: "6px 8px"
 }
})
// Customizable Area End

export default class Catalouges extends CatalougesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  Catalougedata = () => {
    return (
      <StyledContainer>
        <Box style={styles.header}>
          <Typography variant="h5" style={styles.heading}>
            Brand Catalogue
          </Typography>
          <Button
            style={styles.checkoutButton}
            onClick={this.handleOpenModal}
            data-testId="MapProduct"
          >
            Add New Catalogue
          </Button>
        </Box>
        <Box
          data-testId="Catalouges"
          style={styles.catalogueContainer}
          onClick={this.navigateToCreditnoteDetail}
        >
{this.state.listBrandCatalogueVariation.length > 0 ? this.state.listBrandCatalogueVariation.map((catalogue, index) => (
  <Box 
    key={index} 
    style={{ 
      position: 'relative',
       borderRadius: 12,
      overflow: 'hidden',  
      height: "120px",
      width: "130px",
    }}
  >
    <img
      src={catalogue.attributes.image.url || ""}
      alt={catalogue.attributes.name}
      style={{  
        width: '100%',
        height: '100%', 
        objectFit: 'cover', 
        display: 'block',
      }}
    />
    <Overlay />
    <Typography 
      title={catalogue.attributes.name} 
      style={{
        position: 'absolute',
        bottom: 16,
        left: 16,
        fontWeight: 400,
        fontSize: "12px",
        textAlign: "center" as "center",
        fontFamily:"Inter, sans-serif",
        width: "75px",
        color: "white",
        zIndex: 2, 
        textOverflow:"ellipsis",
        overflow:"hidden" as "hidden",
        whiteSpace: "nowrap" as "nowrap"
      }}
    >
      {catalogue.attributes.name}
    </Typography>
  </Box>
)) : <>No Brand Catalogue Found</>}
        </Box>
        <StyledModal
          open={this.state.openModal}
          onClose={this.handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={this.state.openModal}>
            <Box
              className="modalContainer"
            >
              <Box
                className="headerTitle"
              >
                <Typography className="modalTitle">
                  Add New Catalogue
                </Typography>
                <img
                  src={Deleteimg}
                  alt="Close Modal"
                  onClick={this.handleCloseModal}
                  className="deleteIcon"
                />
              </Box>
              <Box
                className="fieldBox"
              >
                <label
                  htmlFor="catalogue-field"
                  style={{
                    display: "block",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#1E293B",
                    marginBottom: "8px",
                    fontFamily:"Inter, sans-serif",

                  }}
                >
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <TextField
                  required
                  value={this.state.catalogueName}
                  onChange={this.handleCatalogueNameChange}
                  inputProps={{ "data-testid": "Name-input" }}
                  data-test-id="nameInput"
                  placeholder="Catalogue 14"
                  style={{
                    width: "100%",
                    height: "44px",
                    borderRadius: "8px",
                    background: "#F3F4F6",
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      padding: "10px",
                      fontSize: "14px",
                      color: "#1E293B",
                    },
                  }}
                />
                {this.state.errors.catalogueName && <StyledError className="errorText">{this.state.errors.catalogueName}</StyledError>}
              </Box>
              <Box
                 className="fieldBox"
              >
                <label
                  htmlFor="catalogue-field"
                  style={{
                    display: "block",
                    fontSize: "14px",
                    fontWeight: 700,
                    color: "#1E293B",
                    marginBottom: "8px",
                    fontFamily:"Inter, sans-serif",
                  }}
                >
                  Category<span style={{ color: "red" }}>*</span>
                </label>
                <StyledSelect
                  value={this.state.category}
                  onChange={this.handleCategoryChange}
                  data-test-id="selectCategory"
                  displayEmpty
                  inputProps={{
                    placeholder: "Select Product Criteria",
                    style: {
                      fontWeight: 400,
                      fontSize: "16px",
                      fontFamily:"Inter, sans-serif",
                    },
                  }}
                  className="selectInput"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        border: "1px solid #CBD5E1",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  disableUnderline
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="" disabled selected> Select Category</MenuItem>
                  {this.state.listCategory?.data?.length >0 && this.state.listCategory.data.map((item) => (
                   <MenuItem value={item.attributes.id}>{item.attributes.name}</MenuItem>
                  ))}
                </StyledSelect>
                {this.state.errors.category && <StyledError className="errorText">{this.state.errors.category}</StyledError>}
              </Box>
              <Box className="fieldBox">
              <label
              htmlFor="catalogue-field"
              style={{
                display: "block",
                fontSize: "14px",
                fontWeight: 700,
                color: "#1E293B",
                marginBottom: "8px",
                fontFamily:"Inter, sans-serif",
              }}
            >
              Upload Image <span style={{ color: "red" }}>*</span>
              </label>
              <Box
                style={{
                  height: "256px",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#F3F4F6",
                  borderBottom: "1px solid #E2E8F0",
                }}
              > 
                <input
                  type="file"
                  id="upload-file"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={this.handleFileUpload}
                  data-testId="product-brand-image"
                /> {this.state.uploadedFile && (
                  <Typography
                    style={{
                         fontFamily:"Inter, sans-serif",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginLeft: "10px",
                      color: "blue",
                      paddingBottom: "10px",
                    }}
                  >
                    {this.state.uploadedFile.name}
                  </Typography>
                )}
                <label htmlFor="upload-file">
                  <img src={UploadImg} alt="Upload" style={{ cursor: "pointer" }} />
                </label>
                <br />
                <Typography
                  style={{
                    fontFamily:"Inter, sans-serif",
                    marginTop: "3px",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  OR
                </Typography>
                <Box style={{ display: "flex", marginLeft: "7px" }}>
                  <label htmlFor="upload-file" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                    <Typography
                      component="span"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "#FF6600",
                           fontFamily:"Inter, sans-serif",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginTop: "8px",
                      }}
                    >
                      Browse Files
                    </Typography>
                  </label>
                  <input
                    type="file"
                    id="upload-file"
                    style={{ display: "none" }}
                    onChange={this.handleFileUpload}
                    accept="image/*"
                  />
                </Box>
              </Box>
              {this.state.errors.image && <StyledError className="errorText">{this.state.errors.image}</StyledError>}
              </Box>
              <Box
                style={{
                  padding: "24px",
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "1px solid #E2E8F0",
                }}
              >
                <Button
                  variant="contained"
                  data-test-id="addNewVariation"
                  style={{
                    backgroundColor: "#FF6600",
                    color: "#fff",
                    padding: "8px 30px",
                    borderRadius: "8px",
                    fontWeight: 700,
                    fontSize: "14px",
                    boxShadow: "none"
                  }}
                  onClick={this.handleAdd}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Fade>
        </StyledModal>
      </StyledContainer>
    );
  }


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Usernavbar />
        <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
          {this.Catalougedata()}
        </SideBar>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0px",
  },
  heading: {
       fontFamily:"Inter, sans-serif",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
  },
  checkoutButton: {
    backgroundColor: "#ff6600",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color: "white",
    borderRadius: "13px",
    height: "44px",
    width: "198px",
    textTransform: "none" as const,
    boxShadow: "none",
       fontFamily:"Inter, sans-serif",
  },
  Addbutton: {
    backgroundColor: "#FF6600",
    color: "white",
    align: "right",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily:"Inter, sans-serif",
    boxShadow: "none",
    textTransform: "none" as const,
    borderRadius: "12px"
  },
  catalogueContainer: {
    display: "flex",
    flexWrap: "wrap" as const,
    border: "1px solid #E2E7Ef",
    borderRadius: "8px",
    padding: "30px",
    gap: "20px",
  },
  catalogueItem: {
    height: "120px",
    width: "130px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #DEE1E633",
    backgroundColor: "#FBFBFB",
    boxShadow: "none",
    borderRadius: "8px",
  },
  catalogueText: {
    fontWeight: 400,
    fontSize: "12px",
    textAlign: "center" as "center",
    fontFamily:"Inter, sans-serif",
    padding: "90px 31px 10px 31px",
    width: "75px",
    color: "white",
    position: 'relative' as "relative", 
    zIndex: 2, 
    textOverflow:"ellipsis",
    overflow:"hidden" as "hidden",
    whiteSpace: "nowrap" as "nowrap"
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    padding: "24px",
    width: "708px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #E2E8F0",
    paddingBottom: "16px",
    marginBottom: "16px",
  },
  formField: {
    marginBottom: "16px",
    width: "100%",
  },
  uploadBox: {
    height: "256px",
    border: "1px dashed #cccccc",
    textAlign: "center" as React.CSSProperties["textAlign"],
    borderRadius: "8px",
    color: "#555555",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#f9f9f9",
    width: "660px",
    display: "flex",
    flexDirection: "column" as React.CSSProperties["flexDirection"],
    justifyContent: "center",
    alignItems: "center",
  },
  uploadButton: {
    display: "block",
    backgroundColor: "#F6AD55",

    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DD6B20",
    },
  },
  addButton: {
    marginTop: "24px",
    backgroundColor: "#F6AD55",
    color: "#ffffff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#DD6B20",
    }
  }
}
// Customizable Area End
