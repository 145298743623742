import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message"
import { MouseEvent } from 'react'

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sidebarToggle: boolean;
  openDialog: boolean;
  activeComponent: string;
  anchorEl: HTMLElement | null;
  error:any;
  value: number | 0;
  editData:any;
  Codes:any;
  totalResults: number;
  resultperPage: number;
  currentPage: number;
  openSuccessModal: boolean;
  brandsData: any;
  productTag:any;
  selectedItemId:any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class BrandsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLogisticalListApiCallId: any;
  deleteLogisticalListApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      sidebarToggle: false,
      openDialog: true,
      Codes:[],
      editData:"",
      error:"",
      activeComponent: "Company",
      productTag:"",
      anchorEl: null,
      value: 12,
      totalResults: 140,
      resultperPage: 12,
      brandsData: [],
      selectedItemId:"",
      currentPage: 1,
      openSuccessModal: false,
      // Customizable Area End

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start  
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start  
    await super.componentDidMount();
    this.getLogisticalListAPICall();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (apiRequestCallId === this.getLogisticalListApiCallId) {
        this.handleGetLogisticalResponse(responseJson);
      }
      else if(apiRequestCallId === this.deleteLogisticalListApiCallId){
        this.handleDeleteLogisticalResponse(responseJson);
      }

    }
  }

  handleChange = (event:any) => {
    this.setState({ productTag: event.target.value });
  };
  

  handleDeleteLogisticalResponse(responseJson: any) {
    if (responseJson) {
     this.setState({ anchorEl: null });
     this.getLogisticalListAPICall();
    }
  }

  handleGetLogisticalResponse(responseJson: any) {
    if (responseJson) {
      const { count, items, page } = responseJson.meta?.pagy || {};
      console.log()
      this.setState({
        brandsData: responseJson?.data,
        totalResults: count || 0,
        resultperPage: items || 12,
        currentPage: page || 1,
      })
    }
  }


  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value });
    this.getLogisticalListAPICall(value)
  };

  getResultText = (page: number): string => {
    const { resultperPage, totalResults } = this.state;
    const start = (page - 1) * resultperPage + 1;
    const end = Math.min(start + resultperPage - 1, totalResults);
    return `${start}-${end} of ${totalResults} results`;
  }

  handleMenuClick = (event: MouseEvent<HTMLElement>,itemId:any,product:any,code:any) => {
    let selectedLogId = null;
    if (Array.isArray(code) && code.length > 0) {
      selectedLogId = code[0].id; // ya koi specific logic
    }
    this.setState({ anchorEl: event.currentTarget, selectedItemId: itemId, editData:product,Codes:selectedLogId});
  };
  
  handleOpen = () => {
    this.setState({ openSuccessModal: true });
  };
  handleClose= () => {
    this.setState({ openSuccessModal: false });
  };
  navigateToAddNewLogistical = () => {
    sessionStorage.setItem("productTag", this.state.productTag);
    sessionStorage.setItem("codes",this.state.Codes)
    sessionStorage.setItem('editdata',this.state.editData)
    sessionStorage.setItem('itemId',this.state.selectedItemId)
    if (!this.state.productTag.trim() && !this.state.editData) {
      this.setState({ error: true });
      return;
    }
    const LogisticalCode = '/AddLogistical';
    window.location.href = LogisticalCode;
  };

  getLogisticalListAPICall = async (page=1) => {

    const header = {
      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getLogisticalListApiCallId = requestMessage.messageId;
    const queryParams = `?page=${page}&size=${this.state.resultperPage}`;
    const endPoint = `${configJSON.getLogisticalListApiEndPoint}${queryParams}`;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteLogisticalListAPICall = async () => {

    const header = {
      token: await getStorageData("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteLogisticalListApiCallId = requestMessage.messageId;
    const endPoint = `${configJSON.getLogisticalListApiEndPoint}/${this.state.selectedItemId}`;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
